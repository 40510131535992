import cloneDeep from "lodash/cloneDeep";
import { useExperienceConfig } from "PFApp/hooks/use_experience_config";
import { useExperienceLabelMap } from "PFApp/hooks/use_experience_label_map";
import { useGrowl } from "PFApp/use_growl";
import { CustomValuePill } from "PFComponents/custom_value_pill";
import { useCustomValueAriaLabel } from "PFComponents/custom_value_pill/hooks/use_custom_value_aria_label";
import { DropdownButton } from "PFComponents/dropdown_button";
import { useDictionaryConnectionCreate } from "PFCore/hooks/queries/custom_values/use_dictionary_connection_create";
import { useCurrentProfile, useCurrentProfileSet } from "PFCore/hooks/queries/profile";
import { fetchProfileCustomValue } from "PFCore/services/custom_values";
import { Experience, Id, ProfileCustomValue } from "PFTypes";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import css from "./editable_custom_value_pill.module.scss";

type EditableCustomValuePillProps = {
  id: Id;
  name: string;
  experience: Experience;
  requiredExperience: Experience;
  customTypeName: string;
  customTypeAriaLabel?: string;
  portalElement?: HTMLDivElement;
  onChange?: (customValue: ProfileCustomValue) => void;
};

export const EditableCustomValuePill = ({
  id,
  name,
  experience,
  requiredExperience,
  customTypeName,
  customTypeAriaLabel,
  portalElement,
  onChange
}: EditableCustomValuePillProps) => {
  const { t } = useTranslation("profiles", { keyPrefix: "show.parts.skillsModal.suggestions.framework" });
  const { t: tTranslation } = useTranslation();
  const { experienceLevels } = useExperienceConfig();
  const experienceLabelMap = useExperienceLabelMap();
  const { data: currentProfile } = useCurrentProfile();
  const growl = useGrowl();
  const ariaLabel = useCustomValueAriaLabel({
    name,
    experience,
    requiredExperience,
    customTypeAriaLabel,
    customClickActionText: t("selectYourProficiency")
  });
  const { mutateAsync: createDictionaryConnection } = useDictionaryConnectionCreate();

  const setCurrentProfile = useCurrentProfileSet();

  const [loading, setLoading] = useState(false);

  const updateCurrentProfileCustomValues = (
    fullCustomValue: ProfileCustomValue,
    newExperience: Experience
  ) => {
    const customValueIndex = currentProfile[customTypeName].findIndex(
      (profileCustomValue) => fullCustomValue.id === profileCustomValue.id
    );
    const profileCustomValues = cloneDeep(currentProfile[customTypeName]);
    if (customValueIndex >= 0) {
      profileCustomValues[customValueIndex].experience = newExperience;
    } else {
      profileCustomValues.push({ ...fullCustomValue, experience: newExperience });
    }

    setCurrentProfile({ ...currentProfile, [customTypeName]: profileCustomValues });
  };

  const handleExperienceChange = (experience: Experience) => {
    setLoading(true);
    createDictionaryConnection({
      experience,
      interest: true,
      source: "skill_suggestion",
      customValueId: id
    })
      .then(() => fetchProfileCustomValue(id, currentProfile.id))
      .then((fullCustomValue) => {
        updateCurrentProfileCustomValues(fullCustomValue, experience);
        onChange?.(fullCustomValue);
        setLoading(false);
        growl({ message: tTranslation("customValueSidepanel.saveSuccess", { value: name }) });
      })
      .catch(() => {
        growl({
          kind: "error",
          message: tTranslation("customValueSidepanel.saveError", { value: name })
        });
      });
  };

  const options = experienceLevels.map((experience) => ({
    id: experience,
    item: experience,
    displayElement: experienceLabelMap[experience]
  }));

  return (
    <DropdownButton
      options={options}
      buttonKind="blank"
      buttonClassName={css.buttonWrapper}
      aria-label={ariaLabel}
      disabled={loading}
      handleChange={handleExperienceChange}
      popperOptions={{
        placement: "bottom-start"
      }}
    >
      <CustomValuePill
        name={name}
        experienceLevels={experienceLevels}
        experience={experience}
        requiredExperience={requiredExperience}
        tooltipAppendTo={portalElement}
      />
    </DropdownButton>
  );
};
