import {
  AllocationWindowRolesParams,
  AllocationWindowsParams
} from "PFCore/services/admin/target_allocation";
import { Id } from "PFTypes";

const ROOT_KEY = "allocation_windows";

export const allocationWindowsKeys = {
  all: () => [ROOT_KEY, "all"],
  byPage: ({ page, perPage, sort, termValue }: AllocationWindowsParams) => [
    ...allocationWindowsKeys.all(),
    "byPage",
    perPage,
    page,
    sort,
    termValue
  ],
  single: (allocationWindowId: Id) => [ROOT_KEY, "single", allocationWindowId],
  allRoles: () => [ROOT_KEY, "roles"],
  rolesByPage: (params: AllocationWindowRolesParams) => [ROOT_KEY, "roles", params]
};
