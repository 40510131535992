import { useOptionalTranslation } from "PFCore/hooks/use_optional_translation";
import { useCallback } from "react";

import { RoleWatcher } from "../../../../../../types/watchers";
import useFormatColumnValue from "./use_format_column_value";

const useFormatWatchersToCsv = (columns) => {
  const { optionalT } = useOptionalTranslation();
  const getFormattedValue = useFormatColumnValue();
  const exportableColumns = columns.filter((column) => column.name !== "shortlist");

  return useCallback(
    (watchers: RoleWatcher[]) =>
      watchers.map((watcher) =>
        exportableColumns.reduce(
          (formattedWatcher, column) => ({
            ...formattedWatcher,
            [optionalT(column.title)]: getFormattedValue(watcher, column)
          }),
          {}
        )
      ),
    [columns]
  );
};

export default useFormatWatchersToCsv;
