import classNames from "classnames";
import { camelizeKeys } from "humps";
import compact from "lodash/compact";
import isEmpty from "lodash/isEmpty";
import isString from "lodash/isString";
import toString from "lodash/toString";
import { ActionDiv } from "PFComponents/containers";
import { Flex, Stack, StackProps } from "PFComponents/containers/flex";
import { IconSize, IconSizeTable } from "PFComponents/icon";
import { ProfileAvatar } from "PFComponents/profile_avatar/profile_avatar";
import { SuspendedPill } from "PFComponents/suspended_pill";
import { Typography } from "PFComponents/typography";
import { getJobTitle, getProfileName } from "PFCore/helpers/profile";
import { BasicProfile, Profile, ProfileMinimized } from "PFTypes";
import { useCallback } from "react";

import css from "./profile_item.module.scss";

type ProfileItemProps = {
  className?: string;
  profile: BasicProfile | Profile | ProfileMinimized;
  style?: React.CSSProperties;
  avatarSize?: IconSize;
  displayNameOnly?: boolean;
  displayAvatar?: boolean;
  displayJobTitle?: boolean;
  displayEmail?: boolean;
  displaySuspendedDate?: boolean;
  displaySuspendedDateBelow?: boolean;
  isHighlighted?: boolean;
  highlightedClassName?: string;
  rootFlexProps?: StackProps;
  onClick?: VoidFunction;
};

const calculateJobTitle = (profile: BasicProfile | Profile): string => {
  const { jobTitle } = profile as BasicProfile;
  const { positions } = profile as Profile;

  if (isString(jobTitle)) {
    return jobTitle;
  }

  if (positions) {
    return toString(getJobTitle(profile as Profile));
  }

  return "";
};

export const ProfileItem = ({
  className,
  profile,
  style,
  avatarSize,
  displayNameOnly,
  displayAvatar = true,
  displayJobTitle = true,
  displayEmail = true,
  displaySuspendedDate = false,
  displaySuspendedDateBelow = true,
  isHighlighted,
  highlightedClassName,
  rootFlexProps = {},
  onClick
}: ProfileItemProps) => {
  const camelizedProfile = camelizeKeys(profile) as BasicProfile | Profile; // TODO: [PROF-5746] Remove "camelizeKeys" when all ProfileItem usages get camelized profile prop
  const { text, suspendedAt } = camelizedProfile as BasicProfile;
  const name = text || getProfileName(camelizedProfile);
  const calculatedJobTitle = calculateJobTitle(camelizedProfile);
  const title = compact([name, camelizedProfile.email]).join(" ");
  const additionalInfo = !displayNameOnly
    ? compact([displayJobTitle && calculatedJobTitle, displayEmail && camelizedProfile.email])
    : [];

  const WrapperComponent = useCallback(
    ({ children }) =>
      onClick ? (
        <ActionDiv onClick={onClick} aria-label={title}>
          {children}
        </ActionDiv>
      ) : (
        <>{children}</>
      ),
    [onClick, title]
  );

  const displayProfileAvatar = displayAvatar && (profile.avatar || !!suspendedAt);
  const displaySuspendedPill = !displayNameOnly && !!displaySuspendedDate && !!suspendedAt;

  return (
    <WrapperComponent>
      <Stack className={classNames(css.root, className)} {...(rootFlexProps ?? {})}>
        <Flex alignItems="center" justifyContent="start" style={style} title={title}>
          {displayProfileAvatar && (
            <ProfileAvatar
              profile={camelizedProfile as ProfileMinimized}
              size={avatarSize ? IconSizeTable[avatarSize] : 40}
              isHighlighted={isHighlighted}
              highlightedClassName={highlightedClassName}
            />
          )}
          <div
            className={classNames(css.dropdownItem, { [css.noAvatar]: !profile.avatar || !displayAvatar })}
          >
            <Typography clipOverflow variant="bodyBold">
              {name}
            </Typography>
            {!isEmpty(additionalInfo) && (
              <Typography clipOverflow variant="labelRegular" tag="p">
                {additionalInfo.join(" ")}
              </Typography>
            )}
            {displaySuspendedPill && !displaySuspendedDateBelow && (
              <SuspendedPill suspendedAt={suspendedAt} className={css.suspended} />
            )}
          </div>
        </Flex>
        {displaySuspendedPill && !!displaySuspendedDateBelow && (
          <SuspendedPill suspendedAt={suspendedAt} className={css.suspended} />
        )}
      </Stack>
    </WrapperComponent>
  );
};
