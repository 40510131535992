import { Booking, BookingCategory } from "PFTypes";
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";

import { BookingDetailSidePanelProfile } from "../../parts/overview/detail_view/booking_detail/booking_detail_side_panel_context/booking_detail_side_panel_context_provider";
import { useDetailsPanelApiContext } from "../details_panel";
import { Requirement } from "./requirement_selector";
import { OnSuccessData } from "./use_handle_submit/use_handle_submit";

export interface FormBooking extends Partial<Omit<Booking, "start_date" | "end_date">> {
  startDate: string;
  endDate: string;
}

export enum BookingFormMode {
  Create = "create",
  Edit = "edit",
  CloneSingle = "cloneSingle",
  CloneAll = "cloneAll"
}

export type BookingFormDataCreateMode = {
  profileId?: number;
  activityId?: number;
  shortlistId?: number;
  bookingCategoryId?: number;
  groupBookingData?: {
    searchId: number;
    total: number;
  };
  bookings?: { startDate: string; endDate: string }[];
};

export type BookingFormDataEditOrCloneMode = {
  bookingId: number;
  suspendedProfileDetails?: BookingDetailSidePanelProfile;
};

export type BookingFormData = BookingFormDataCreateMode | BookingFormDataEditOrCloneMode;

interface FormPropsType {
  onSuccess?: (data?: OnSuccessData) => Promise<void> | void;
  mode?: BookingFormMode;
}

interface FormDataStateType {
  isOpen: boolean;
  data?: BookingFormData;
  props?: FormPropsType;
}

export type BookingFormType = {
  state: FormDataStateType;
  open: (data?: BookingFormData, props?: FormPropsType) => void;
  close: () => void;
  refreshData: Dispatch<SetStateAction<FormDataStateType>>;
};

export enum BookingDataItemLimitation {
  None = "none",
  Readonly = "readonly",
  Hidden = "hidden"
}

export type RepeatDaysData = {
  selectedWeekDays: number[];
  allowedWeekDays: number[];
};

export type BookingDataItem = {
  key: string;
  startDate: string;
  endDate: string;
  requirement: Requirement;
  limitation: BookingDataItemLimitation;
} & Partial<{
  id: number;
  bookingTemplateId: number;
  phaseSourceId: string | undefined;
  startTime: string;
  endTime: string;
  repeatDays: RepeatDaysData | undefined;
  overridesDiaryTime: boolean;
  overridesNonWorkingTime: boolean;
  category?: BookingCategory | null;
  title: string;
  description: string;
}>;

type UseBookingFormReturn = {
  open: (data: BookingFormData, props?: FormPropsType) => void;
  close: () => void;
  refreshData: Dispatch<SetStateAction<FormDataStateType>>;
  state: FormDataStateType;
};

export const useBookingForm = (): UseBookingFormReturn => {
  const [formData, setFormData] = useState<FormDataStateType>({ isOpen: false });

  const { pushToOrderStack, popFromOrderStack } = useDetailsPanelApiContext();

  const open = useCallback((data: BookingFormData, props?: FormPropsType) => {
    setFormData({ isOpen: true, data, props });
    const uniqueId = new Date().getTime();
    pushToOrderStack("activityId" in data ? data.activityId ?? -1 : -1, "booking_form", uniqueId);
  }, []);

  const close = useCallback(() => {
    setFormData({ isOpen: false });
    popFromOrderStack();
  }, []);

  return useMemo(() => ({ open, close, refreshData: setFormData, state: formData }), [formData]);
};
