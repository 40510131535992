import { camelizeKeys } from "humps";
import { useAppContext } from "PFApp/app_context";
import { PROFILE_PREVIEW_ACTIONS } from "PFReducers/profile_preview_reducer";
import { Profile, ProfilePreview } from "PFTypes";
import { useCallback } from "react";

export const useProfilePreview = () => {
  const {
    dispatch,
    store: { profilePreview }
  } = useAppContext();

  const showPreview = useCallback(
    (payload: Pick<ProfilePreview, "id" | "profile" | "showBookingButtons">) => {
      const camelizedProfile = camelizeKeys(payload.profile); // TODO: [SP-3359] Remove "camelizeKeys" when Profile type is camelized
      if (camelizedProfile?.suspendedAt) {
        return;
      }
      return dispatch({
        type: PROFILE_PREVIEW_ACTIONS.PROFILE_PREVIEW_SHOW,
        payload
      });
    },
    [dispatch]
  );

  const hidePreview = useCallback(
    () => dispatch({ type: PROFILE_PREVIEW_ACTIONS.PROFILE_PREVIEW_HIDE }),
    [dispatch]
  );

  const unmountPreview = useCallback(
    () => dispatch({ type: PROFILE_PREVIEW_ACTIONS.PROFILE_PREVIEW_UNMOUNT }),
    [dispatch]
  );

  const completePreview = useCallback(
    (profile: Profile) =>
      dispatch({ type: PROFILE_PREVIEW_ACTIONS.PROFILE_PREVIEW_DONE, payload: { profile } }),
    [dispatch]
  );

  return { showPreview, hidePreview, unmountPreview, completePreview, previewState: profilePreview };
};
