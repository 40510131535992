import { CalendarPillProps, CalendarPillValue } from "PFComponents/double_section_pill/calendar_pill";
import { CustomValue, Id } from "PFTypes";
import { Fragment, HTMLAttributes, SyntheticEvent } from "react";

import { BadgeKind } from "../../../badges_list/simple_badge";
import { AutoSelectValueCalendar } from "./auto_select_value_calendar";
import { AutoSelectValueGeneric } from "./auto_select_value_generic";

export type RemoveHandler = (value: SelectValue, event: SyntheticEvent) => void;

export type AutoSelectValueGenericContentProps = {
  value: SelectValue;
  locked?: boolean;
  displayValue: string;
};

export type AutoSelectValueProps = AutoSelectValueGenericContentProps & {
  hasValuesWithExpiryDate?: boolean;
  multi?: boolean;
  suffixElement?: Pick<HTMLAttributes<HTMLDivElement>, "className"> & { text: string };
  onRemove: RemoveHandler | CalendarPillProps["onRemove"];
  onDateChange?: (item: CustomValue, event: SyntheticEvent) => void;
};

export type SelectValue = {
  item: Record<string, unknown> & { kind?: BadgeKind; locked?: boolean };
  id: Id;
  displayElement?: string | number;
  text?: string | number;
  value?: string | number | CalendarPillValue;
};

export const AutoSelectValue = ({
  hasValuesWithExpiryDate,
  suffixElement,
  onRemove,
  ...props
}: AutoSelectValueProps) => {
  const { value } = props;

  let displayValue = value.displayElement || value.text || value.value || "";

  if (value.displayElement === 0 || value?.text === 0 || value?.value === 0) {
    displayValue = 0;
  }

  if (hasValuesWithExpiryDate) {
    return (
      <AutoSelectValueCalendar
        {...props}
        value={value as CalendarPillValue}
        displayValue={displayValue as string}
        onRemove={onRemove as CalendarPillProps["onRemove"]}
      />
    );
  }

  return (
    <Fragment>
      <AutoSelectValueGeneric
        {...props}
        onRemove={onRemove as RemoveHandler}
        displayValue={displayValue as string}
      />
      {suffixElement && <div className={suffixElement.className}>{suffixElement.text}</div>}
    </Fragment>
  );
};
