import { getVisibleProperties } from "PFCore/helpers/templates";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { Subtemplate } from "PFTypes";
import { useMemo } from "react";

import { useActivityEditPageContext } from "../../activity_edit_page_context";
import { shouldDisplayProperty } from "../../utils/should_display_property";

export const useFilteredMetadataProperties = (
  subtemplate: Subtemplate | undefined,
  select: "match" | "segment" | "attribute"
) => {
  const { data: currentProfile } = useCurrentProfile();
  const { customTypes } = useCustomTypes();
  const { template } = useActivityEditPageContext();

  return useMemo(() => {
    const visibleProperties = getVisibleProperties(subtemplate || template);

    return visibleProperties.filter((property) => {
      if (
        (select === "match" && !property.match) ||
        (select === "segment" && !property.segment) ||
        (select === "attribute" && (property.match || property.segment))
      ) {
        return false;
      }

      if (property.type === "custom_field") {
        return shouldDisplayProperty(property, customTypes, currentProfile);
      }

      return true;
    });
  }, [subtemplate, template, select, customTypes, currentProfile]);
};
