import filter from "lodash/filter";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import keys from "lodash/keys";
import map from "lodash/map";
import omit from "lodash/omit";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import LazyLocalExportTrigger from "PFApp/components/document_buttons/lazy_local_export_trigger";
import FiltersButton from "PFApp/components/filters/filters_button";
import { SearchEntity } from "PFApp/components/search_autocomplete/search_autocomplete.types";
import { SearchInput } from "PFApp/components/search_input";
import { getAPIFilters } from "PFApp/use_filtered_collection";
import { TablePropertiesButton } from "PFApp/workflow/parts/table_properties";
import { Button } from "PFComponents/button";
import { Inline } from "PFComponents/containers";
import { Typography } from "PFComponents/typography";
import { useActivityInvalidate } from "PFCore/hooks/queries/activity";
import { fetchActivityWatchers } from "PFCore/services/activities";
import { getExportProfiles } from "PFCore/services/export_profiles";
import { RecentSearchesPageKey } from "PFTypes/recent_searches";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { RoleWatcher } from "../../../../../types/watchers";
import RefreshAction from "../../../../parts/refresh_action";
import useFormatWatchersToCsv from "./hooks/use_format_watchers_to_csv";
import css from "./interested.module.scss";
import { useInterestedContext } from "./interested_context_provider";

const getSelectedProfiles = (watchers: RoleWatcher[], selectedIds: Record<string, true>) =>
  filter(watchers, ({ id }) => includes(keys(selectedIds), String(id)));

const getWatchersProfilesIds = (watchers: RoleWatcher[], selectedIds: Record<string, true>) =>
  map(getSelectedProfiles(watchers, selectedIds), "profile.id");

const InterestedPageHeader = () => {
  const {
    setTablePropertiesVisibility,
    toggleShowFilters,
    filtersStorage,
    selectedIds,
    watchers,
    columns,
    watchersMetadata,
    searchTerm,
    setSearchTerm
  } = useInterestedContext();
  const { t } = useTranslation("activities", { keyPrefix: "show.interested" });
  const { task } = useActivityPageContext() as {
    task: { id: number };
  };
  const formatWatchers = useFormatWatchersToCsv(columns);
  const { invalidateWatchers } = useActivityInvalidate();
  const navigate = useNavigate();
  const { selectedFilters } = filtersStorage;
  const profilesForExport =
    keys(selectedIds).length > 0 ? getSelectedProfiles(watchers, selectedIds) : watchers;

  const fetchProfilesForExport = () =>
    getExportProfiles({
      profileIds: profilesForExport.map(({ profile }) => profile.id),
      activityId: task.id
    }).then((resp) => ({
      entries: resp.map((profile) => ({
        profile
      })),
      meta: { total: resp.length, page: 1 }
    }));

  const compareSelectedProfiles = () => {
    navigate(
      {
        pathname: `/activities/${task.id}/compare/details/${getWatchersProfilesIds(
          watchers,
          selectedIds
        ).join(",")}/interested`
      },
      { state: { prevPath: location.pathname } }
    );
  };

  return (
    <Inline spread alignItems="center">
      <Inline alignItems="center">
        <SearchInput
          term={searchTerm}
          placeholder={t("table.termSearch.placeholder")}
          pageKey={RecentSearchesPageKey.ActivityInterested}
          autocompleteConfig={[
            {
              entity: SearchEntity.Profile,
              redirect: true
            }
          ]}
          onTermChange={setSearchTerm}
          qaId="ActivityInterestedTabSearchTermInput"
        />

        <Typography className={css.counter} variant="labelRegular">
          {t("counter", { total: watchersMetadata?.total })}
        </Typography>
      </Inline>
      <Inline>
        <RefreshAction
          onUpdate={() => invalidateWatchers(task.id)}
          activityId={task.id}
          showLabel={false}
          qaId="ActivityInterestedTabRefreshMatchesButton"
        />
        <Button
          icon="compare"
          kind="secondary"
          onClick={compareSelectedProfiles}
          disabled={keys(selectedIds).length < 2}
          qaId="ActivityInterestedTabCompareButton"
        />
        {/* @ts-ignore */}
        <LazyLocalExportTrigger
          icon="export"
          buttonKind="secondary"
          quantity={profilesForExport.length}
          getProfilesCollection={fetchProfilesForExport}
          getRawCollection={() =>
            fetchActivityWatchers(task.id, 1, 1000, getAPIFilters(selectedFilters)).then((res) => ({
              ...res,
              entries: formatWatchers(res.entries)
            }))
          }
          qaId="ActivityInterestedTabExportButton"
        />
        <TablePropertiesButton onClick={() => setTablePropertiesVisibility(true)} />
        <FiltersButton
          onClick={toggleShowFilters}
          filtersAreClear={isEmpty(omit(selectedFilters || {}, "utmSource"))}
        />
      </Inline>
    </Inline>
  );
};

export default InterestedPageHeader;
