import SimpleBadge from "PFCore/components/badges_list/simple_badge";
import { useActivityVacancies } from "PFCore/hooks/queries/activity";
import { Activity } from "PFTypes";

type RoleVacanciesBadgeProps = {
  role: Activity;
};

export const RoleVacanciesBadge = ({ role }: RoleVacanciesBadgeProps) => {
  const { data: vacancies } = useActivityVacancies(
    {
      activityId: role.id
    },
    {
      enabled: role.coowned
    }
  );

  if (!role.coowned || !vacancies) {
    return null;
  }

  const isLoading = !vacancies.meta;
  const openCount = vacancies.entries.filter(({ state }) => state === "opened").length;
  const message = `${openCount} / ${vacancies.entries.length}`;

  return !isLoading && <SimpleBadge small text={message} />;
};
