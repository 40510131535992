import omit from "lodash/omit";
import EngagementContent from "PFApp/components/card_tx/content/engagement_content";
import EventContent from "PFApp/components/card_tx/content/event_content";
import PostContent from "PFApp/components/card_tx/content/post_content";
import QuestionSuggestionContent from "PFApp/components/card_tx/content/question_suggestion_content";
import RolePositionContent from "PFApp/components/card_tx/content/role_position_content";
import EngagementFooter from "PFApp/components/card_tx/footer/engagement_footer";
import Footer from "PFApp/components/card_tx/footer/footer";
import PostFooter from "PFApp/components/card_tx/footer/post_footer";
import RolePositionFooter from "PFApp/components/card_tx/footer/role_position_footer";
import Header from "PFApp/components/card_tx/header/header";
import PostHeader from "PFApp/components/card_tx/header/post_header";
import Status from "PFApp/components/card_tx/status/status";
import { Card } from "PFComponents/card";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import PropTypes from "prop-types";

const CardTx = ({
  task,
  numberOfRoles,
  isHotVisible,
  isFeed,
  handleEditPost,
  handleHeaderClick,
  fromSearch,
  onShare,
  handleRefetchActivities
}) => {
  const {
    data: { templates }
  } = useCurrentProfile();

  const type = (templates || []).find(({ id }) => id === task.template_id) || { key: "default" };

  const isDraft = task.state === "draft";
  const canShare = task.state !== "draft" && ((task.coowned && task.private) || !task.private);
  const handleShare = canShare ? onShare : null;

  const commonHeaderProps = {
    task,
    type,
    isFeed,
    onShare: handleShare,
    handleCloseActivitySuccess: handleRefetchActivities
  };

  const cardTags = {
    post: (
      <>
        <PostHeader
          {...omit(commonHeaderProps, ["type"])}
          handleEditPost={handleEditPost}
          handleHeaderClick={handleHeaderClick}
        />
        <Status isPrivate={task.private} isDraft={isDraft} />
        <PostContent task={task} />
        <PostFooter taskId={task.id} />
      </>
    ),
    role: (
      <>
        <Header {...commonHeaderProps} fromSearch={fromSearch} />
        <Status isPrivate={task.private} isDraft={isDraft} />
        <RolePositionContent task={task} isFeed={isFeed} />
        <RolePositionFooter isHotVisible={isHotVisible} interestedIn={task.statistics.watchers_count} />
      </>
    ),
    audit_role: (
      <>
        <Header task={task} type={type} isFeed={isFeed} fromSearch={fromSearch} onShare={handleShare} />
        <Status isPrivate={task.private} isDraft={isDraft} />
        <RolePositionContent task={task} isFeed={isFeed} />
        <RolePositionFooter isHotVisible={isHotVisible} interestedIn={task.statistics.watchers_count} />
      </>
    ),
    engagement: (
      <>
        <Header {...commonHeaderProps} />
        <Status isPrivate={task.private} isDraft={isDraft} />
        <EngagementContent task={task} isFeed={isFeed} />
        <EngagementFooter numberOfRoles={numberOfRoles} />
      </>
    ),
    audit_engagement: (
      <>
        <Header task={task} type={type} isFeed={isFeed} onShare={handleShare} />
        <Status isPrivate={task.private} isDraft={isDraft} />
        <EngagementContent task={task} isFeed={isFeed} />
        <EngagementFooter numberOfRoles={numberOfRoles} />
      </>
    ),
    suggestion: (
      <>
        <Header {...commonHeaderProps} />
        <Status isPrivate={task.private} isDraft={isDraft} />
        <QuestionSuggestionContent task={task} type={type.key} />
        <Footer participants={task.participants} />
      </>
    ),
    question: (
      <>
        <Header {...commonHeaderProps} />
        <Status isPrivate={task.private} isDraft={isDraft} />
        <QuestionSuggestionContent task={task} type={type.key} />
        <Footer participants={task.participants} />
      </>
    ),
    event: (
      <>
        <Header {...commonHeaderProps} />
        <Status isPrivate={task.private} isDraft={isDraft} />
        <EventContent task={task} />
        <Footer participants={task.participants} />
      </>
    ),
    position: (
      <>
        <Header {...commonHeaderProps} />
        <Status isPrivate={task.private} isDraft={isDraft} />
        <RolePositionContent task={task} />
        <RolePositionFooter isHotVisible={isHotVisible} interestedIn={task.statistics.watchers_count} />
      </>
    ),
    project: (
      <>
        <Header {...commonHeaderProps} />
        <Status isPrivate={task.private} isDraft={isDraft} />
        <RolePositionContent task={task} />
        <RolePositionFooter isHotVisible={isHotVisible} interestedIn={task.statistics.watchers_count} />
      </>
    ),
    lesson: (
      <>
        <Header {...commonHeaderProps} />
        <Status isPrivate={task.private} isDraft={isDraft} />
        <RolePositionContent task={task} />
        <Footer participants={task.participants} />
      </>
    ),
    default: (
      <>
        <Header {...commonHeaderProps} />
        <Status isPrivate={task.private} isDraft={isDraft} />
        <PostContent task={task} />
        <Footer participants={task.participants} />
      </>
    )
  };

  const renderer = cardTags[type.key] || cardTags.default;

  return <Card>{renderer}</Card>;
};

CardTx.propTypes = {
  task: PropTypes.shape({
    template_id: PropTypes.number,
    template_name: PropTypes.string,
    profile: PropTypes.shape({
      avatar: PropTypes.shape({}),
      email: PropTypes.string,
      first_name: PropTypes.string
    }),
    participants: PropTypes.array,
    created_at: PropTypes.string,
    id: PropTypes.id,
    private: PropTypes.bool,
    state: PropTypes.string,
    statistics: PropTypes.shape({
      watchers_count: PropTypes.number
    }),
    coowned: PropTypes.bool
  }).isRequired,
  numberOfRoles: PropTypes.number,
  isHotVisible: PropTypes.bool,
  isFeed: PropTypes.bool,
  handleEditPost: PropTypes.func,
  handleHeaderClick: PropTypes.func,
  onShare: PropTypes.func,
  handleRefetchActivities: PropTypes.func,
  fromSearch: PropTypes.bool
};

export default CardTx;
