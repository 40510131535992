import { CalendarBadge } from "./calendar_badge";
import { RemoveHandler, ValueBadgeGenericContentProps, ValueBadgeProps } from "./value_badge";

type ValueBadgeCalendarProps = ValueBadgeGenericContentProps & {
  onRemove: RemoveHandler;
} & Pick<ValueBadgeProps, "onDateChange">;

export const ValueBadgeCalendar = ({
  displayValue,
  value,
  locked,
  onDateChange,
  ...props
}: ValueBadgeCalendarProps) => {
  if (!onDateChange) {
    return null;
  }

  return (
    <CalendarBadge
      value={value}
      isLocked={locked || false}
      key={value.id}
      displayValue={displayValue}
      onDateChange={onDateChange}
      {...props}
    />
  );
};
