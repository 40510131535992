import NotFoundRoute from "PFApp/routes/not_found_route";
import { EnsureLoggedInAndRegistered, WithLayout } from "PFApp/routes/route_helpers";
import RecentSearchesPage from "PFApp/search/recent_searches_page";
import SearchComponent from "PFApp/search/search_component";
import { Route, Routes } from "react-router-dom";

export const SearchRoutes = () => (
  <EnsureLoggedInAndRegistered>
    <WithLayout>
      <Routes>
        <Route path="/" element={<RecentSearchesPage />} />
        <Route path=":id/:tab/:term?" element={<SearchComponent />} />
        <Route path="*" element={<NotFoundRoute />} />
      </Routes>
    </WithLayout>
  </EnsureLoggedInAndRegistered>
);
