import sortBy from "lodash/sortBy";
import { TemplateKey } from "PFApp/constants/templates";
import { LoadingDots } from "PFComponents/loading_dots";
import NotFoundComponent from "PFComponents/not_found/not_found";
import usePageWrapper from "PFCore/helpers/use_page_wrapper";
import { useActivities } from "PFCore/hooks/queries/activities/use_activities";
import { useCurrentProfileSet } from "PFCore/hooks/queries/profile";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { ApiRoute } from "PFCore/utilities/routes";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import ProfileShowPage from "./profile_show_page";
import useInterests from "./query/use_interests";

const ProfileShowController = () => {
  const navigate = useNavigate();
  const { data: currentProfile } = useCurrentProfile();
  const { t } = useTranslation("profiles");
  const setCurrentProfile = useCurrentProfileSet();

  const { id, section } = useParams();

  useEffect(() => {
    if (id !== "me" && Number(id) === currentProfile.id) {
      navigate(`/profiles/me/${section ? `/${section}` : ""}`, { replace: true });
    }
  }, []);

  const [errors, setErrors] = useState(null);
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    if (!currentProfile || !currentProfile.id) {
      return;
    }

    if (id === "me" || Number(id) === currentProfile.id) {
      setProfile(currentProfile);
    } else {
      $.get({ url: ApiRoute(`/api/profiles/${id}`), api_version: 2 })
        .then((resp) => setProfile(resp))
        .catch(() => setErrors([true]));
    }
  }, [currentProfile, id]);

  const {
    data: internalExperience,
    isLoading: isInternalExperienceLoading,
    refetch: refetchInternalExperience
  } = useActivities({
    perPage: 1000,
    filters: {
      fields: { ...{ ownerId: id === "me" ? currentProfile.id : id }, templateKey: TemplateKey.Work }
    }
  });

  const processedInternalExperience = useMemo(() => {
    const processedEntries = sortBy(
      (internalExperience?.entries || []).map(({ activity }) => {
        ["engagement_hours", "location"].forEach((field) => {
          if (!(field in activity.metadata)) {
            activity.metadata[field] = undefined;
          }
        });
        return activity;
      }),
      (obj) => {
        const dateRange = obj.metadata.date_range || {};
        if (!dateRange.start_date) {
          return Infinity;
        } else if (dateRange.current) {
          return -Infinity;
        } else {
          return -moment(dateRange.start_date);
        }
      }
    );

    return processedEntries;
  }, [internalExperience]);

  const interestsCollection = useInterests();
  const WrappedProfileShowPage = usePageWrapper(ProfileShowPage);

  if (profile?.suspended_at) {
    return <Navigate to="/profiles" replace />;
  }

  if (!profile && !errors) {
    return <LoadingDots circlesEnabled pageViewEnabled />;
  }

  const handleProfileUpdate = (attributes = {}) => {
    const isMe = id === "me" || id === currentProfile.id;
    const url = ApiRoute(isMe ? "/api/me" : `/api/profiles/${id}`);
    const noUpdate = Object.keys(attributes).length === 0;

    return $.ajax({
      url,
      method: noUpdate ? "GET" : "PUT",
      api_version: 2,
      contentType: "application/json; charset=utf-8",
      data: noUpdate ? null : JSON.stringify(attributes)
    }).then((resp) => {
      setProfile(resp);
      if (profile.id === currentProfile.id) {
        setCurrentProfile(resp);
      }
      return resp;
    });
  };

  return (
    <WrappedProfileShowPage
      errors={errors}
      profile={profile || {}}
      onProfileUpdate={handleProfileUpdate}
      fetchInternalExperience={refetchInternalExperience}
      internalExperiences={processedInternalExperience}
      loading={!errors && (isInternalExperienceLoading || !profile)}
      interestsCollection={interestsCollection}
      initSection={section}
      errorComponent={
        <NotFoundComponent
          message={t("profileDoesntExist")}
          buttonMessage={t("show.searchForExisting")}
          buttonUrl="/profiles"
        />
      }
    />
  );
};

ProfileShowController.propTypes = {
  type: PropTypes.string
};

export default ProfileShowController;
