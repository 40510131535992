import { CustomValueOption, CustomValuesEditField } from "PFApp/components/custom_values_edit_field";
import { ExperienceMultiToggle } from "PFApp/components/experience_multi_toggle";
import { Button } from "PFComponents/button";
import { Typography } from "PFComponents/typography";
import canonicalId from "PFCore/helpers/canonicalId";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { useSkillsType } from "PFCore/hooks/use_skills_type";
import { Experience } from "PFTypes";
import { Dispatch, SetStateAction, useRef } from "react";
import { useTranslation } from "react-i18next";

import { NewSkill } from "../../hooks/use_skills_modal";
import { BlockedSkillsAlert } from "../blocked_skills_alert";
import css from "./add_new_skill_section.module.scss";
import { SkillOptionDisplayElement } from "./skill_option_display_element";

type AddNewSkillSectionProps = {
  addNewSkill: VoidFunction;
  skillsValues: string[];
  newSkills: NewSkill[];
  newSkillRating: Experience | null;
  setNewSkillRating: Dispatch<SetStateAction<Experience | null>>;
  addedValues: string[];
  deletedValues: string[];
  handleSkillChange: (newSkills: NewSkill[]) => void;
  blockedSkills: string[];
};

export const AddNewSkillSection = ({
  addNewSkill,
  skillsValues,
  newSkills,
  newSkillRating,
  setNewSkillRating,
  addedValues,
  deletedValues,
  handleSkillChange,
  blockedSkills
}: AddNewSkillSectionProps) => {
  const { t } = useTranslation("profiles", { keyPrefix: "show.parts.skillsModal" });
  const portalRef = useRef<HTMLDivElement>(null);

  const { data: currentProfile } = useCurrentProfile();
  const skillsCustomType = useSkillsType();

  return (
    <section className={css.root}>
      <div ref={portalRef} className={css.portal} />
      <Typography variant="h5">{t("addNewSkill")}</Typography>
      <div className={css.skillSelectorContainer}>
        <CustomValuesEditField
          label={`${t("skillsLabel")} *`}
          placeholder={newSkills.length === 0 ? t("skillPlaceholder") : undefined}
          tip=""
          customType={skillsCustomType ?? null}
          values={newSkills as any}
          qaIdPrefix="profile-skills-new-input"
          adminPage={false}
          letClear
          useProfilesEndpoint
          profile={currentProfile}
          handleChange={(changedSkills) => handleSkillChange(changedSkills as any[])}
          hasValuesWithExpiryDate={false}
          createOptionBlocklist={skillsValues}
          parseOptions={(options: CustomValueOption[]) =>
            options.map((option) => {
              const { original } = option;
              const canonicalOptionText = canonicalId(original.text);
              const isAlreadyAdded =
                (original.added && !deletedValues.includes(canonicalOptionText)) ||
                addedValues.includes(canonicalOptionText);
              const isBlocked = original.state === "blocked";
              const isDisabled = isBlocked || isAlreadyAdded;

              return {
                ...option,
                disabled: isDisabled,
                displayElement: (
                  <SkillOptionDisplayElement
                    option={original}
                    isDisabled={isDisabled}
                    isAlreadyAdded={isAlreadyAdded}
                    portalRef={portalRef}
                  />
                )
              };
            })
          }
        />
        {blockedSkills.length > 0 && <BlockedSkillsAlert blockedSkills={blockedSkills} />}
      </div>
      <ExperienceMultiToggle experience={newSkillRating} onChange={setNewSkillRating} />
      <Button
        onClick={addNewSkill}
        disabled={blockedSkills.length > 0 || newSkills.length === 0 || !newSkillRating}
        aria-label={t("addNewSkill")}
        className={css.addButton}
      >
        {t("add")}
      </Button>
    </section>
  );
};
