import { ProfilesViewMode } from "PFApp/activities/types/profiles_view_mode";
import { TableColumn } from "PFTypes";
import { Activity, Id, Match, MatchFetchState, Meta, MetaWithPagination, Shortlist } from "PFTypes";
import { QueryParams } from "PFTypes";
import { MatchesDistribution } from "PFTypes/matches_distribution";
import { createContext, useContext } from "react";

import { ActivityPageTab } from "./hooks/use_activity_page_tabs";

export type ActivityContextType = {
  task: Activity;
  isAudit: boolean;
  isEngagement: boolean;
  isRole: boolean;
  roleKey?: string;
  isFillDisabled: boolean;
  // Tabs
  showTabs: boolean;
  tabs: ActivityPageTab[];
  tabActiveId?: string;
  handleChangeTab: (tab: ActivityPageTab["id"], navigate?: boolean | undefined) => void;
  // Matches
  matches?: Match[];
  matchesMeta: MetaWithPagination<{ distribution?: MatchesDistribution }>;
  matchesFetchState: MatchFetchState;
  matchesParams?: { page: number; per_page: number };
  updateMatchesParams: (x: { [key: string]: any }) => void;
  // Shortlists
  shortlists: Shortlist[];
  suspendedShortlists: Shortlist[];
  allShortlists: Shortlist[];
  isLoadingShortlists: boolean;
  rankedShortlists: Match[];
  shortlistsMeta: Meta;
  suspendedShortlistsMeta: Meta;
  updateShortlistsParams: (params: QueryParams) => void;
  // Compare profiles
  compareIds: Record<Id, boolean>;
  setCompareIds: React.Dispatch<React.SetStateAction<Record<Id, boolean>>>;
  clearComparisonToast: () => void;
  isProfileInComparison: (id: Id) => boolean;
  addProfileToComparison: (id: Id) => void;
  removeProfileFromComparison: (id: Id) => void;
  // Permissions
  canSeeMatches: boolean;
  canCreateRoles: boolean;
  canManageBookings: boolean;
  canSeeBookingModule: boolean;
  canSeeViewInBookingModuleButton: boolean;
  // Matches/Shortlist profiles mode
  profilesViewMode: ProfilesViewMode;
  setProfilesViewMode: React.Dispatch<React.SetStateAction<ProfilesViewMode>>;
  availableMatchesShortlistColumns: TableColumn[];
  selectedMatchesShortlistColumns: TableColumn[];
  setSelectedMatchesShortlistColumns: React.Dispatch<React.SetStateAction<TableColumn[]>>;
};

export const activityContextPlaceholder: ActivityContextType = {
  //@ts-ignore-line in LoadedActivity we always have Activity
  task: null,
  isAudit: false,
  isEngagement: false,
  isRole: false,
  // Tabs
  tabs: [],
  showTabs: false,
  handleChangeTab: () => {},
  // Matches
  matches: [],
  updateMatchesParams: () => {},
  matchesMeta: {
    totalPages: 0,
    order: {
      options: [],
      value: { direction: "", text: "", name: [] },
      name: "",
      title: "",
      type: ""
    },
    filters: {},
    total: 0,
    page: 1,
    perPage: 10
  },
  matchesFetchState: MatchFetchState.Pending,
  //Shortlist
  shortlists: [],
  suspendedShortlists: [],
  allShortlists: [],
  isLoadingShortlists: false,
  rankedShortlists: [],
  shortlistsMeta: {
    total: 0
  },
  suspendedShortlistsMeta: {
    total: 0
  },
  // Compare profiles
  updateShortlistsParams: () => {},
  clearComparisonToast: () => {},
  isProfileInComparison: () => false,
  addProfileToComparison: () => {},
  removeProfileFromComparison: () => {},
  // Permissions
  canCreateRoles: false,
  canSeeMatches: false,
  canManageBookings: false,
  canSeeBookingModule: false,
  canSeeViewInBookingModuleButton: false
};

const ActivityPageContext = createContext<ActivityContextType>(activityContextPlaceholder);

export const useActivityPageContext = () => useContext(ActivityPageContext);

export default ActivityPageContext;
