import { GrowlEntry } from "PFApp/components/growls";
import { Search } from "PFCore/initial_state/search";
import {
  ActivityPreview,
  AppLayout,
  Chats,
  CurrentAccount,
  CurrentProfile,
  CustomType,
  CustomValuePreview,
  Notifications,
  ProfilePreview,
  Segment,
  TemplateConfiguration
} from "PFTypes";
import { createContext, useContext } from "react";

import { PlannerPageContext } from "./workflow/parts/planner/use_planner_tab";

export type Dispatch = <ACTION extends object>(action: ACTION) => void | Promise<any>;

export type AppContextStore = {
  activityPreview: ActivityPreview;
  appLayout: AppLayout;
  chats: Chats;
  currentAccount: CurrentAccount;
  currentProfile: CurrentProfile;
  customTypes: CustomType[];
  customValuePreview: CustomValuePreview;
  growls: GrowlEntry[];
  modalSearch: unknown;
  notifications: Notifications;
  pages: Record<string, any> & { workflowPage: Record<string, any> & { planner: PlannerPageContext } };
  profilePreview: ProfilePreview;
  search: Search;
  segment: Segment;
  templatesConfiguration: TemplateConfiguration[];
  themeVars: Record<string, string>;
};

type AppContextType = {
  store: AppContextStore;
  dispatch: Dispatch;
};

const AppContext = createContext<AppContextType>({ store: {} } as AppContextType);

export const useAppContext = () => useContext(AppContext);

export default AppContext;
