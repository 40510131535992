import { ActivitiesOptionsParams } from "PFCore/services/activities";
import { FetchActivitiesStatisticsParams } from "PFCore/services/marketplace/fetch_activities_statistics";
import { MatchingActivitiesParams } from "PFCore/services/matching_activities/fetch_matching_activities";
import { FetchMatchingActivityStatusParams } from "PFCore/services/matching_activities/fetch_matching_activity_status";
import { Id, QueryParams } from "PFTypes";

export enum ActivitiesCollection {
  All = "all",
  Saved = "saved",
  Shared = "shared",
  Interested = "interested",
  Unread = "unread",
  MatchingActivities = "matchingActivities",
  NextAvailabilities = "nextAvailabilities",
  NextBookings = "nextBookings",
  MarketplaceActivity = "marketplaceActivity",
  Statistics = "statistics",
  MatchingActivityStatus = "matchingActivityStatus",
  Options = "options"
}

export const activitiesKeys = {
  [ActivitiesCollection.All]: (params = {}) => ["activities", params],
  [ActivitiesCollection.MatchingActivities]: (params: MatchingActivitiesParams) => [
    ...activitiesKeys.all(),
    ActivitiesCollection.MatchingActivities,
    params
  ],
  [ActivitiesCollection.Saved]: (params: QueryParams = {}) => [
    ...activitiesKeys.all(),
    ActivitiesCollection.Saved,
    params
  ],
  [ActivitiesCollection.Shared]: (params: QueryParams = {}) => [
    ...activitiesKeys.all(),
    ActivitiesCollection.Shared,
    params
  ],
  [ActivitiesCollection.Interested]: (params: QueryParams = {}) => [
    ...activitiesKeys.all(),
    ActivitiesCollection.Interested,
    params
  ],
  [ActivitiesCollection.NextAvailabilities]: (params: QueryParams = {}) => [
    ...activitiesKeys.all(),
    ActivitiesCollection.NextAvailabilities,
    params
  ],
  [ActivitiesCollection.NextBookings]: (params: QueryParams = {}) => [
    ...activitiesKeys.all(),
    ActivitiesCollection.NextBookings,
    params
  ],
  [ActivitiesCollection.MarketplaceActivity]: (activityId: Id) => [
    ...activitiesKeys.all(),
    ActivitiesCollection.MarketplaceActivity,
    String(activityId)
  ],
  [ActivitiesCollection.Statistics]: (params: FetchActivitiesStatisticsParams) => [
    ...activitiesKeys.all(),
    "statistics",
    params
  ],
  [ActivitiesCollection.MatchingActivityStatus]: (params: FetchMatchingActivityStatusParams) => [
    ...activitiesKeys.all(),
    "matchingActivityStatus",
    params
  ],
  [ActivitiesCollection.Options]: (params: ActivitiesOptionsParams) => [
    ...activitiesKeys.all(),
    ActivitiesCollection.Options,
    params
  ],
  imports: (templateKey: string, params = {}) => [
    ...activitiesKeys.all(),
    "imports",
    templateKey,
    ...(params ? [params] : [])
  ],
  importRows: (importId: Id, params = {}) => [
    ...activitiesKeys.all(),
    "importRows",
    importId,
    ...(params ? [params] : [])
  ]
};
