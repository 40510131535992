import classNames from "classnames";
import trim from "lodash/trim";
import truncate from "lodash/truncate";
import { InputFieldSet } from "PFComponents/text/input_field_set";
import { TextArea } from "PFComponents/text/text_area";
import { useRef } from "react";
import { Controller, FieldPath, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { BookingDetailItem } from "../booking_detail_item";
import { BookingCategorySelect } from "./booking_category_select";
import css from "./booking_form.module.scss";
import { BookingFormValues } from "./booking_form_provider";
import { OverrideToggles } from "./override_toggles";

export const TITLE_MAX_LENGTH = 250;
export const truncateTitle = (title?: string) => truncate(trim(title), { length: TITLE_MAX_LENGTH });

type BookingFormCommonInputsProps = {
  overridesDiaryTimeFormValueName: FieldPath<BookingFormValues>;
  overridesNonWorkingTimeFormValueName: FieldPath<BookingFormValues>;
  categoryFormValueName: FieldPath<BookingFormValues>;
  categoryFormErrorKeys: string[];
  titleFormValueName: FieldPath<BookingFormValues>;
  descriptionFormValueName: FieldPath<BookingFormValues>;
  isOverridesNonWorkingDaysDisabled: boolean;
};

export const BookingFormCommonInputs = ({
  overridesDiaryTimeFormValueName,
  overridesNonWorkingTimeFormValueName,
  categoryFormValueName,
  categoryFormErrorKeys,
  titleFormValueName,
  descriptionFormValueName,
  isOverridesNonWorkingDaysDisabled
}: BookingFormCommonInputsProps) => {
  const { t } = useTranslation("bookingModule");

  const { control } = useFormContext();

  const inputTagRef = useRef<HTMLInputElement>(null);

  const handleFocus = () => {
    inputTagRef?.current?.select();
  };

  return (
    <>
      <OverrideToggles
        overridesCalendarFormValueName={overridesDiaryTimeFormValueName}
        overridesNonWorkingDaysFormValueName={overridesNonWorkingTimeFormValueName}
        isOverridesNonWorkingDaysDisabled={isOverridesNonWorkingDaysDisabled}
      />
      <BookingCategorySelect
        categoryFormValueName={categoryFormValueName}
        categoryFormErrorKeys={categoryFormErrorKeys}
        titleFormValueName={titleFormValueName}
      />
      <BookingDetailItem
        label={t("bookings.create.title")}
        value={
          <Controller
            name={titleFormValueName}
            control={control}
            render={({ field }) => (
              <InputFieldSet
                value={field.value}
                maxLength={TITLE_MAX_LENGTH}
                onFocus={handleFocus}
                onChange={field.onChange}
                className={classNames(css.selectInput)}
                ref={inputTagRef}
              />
            )}
          />
        }
      />
      <BookingDetailItem
        label={t("bookings.create.description")}
        value={
          <Controller
            name={descriptionFormValueName}
            control={control}
            render={({ field }) => (
              <TextArea
                value={field.value}
                onChange={field.onChange}
                className={css.textarea}
                minHeight={145}
                maxLength={2000}
                placeholder={t("bookings.create.descriptionPlaceholder")}
              />
            )}
          />
        }
      />
    </>
  );
};
