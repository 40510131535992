import { getAriaProps } from "PFCore/helpers/get_aria_props";
import React, { AriaAttributes, forwardRef } from "react";

import { Pill, PillProps } from "../pill/pill";
import { Typography } from "../typography";
import css from "./key_value_pill.module.scss";

type KeyValuePillProps = {
  keyText: string;
  value: string;
} & Pick<PillProps, "onDelete" | "deleteTitle" | "title"> &
  AriaAttributes;

export const KeyValuePill = forwardRef<HTMLDivElement, KeyValuePillProps>(
  ({ keyText, value, onDelete, deleteTitle, title, ...props }, ref) => {
    const { ariaProps } = getAriaProps(props);

    return (
      <Pill
        ref={ref}
        kind="regular"
        className={css.root}
        onDelete={onDelete}
        deleteTitle={deleteTitle}
        {...ariaProps}
      >
        <div className={css.content} title={title}>
          <Typography variant={"labelRegular"} tag="span" className={css.keyText} clipOverflow>
            {keyText}
          </Typography>
          <Typography variant={"bodyBold"} tag="span" className={css.valueText} clipOverflow>
            {value}
          </Typography>
        </div>
      </Pill>
    );
  }
);

KeyValuePill.displayName = "KeyValuePill";
