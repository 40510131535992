import compact from "lodash/compact";
import find from "lodash/find";
import useStorage from "PFCore/helpers/use_storage";
import { TableColumn } from "PFTypes";
import { useCallback, useEffect, useMemo } from "react";

// this hook stores only the names of the selected columns in localStorage, not the whole column data
export const useStorageTableColumns = (
  storageKey: string,
  availableColumns: TableColumn[],
  defaultColumns: TableColumn[]
) => {
  const [storedColumnNames, setStoredColumnNames] = useStorage<string[] | { name: string }[]>(
    storageKey,
    defaultColumns.map(({ name }) => name)
  );

  // preserving the old stored columns and changing them to new format
  // ---
  useEffect(() => {
    const hasObject = (storedColumnNames || []).some((column) => typeof column !== "string");
    if (hasObject) {
      setStoredColumnNames((prevColumns) => (prevColumns as { name: string }[]).map(({ name }) => name));
    }
  }, [storedColumnNames, setStoredColumnNames]);
  // ---

  const selectedColumns = useMemo<TableColumn[]>(
    () => compact(storedColumnNames.map((name) => find(availableColumns, { name }))),
    [availableColumns, storedColumnNames]
  );

  const setSelectedColumns = useCallback(
    (columnsToStore: TableColumn[]) => {
      setStoredColumnNames(columnsToStore.map(({ name }) => name));
    },
    [setStoredColumnNames]
  );

  useEffect(() => {
    if (!storedColumnNames.length) {
      setSelectedColumns(defaultColumns.filter(({ isDefault }) => isDefault));
    }
  }, [defaultColumns, storedColumnNames, setSelectedColumns]);

  return { selectedColumns, setSelectedColumns };
};
