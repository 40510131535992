import React from "react";
import { useTranslation } from "react-i18next";

import { useDateFormatter } from "../../hooks";
import { Pill } from "../pill";
import { Typography } from "../typography";

type SuspendedPillProps = {
  suspendedAt: string;
  className?: string;
};

export const SuspendedPill = ({ suspendedAt, className }: SuspendedPillProps) => {
  const { formatDate } = useDateFormatter();
  const { t } = useTranslation();

  return (
    <Pill kind="danger" small className={className}>
      <Typography variant="labelRegular">{t("suspendedDate", { date: formatDate(suspendedAt) })}</Typography>
    </Pill>
  );
};
