import { Button } from "PFComponents/button";
import { DropdownButton } from "PFComponents/dropdown_button";
import { LoadingDots } from "PFComponents/loading_dots/loading_dots";
import isIOS from "PFCore/helpers/ios";
import isSafari from "PFCore/helpers/is_safari";
import { useIsCurrentUserPermittedTo } from "PFCore/helpers/use_is_permitted_to";
import DownloadIcon from "PFIcons/download.svg";
import { PermissionRule } from "PFTypes";
import { DocFormat } from "PFTypes/doc_format";
import { memo } from "react";
import { useTranslation } from "react-i18next";

import css from "./document_buttons.module.scss";
import { UseDownloadDocument, useDownloadDocument } from "./use_download_document";

export type DocumentButtonComponentProps = {
  onClick: (format: DocFormat) => void;
};

type DocumentButtonsProps = UseDownloadDocument &
  (
    | {
        exportOptions?: DocFormat[];
        buttonComponent?: never;
      }
    | {
        exportOptions?: never;
        buttonComponent?: React.ComponentType<DocumentButtonComponentProps>;
      }
  );

export const DocumentButtons = memo(
  ({
    generateDocument,
    exportOptions = [DocFormat.Doc, DocFormat.Pdf, DocFormat.Csv],
    buttonComponent: ButtonComponent
  }: DocumentButtonsProps) => {
    const { t } = useTranslation(["core", "translation"]);
    const { handleDownloadClick, fileUrl, isLoading } = useDownloadDocument({ generateDocument });

    const useAnchorTag = isIOS() || isSafari();

    const renderDownloadButtons = () => {
      if (ButtonComponent) {
        return <ButtonComponent onClick={handleDownloadClick} />;
      }

      if (exportOptions.length === 1) {
        return (
          <Button
            text={t("core:documentButtons.export")}
            onClick={() => handleDownloadClick(exportOptions[0])}
          />
        );
      }

      const options = exportOptions.map((option) => ({
        id: option,
        item: option,
        displayElement: `.${option}`
      }));

      return (
        <DropdownButton
          text={t("core:documentButtons.export")}
          icon="chevron-down"
          options={options}
          handleChange={handleDownloadClick}
          iconPlacement="right"
          popperOptions={{
            placement: "bottom-start"
          }}
        />
      );
    };

    const renderDownloadIframe = () => {
      const style = {
        display: "inline-block",
        fill: "currentColor",
        verticalAlign: "middle",
        lineHeight: "15px"
      };

      return useAnchorTag ? (
        <span>
          <a target="_blank" href={fileUrl} rel="noopener noreferrer">
            <span style={style}>
              <DownloadIcon width={16} height={16} />
            </span>
            {t("translation:download")}
          </a>
        </span>
      ) : (
        <iframe src={fileUrl} style={{ display: "none" }} title={t("translation:download")} />
      );
    };

    const canExportDocs = useIsCurrentUserPermittedTo(PermissionRule.ExportDocuments);

    if (!canExportDocs) {
      return null;
    }

    return (
      <div className={css.documentButtons} data-qa-id="export-doc-trigger">
        {isLoading && <LoadingDots />}
        {!isLoading && ((useAnchorTag && !fileUrl) || !useAnchorTag) && renderDownloadButtons()}
        {!isLoading && fileUrl && renderDownloadIframe()}
      </div>
    );
  }
);

DocumentButtons.displayName = "DocumentButtons";
