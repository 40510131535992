import { Checkbox } from "PFComponents/checkbox";
import { Id } from "PFTypes";

import css from "./skill_checkbox.module.scss";

type SkillCheckboxProps = {
  skillId: Id;
  selectedSkillsIds: Id[];
  onSkillSelectionChange: (skillId: Id) => void;
};

export const SkillCheckbox = ({ skillId, selectedSkillsIds, onSkillSelectionChange }: SkillCheckboxProps) => (
  <Checkbox
    checked={selectedSkillsIds.includes(skillId)}
    onChange={() => onSkillSelectionChange(skillId)}
    className={css.checkbox}
  />
);
