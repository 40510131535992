import first from "lodash/first";
import isNil from "lodash/isNil";
import {
  CustomValueOption,
  CustomValueSelect
} from "PFApp/components/custom_value_select/custom_value_select";
import { EXPIRABLE_CUSTOM_TYPES } from "PFApp/constants/expirable_custom_types";
import { InlineCalendar } from "PFComponents/calendar/inline_calendar";
import { allowsNewValues } from "PFCore/helpers/custom_type";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import {
  AutocompleteCustomValuesResponse,
  fetchAutocompleteCustomValues
} from "PFCore/services/autocomplete/fetch_autocomplete_custom_values";
import { CustomValuesOptionsQueryParams, fetchCustomValuesOptions } from "PFCore/services/custom_values";
import { fetchLocationsOptions } from "PFCore/services/locations";
import {
  Collection,
  CustomField,
  CustomType,
  CustomValue,
  Position,
  Profile,
  PureCustomValue,
  PureCustomValueData
} from "PFTypes";
import { RefObject } from "react";

import { useCommonProps } from "./use_common_props";

export * from "PFApp/components/custom_value_select/custom_value_select";

export type CustomValuesEditFieldProps = {
  handleChange: (values: PureCustomValueData[]) => void;
  values?: PureCustomValueData[]; // in a legacy code, values can be undefined (for example `basic_info.jsx on profile page)
  customType: CustomType | null;
  kind?: "bordered" | "blank";
  position?: Position;
  profile?: Profile;
  connectedFields?: { values: Pick<CustomField["values"][number], "id">[] }[] | null;
  parseOptions?: (options: CustomValueOption[]) => CustomValueOption[];
  adminPage?: boolean;
  multi?: boolean;
  required?: boolean;
  letClear?: boolean;
  letCreate?: boolean | null;
  tip?: string;
  label?: string;
  placeholder?: string;
  errors?: string | string[];
  hasValuesWithExpiryDate?: boolean;
  createOptionBlocklist?: string[];
  isActivity?: boolean;
  closeOnChange?: boolean;
  useProfilesEndpoint?: boolean;
  disabled?: boolean;
  forceUnlocked?: boolean;
  displayValues?: React.ReactElement;
  qaIdPrefix?: string;
  classes?: {
    root?: string;
  };
  portalRef?: RefObject<HTMLElement | null>;
  onRestore?: VoidFunction;
  restoreLabel?: string;
  displayValuesBelow?: boolean;
};

export const CustomValuesEditField = ({
  kind,
  position,
  customType,
  errors,
  adminPage,
  profile,
  label,
  placeholder,
  tip,
  required,
  isActivity,
  forceUnlocked,
  multi,
  values = [],
  qaIdPrefix,
  letClear,
  disabled,
  letCreate = null,
  hasValuesWithExpiryDate,
  classes = {},
  parseOptions,
  createOptionBlocklist,
  displayValues,
  displayValuesBelow,
  handleChange,
  connectedFields = null,
  useProfilesEndpoint,
  portalRef,
  onRestore,
  restoreLabel
}: CustomValuesEditFieldProps) => {
  const { formatISODate } = useDateFormatter();
  const commonProps = useCommonProps({
    adminPage,
    customType,
    profile,
    position,
    isActivity,
    forceUnlocked,
    label,
    errors,
    placeholder,
    tip,
    required,
    kind
  });

  // When the value is not passed we default to custom type config
  const finalLetCreate = isNil(letCreate) && customType ? allowsNewValues(customType) : letCreate;

  const getId = (customValue: PureCustomValue | PureCustomValueData) =>
    customValue.id ?? customValue.globalId ?? customValue.global_id;

  const formatOptions = (
    response: PureCustomValue[] | Collection<PureCustomValue[]>
  ): Collection<CustomValueOption[]> => {
    const entries = Array.isArray(response) ? response : response.entries;
    const formattedEntries = entries.map((customValue) => ({
      ...customValue,
      id: getId(customValue),
      value: String(customValue.text || customValue.value),
      original: customValue
    }));
    return {
      entries: formattedEntries,
      meta: Array.isArray(response) ? { total: entries.length } : response.meta
    };
  };

  const fetchOptions = async ({
    searchTerm,
    ids
  }: {
    searchTerm: string;
    ids?: string[];
  }): Promise<Collection<CustomValueOption[]>> => {
    const term = searchTerm;
    if (ids?.length) {
      return await Promise.resolve(formatOptions(values as PureCustomValue[]));
    }
    const type = customType!.name;
    let result: PureCustomValue[] | AutocompleteCustomValuesResponse | Collection<PureCustomValue[]> = [];

    if (useProfilesEndpoint) {
      result = await fetchAutocompleteCustomValues({
        term,
        type,
        global: true
      });
    } else if (type === "skills") {
      result = await fetchAutocompleteCustomValues({
        term,
        type
      });
    } else if (customType?.value_type === "location") {
      result =
        term.length < 3
          ? await Promise.resolve([] as PureCustomValue[])
          : await fetchLocationsOptions({
              term,
              customTypeId: customType.id
            });
    } else {
      const queryParams: CustomValuesOptionsQueryParams = {
        term,
        type
      };

      if (!isNil(connectedFields)) {
        queryParams.connectedOptions = true;
        queryParams.customValueIds = (connectedFields || [])
          .map(({ values }) => values.map(({ id }) => id))
          .flat();
      }

      result = await fetchCustomValuesOptions(queryParams);
    }
    return formatOptions(result);
  };

  const isMulti = multi ?? customType?.kind === "multiple";

  const handleCalendarChange = (date: string) => {
    // backend does not update date value if it is provided with existing id
    // (contrary to other custom values updates)
    handleChange([{ value: formatISODate(date) } as PureCustomValueData]);
  };

  const isCalendar = customType?.value_type === "date";

  const hasExpiryDate = isNil(hasValuesWithExpiryDate)
    ? EXPIRABLE_CUSTOM_TYPES.includes(customType?.name || "")
    : hasValuesWithExpiryDate;

  const handleExpiryDateChange = (option: CustomValueOption) => {
    const newValues = [...values] as CustomValue[];
    const changedValue = newValues.find((value) => value.id === option.id);
    if (changedValue) {
      changedValue.expiry_date = option.original?.expiry_date;
    }
    handleChange(newValues);
  };

  const handleCreate = (createdOption: CustomValueOption) => {
    const newOption = { ...createdOption, created: true };
    const newValues = (isMulti ? [...values, newOption] : [newOption]) as PureCustomValueData[];
    handleChange(newValues);
    return Promise.resolve(createdOption);
  };

  const commonSelectProps = {
    cacheTime: 0,
    fetchOptions,
    displayValueBelow: displayValuesBelow,
    withExpiryDate: hasExpiryDate,
    onRestore,
    disabled,
    onDateChange: handleExpiryDateChange,
    onClear: letClear && values.length > 0 ? () => handleChange([]) : undefined,
    onCreateOption: finalLetCreate ? handleCreate : undefined,
    createOptionBlocklist,
    restoreLabel,
    displayValues,
    isFetchingSelectedOptions: false,
    parseOptions
  };

  return (
    <div className={classes.root} key={customType?.id} data-qa-id={`${qaIdPrefix}-${customType?.name}`}>
      {isCalendar ? (
        // @ts-ignore
        <InlineCalendar
          {...commonProps}
          selectedDate={first(values)?.value ?? null}
          handleChange={handleCalendarChange}
          portalRef={portalRef}
        />
      ) : (
        <>
          {isMulti ? (
            <CustomValueSelect
              multiple
              value={values.map(getId)}
              onChange={(values: CustomValueOption[]) => handleChange(values.map(({ original }) => original))}
              {...commonSelectProps}
              {...commonProps}
            />
          ) : (
            <CustomValueSelect
              value={values[0] && getId(values[0])}
              onChange={(value: CustomValueOption) => handleChange([value.original])}
              {...commonSelectProps}
              {...commonProps}
            />
          )}
        </>
      )}
    </div>
  );
};
