import classNames from "classnames";
import useIsAvailabilityPermitted from "PFApp/use_is_availability_permitted";
import { isAdmin } from "PFCore/helpers/profile";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";

import { AvailabilitySection } from "./availability_section";
import { ExpertiseField } from "./expertise_field";
import { OrganisationData } from "./organisation_data";
import css from "./section_summary.module.scss";
import { SkillsChartSection } from "./skills_chart_section";
import SkillsGroups from "./skills_groups";
import { SkillsModal } from "./skills_modal";

const GRID_MEDIA_QUERY = "(min-width: 1440px) and (min-height: 900px), (min-width: 1650px)";

const SummarySection = ({
  profile,
  handleProfileUpdate,
  editMode,
  me,
  typeToShowInGrid,
  isPendingUpdate,
  errors,
  setEditMode,
  basicCustomTypes,
  className
}) => {
  const availabilityRef = useRef(null);
  const { data: currentProfile } = useCurrentProfile();
  const { additionalSection } = useParams();
  const navigate = useNavigate();
  const gridMatches = useMediaQuery({ query: GRID_MEDIA_QUERY });
  const [expandModeEnabled, setExpandModeEnabled] = useState(false);
  const shouldDisplayGrid = gridMatches && !expandModeEnabled;
  const shouldHideExpandAction = !gridMatches && !expandModeEnabled;

  const isAvailabilityPermitted = useIsAvailabilityPermitted(profile);

  const [triggerNavigationToSummary, setTriggerNavigationToSummary] = useState(false);

  useEffect(() => {
    if (expandModeEnabled) {
      availabilityRef.current.scrollIntoView();
    }
  }, [expandModeEnabled]);

  // navigate does not work when passed in async callback
  // so we have to use useEffect: https://github.com/remix-run/react-router/issues/11240
  useEffect(() => {
    if (triggerNavigationToSummary) {
      navigate(`/profiles/${me ? "me" : profile.id}/summary`);
    }
    return () => setTriggerNavigationToSummary(false);
  }, [triggerNavigationToSummary]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={classNames(
        {
          [css.gridMediaQuery]: shouldDisplayGrid,
          [css.flexSpacing]: !shouldDisplayGrid
        },
        className
      )}
    >
      <div className={classNames(css.summary)}>
        <div className={css.col1}>
          <SkillsChartSection
            key={currentProfile.preferences.display?.skills_insights}
            className={classNames(css.cell, { [css.chartSectionHeightLimit]: !shouldDisplayGrid })}
            profile={profile}
          />
          <SkillsGroups className={css.cell} profile={profile} onProfileUpdate={handleProfileUpdate} />

          {typeToShowInGrid && (
            <ExpertiseField
              className={classNames(css.cell, css.typeToShowInGrid)}
              key={typeToShowInGrid.name}
              customType={typeToShowInGrid}
              profile={profile}
              isMe={me}
              onProfileUpdate={handleProfileUpdate}
              isEditMode={editMode[`cf_${typeToShowInGrid.name}`]}
              onEditModeChange={() =>
                setEditMode(`cf_${typeToShowInGrid?.name}`, !editMode[`cf_${typeToShowInGrid.name}`])
              }
              isLoading={isPendingUpdate}
            />
          )}
        </div>
        <div className={css.col2}>
          <OrganisationData
            className={css.cell}
            isMe={me}
            profile={profile}
            errors={errors}
            basicCustomTypesNames={basicCustomTypes}
            isEditMode={editMode.basic_info}
            isLoading={isPendingUpdate}
            onProfileUpdate={handleProfileUpdate}
            onEditModeChange={() => setEditMode("basic_info", !editMode.basic_info)}
          />
          {profile && isAvailabilityPermitted && (
            <AvailabilitySection
              className={classNames(css.cell, css.availabilityCell)}
              profile={profile}
              isLoading={isPendingUpdate}
              isAdmin={isAdmin(currentProfile)}
              isMe={me}
              onExpand={() => {
                setExpandModeEnabled(!expandModeEnabled);
              }}
              isExpanded={!shouldDisplayGrid}
              shouldHideExpandAction={shouldHideExpandAction}
              myRef={availabilityRef}
            />
          )}
        </div>
        <div className={css.wrapInner}></div>
        {additionalSection === "skills" && (
          <SkillsModal
            profile={profile}
            handleProfileUpdate={handleProfileUpdate}
            onClose={() => setTriggerNavigationToSummary(true)}
          />
        )}
      </div>
    </div>
  );
};

SummarySection.propTypes = {
  className: PropTypes.string,
  me: PropTypes.bool.isRequired,
  isPendingUpdate: PropTypes.bool.isRequired,
  profile: PropTypes.object.isRequired,
  handleProfileUpdate: PropTypes.func.isRequired,
  editMode: PropTypes.object.isRequired,
  setEditMode: PropTypes.func.isRequired,
  typeToShowInGrid: PropTypes.shape({
    name: PropTypes.string
  }),
  basicCustomTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  errors: PropTypes.arrayOf(PropTypes.object)
};

export default SummarySection;
