import jqXHR = JQuery.jqXHR;
import { ApiRoute } from "PFCore/utilities/routes";
import { ProfileMinimized } from "PFTypes";

export type SaveParticipantPayload = {
  conversationId: number;
  profileId: number;
};

type SaveParticipantResponse = {
  entries: ProfileMinimized[];
  meta: { total: number };
};

export const saveParticipant = ({
  profileId,
  conversationId
}: SaveParticipantPayload): jqXHR<SaveParticipantResponse> =>
  // @ts-ignore
  $.ajax({
    url: ApiRoute(`/api/chat/participants`),
    api_version: 2,
    method: "POST",
    data: {
      conversation_id: conversationId,
      profile_id: profileId
    }
  });
