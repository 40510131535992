import classNames from "classnames";
import { useGrowl } from "PFApp/use_growl";
import DropDown from "PFComponents/dropdown/dropdown";
import { LoadingDots } from "PFComponents/loading_dots";
import { useCustomValuesOptions } from "PFCore/hooks/queries/custom_values/use_custom_values_options";
import { ApiRoute } from "PFCore/utilities/routes";
import CheckIcon from "PFIcons/check.svg";
import PropTypes from "prop-types";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { PriorityBadge } from "../priority_badge";
import css from "./priority_dropdown.module.scss";

const PriorityDropdown = ({ activity, disabled, required, onSelected }) => {
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation("workflow", { keyPrefix: "parts.threeDotsMenu" });
  const growl = useGrowl();

  const selectedPriorityId = activity.priority?.[0]?.id;

  const { data, isFetching } = useCustomValuesOptions({ type: "priority" });

  const handleSetPriority = (priority) => {
    if (loading) {
      return;
    }
    setLoading(true);
    $.ajax({
      url: ApiRoute(`/api/activities/${activity.id}`),
      method: "PUT",
      data: JSON.stringify({
        custom_fields: [{ type: { name: "priority" }, values: priority ? [{ id: priority.id }] : [] }]
      }),
      api_version: 2,
      contentType: "application/json"
    }).then(
      () => {
        setLoading(false);
        growl({ message: t("priority.success") });
        onSelected && onSelected(priority);
        activity.priority = priority ? [{ id: priority.id, text: priority.text }] : null;

        // Hack to close parent dropdown
        const fakeButton = document.createElement("button");
        const parent = document.getElementById("react_app");
        parent.appendChild(fakeButton);
        fakeButton.click();
        fakeButton.remove();
      },
      () => {
        setLoading(false);
        growl({ message: t("priority.error"), kind: "error" });
      }
    );
  };

  const renderDisplayElement = useCallback(
    (option = {}, className) => (
      <div className={classNames(css.priorityOption, className, { [css.loading]: loading })}>
        <PriorityBadge level={option.text?.toLowerCase()} />
        <span>{option.text || t("translation:none")}</span>
        {selectedPriorityId === option.id ? (
          <CheckIcon className={css.checkIcon} width={12} height={12} />
        ) : (
          <div className={css.iconPlaceholder} />
        )}
      </div>
    ),
    [loading, selectedPriorityId, t]
  );

  const formattedOptions = useMemo(() => {
    if (isFetching) {
      return [
        {
          id: "loading",
          displayElement: <LoadingDots />,
          item: null
        }
      ];
    }
    const result = (data?.entries ?? []).map((option) => ({
      id: option.id,
      displayElement: renderDisplayElement(option),
      item: option,
      disabled
    }));

    result.push({
      id: "none",
      displayElement: renderDisplayElement({}, css.none),
      item: null,
      disabled: disabled || required
    });

    return result;
  }, [isFetching, data?.entries, renderDisplayElement, disabled, required]);

  return (
    <DropDown
      rootClassName={css.priorityDropdown}
      options={formattedOptions}
      handleChange={(item) => handleSetPriority(item)}
    />
  );
};

PriorityDropdown.propTypes = {
  onSelected: PropTypes.func,
  activity: PropTypes.shape({
    id: PropTypes.number.isRequired,
    priority: PropTypes.array
  }),
  disabled: PropTypes.bool,
  required: PropTypes.bool
};

export default PriorityDropdown;
