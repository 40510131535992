import camelCase from "lodash/camelCase";
import compact from "lodash/compact";
import { isAdmin, isSaasAdmin } from "PFCore/helpers/profile";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import useIsPermittedTo from "PFCore/helpers/use_is_permitted_to";
import useStorage from "PFCore/helpers/use_storage";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { useSkillsType } from "PFCore/hooks/use_skills_type";
import CogIcon from "PFIcons/cog.svg";
import { FeatureFlag, PermissionRule } from "PFTypes";
import { useTranslation } from "react-i18next";

import { CommonNavItemProps, NavItem } from "../parts/nav_item";
import { NavDropdownItem } from "../types";

const useItems = () => {
  const { t } = useTranslation();
  const { data: currentProfile } = useCurrentProfile();

  const isEnabled = useIsFeatureEnabled();
  const isPermittedTo = useIsPermittedTo(currentProfile);
  const saasAdmin = isSaasAdmin(currentProfile);
  const isNewAnalyticsConfigPageEnabled = useStorage("new_analytics_config_page", false);
  const skillsType = useSkillsType();

  const link = (hrefKey: string, nameKey: string | null, actionHref: boolean): NavDropdownItem => {
    const name = t(
      `navBar.adminSiteNav.${camelCase(nameKey || hrefKey)}` as unknown as TemplateStringsArray,
      {
        defaultValue: nameKey || hrefKey
      }
    );

    return {
      name,
      href: `/admin/${hrefKey}`,
      action: actionHref
        ? {
            href: `/admin/${hrefKey}/new`,
            icon: "add",
            label: t("navBar.adminSiteNav.addActionLabel", { item: name })
          }
        : undefined
    };
  };

  // user_manager is a permission that actually limits permissions
  if (isPermittedTo(PermissionRule.UserManager)) {
    return [link("users", "manage_users", true)];
  }

  const isAuditLogPermitted = isPermittedTo(PermissionRule.AuditLog);

  return compact([
    isEnabled(FeatureFlag.SkillsFramework) && link("skills_frameworks", null, true),
    isEnabled(FeatureFlag.SecureDomains) && link("allowed_domains", null, true),
    isPermittedTo(PermissionRule.PermissionsGroupsManagement) &&
      link("permissions_groups", "permissions", true),
    isEnabled(FeatureFlag.RequestAccess) && link("access_requests", null, false),
    isAuditLogPermitted && link("audit_logs", null, false),
    saasAdmin && isEnabled(FeatureFlag.SamlSignin) && link("saml_configs", "SAML", true),
    { sep: true },
    link("users", "manage_users", true),
    link("custom_fields", null, saasAdmin),
    link(`custom_fields/${skillsType?.id ?? 1}/content`, "skillsManagement", false),
    link(`notifications`, null, false),
    isEnabled(FeatureFlag.InsightsPage) && isNewAnalyticsConfigPageEnabled && link("analytics", null, false),
    link("branding", null, false),
    link("rss", "RSS Feeds", false),
    link("account_contacts", null, false),
    link("email_templates", null, true),
    link("collaborators_configs", null, true),
    link("interest_questions", null, false),
    isEnabled(FeatureFlag.BookingModule) &&
      isPermittedTo(PermissionRule.BookingAdmin) &&
      link("booking", null, false),
    isEnabled(FeatureFlag.AuditAllocationWindows) &&
      isPermittedTo(PermissionRule.AuditAdmin) &&
      link("target_allocations", null, false),
    { sep: true },
    // TODO: [SP-3122] Create target allocation window settings page
    saasAdmin && { name: t("navBar.adminSiteNav.saasAdminAccounts"), href: "/saas_admin/accounts" }
  ]);
};

export const AdminNavItem = (props: CommonNavItemProps) => {
  const { t } = useTranslation("translation", { keyPrefix: "navBar.items" });
  const { data: currentProfile } = useCurrentProfile();

  const items = useItems();

  if (!isAdmin(currentProfile)) {
    return null;
  }

  return (
    <NavItem
      icon={<CogIcon width={20} height={20} />}
      isActiveTest={(path) => path.match(/^\/admin/)}
      name={t("admin")}
      href={"/admin"}
      id="admin"
      {...props}
      items={items}
    />
  );
};
