import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { RoleWatcher } from "PFApp/activities/types/watchers";
import ProfileCell from "PFApp/components/profile_cell/profile_cell";
import { useProfilePreview } from "PFApp/use_profile_preview";
import { Markdown } from "PFComponents/markdown";
import { useDateFormatter } from "PFCore/hooks";
import { useActivityInvalidate } from "PFCore/hooks/queries/activity";
import { TableColumn } from "PFTypes";
import { Profile } from "PFTypes";

import AddShortlistButton from "../../../../../parts/add_shortlist_button";
import MatchValueTooltipped from "../interested_table_cells/match_value";
import { Questions } from "../interested_table_cells/questions";

export const useCellFactory = () => {
  const { formatDate, formatDateTime } = useDateFormatter();

  const { task } = useActivityPageContext();
  const { invalidateWatchers } = useActivityInvalidate();
  const { showPreview } = useProfilePreview();

  const handleProfileClick = (watcher: RoleWatcher) => {
    showPreview({
      id: watcher.profile.id,
      profile: watcher.profile as unknown as Profile
    });
  };

  const cellRenderers = {
    "name": ({ row }) => (
      <ProfileCell profile={row.original.profile} onClick={() => handleProfileClick(row.original)} />
    ),
    "profile_id": ({ row }) => row.original.profile.id,
    "questions": ({ row }) => <Questions watcher={row.original} />,
    "statement": ({ getValue }) => <Markdown raw={getValue() || ""} crop={150} emojify={false} />,
    "shortlist": ({ row }) => (
      <AddShortlistButton profile={row.original} task={task} onClick={() => invalidateWatchers(task.id)} />
    ),
    "date": ({ getValue }) => getValue() && formatDate(getValue()),
    "date-time": ({ getValue }) => getValue() && formatDateTime(getValue()),
    "availability_score": ({ row }) => (
      <MatchValueTooltipped scoreName={"availability_score"} scores={row.original.scores} />
    ),
    "normalized_score": ({ row }) => (
      <MatchValueTooltipped scoreName={"normalized_score"} scores={row.original.scores} />
    )
  };

  const defaultRenderer = ({ getValue }) => getValue() && String(getValue());

  return (column: TableColumn) =>
    cellRenderers[column.name] || cellRenderers[column.type!] || defaultRenderer;
};
