import capitalize from "lodash/capitalize";
import { Typography } from "PFComponents/typography";
import { BookingHistoryProfile } from "PFCore/hooks/queries/bookings/history/use_booking_history";

import { AttributeKey, AttributeValue } from "../../../../../../types/booking_history_item";
import { SingleChangeItem } from "./single_change_item";
import { useAttribute } from "./use_attribute";

export type AttributeData = {
  attributeId: AttributeKey;
  valueFrom: AttributeValue;
  valueTo: AttributeValue;
};

type AttributeProps = {
  title?: string;
  attribute: AttributeData;
  metaProfiles?: Record<number, BookingHistoryProfile>;
};

export const Attribute = ({ title, attribute, metaProfiles }: AttributeProps) => {
  const { valueFrom, valueTo, attributeId } = attribute;
  const { attributeRenderer } = useAttribute({ attributeId, metaProfiles });

  return (
    <div>
      {title && (
        <Typography withMargin variant="bodyBold" tag="span">
          {capitalize(title)}
        </Typography>
      )}
      <SingleChangeItem data={{ valueFrom, valueTo }} valueRenderer={attributeRenderer} />
    </div>
  );
};
