import camelCase from "lodash/camelCase";
import compact from "lodash/compact";
import startCase from "lodash/startCase";
import { getVisibleProperties } from "PFCore/helpers/templates";
import { CurrentProfile, CustomType, Meta, Template } from "PFTypes";

import { shouldDisplayProperty } from "../activities/edit/utils/should_display_property";

export const isDraft = (meta: Meta) => Boolean([meta.filters?.fields?.state?.value].flat().includes("draft"));

export const getTemplateVisibleProperties = (
  customTypes: CustomType[],
  currentProfile: CurrentProfile,
  blockedProperties: string[],
  template?: Template
) => {
  const visibleProperties = template ? getVisibleProperties(template) : [];

  return compact(
    visibleProperties
      .filter(({ name }) => !blockedProperties.includes(name))
      .map((element) => {
        let { title } = element;
        if (element.type === "custom_field") {
          if (!shouldDisplayProperty(element, customTypes, currentProfile)) {
            return null;
          }
          const customType = customTypes.find((type) => type.name === element.name)!;
          title = customType.display_as;
        }
        return { ...element, title };
      })
  );
};

export const getTableColumnsFromProperties = (
  templateVisibleProperties: {
    name: string;
    title?: string;
    type: string;
    kind?: string;
  }[]
) =>
  templateVisibleProperties.map(({ name, title, type, kind }) => ({
    name,
    title: title || startCase(camelCase(name)),
    type: type,
    orderKey: type === "custom_field" && kind === "single" ? `custom_fields.${name}` : null
  }));
