import { SyntheticEvent } from "react";

import { CustomValueOption } from "../custom_value_select";
import { ValueBadgeCalendar } from "./value_badge_calendar";
import { ValueBadgeGeneric } from "./value_badge_generic";

export type RemoveHandler = (value: CustomValueOption, event: SyntheticEvent) => void;

export type ValueBadgeGenericContentProps = {
  value: CustomValueOption;
  locked?: boolean;
  displayValue: string;
};

export type ValueBadgeProps = Pick<ValueBadgeGenericContentProps, "value" | "locked"> & {
  withExpiryDate?: boolean;
  onRemove: RemoveHandler;
  onDateChange?: (option: CustomValueOption, event: SyntheticEvent) => void;
};

export const ValueBadge = ({ withExpiryDate, onRemove, onDateChange, ...props }: ValueBadgeProps) => {
  const { value } = props;

  let displayValue = value.displayElement || value.value || "";

  if (value.displayElement === 0 || Number(value?.value) === 0) {
    displayValue = 0;
  }

  if (withExpiryDate) {
    return (
      <ValueBadgeCalendar
        {...props}
        onDateChange={onDateChange}
        value={value}
        displayValue={displayValue as string}
        onRemove={onRemove}
      />
    );
  }

  return (
    <ValueBadgeGeneric
      {...props}
      onRemove={onRemove as RemoveHandler}
      displayValue={displayValue as string}
    />
  );
};
