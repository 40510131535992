import { RefObject } from "react";

import { SimplePagination } from "../pagination/simple_pagination";
import { PaginationOptions } from "./table.types";

type TablePaginationProps = {
  paginationOptions: PaginationOptions;
  paginationRef?: RefObject<HTMLDivElement | null>;
};

export const TablePagination = ({ paginationOptions, paginationRef }: TablePaginationProps) => {
  const { currentPage, totalPages, onClick } = paginationOptions;

  return (
    <div ref={paginationRef} className={paginationOptions.className}>
      <SimplePagination currentPage={currentPage} totalPages={totalPages} handleClick={onClick} />
    </div>
  );
};
