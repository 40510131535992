import camelCase from "lodash/camelCase";
import groupBy from "lodash/groupBy";
import keys from "lodash/keys";
import sortBy from "lodash/sortBy";
import { Accordion } from "PFComponents/accordion";
import { Button } from "PFComponents/button";
import { Stack } from "PFComponents/containers";
import { Divider } from "PFComponents/divider";
import { stringify } from "PFCore/helpers/use_deterministic_stringify";
import { NotificationSetting } from "PFTypes";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { NotificationSettingSelect } from "./notification_setting_select";
import css from "./notifications_settings_form.module.scss";
import { NotificationSettingsFormValues } from "./notifications_settings_form.types";
import { parseNotificationsSettingsToFormValues } from "./notifications_settings_form.utils";

type NotificationsSettingsFormProps = {
  notificationsSettings: NotificationSetting[];
  onSubmit: (changedSettings: Pick<NotificationSetting, "id" | "frequency">[]) => void;
};

export const NotificationsSettingsForm = ({
  notificationsSettings,
  onSubmit
}: NotificationsSettingsFormProps) => {
  const { t: tTranslation } = useTranslation();
  const { t } = useTranslation("notificationsSettings");

  const defaultValues = parseNotificationsSettingsToFormValues(notificationsSettings);
  const formMethods = useForm<NotificationSettingsFormValues>({
    defaultValues
  });
  const {
    handleSubmit,
    reset,
    formState: { isDirty }
  } = formMethods;

  useEffect(() => {
    reset(defaultValues);
  }, [stringify(defaultValues), reset]);

  const submitChangedValues = (formData: NotificationSettingsFormValues) => {
    const payload = notificationsSettings
      .filter(({ id, frequency }) => formData[id] !== frequency)
      .map(({ id }) => ({ id, frequency: formData[id] }));

    onSubmit(payload);
  };

  const groupedSettings = groupBy(notificationsSettings, "group");
  const sortedGroupKeys = sortBy(keys(groupedSettings));

  return (
    <FormProvider {...formMethods}>
      <Stack gap="spacingLg">
        {sortedGroupKeys.map((groupKey, index, groupsList) => (
          <>
            <Accordion
              key={groupKey}
              header={t(`group.${camelCase(groupKey)}` as unknown as TemplateStringsArray)}
              headerVariant="h5"
              iconSet="chevron"
              animated={false}
            >
              <Stack gap="spacingLg">
                {groupedSettings[groupKey].map((notificationSetting) => (
                  <NotificationSettingSelect
                    key={notificationSetting.id}
                    notificationSetting={notificationSetting}
                  />
                ))}
              </Stack>
            </Accordion>
            {index < groupsList.length - 1 && <Divider />}
          </>
        ))}
        <Button onClick={handleSubmit(submitChangedValues)} disabled={!isDirty} className={css.submitButton}>
          {tTranslation("update")}
        </Button>
      </Stack>
    </FormProvider>
  );
};
