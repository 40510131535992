import { useAppContext } from "PFApp/app_context";
import { useGrowl } from "PFApp/use_growl";
import api from "PFCore/api";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { useCurrentProfileSet } from "PFCore/hooks/queries/profile/use_current_profile_set";
import { GROWL_ACTIONS } from "PFCore/reducers/growl_reducer";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useSearchParams } from "react-router-dom";

export const EmailConfirmationController = (): any => {
  const [searchParams] = useSearchParams();
  const confirmationToken = searchParams.get("confirmation_token");

  const [complete, setComplete] = useState(false);

  const { dispatch } = useAppContext();
  const { data: currentProfile } = useCurrentProfile();
  const setCurrentProfile = useCurrentProfileSet();

  const growl = useGrowl();
  const { t } = useTranslation();

  useEffect(() => {
    if (!confirmationToken) {
      return;
    }
    api({
      url: "auth/confirmation",
      headers: {
        "Content-Type": "multipart/form-data",
        "apiVersion": 1
      },
      method: "post",
      data: { "user[confirmation_token]": confirmationToken }
    })
      .then((resp) => {
        if (currentProfile) {
          dispatch({
            type: GROWL_ACTIONS.GROWL_RENDER,
            payload: {
              message: t("emailConfirmation.emailChanged"),
              kind: "success"
            }
          });
          setCurrentProfile({
            ...currentProfile,
            email: resp.email
          });
        } else {
          dispatch({
            type: GROWL_ACTIONS.GROWL_RENDER,
            payload: {
              title: t("emailConfirmation.emailChanged"),
              message: t("emailConfirmation.canLoginWithNew"),
              kind: "alert"
            }
          });
        }

        setComplete(true);
      })
      .catch(() => {
        growl({
          title: t("error"),
          message: t("emailConfirmation.tokenExpiredError"),
          kind: "error"
        });

        setComplete(true);
      });
  }, []);

  if (complete) {
    return <Navigate to="/" replace />;
  }

  return null;
};
