import head from "lodash/head";
import isEmpty from "lodash/isEmpty";
import last from "lodash/last";
import orderBy from "lodash/orderBy";
import { getProfileName } from "PFCore/helpers/profile";
import roundToDecimals from "PFCore/helpers/round_to_decimals";
import { ProfileWarningsIndex } from "PFCore/hooks/queries/profile";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import {
  Activity,
  AuditPlannerException,
  AuditPlannerExceptionsDictionary,
  AuditRole,
  BasicProfile,
  CamelizedShortlist
} from "PFTypes";
import { useTranslation } from "react-i18next";
import { humanize, titleize } from "underscore.string";

export const workflowStatesByTabs = {
  exceptions: ["exception"],
  manual_interventions: ["shortlisting"]
};

export const getExceptionType = (exception: AuditPlannerExceptionsDictionary) => {
  const isMultiple = exception.availability && exception.compliance;
  return isMultiple
    ? "Multiple"
    : Object.keys(Object.values(exception)[0] || {})
        .map((type) => titleize(humanize(type)))
        .join(", ");
};

export const exceptionsArrayToException = (auditPlannerExceptions: AuditPlannerException[]) => {
  const exceptionsArray = auditPlannerExceptions.map(({ exceptionType, exceptions }) => ({
    [exceptionType]: exceptions
  }));
  const exceptions = orderBy(auditPlannerExceptions, ["id"], ["desc"]); // sort newest first to search for requester, approver and reason in the proper order

  const requester =
    exceptions.find(({ requester }) => requester)?.requester ||
    ((exceptions.find(({ requesterId }) => requesterId) && {
      id: exceptions.find(({ requesterId }) => requesterId)?.requesterId
    }) as BasicProfile);

  const approver =
    exceptions.find(({ approver }) => approver)?.approver ||
    ((exceptions.find(({ approverId }) => approverId) && {
      id: exceptions.find(({ approverId }) => approverId)?.approverId
    }) as BasicProfile);

  return {
    exceptions: exceptionsArray.reduce((acc, curr) => ({ ...curr, ...acc }), {}),
    requester,
    approver,
    requestReason: exceptions.find(({ requestReason }) => requestReason)?.requestReason
  };
};

export const getExceptionFromRole = (role: AuditRole) => {
  const exceptions = role.shortlists?.find(({ state }) => ["filled", "booked"].includes(state))?.exceptions;
  return exceptions && exceptions.length > 0 ? exceptionsArrayToException(exceptions) : null;
};

export const isAuditRoleDisabled = (role: AuditRole | Activity) =>
  ["pending", "auto_filling", "auto_shortlisting"].includes(
    (role as AuditRole).workflowState || (role as Activity).workflow_state
  );

export const findFilledOrBookedProfile = (auditRole: AuditRole): CamelizedShortlist["profile"] | undefined =>
  auditRole.shortlists?.find(({ state }) => ["filled", "booked"].includes(state))?.profile;

export const isOverbooked = (role: AuditRole, rolesWarningsByProfile: ProfileWarningsIndex) => {
  const profile = findFilledOrBookedProfile(role);
  const { overbookings } = profile ? rolesWarningsByProfile[profile.id] ?? {} : {};
  return !isEmpty(overbookings);
};

export const useOverbookingMessage = (role: AuditRole, rolesWarningsByProfile?: ProfileWarningsIndex) => {
  const { formatDate, utc } = useDateFormatter();
  const { t } = useTranslation("auditPlanner");

  const profile =
    role.shortlists && role.shortlists.find(({ state }) => ["filled", "booked"].includes(state))?.profile;
  const { overbookings = [] } = profile ? rolesWarningsByProfile?.[profile.id] ?? {} : {};

  if (!profile || isEmpty(overbookings)) {
    return null;
  }

  const overbookedMinutes = overbookings
    .filter(({ availableMinutes }) => availableMinutes < 0)
    .map(({ availableMinutes }) => Math.abs(availableMinutes))
    .reduce((sum, value) => sum + value, 0);

  const { date: startDate } = head(overbookings)!;
  const { date: endDate } = last(overbookings)!;

  return t("parts.userIsOverbooked", {
    name: getProfileName(profile),
    hours: roundToDecimals(overbookedMinutes / 60),
    startDate: formatDate(utc(startDate)),
    endDate: formatDate(utc(endDate))
  });
};
