import { ProfileItem } from "PFApp/components/profile_item";
import { LoadingDots } from "PFComponents/loading_dots";
import { BookingHistoryProfile } from "PFCore/hooks/queries/bookings/history/use_booking_history";
import { useProfile } from "PFCore/hooks/queries/profile/use_profile";
import { BasicProfile } from "PFTypes";
import { useNavigate } from "react-router-dom";

type ProfileAttributeProps = {
  profileId: number;
  metaProfiles?: Record<number, BookingHistoryProfile>;
};

export const ProfileAttribute = ({ profileId, metaProfiles }: ProfileAttributeProps) => {
  const navigate = useNavigate();

  const isSuspended = !!metaProfiles?.[profileId]?.suspended_at;
  const { data, isLoading } = useProfile(profileId, { enabled: !isSuspended });
  const profile = isSuspended ? metaProfiles?.[profileId] : data;

  if (isLoading) {
    return <LoadingDots />;
  }

  const goToProfile = () => profile && navigate(`/profiles/${profile.id}`);

  return (
    <ProfileItem
      profile={profile as BasicProfile}
      displayAvatar={false}
      displayJobTitle={false}
      displaySuspendedDate={true}
      rootFlexProps={{ gap: null }}
      onClick={!isSuspended ? goToProfile : undefined}
    />
  );
};
