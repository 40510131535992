import trim from "lodash/trim";
import { DropdownOption } from "PFComponents/dropdown/dropdown";
import { useActivitiesOptions } from "PFCore/hooks/queries/activities/use_activities_options";
import { useProfilesOptions } from "PFCore/hooks/queries/profiles/use_profiles_options";
import { IconName } from "PFTheme/graphics/icons";
import { Id } from "PFTypes";
import { ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { EntityRow } from "../parts/entity_row";
import { EntityConfig, SearchDropdownItem, SearchEntity } from "../search_autocomplete.types";

type BasicEntity = { id: Id };

const PER_PAGE = 3;
const getTitle = (entity: any) => entity?.name;
const getSubTitle = (entity: BasicEntity) => `ID ${entity.id}`;
const ACTIVITY_ENTITIES = [
  SearchEntity.Role,
  SearchEntity.AuditRole,
  SearchEntity.Engagement,
  SearchEntity.AuditEngagement
];

type UseAutocompleteEntityOptions<T extends object> = {
  term: string;
  config: EntityConfig | null;
  templateKey?: string;
  iconName: IconName;
  label: string;
  getEntityTitle?: (entity: T) => string;
  getEntitySubTitle?: (entity: T) => string;
  getEntityAdditionalData?: (entity: T) => { ariaLabel: string; displayElement: ReactNode } | null;
  getRedirectUrl: (entity: T) => string;
};

export const useAutocompleteEntityOptions = <T extends BasicEntity>({
  term,
  config,
  templateKey,
  iconName,
  label,
  getEntityTitle = getTitle,
  getEntitySubTitle = getSubTitle,
  getEntityAdditionalData,
  getRedirectUrl
}: UseAutocompleteEntityOptions<T>) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const trimmedTerm = trim(term);
  const enabled = !!config && !!trimmedTerm;
  const isActivityEntity = enabled && ACTIVITY_ENTITIES.includes(config?.entity);
  const isProfileEntity = enabled && SearchEntity.Profile === config?.entity;

  const { data: activityOptionsData } = useActivitiesOptions(
    { term: trimmedTerm, templateKey: templateKey ?? "", perPage: PER_PAGE },
    { enabled: enabled && isActivityEntity, retry: false }
  );
  const { data: profileOptionsData } = useProfilesOptions(
    { term: trimmedTerm, perPage: PER_PAGE },
    { enabled: enabled && isProfileEntity, retry: false }
  );

  const data = isProfileEntity ? profileOptionsData : isActivityEntity ? activityOptionsData : undefined;

  const entities = useMemo(() => (enabled ? data?.entries || [] : []), [data?.entries, enabled]);

  return useMemo(
    () =>
      entities.map<DropdownOption>((entity) => {
        const id = `${config!.entity}-${entity.id}`;
        const entityTitle = getEntityTitle(entity);
        const entitySubTitle = getEntitySubTitle(entity);
        const { ariaLabel: additionalDataAriaLabel, displayElement: additionalData } =
          getEntityAdditionalData?.(entity) ?? {};
        const redirectTo = config!.redirect ? () => navigate(getRedirectUrl(entity)) : undefined;
        const item: SearchDropdownItem = {
          text: entityTitle,
          onClick: redirectTo,
          useAsTerm: config!.useAsTerm,
          entity: {
            id: entity.id,
            type: config!.entity
          }
        };

        const entityDescription = `${label}: ${entityTitle}, ${entitySubTitle}${
          additionalDataAriaLabel ? `, ${additionalDataAriaLabel}` : ""
        }`;

        return {
          id,
          item,
          displayElement: (
            <EntityRow
              key={id}
              title={entityTitle}
              subtitle={entitySubTitle}
              redirect={!!config!.redirect}
              entityLabel={label}
              iconName={iconName}
              additionalData={additionalData}
            />
          ),
          ariaLabel: redirectTo ? t("redirectTo", { target: entityDescription }) : entityDescription
        };
      }),
    [entities, config, getEntityTitle, getEntitySubTitle, label, iconName, t, history, getRedirectUrl]
  );
};
