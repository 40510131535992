import {
  CalendarPill,
  CalendarPillProps,
  CalendarPillValue
} from "PFComponents/double_section_pill/calendar_pill";

import { AutoSelectValueGenericContentProps, AutoSelectValueProps } from "./auto_select_value";

type AutoSelectValueCalendarProps = Omit<AutoSelectValueGenericContentProps, "value"> & {
  displayValue: string;
  onRemove: CalendarPillProps["onRemove"];
  value: CalendarPillValue;
} & Pick<AutoSelectValueProps, "onDateChange">;

export const AutoSelectValueCalendar = ({
  displayValue,
  value,
  locked,
  onDateChange,
  ...props
}: AutoSelectValueCalendarProps) => {
  if (!onDateChange) {
    return null;
  }

  return (
    <CalendarPill
      value={value}
      isLocked={locked || false}
      key={value.id}
      displayValue={displayValue}
      onDateChange={onDateChange}
      {...props}
    />
  );
};
