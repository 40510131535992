import { CollisionDetection, pointerWithin, rectIntersection } from "@dnd-kit/core";

export const customCollisionDetectionAlgorithm: CollisionDetection = (args) => {
  // https://docs.dndkit.com/api-documentation/context-provider/collision-detection-algorithms#composition-of-existing-algorithms
  const pointerCollisions = pointerWithin(args);

  if (pointerCollisions.length > 0) {
    return pointerCollisions;
  }

  return rectIntersection(args);
};
