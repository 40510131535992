import React, { MutableRefObject, PropsWithChildren, useEffect, useState } from "react";
import { usePopper } from "react-popper";
import { useClickAway } from "react-use";

import css from "./popover.module.scss";

type PopoverProps = PropsWithChildren<{
  reference: MutableRefObject<HTMLDivElement | null>;
  onClickOutside: VoidFunction;
}>;

export const Popover = ({ children, reference, onClickOutside }: PopoverProps) => {
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const { styles, attributes, update } = usePopper(reference.current, popperElement, {
    placement: "bottom-start",
    modifiers: [
      {
        name: "flip",
        options: {
          allowedAutoPlacements: ["top", "bottom"]
        }
      }
    ]
  });

  useEffect(() => {
    if (!reference.current) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => update?.());
    resizeObserver.observe(reference.current);

    return () => resizeObserver.disconnect();
  }, [update]); // eslint-disable-line react-hooks/exhaustive-deps

  useClickAway(reference, (event) => {
    event.stopPropagation();
    event.preventDefault();
    onClickOutside();
  });

  return (
    <div
      ref={setPopperElement}
      className={css.root}
      style={{
        ...styles.popper,
        minWidth: reference.current?.offsetWidth
      }}
      {...attributes.popper}
    >
      {children}
    </div>
  );
};
