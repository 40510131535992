import { BadgeKind } from "PFComponents/badges_list/simple_badge";
import { Option, SelectV2, SelectV2Props } from "PFComponents/select_v2";
import { PureCustomValue } from "PFTypes";

import { ValuesList } from "./values_list";

export type OptionOriginal = PureCustomValue & {
  kind?: BadgeKind;
  expiry_date?: string | null;
};

export type CustomValueOption = Option<OptionOriginal>;

export type CustomValueSelectProps = SelectV2Props<OptionOriginal> & {
  onDateChange?: (option: Option<OptionOriginal>) => void;
  withExpiryDate?: boolean;
  displayValues?: React.ReactNode;
  placeholder?: string;
};

export const CustomValueSelect = (props: CustomValueSelectProps) => {
  const { multiple, onChange, onDateChange, withExpiryDate, displayValues, placeholder } = props;

  const formatSelectedOptions = (selectedOptions: CustomValueOption | CustomValueOption[]) =>
    Array.isArray(selectedOptions) ? selectedOptions : [selectedOptions];

  const renderDisplayValue = (selectedOptions) =>
    displayValues || (
      <ValuesList
        selectedOptions={formatSelectedOptions(selectedOptions || [])}
        onChange={onChange}
        onDateChange={onDateChange}
        withExpiryDate={withExpiryDate}
        multiple={multiple}
        placeholder={placeholder}
      />
    );

  return <SelectV2<OptionOriginal> {...props} renderDisplayValue={renderDisplayValue} />;
};
