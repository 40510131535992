import { ProfileItem } from "PFApp/components/profile_item";
import { useGrowl } from "PFApp/use_growl";
import AutoSelect from "PFComponents/select/autoselect";
import { getActiveActivityProfiles } from "PFCore/helpers/get_active_activity_profiles";
import { getProfileName, isProfileActive } from "PFCore/helpers/profile";
import { useResponseErrors } from "PFCore/helpers/use_response_errors";
import { useOwnerCreate, useOwnerUpdate } from "PFCore/hooks/queries/owners";
import { Activity, ActivityProfileType, Profile } from "PFTypes";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useOwnerRemove } from "../hooks/use_owner_remove";
import { UseAddActivityProfilesReturn } from "./activity_profiles.types";
import { AddActivityProfilesModalContent } from "./add_activity_profiles_modal_content";

type UseAddOwnersModalProps = {
  handleClose: VoidFunction;
  activity: Activity;
};

export const useAddOwnersModal = ({
  handleClose,
  activity
}: UseAddOwnersModalProps): UseAddActivityProfilesReturn => {
  const { owners } = activity;
  const { getFirstError } = useResponseErrors();
  const taskOwners = getActiveActivityProfiles(activity.owners);
  const currentPrincipalOwner = owners.find(({ principal }) => principal)?.profile || null;

  const [newProfiles, setNewProfiles] = useState<Profile[]>([]);
  const [principalOwner, setPrincipalOwner] = useState<Profile | null>(currentPrincipalOwner);
  const { t } = useTranslation("activities");
  const growl = useGrowl();

  const { mutate: createOwner } = useOwnerCreate();
  const { mutate: updateOwner } = useOwnerUpdate(activity.id);

  const { handleRemove, getRemoveModalContent } = useOwnerRemove(activity, handleClose);

  const principalOwnerOptions = useMemo(
    () =>
      owners
        .filter(({ profile }) => isProfileActive(profile))
        .map(({ profile }) => profile)
        .concat(newProfiles),
    [owners, newProfiles]
  );

  useEffect(() => {
    if (principalOwner && !principalOwnerOptions.find(({ id }) => principalOwner.id === id)) {
      setPrincipalOwner(null);
    }
  }, [principalOwnerOptions, principalOwner]);

  const handleReset = () => {
    setNewProfiles([]);
    setPrincipalOwner(currentPrincipalOwner);
  };

  const handleSubmit = () => {
    const removedPrincipalOwner = !principalOwner && owners.find((owner) => owner.principal);
    const existingPrincipalOwner =
      principalOwner && owners.find((owner) => owner.profile.id === principalOwner.id && !owner.principal);

    const principalOwnerToUpdate = removedPrincipalOwner || existingPrincipalOwner;

    if (principalOwnerToUpdate) {
      updateOwner(
        {
          id: principalOwnerToUpdate.id,
          principal: !!principalOwner
        },
        {
          onSuccess: () => {
            growl({
              message: t("show.parts.principalOwnerUpdated"),
              kind: "success"
            });
          }
        }
      );
    }
    newProfiles.forEach((profile) =>
      createOwner(
        {
          profileId: profile.id,
          activityId: activity.id,
          ...(principalOwner?.id === profile.id ? { principal: true } : {})
        },
        {
          onSuccess: () =>
            growl({
              message: t("show.parts.profileIsCoOwner", {
                profileName: getProfileName(profile),
                taskName: activity.name
              }),
              kind: "success"
            }),
          onError: ({ response }) => {
            const error = getFirstError(response);
            growl({
              message: error || t("show.parts.taskNotShared", { taskName: activity.name }),
              kind: "error"
            });
          }
        }
      )
    );
  };

  const getModalContent = () => (
    <AddActivityProfilesModalContent
      activity={activity}
      profiles={taskOwners}
      onRemove={handleRemove}
      description={t("show.parts.ownersModalDescription")}
      newProfiles={newProfiles}
      onNewProfilesChange={setNewProfiles}
      getRemoveModalContent={getRemoveModalContent}
      activityProfileType={ActivityProfileType.Owner}
    >
      <AutoSelect
        key={JSON.stringify(principalOwnerOptions.map((id) => id))}
        values={principalOwner ? [principalOwner] : null}
        label={t("show.parts.ownersSelectLabel")}
        closeOnChange
        query={(term) =>
          Promise.resolve(
            principalOwnerOptions.filter((item) =>
              getProfileName(item).toLowerCase().includes(term.toLowerCase())
            )
          )
        }
        displayValues={principalOwner ? <ProfileItem profile={principalOwner} /> : null}
        handleChange={(option) => setPrincipalOwner(option[0] ? option[0] : null)}
        formatOption={(item) => ({
          id: item.id,
          displayElement: <ProfileItem profile={item} />,
          item
        })}
        letClear
      />
    </AddActivityProfilesModalContent>
  );

  return {
    getModalContent,
    handleSubmit,
    handleReset,
    enableSubmit: principalOwner !== currentPrincipalOwner || newProfiles.length > 0
  };
};
