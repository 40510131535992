/*
 * We need to create the minimum structure that mirrors the values selected
 *
 * valueFilters: ValueFilters TO apiFilters: ValueFilters
 *
 * This structure needs to preserve all keys that have present values
 * It will clear nulls and empty arrays and empty objects
 *
 * returns ValueFilters
 * */
import cloneDeep from "lodash/cloneDeep";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import mapValues from "lodash/mapValues";
import { AvailabilityRule, Filters, Segment, Value } from "PFTypes";

export const getAPIFilters = (valueFilters: Filters<Value> | undefined, segment?: Segment) => {
  const valueFiltersCopy = cloneDeep(valueFilters) || {};

  if (segment) {
    valueFiltersCopy.strings = {
      ...valueFiltersCopy.strings,
      ...mapValues(segment, (segmentValues) => map(segmentValues, "id"))
    };
  }

  if (isEmpty(valueFilters)) {
    return valueFiltersCopy;
  }

  Object.keys(valueFiltersCopy).forEach((key) => {
    if (key === "availability") {
      const isAvailabilityWithEmptyRanges =
        key === "availability" && isEmpty((valueFiltersCopy[key] as AvailabilityRule)?.ranges);

      if (isEmpty(valueFiltersCopy[key]) || isAvailabilityWithEmptyRanges) {
        delete valueFiltersCopy[key];
      }

      return;
    }

    if (key === "children") {
      const childrenApiFilters = getAPIFilters(valueFilters.children);
      if (childrenApiFilters) {
        valueFiltersCopy.children = childrenApiFilters;
      }
      return;
    }

    const innerFilters = valueFiltersCopy[key];
    Object.keys(innerFilters).forEach((filterName) => {
      const value = innerFilters[filterName];

      if (
        (isArray(value) && isEmpty(value)) ||
        value == null || // eslint-disable-line eqeqeq
        value === ""
      ) {
        delete innerFilters[filterName];
      }
    });

    if (isEmpty(valueFiltersCopy[key])) {
      delete valueFiltersCopy[key];
    }
  });

  return valueFiltersCopy;
};
