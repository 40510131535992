import { Stack } from "PFComponents/containers";
import { SuspendedPill } from "PFComponents/suspended_pill";
import { Typography } from "PFComponents/typography";
import { getProfileName } from "PFCore/helpers/profile";

import { ProfileFrom } from "../../parts/overview/booking_module_calendar/context/reassign_action_context_provider";
import css from "./profile_item_display.module.scss";

type ProfileItemDisplayProps = {
  profile: ProfileFrom;
};

export const ProfileItemDisplay = ({ profile }: ProfileItemDisplayProps) => (
  <Stack gap="spacingSm">
    <Typography variant="h5" className={css.wrappedContainer}>
      {getProfileName(profile)}
    </Typography>
    {!!profile.email && (
      <Typography withMargin variant="bodyRegular" tag="span" className={css.wrappedContainer}>
        {profile.email}
      </Typography>
    )}
    {!!profile.suspendedAt && <SuspendedPill suspendedAt={profile.suspendedAt} className={css.suspended} />}
  </Stack>
);
