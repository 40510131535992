import classNames from "classnames";
import { PropsWithChildren, RefObject } from "react";

import css from "./table.module.scss";
import { useTableResize } from "./use_table_resize";

type TableResizerProps = {
  tableRef: RefObject<HTMLTableElement | null>;
  paginationRef: RefObject<HTMLDivElement | null>;
  bottomElementsSize?: number;
  disableAutoResizing?: boolean;
  disableInternalScrolling?: boolean;
};

export const TableResizer = ({
  tableRef,
  paginationRef,
  bottomElementsSize,
  disableAutoResizing,
  disableInternalScrolling,
  children
}: PropsWithChildren<TableResizerProps>) => {
  const { maxHeight } = useTableResize({
    tableRef,
    paginationRef,
    bottomElementsSize,
    disableAutoResizing
  });

  return (
    <div
      className={classNames(css.tableResizer, { [css.disableInternalScrolling]: disableInternalScrolling })}
      style={{ maxHeight }}
    >
      {children}
    </div>
  );
};
