import isNil from "lodash/isNil";
import pick from "lodash/pick";
import { useBookingActivityContext } from "PFApp/booking/parts/providers/booking_activity_context_provider";
import { EngagementSelect } from "PFApp/components/engagement_select";
import { TemplateKey } from "PFApp/constants/templates";
import { useTemplate } from "PFApp/hooks";
import { useEngagementQuery } from "PFApp/hooks/use_engagement_query";
import { LoadingDots } from "PFComponents/loading_dots";
import useBookingCategories from "PFCore/hooks/use_booking_categories";
import { ActivitiesResponse } from "PFCore/services/activities";
import { Engagement, Template } from "PFTypes";
import { useCallback, useEffect, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useUnmount } from "react-use";

import { truncateTitle } from "../booking_form_common_inputs";
import { BookingFormValues, BookingType } from "../booking_form_provider";
import { BookingFormMode } from "../use_booking_form";

type SimpleEngagement = Pick<Engagement, "id" | "name" | "description" | "booking_category_id">;

const parseEngagementToOption = (activity) => ({
  id: activity.id,
  name: activity.name,
  description: activity.description
});

type EngagementSelectorProps = {
  mode: BookingFormMode;
};

export const EngagementSelector = ({ mode }: EngagementSelectorProps) => {
  const { activity, isLoading } = useBookingActivityContext();
  const engagementTemplate = useTemplate(TemplateKey.Engagement as Template["key"]);
  const { getBookingCategory } = useBookingCategories();

  const isInitialActivityEngagement = activity && engagementTemplate?.id === activity.template_id;
  const [selectedEngagement, setSelectedEngagement] = useState<SimpleEngagement | null>(null);
  const { control, setValue } = useFormContext();
  const [title, bookingType, category] = useWatch<
    BookingFormValues,
    ["globalTitle", "bookingType", "globalCategory"]
  >({
    name: ["globalTitle", "bookingType", "globalCategory"]
  });
  const isRepeated = bookingType === BookingType.Repeated;

  const { engagementSelectQuery, engagementSelectParseResponse } = useEngagementQuery({
    isAudit: false
  });

  const handleChange = useCallback(
    (engagementOption: SimpleEngagement, onChange: (value: any) => void) => {
      const isTitlePreviousEngagementName = selectedEngagement?.name === title;
      const isCategoryPreviousEngagementCategory = selectedEngagement?.booking_category_id === category?.id;

      if (engagementOption) {
        if (!title || isTitlePreviousEngagementName) {
          setValue("globalTitle", truncateTitle(engagementOption.name));
        }
        if (
          !isNil(engagementOption.booking_category_id) &&
          (!category || isCategoryPreviousEngagementCategory)
        ) {
          setValue("globalCategory", getBookingCategory(engagementOption.booking_category_id), {
            shouldValidate: true
          });
        }
      }
      setSelectedEngagement(engagementOption);
      onChange(engagementOption?.id);
    },
    [selectedEngagement, title, setValue, category, getBookingCategory]
  );

  useEffect(() => {
    const initialSelectedEngagement = isInitialActivityEngagement
      ? pick(activity, ["id", "name", "description"])
      : null;
    setSelectedEngagement(initialSelectedEngagement);
  }, [activity]);

  useUnmount(() => setValue("activityId", null));

  if (isLoading) {
    return <LoadingDots />;
  }

  return (
    <Controller
      name="activityId"
      control={control}
      render={({ field }) => (
        <EngagementSelect<ActivitiesResponse, SimpleEngagement>
          selectedEngagement={selectedEngagement}
          letClear={!isInitialActivityEngagement || mode !== BookingFormMode.Edit}
          query={engagementSelectQuery}
          parseResponse={engagementSelectParseResponse}
          handleChange={(option) => {
            handleChange(option, field.onChange);
          }}
          parseEngagementToOption={parseEngagementToOption}
          isRepeatedBooking={isRepeated}
        />
      )}
    />
  );
};
