import isNil from "lodash/isNil";
import { useBookingFormContext } from "PFApp/booking/components/booking_form";
import { BookingFormMode } from "PFApp/booking/components/booking_form/use_booking_form";
import { OnSuccessData } from "PFApp/booking/components/booking_form/use_handle_submit/use_handle_submit";
import BookingDeleteButton from "PFApp/booking/components/delete_booking_modal/booking_delete_button";
import { DeleteMode } from "PFApp/booking/components/delete_booking_modal/delete_booking.types";
import { useDetailsPanelApiContext } from "PFApp/booking/components/details_panel";
import { TemplateKey } from "PFApp/constants/templates";
import { Alert } from "PFComponents/alert";
import { Button } from "PFComponents/button";
import { LoadingDots } from "PFComponents/loading_dots";
import { isProfileActive } from "PFCore/helpers/profile";
import { useBooking } from "PFCore/hooks/queries";
import useBookingCategories from "PFCore/hooks/use_booking_categories";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { Booking } from "PFTypes/booking";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useTemplate } from "../../../../../../hooks";
import { useBookingActivityContext } from "../../../../providers/booking_activity_context_provider";
import css from "../../detail_view.module.scss";
import { useBookingDetailSidePanelContext } from "../booking_detail_side_panel_context";
import { CloneButton } from "./clone_button";
import { ReassignButton } from "./reassign_button";

export const getDeleteMode = (activityId: number | null, activitySiblingsCount: number): DeleteMode => {
  if (!activityId) {
    return DeleteMode.NoActivityBooking;
  }
  if (activitySiblingsCount > 0) {
    return DeleteMode.MultipleBookings;
  }
  return DeleteMode.LastBooking;
};

interface ButtonsProps {
  profileFullName: string;
  onDeleted?: (booking: Booking) => Promise<void>;
  onEdit: (data: OnSuccessData) => void;
}

const Buttons = ({ profileFullName, onDeleted, onEdit }: ButtonsProps) => {
  const { modal } = useBookingFormContext();
  const { closeDetailsPanel } = useDetailsPanelApiContext();
  const { utc } = useDateFormatter();
  const { t } = useTranslation(["bookingModule", "translation"]);
  const { profile, siblingBookings, currentSiblingBookingIndex, bookingTemplate, isBookingTemplateFetching } =
    useBookingDetailSidePanelContext();

  const { activity } = useBookingActivityContext();
  const template = useTemplate(activity?.template_id);
  const isEngagement =
    !!template && [TemplateKey.Engagement, TemplateKey.AuditEngagement].includes(template.key);

  const currentSiblingBooking = !isNil(currentSiblingBookingIndex)
    ? siblingBookings?.[currentSiblingBookingIndex]
    : undefined;

  const { getCategory } = useBookingCategories();
  const isCategoryHidden = currentSiblingBooking ? !getCategory(currentSiblingBooking) : false;
  // buttons need to fetch full booking details to check source and readonly
  const { data: booking, isFetching: isBookingFetching } = useBooking(currentSiblingBooking?.id, {
    enabled: !!currentSiblingBooking?.id && !isCategoryHidden
  });

  const isSingleDayBookingTemplate = useMemo(
    () => bookingTemplate && utc(bookingTemplate.end_date).diff(bookingTemplate.start_date, "days") === 0,
    [bookingTemplate, utc]
  );

  if (isBookingFetching) {
    return <LoadingDots centered circlesEnabled circleSize="xs" className={css.buttonsLoader} />;
  } else if (!booking || isCategoryHidden) {
    return null;
  }

  const {
    id,
    booking_template_id: bookingTemplateId,
    activity_siblings_count, // eslint-disable-line camelcase
    source,
    readonly
  } = booking || {};

  const handleEdit = () => {
    const isSuspended = !!profile?.suspendedAt;
    modal.open(
      { bookingId: id, ...(isSuspended ? { suspendedProfileDetails: profile } : {}) },
      { onSuccess: onEdit, mode: BookingFormMode.Edit }
    );
  };

  const deleteMode = getDeleteMode(booking.activity_id ?? null, activity_siblings_count);

  const cloneEnabled = isProfileActive(profile) && !booking?.booking_template_id;

  const handleDelete = (entity: Booking) => {
    closeDetailsPanel();
    return onDeleted ? onDeleted(entity) : Promise.resolve();
  };

  const handleClone = (data: OnSuccessData) => {
    closeDetailsPanel();
    return onEdit(data);
  };

  return (
    <div className={css.footer}>
      {source === "ui" ? (
        !readonly && (
          <div className={css.buttons}>
            <Button
              icon="edit"
              onClick={handleEdit}
              kind="secondary"
              disabled={Number.isInteger(bookingTemplateId) && isBookingTemplateFetching}
              title={t("bookingModule:bookings.update.editBooking")}
            />
            {cloneEnabled && <CloneButton bookingId={id} onSuccess={handleClone} />}
            {booking.activity_id && (
              <ReassignButton
                booking={booking}
                activity={activity}
                isEngagement={isEngagement}
                title={t("bookingModule:bookings.reassign.reassignBooking")}
              />
            )}
            <BookingDeleteButton
              deleteMode={deleteMode}
              booking={booking}
              profileFullName={profileFullName}
              onDeleted={handleDelete}
              isSingleDayBookingTemplate={isSingleDayBookingTemplate}
              title={t("bookingModule:bookings.delete.deleteBooking")}
            />
          </div>
        )
      ) : (
        <Alert title={t("bookingModule:bookings.delete.externalSourceWarning")} hideIcon small />
      )}
    </div>
  );
};

export default Buttons;
