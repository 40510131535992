import classNames from "classnames";
import uniqueId from "lodash/uniqueId";
import { Icon } from "PFComponents/icon/icon";
import { Typography } from "PFComponents/typography";
import { IconName } from "PFTheme/graphics/icons";
import { EventKey } from "PFTypes/event_key";
import { KeyboardEvent, PropsWithChildren, ReactNode, useCallback } from "react";

import { Animate } from "../containers";
import css from "./accordion.module.scss";
import { useAccordionState } from "./use_accordion_state";

const getIconName = (iconSet: IconSet, isOpen: boolean): IconName => {
  switch (iconSet) {
    case "chevron":
      return isOpen ? "chevron-down" : "chevron-right";
    case "caret":
      return isOpen ? "caret-down" : "caret-right";
  }
};

export type AccordionState = "open" | "closed";
type HeaderVariant = "bodyBold" | "h4" | "h5";
type IconSet = "chevron" | "caret";

export type AccordionProps = {
  header?: ReactNode;
  defaultState?: AccordionState;
  state?: AccordionState;
  onStateChange?: (state: AccordionState) => void;
  headerVariant?: HeaderVariant;
  headerFullWidth?: boolean;
  iconSet?: IconSet;
  actions?: React.ReactNode;
  animated?: boolean;
  trigger?: boolean;
  className?: string;
};

export const Accordion = ({
  header,
  defaultState = "open",
  state,
  onStateChange,
  children,
  headerVariant = "h4",
  headerFullWidth,
  iconSet = "caret",
  actions,
  animated = true,
  className
}: PropsWithChildren<AccordionProps>) => {
  const contentId = uniqueId("accordion-content-");
  const headerId = uniqueId("accordion-header-");

  const { toggle, isOpen } = useAccordionState({
    onStateChange,
    defaultState,
    state
  });

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key !== EventKey.Enter && event.key !== EventKey.Space) {
        return;
      }

      event.preventDefault();
      toggle();
    },
    [toggle]
  );

  const Wrapper = animated ? Animate : "div";

  return (
    <Wrapper className={classNames(css.root, className)}>
      {!!header && (
        <div className={css.header}>
          <div
            className={classNames(css.headerTitle, { [css.headerFullWidth]: headerFullWidth })}
            id={headerId}
            role="button"
            aria-expanded={isOpen}
            aria-controls={contentId}
            onClick={toggle}
            onKeyDown={handleKeyDown}
            tabIndex={0}
          >
            <Icon name={getIconName(iconSet, isOpen)} size="sm" />
            <Typography
              className={classNames({ [css.headerFullWidth]: headerFullWidth })}
              variant={headerVariant}
            >
              {header}
            </Typography>
          </div>
          {actions && <div>{actions}</div>}
        </div>
      )}
      {isOpen && (
        <div role="region" id={contentId} aria-labelledby={headerId} className={css.content}>
          <div className={classNames(!!header && css.contentMargin)}>{children}</div>
        </div>
      )}
    </Wrapper>
  );
};
