import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { useTemplate, useTemplateConfiguration } from "PFApp/hooks";
import { TableColumn } from "PFTypes";
import { Activity } from "PFTypes";

export const useExcludeHiddenColumns = () => {
  const { task } = useActivityPageContext() as { task: Activity };

  const template = useTemplate({ id: task.template_id });
  const templateSettings = useTemplateConfiguration(template);

  const rules = { statement: !(!!templateSettings?.hide_on_closed_roles && task.state === "complete") };

  return (columns: TableColumn[]) =>
    columns.filter(({ name }) => (Object.keys(rules).includes(name) ? rules[name] : true));
};
