import reduce from "lodash/reduce";
import { CurrentAccount, CurrentProfile, Segment } from "PFTypes";

export const getSegment = (
  currentAccount: CurrentAccount,
  currentProfile: CurrentProfile,
  storageSegment?: Segment
): Segment =>
  reduce(
    currentAccount.segment,
    (acc: Segment, segmentName: string) => {
      const segmentValue = storageSegment?.[segmentName] || currentProfile[segmentName];

      if (!segmentValue) {
        return acc;
      }

      return { ...acc, [segmentName]: segmentValue };
    },
    {}
  );
