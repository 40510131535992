import filter from "lodash/filter";
import find from "lodash/find";
import map from "lodash/map";
import { Typography } from "PFComponents/typography";
import { getVisibleProperties } from "PFCore/helpers/templates";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { FeatureFlag } from "PFTypes";

import { useActivityPageContext } from "../../../../../../activity_page_context";
import { useActivityTemplate } from "../../../../../../hooks/use_activity_template";
import css from "./segment_information.module.scss";

export const SegmentInformation = () => {
  const { task: activity } = useActivityPageContext();
  const template = useActivityTemplate();
  const isSegmentEnabled = useIsFeatureEnabled()(FeatureFlag.Segments);
  const templateProperties = template ? getVisibleProperties(template) : [];
  const segmentAttributes = filter(templateProperties, "segment");

  if (!isSegmentEnabled) {
    return null;
  }

  return (
    <>
      {segmentAttributes.map(({ name }) => {
        const { values } = find(activity.custom_fields, ["type.name", name]) || { values: [] };

        if (values.length === 0) {
          return null;
        }

        const valuesString = map(values, "value").join(", ");

        return (
          <Typography
            key={name}
            variant="labelRegular"
            title={valuesString}
            className={css.segmentFieldValues}
          >
            {valuesString}
          </Typography>
        );
      })}
    </>
  );
};
