import { useAppContext } from "PFApp/app_context";
import { Button } from "PFComponents/button";
import useDebounce from "PFCore/helpers/use_debounce";
import useWindowSize from "PFCore/helpers/use_window_size";
import { useSynchronizedState } from "PFCore/hooks/use_synchronized_state";
import SearchFatIcon from "PFIcons/search_fat.svg";
import { SEARCH_ACTIONS } from "PFReducers/search_reducer/search_reducer";
import { Importance } from "PFTypes";
import PropTypes from "prop-types";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { matchesTab, modalFakeTab } from "../tabs_constants";
import css from "./search_box.less";

/* eslint-disable jsx-a11y/no-autofocus */
const SearchBox = ({ buttonHref }) => {
  const {
    dispatch,
    store: {
      search: {
        tab,
        term,
        choosenKeywords,
        suggestedKeywords,
        trackKeywordsIds,
        autoselectEnabled,
        matchTypes,
        filterTypes
      }
    }
  } = useAppContext();
  const { t } = useTranslation("search");
  const navigate = useNavigate();

  const [localTerm, setLocalTerm] = useSynchronizedState(term ?? "");

  const availableSearchables = useMemo(
    () =>
      [...matchTypes, ...filterTypes]
        // We want to at max show 5 custom fields, so we randomise the list, and pick the first 5
        .sort(() => 0.5 - Math.random())
        .slice(0, 5),
    [matchTypes, filterTypes]
  );

  // We always want to show skills in the search bar
  const displaySearchText = availableSearchables.map((customType) => customType.display_as).join(" / ");

  useEffect(() => {
    if (autoselectEnabled) {
      const type = SEARCH_ACTIONS.SEARCH_PUSH_CHOOSEN_KEYWORD;
      suggestedKeywords
        .filter(({ autoSelect }) => autoSelect)
        .filter(({ id }) => !trackKeywordsIds.includes(id)) // avoid being pushy with auto-select
        .filter(({ value }) => value?.length > 2)
        .forEach((keyword) => dispatch({ type, payload: { ...keyword, importance: Importance.Essential } }));
    }
  }, [JSON.stringify(suggestedKeywords)]);

  const showKeywords =
    term !== "" &&
    (tab === matchesTab || tab === modalFakeTab) &&
    (choosenKeywords.length > 0 || suggestedKeywords.length > 0);

  const debouncedHandleTermChange = useDebounce(() => {
    dispatch({ type: SEARCH_ACTIONS.SEARCH_MERGE_STATE, payload: { autoselectEnabled: true } });
    dispatch({ type: SEARCH_ACTIONS.SEARCH_SET_TERM, payload: localTerm });

    if (localTerm) {
      const termOrder = {
        direction: "desc",
        name: ["scores.term_score"],
        text: t("searchBox.mostRelevant")
      };
      dispatch({
        type: SEARCH_ACTIONS.SEARCH_MERGE_PROFILES_REQUEST_ORDER,
        payload: termOrder
      });
      dispatch({
        type: SEARCH_ACTIONS.SEARCH_MERGE_ACTIVITIES_REQUEST_ORDER,
        payload: termOrder
      });
    }
  }, 1000);

  const handleChange = (event) => {
    setLocalTerm(event.target.value);
    debouncedHandleTermChange();
  };

  const { windowWidth } = useWindowSize();

  const triggerSubmit = buttonHref
    ? () => navigate(buttonHref)
    : (event) => {
        event.preventDefault();
        dispatch({ type: SEARCH_ACTIONS.SEARCH_TRIGGER });
      };

  return (
    <label className={css.box} style={{ marginBottom: showKeywords ? 0 : 20 }} data-qa-id="SearchBox">
      <SearchFatIcon width={23} height={23} className={css.icon} />

      <div className={css.input}>
        <input
          autoFocus
          value={localTerm}
          onChange={handleChange}
          placeholder={
            windowWidth > 700
              ? t("searchBox.searchByText", { displayText: displaySearchText })
              : t("searchBox.searchByTextShort", { displayText: displaySearchText })
          }
        />
      </div>

      <Button onClick={triggerSubmit} disabled={localTerm.trim() === ""}>
        {t("searchBox.search")}
      </Button>
    </label>
  );
};

SearchBox.propTypes = {
  buttonHref: PropTypes.string
};

export default SearchBox;
