import { Camelized } from "humps";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { ActivityHistoryProfile } from "PFCore/services/activity";
import { ActivityEvent, Profile } from "PFTypes";
import { useTranslation } from "react-i18next";

import { useTranslatedActivityType } from "../../../hooks/use_translated_activity_type";
import { HistoryEntryDataPlaceholder } from "./history_entry_data_placeholder";
import { HistoryEntryProfile } from "./history_entry_profile";
import { HistoryEntryRow } from "./history_entry_row";

export type HistoryEntryHeaderProps = {
  profile: Camelized<Profile> | ActivityHistoryProfile | undefined;
  isLoading: boolean | undefined;
} & Pick<ActivityEvent, "event" | "createdAt">;

export const HistoryEntryHeader = ({ profile, event, createdAt, isLoading }: HistoryEntryHeaderProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.history" });
  const { formatDateTime } = useDateFormatter();
  const activityType = useTranslatedActivityType();

  if (isLoading) {
    return <HistoryEntryDataPlaceholder height="40px" />;
  }

  if (!profile) {
    return null;
  }

  return (
    <HistoryEntryRow>
      <HistoryEntryProfile
        profile={profile}
        subtitle={t(`entry.message.${event}` as unknown as TemplateStringsArray, {
          activityType,
          date: formatDateTime(createdAt)
        })}
      />
    </HistoryEntryRow>
  );
};
