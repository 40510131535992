import { decamelizeKeys } from "humps";
import map from "lodash/map";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { useActivityPageRoles } from "PFApp/activities/show/hooks/use_activity_page_roles";
import { TemplateKey } from "PFApp/constants/templates";
import PageTitle from "PFComponents/page/page_title";
import { useRolesInvalidate } from "PFCore/hooks/queries/roles/use_roles_invalidate";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ActivitiesTable } from "../../../../../../workflow/parts/activities_table";
import { NoLinkedRoles } from "../../../../parts/no_linked_roles";
import RolesActions from "./roles_actions";

export const ActivityRoles = () => {
  const { t } = useTranslation("activities", { keyPrefix: "roles" });

  const columns = useMemo(
    () => [
      { name: "name", title: t("roleTitle"), size: 2, type: "text" },
      { name: "id", title: t("id"), size: 1, type: "text" },
      { name: "status", title: t("status"), size: 1, type: "text" },
      { name: "workflow_state", title: t("workflowState"), size: 2, type: "text" },
      { name: "post_until", title: t("expiryDate"), size: 1, type: "date-time" },
      { name: "availability", title: t("availabilityRequirement"), size: 2, type: "availability" },
      { name: "assignee", title: t("assignee"), size: 2, type: "profile", attribute: ["assignee", "profile"] }
    ],
    [t]
  );

  const { roleKey, canCreateRoles, task: activity, isEngagement, isAudit } = useActivityPageContext();
  const { invalidateRoles, invalidateAuditRoles } = useRolesInvalidate();
  const { roles, changePage } = useActivityPageRoles();
  const { meta, entries } = decamelizeKeys(roles);

  const canCreate = activity.coowned && isEngagement && canCreateRoles;

  return (
    <>
      <PageTitle actions={<RolesActions canCreateRoles={canCreate} roleKey={roleKey} activity={activity} />}>
        {t("tabTitle")}
      </PageTitle>
      {entries.length === 0 ? (
        <NoLinkedRoles activity={activity} showActions={false} canCreateRoles={canCreate} roleKey={roleKey} />
      ) : (
        <ActivitiesTable
          type={isAudit ? TemplateKey.AuditRole : TemplateKey.Role}
          activities={map(entries, "activity") || []}
          columns={columns}
          onPageChange={(page) => changePage({ page, perPage: meta.per_page })}
          onRefetch={() => (isAudit ? invalidateAuditRoles() : invalidateRoles())}
        />
      )}
    </>
  );
};
