import { Flex, Inline } from "PFComponents/containers";
import { Tile } from "PFComponents/tile";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { FeatureFlag } from "PFTypes";
import { ReactNode } from "react";

import { SegmentComposer } from "../segment_composer/segment_composer";
import css from "./header_toolbox.module.scss";

type HeaderToolboxProps = {
  items?: ReactNode[];
  showSegment?: boolean;
  segmentDisabled?: boolean;
  className?: string;
};

export const HeaderToolbox = ({
  items = [],
  showSegment,
  segmentDisabled,
  className
}: HeaderToolboxProps) => {
  const isSegmentEnabled = useIsFeatureEnabled()(FeatureFlag.Segments);

  if (!items.length && (!isSegmentEnabled || !showSegment)) {
    return null;
  }

  return (
    <Tile paddingVariant="md" className={className} light>
      <Inline alignItems="center" gap={null}>
        {items.map((element, index) => (
          <Flex key={index} center className={css.item}>
            {element}
          </Flex>
        ))}
        {isSegmentEnabled && showSegment && (
          <SegmentComposer className={css.segment} disabled={segmentDisabled} />
        )}
      </Inline>
    </Tile>
  );
};
