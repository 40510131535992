import isFunction from "lodash/isFunction";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";

const GLOBAL_STORAGE_VERSION = 2;

export const getFullKey = (key: string) => `${key}[V${GLOBAL_STORAGE_VERSION}]`;

const useStorage = <T extends object | string | boolean | null>(
  key: string,
  initial: T,
  options?
): [T, Dispatch<SetStateAction<T>>] => {
  const fullKey = getFullKey(key);
  const storedValue = window.storage.getItem(fullKey);
  const [value, setValue] = useState<T>(storedValue !== null ? storedValue : initial);

  const setStorageValue = useCallback(
    (value: SetStateAction<T>) => {
      if (isFunction(value)) {
        setValue((prev) => {
          const storageValue = value(prev);
          window.storage.setItem(fullKey, storageValue, options);
          return storageValue;
        });
      } else {
        window.storage.setItem(fullKey, value, options);
        setValue(value);
      }
    },
    [fullKey, options]
  );

  useEffect(() => {
    setValue(storedValue !== null ? storedValue : initial);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullKey]);

  return [value, setStorageValue];
};

export default useStorage;
