import { ColumnHelper } from "@tanstack/react-table";
import { EconomicsVacancy, Id } from "PFTypes";
import { useCallback } from "react";

import css from "../vacancies_table.module.scss";
import { VacancyActions } from "../vacancy_actions";

export type UseActionsColumn = {
  scenarioId: Id;
  isEditable: boolean;
  onDelete: (vacancyId: Id) => void;
};

export const useActionsColumn = ({ scenarioId, isEditable, onDelete }: UseActionsColumn) =>
  useCallback(
    (columnHelper: ColumnHelper<EconomicsVacancy>) =>
      columnHelper.accessor((row) => row, {
        id: "actions",
        header: "",
        cell: (info) => (
          <span className={css.rightAlign}>
            <VacancyActions
              vacancy={info.getValue()}
              scenarioId={scenarioId!}
              disabled={!isEditable}
              onDelete={onDelete}
            />
          </span>
        ),
        enableSorting: false,
        size: 22
      }),
    [isEditable, onDelete, scenarioId]
  );
