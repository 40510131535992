import classNames from "classnames";
import { DropdownOption } from "PFComponents/dropdown/dropdown";
import { Select } from "PFComponents/select/select";

import { PillProps } from "../../pill";
import { DoubleSectionPill, DoubleSectionPillProps } from "../double_section_pill";
import css from "./select_pill.module.scss";

type SelectPillProps = Omit<DoubleSectionPillProps, "rightContent" | "rightContentClassName"> & {
  attribute: React.ReactElement | string;
  attributeOptions: DropdownOption[];
  onAttributeChange?: (newAttribute: DropdownOption["item"]) => void;
} & Pick<PillProps, "small">;

export const SelectPill = ({
  attribute,
  attributeOptions,
  onAttributeChange,
  small,
  ...rest
}: SelectPillProps) => (
  <DoubleSectionPill
    small={small}
    {...rest}
    rightContent={
      <span className={css.selectWrapper}>
        <Select
          readOnly
          controlledValue
          options={attributeOptions}
          value={attribute}
          onChange={onAttributeChange}
          className={classNames(css.customSelect, { [css.smallCustomSelect]: small })}
        />
      </span>
    }
    rightContentClassName={css.rightContentRoot}
  />
);
