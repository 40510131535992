import { useQuery } from "@tanstack/react-query";
import { useAppContext } from "PFApp/app_context";
import { BOOKING_CATEGORY_FILTER } from "PFApp/booking/parts/overview/booking_module_calendar/toolbar/booking_category_filter/booking_category_filter";
import { fetchWorkforce } from "PFApp/booking/services/api";
import {
  filterWithValueExists,
  getAPIFilters,
  handleRequestFiltersChange
} from "PFApp/use_filtered_collection";
import { CalendarRange, OrderOption, PaginatedCollection, Profile, QueryOptions } from "PFTypes";

import { workforceKeys } from "./query_keys";

export type WorkforceItem = {
  profile: Pick<Profile, "id" | "first_name" | "last_name" | "positions" | "suspended_at"> & {
    has_bookings: boolean;
  } & Record<string, any>;
  scores?: Record<string, any>;
  id: number;
  optional_fields: Record<string, any>;
};

export type WorkforceResponse = PaginatedCollection<
  WorkforceItem[],
  {
    total_billable_utilization?: number;
    dynamic_wm_info_custom_types?: Record<number, string>;
    search_id?: number;
  }
>;

export const useWorkforceEntries = (
  page: number = 1,
  perPage: number = 15,
  order: Partial<OrderOption>,
  filters: any,
  dateRange: CalendarRange,
  previousSearchId: number | undefined,
  options: QueryOptions<WorkforceResponse, { status: number }> = {}
) => {
  const {
    store: { segment }
  } = useAppContext();
  const apiFilters = getAPIFilters(filters, segment);
  return useQuery<WorkforceResponse, { status: number }>({
    retry: false,
    queryKey: workforceKeys.withParameters(apiFilters, order, dateRange, page, perPage),
    queryFn: () => {
      const filtersHaveBookingCategory = filterWithValueExists(filters, BOOKING_CATEGORY_FILTER);

      if (!filtersHaveBookingCategory) {
        return fetchWorkforce({
          page,
          perPage,
          order,
          filters: apiFilters,
          dateRange,
          previousSearchId
        }) as unknown as Promise<WorkforceResponse>;
      }

      const filtersWithoutBookingCategory = handleRequestFiltersChange(
        filters,
        { name: BOOKING_CATEGORY_FILTER },
        undefined
      );

      return (
        fetchWorkforce({
          page,
          perPage: 0,
          order,
          filters: getAPIFilters(filtersWithoutBookingCategory, segment),
          dateRange,
          previousSearchId
        }) as unknown as Promise<WorkforceResponse>
      ).then(
        ({ meta }) =>
          fetchWorkforce({
            page,
            perPage,
            order,
            filters: apiFilters,
            dateRange,
            previousSearchId: meta.search_id
          }) as unknown as Promise<WorkforceResponse>
      );
    },
    ...options
  });
};
