import { decamelizeKeys } from "humps";
import { ProfileCustomValuesList, ProfileCustomValuesListProps } from "PFApp/components/custom_values_list";
import { useCustomValuePreviewActions } from "PFApp/hooks/use_custom_value_preview_actions";
import { ActionIcon } from "PFComponents/action_icon";
import { Carousel, CarouselActions, CarouselItem } from "PFComponents/carousel";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useDictionaryConnectionCreate } from "PFCore/hooks/queries/custom_values/use_dictionary_connection_create";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { useSkillsType } from "PFCore/hooks/use_skills_type";
import { fetchRareSkills } from "PFCore/services/skills/fetch_rare_skills";
import { ActivityPureCustomValue, CustomValueScope, Experience, Profile } from "PFTypes";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useToggle } from "react-use";

import { getCanEditCustomFieldData } from "../../../../components/custom_values_edit_field/custom_values_edit_field.utils";
import { useProfileSuggestedSkills } from "../../hooks/use_profile_suggested_skills";
import { OnProfileUpdateHandler } from "../../profile_show_page.types";
import { Skill } from "../common/skill";
import { PageSection } from "../page_section";
import { AddSkillsButton } from "./add_skills_button";
import { NoItemsToShow } from "./no_items_to_show";
import { SkillsFrameworksSuggestedSkillsModal } from "./skills_frameworks_suggested_skills_modal";
import css from "./skills_groups.module.scss";
import { SkillsTile } from "./skills_tile";

const SKILL_TRUNCATE_LENGTH = 20;

type SkillsGroupsProps = {
  profile: Profile;
  className: string;
  onProfileUpdate: OnProfileUpdateHandler;
};

const SkillsGroups = ({
  profile,
  className,
  onProfileUpdate
}: SkillsGroupsProps): React.ReactElement | null => {
  const { data: currentAccount } = useCurrentAccount();
  const { data: currentProfile } = useCurrentProfile();
  const { t } = useTranslation("profiles", { keyPrefix: "show.parts" });
  const { showPreview } = useCustomValuePreviewActions();
  const { mutateAsync: createDictionaryConnection } = useDictionaryConnectionCreate();

  const [isSkillsFrameworksModalOpened, toggleSkillsFrameworksModalOpened] = useToggle(false);
  const isMe = profile.id === currentProfile.id;

  const { suggestedFrameworksSkills, isLoading: isLoadingSuggestedFrameworksSkills } =
    useProfileSuggestedSkills({ profileId: profile.id, enabled: isMe });

  const [rareSkills, setRareSkills] = useState([]);
  const [updatingSkills, setUpdatingSkills] = useState(false);

  useEffect(() => {
    fetchRareSkills(profile.id).then((response) => setRareSkills(response.entries));
  }, [profile.id]);

  const skillsType = useSkillsType();

  const isAllowedToEdit =
    isMe &&
    skillsType &&
    !getCanEditCustomFieldData({
      adminPage: false,
      customType: skillsType,
      profile,
      isActivity: false,
      forceUnlocked: false,
      currentProfile,
      t
    }).locked;

  const [coreSkills, developmentalSkills] = useMemo(() => {
    const allSkills = profile?.skills || [];
    return [allSkills.filter((skill) => skill.top), allSkills.filter((skill) => skill.developmental)];
  }, [profile]);

  const suggestedSkills = useMemo(
    () => (profile.suggestions?.skills || []).map((skill) => ({ text: String(skill.value), ...skill })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [(profile.suggestions?.skills || []).map(({ id }) => id).join(",")]
  );

  const getInfoTooltipContent = (selectedOptionId: "tag_as_core" | "tag_as_developmental") => (
    <div className={css.skillItemContainer}>
      <Skill
        skill={{ text: t("skillsModal.skill"), experience: Experience.Basic }}
        staticProps={{
          cursorPointer: true,
          keepOptionsOpen: true,
          selectedOptionId
        }}
      />
    </div>
  );

  const onRemoveSuggestion = (event, skillId) => {
    event.stopPropagation();
    setUpdatingSkills(true);
    return createDictionaryConnection({
      interest: false,
      experience: Experience.None,
      customValueId: skillId
    }).then(() =>
      onProfileUpdate().then(() => {
        setUpdatingSkills(false);
      })
    );
  };

  const onMissingSkillClick = (skillId) =>
    showPreview({
      customTypeId: skillsType!.id,
      customValueId: skillId,
      scope: CustomValueScope.Profile,
      profileId: profile.id,
      canEdit: true,
      isNew: true
    });

  const renderRemoveSuggestionIcon = (skillId) => (
    <ActionIcon
      name="cross"
      size="sm"
      onClick={(event) => onRemoveSuggestion(event, skillId)}
      disabled={updatingSkills || isLoadingSuggestedFrameworksSkills}
    />
  );

  const commonSkillsListProps: Omit<ProfileCustomValuesListProps, "customValues"> = {
    customFieldName: "skills",
    profileId: profile.id,
    maxNameLength: SKILL_TRUNCATE_LENGTH,
    canEdit: true,
    onMissingClick: onMissingSkillClick
  };

  const items: CarouselItem[] = [
    {
      content: (
        <SkillsTile
          title={t("skillsModal.suggestions.framework.title")}
          tooltipContent={t("skillsModal.suggestions.framework.info", { name: currentAccount.name })}
          customHeaderRightContent={
            <ActionIcon
              name="open"
              size="sm"
              classes={{ root: css.skillsFrameworksModalIcon }}
              onClick={toggleSkillsFrameworksModalOpened}
              aria-label={t("openSkillsFrameworksSuggestionsModal")}
            />
          }
        >
          <ProfileCustomValuesList
            {...commonSkillsListProps}
            customValues={
              suggestedFrameworksSkills.map((customValue) =>
                decamelizeKeys(customValue)
              ) as ActivityPureCustomValue[]
            }
            renderCustomValueRightContent={renderRemoveSuggestionIcon}
          />
        </SkillsTile>
      ),
      hide: !isMe || suggestedFrameworksSkills.length === 0
    },
    {
      content: (
        <SkillsTile title={t("coreSkills")} tooltipContent={getInfoTooltipContent("tag_as_core")}>
          {coreSkills.length ? (
            <ProfileCustomValuesList {...commonSkillsListProps} customValues={coreSkills} />
          ) : (
            <NoItemsToShow />
          )}
        </SkillsTile>
      )
    },
    {
      content: (
        <SkillsTile
          title={t("developmentalSkills")}
          tooltipContent={getInfoTooltipContent("tag_as_developmental")}
        >
          {developmentalSkills.length ? (
            <ProfileCustomValuesList {...commonSkillsListProps} customValues={developmentalSkills} />
          ) : (
            <NoItemsToShow />
          )}
        </SkillsTile>
      ),
      hide: !isMe && developmentalSkills.length === 0
    },
    {
      content: (
        <SkillsTile title={t("suggestedSkills")} tooltipContent={t("skillsModal.suggestions.suggested.info")}>
          <ProfileCustomValuesList
            {...commonSkillsListProps}
            customValues={suggestedSkills.slice(0, 5)}
            renderCustomValueRightContent={renderRemoveSuggestionIcon}
          />
        </SkillsTile>
      ),
      hide: !isMe || suggestedSkills.length === 0
    },
    {
      content: (
        <SkillsTile title={t("rareSkills")} tooltipContent={t("rareSkillsInfo")}>
          <ProfileCustomValuesList {...commonSkillsListProps} customValues={rareSkills} />
        </SkillsTile>
      ),
      hide: rareSkills.length === 0
    }
  ]
    .filter(({ hide }) => !hide)
    .map((item, index) => ({ id: index, ...item }));

  const [index, setIndex] = useState(0);

  const action = (
    <CarouselActions
      onClickLeft={() => setIndex(Math.max(index - 1, 0))}
      onClickRight={() => setIndex(Math.min(index + 1, items.length - 1))}
      index={index}
      scrollLength={items.length - 1}
    />
  );

  const noSkills =
    coreSkills.length +
      developmentalSkills.length +
      suggestedSkills.length +
      rareSkills.length +
      suggestedFrameworksSkills.length ===
    0;

  return !noSkills || isMe ? (
    <PageSection
      id="skills-groups"
      className={className}
      contentStyle={{ overflow: "auto" }}
      title={t("skillsGroups")}
      action={action}
      skipToSection={{ targetId: "organisation-data", text: t("skipLinks.organisationData") }}
    >
      {noSkills && isAllowedToEdit && <AddSkillsButton />}
      {!noSkills && <Carousel items={items} activeIndex={index} visibleItems={2} />}
      {isSkillsFrameworksModalOpened && (
        <SkillsFrameworksSuggestedSkillsModal
          handleClose={toggleSkillsFrameworksModalOpened}
          profile={profile}
        />
      )}
    </PageSection>
  ) : null;
};

export default SkillsGroups;
