import { decamelizeKeys } from "humps";
import flatten from "lodash/flatten";
import isEmpty from "lodash/isEmpty";
import { TemplateKey } from "PFApp/constants/templates";
import { fetchPaginatedCollection } from "PFCore/helpers/collection";
import { getId } from "PFCore/helpers/get_id";
import { getVisibleProperties } from "PFCore/helpers/templates";
import initialSearch from "PFCore/initial_state/search";
import { createActivity, fetchActivity, updateActivity } from "PFCore/services/activities";
import { fetchKeywords } from "PFCore/services/keywords";
import { fetchProfiles } from "PFCore/services/profile";
import { ApiRoute } from "PFCore/utilities/routes";

import getSearchTask from "./get_search_task_model";
import { prepareMatchesCollectionFetch } from "./helpers/fetch_matches_collection";
import { isMatch, normalizeList } from "./helpers/normalize_keywords";
import mergeMetaFilters from "./merge_meta_filters";
import pushKeyword from "./push_keyword";
import removeKeyword from "./remove_keyword";

let abortKeywordsController;
let abortProfilesController;

export const SEARCH_ACTIONS = {
  SEARCH_TRIGGER: "SEARCH_TRIGGER",
  SEARCH_MERGE_STATE: "SEARCH_MERGE_STATE",
  SEARCH_SET_TAB: "SEARCH_SET_TAB",
  SEARCH_SET_TERM: "SEARCH_SET_TERM",
  SEARCH_MORE_ACTIVITIES: "SEARCH_MORE_ACTIVITIES",
  SEARCH_MORE_MATCHES: "SEARCH_MORE_MATCHES",
  SEARCH_MORE_PROFILES: "SEARCH_MORE_PROFILES",
  SEARCH_TOGGLE_FILTERS: "SEARCH_TOGGLE_FILTERS",
  SEARCH_MERGE_ACTIVITIES_META_FILTERS: "SEARCH_MERGE_ACTIVITIES_META_FILTERS",
  SEARCH_MERGE_PROFILES_META_FILTERS: "SEARCH_MERGE_PROFILES_META_FILTERS",
  SEARCH_MERGE_MATCHES_META_FILTERS: "SEARCH_MERGE_MATCHES_META_FILTERS",
  SEARCH_SUGGESTED_KEYWORDS_FROM_RESP: "SEARCH_SUGGESTED_KEYWORDS_FROM_RESP",
  SEARCH_SUGGESTED_KEYWORDS_CYCLCE_PAGE_INCREMENT: "SEARCH_SUGGESTED_KEYWORDS_CYCLCE_PAGE_INCREMENT",
  SEARCH_RESET_STATE: "SEARCH_RESET_STATE",
  SEARCH_START_LOADING_MATCHES: "SEARCH_START_LOADING_MATCHES",
  SEARCH_START_LOADING_PROFILES: "SEARCH_START_LOADING_PROFILES",
  SEARCH_CLEAR_MATCHES: "SEARCH_CLEAR_MATCHES",
  SEARCH_CLEAR_PROFILES: "SEARCH_CLEAR_PROFILES",
  SEARCH_CLEAR_ACTIVITIES: "SEARCH_CLEAR_ACTIVITIES",
  SEARCH_MATCHES_DONE: "SEARCH_MATCHES_DONE",
  SEARCH_ACTIVITIES_DONE: "SEARCH_ACTIVITIES_DONE",
  SEARCH_UPDATE_ACTIVITY: "SEARCH_UPDATE_ACTIVITY",
  SEARCH_UPDATE_PAGINATION: "SEARCH_UPDATE_PAGINATION",
  SEARCH_PROFILES_DONE: "SEARCH_PROFILES_DONE",
  SEARCH_PUSH_CHOOSEN_KEYWORD: "SEARCH_PUSH_CHOOSEN_KEYWORD",
  SEARCH_REMOVE_CHOOSEN_KEYWORD: "SEARCH_REMOVE_CHOOSEN_KEYWORD",
  SEARCH_CLEAR_CHOOSEN_KEYWORD: "SEARCH_CLEAR_CHOOSEN_KEYWORD",
  SEARCH_PUSH_UPDATE_FROM_TASK_RESP_TO_MATCHES: "SEARCH_PUSH_UPDATE_FROM_TASK_RESP_TO_MATCHES",
  SEARCH_TASK_SET: "SEARCH_TASK_SET",
  SEARCH_ABORT_ACTIVITIES: "SEARCH_ABORT_ACTIVITIES",
  SEARCH_MERGE_PROFILES_REQUEST_FILTERS: "SEARCH_MERGE_PROFILES_REQUEST_FILTERS",
  SEARCH_MERGE_PROFILES_REQUEST_ORDER: "SEARCH_MERGE_PROFILES_REQUEST_ORDER",
  SEARCH_CLEAR_PROFILES_FILTERS: "SEARCH_CLEAR_PROFILES_FILTERS",
  SEARCH_MERGE_ACTIVITIES_REQUEST_FILTERS: "SEARCH_MERGE_ACTIVITIES_REQUEST_FILTERS",
  SEARCH_MERGE_ACTIVITIES_REQUEST_ORDER: "SEARCH_MERGE_ACTIVITIES_REQUEST_ORDER",
  SEARCH_MERGE_MATCHES_REQUEST_FILTERS: "SEARCH_MERGE_MATCHES_REQUEST_FILTERS",
  SEARCH_MERGE_MATCHES_REQUEST_ORDER: "SEARCH_MERGE_MATCHES_REQUEST_ORDER",
  SEARCH_SET_PROFILES_REQUEST_FILTERS: "SEARCH_SET_PROFILES_REQUEST_FILTERS",
  SEARCH_SET_ACTIVITIES_REQUEST_FILTERS: "SEARCH_SET_ACTIVITIES_REQUEST_FILTERS",
  SEARCH_SET_MATCHES_REQUEST_FILTERS: "SEARCH_SET_MATCHES_REQUEST_FILTERS",
  SEARCH_SET_MATCHES_COLLECTION: "SEARCH_SET_MATCHES_COLLECTION",
  SEARCH_SET_TYPES: "SEARCH_SET_TYPES",
  SET_AVAILABLE_TEMPLATES: "SET_AVAILABLE_TEMPLATES",
  SET_ACTIVITIES_PAGINATION: "SET_ACTIVITIES_PAGINATION",
  SET_DEFAULT_ORDER: "SET_DEFAULT_ORDER",
  SEARCH_ALL_ACTIVITIES_DONE: "SEARCH_ALL_ACTIVITIES_DONE",
  SEARCH_FILTER_ACTIVITIES: "SEARCH_FILTER_ACTIVITIES"
};

export const searchReducer = (store, { type, payload }) => {
  const mergeSearchData = (newData) => ({ ...store, search: { ...store.search, ...newData } });
  const { search } = store;

  switch (type) {
    case SEARCH_ACTIONS.SEARCH_TRIGGER:
      return mergeSearchData({ triggerCounter: search.triggerCounter + 1 });

    case SEARCH_ACTIONS.SEARCH_MERGE_STATE:
      return mergeSearchData(payload);

    case SEARCH_ACTIONS.SEARCH_SET_TAB:
      return mergeSearchData({ tab: payload });

    case SEARCH_ACTIONS.SEARCH_SET_TERM:
      return mergeSearchData({ term: payload || "" });

    case SEARCH_ACTIONS.SEARCH_MORE_MATCHES: {
      return mergeSearchData({
        matchesPage: search.matchesPage + (search.matchesNextPagePending ? 0 : 1),
        matchesNextPagePending: true
      });
    }

    case SEARCH_ACTIONS.SEARCH_MORE_PROFILES:
      return mergeSearchData({
        profilesPage: search.profilesPage + (search.profilesNextPagePending ? 0 : 1),
        profilesNextPagePending: true
      });

    case SEARCH_ACTIONS.SEARCH_TOGGLE_FILTERS:
      return mergeSearchData({ filtersShown: !search.filtersShown });

    case SEARCH_ACTIONS.SEARCH_MERGE_ACTIVITIES_META_FILTERS:
      return mergeSearchData(mergeMetaFilters(search, "activitiesMeta", payload));

    case SEARCH_ACTIONS.SEARCH_MERGE_PROFILES_META_FILTERS:
      return mergeSearchData(mergeMetaFilters(search, "profilesMeta", payload));

    case SEARCH_ACTIONS.SEARCH_CLEAR_PROFILES_FILTERS:
      return mergeSearchData({
        profilesRequestFilters: {},
        profilesRequestOrder: {},
        profilesMeta: { ...search.profilesMeta, filters: [] }
      });

    case SEARCH_ACTIONS.SEARCH_MERGE_MATCHES_META_FILTERS:
      return mergeSearchData(mergeMetaFilters(search, "matchesMeta", payload));

    case SEARCH_ACTIONS.SEARCH_SUGGESTED_KEYWORDS_FROM_RESP:
      return mergeSearchData({
        suggestedKeywordsCyclePage: 1,
        suggestedKeywords: normalizeList(
          payload,
          store.search.customTypes,
          store.search.matchTypes,
          store.search.filterTypes
        ),
        pendingKeywordSearch: false
      });

    case SEARCH_ACTIONS.SEARCH_RESET_STATE:
      return {
        ...store,
        search: initialSearch(payload || {})
      };

    case SEARCH_ACTIONS.SEARCH_START_LOADING_MATCHES:
      return mergeSearchData({
        prevSearchTaskFetch: payload.prevSearchTaskFetch || search.prevSearchTaskFetch,
        // prevSearchMatchesFetch: ???
        matchesPending: true
      });

    case SEARCH_ACTIONS.SEARCH_START_LOADING_PROFILES:
      return mergeSearchData({
        profilesPending: true
      });

    case SEARCH_ACTIONS.SEARCH_CLEAR_MATCHES:
      return mergeSearchData({
        matchesPending: false,
        prevMatchesFetch: false,
        matches: [],
        matchesMeta: {}
      });

    case SEARCH_ACTIONS.SEARCH_CLEAR_PROFILES:
      return mergeSearchData({ profilesPending: false, profiles: [], profilesMeta: {} });

    case SEARCH_ACTIONS.SEARCH_CLEAR_ACTIVITIES:
      return mergeSearchData({ activitiesPending: false, activities: [], activitiesMeta: {} });

    case SEARCH_ACTIONS.SEARCH_MATCHES_DONE:
      return mergeSearchData({
        // infinite pagination:
        matches: search.matchesPage === 1 ? payload.entries : [...search.matches, ...payload.entries],
        matchesMeta: payload.meta,
        matchesPending: false,
        prevMatchesFetch: false,
        matchesNextPagePending: false
      });

    case SEARCH_ACTIONS.SEARCH_UPDATE_ACTIVITY: {
      const activity = search.activities.find(({ id }) => id === payload.id);
      const index = search.activities.indexOf(activity);
      if (index >= 0) {
        search.activities[index] = payload;
      }
      return mergeSearchData({ activities: [...search.activities] });
    }

    case SEARCH_ACTIONS.SEARCH_UPDATE_PAGINATION: {
      const { activitiesPagination, tab } = search;

      return mergeSearchData({
        activitiesPagination: {
          ...activitiesPagination,
          [tab]: activitiesPagination[tab] + 1
        },
        activitiesPending: true
      });
    }

    case SEARCH_ACTIONS.SEARCH_ALL_ACTIVITIES_DONE:
      return mergeSearchData({
        // infinite pagination:
        allActivities: payload,
        allActivitiesMeta: Object.keys(payload).reduce(
          (acc, item) => ({ ...acc, [item]: payload[item].meta }),
          {}
        ),
        activitiesPending: false,
        activitiesNextPagePending: false
      });

    case SEARCH_ACTIONS.SEARCH_PROFILES_DONE:
      return mergeSearchData({
        // infinite pagination:
        profiles: search.profilesPage === 1 ? payload.entries : [...search.profiles, ...payload.entries],
        profilesMeta: payload.meta,
        profilesPending: false,
        profilesNextPagePending: false
      });

    case SEARCH_ACTIONS.SEARCH_PUSH_CHOOSEN_KEYWORD:
      return mergeSearchData(pushKeyword(search, store, payload));

    case SEARCH_ACTIONS.SEARCH_REMOVE_CHOOSEN_KEYWORD:
      return mergeSearchData(removeKeyword(search, store, payload));

    case SEARCH_ACTIONS.SEARCH_CLEAR_CHOOSEN_KEYWORD:
      return mergeSearchData({ choosenKeywords: [] });

    case SEARCH_ACTIONS.SEARCH_SUGGESTED_KEYWORDS_CYCLCE_PAGE_INCREMENT:
      return mergeSearchData({ suggestedKeywordsCyclePage: search.suggestedKeywordsCyclePage + 1 });

    case SEARCH_ACTIONS.SEARCH_PUSH_UPDATE_FROM_TASK_RESP_TO_MATCHES: {
      // KEYWORDS needs to be noremalized
      // [{ type: {id,type}, values:[{id,weight}, ...] }, ...] -> [{id, weight, type, match, filterKey}, ...]
      const items = flatten(
        (payload.custom_fields || []).map(({ values, type }) =>
          (values || []).map((val) => ({ ...val, type }))
        )
      );

      const normalizedKeywords = normalizeList(
        items,
        store.search.customTypes,
        store.search.matchTypes,
        store.search.filterTypes
      );
      const pushOne = (mem, keyword) => pushKeyword(mem, store, keyword);
      const newSearch = normalizedKeywords.reduce(pushOne, { ...search });

      return mergeSearchData(newSearch);
    }

    case SEARCH_ACTIONS.SEARCH_TASK_SET:
      return mergeSearchData({
        searchTaskId: payload.id,
        searchTask: payload,
        deletedKeywords: []
      });

    case SEARCH_ACTIONS.SEARCH_ABORT_ACTIVITIES:
      if (search.prevActivitiesFetch) {
        search.prevActivitiesFetch.abort();
      }
      return mergeSearchData({ prevActivitiesFetch: null });

    case SEARCH_ACTIONS.SEARCH_SET_MATCHES_REQUEST_FILTERS:
      return mergeSearchData({ matchesRequestFilters: { ...payload } });
    case SEARCH_ACTIONS.SEARCH_SET_MATCHES_COLLECTION:
      return mergeSearchData({ collectionKind: payload });
    case SEARCH_ACTIONS.SEARCH_SET_PROFILES_REQUEST_FILTERS:
      return mergeSearchData({ profilesRequestFilters: { ...payload } });

    case SEARCH_ACTIONS.SEARCH_SET_ACTIVITIES_REQUEST_FILTERS:
      return mergeSearchData({
        activitiesRequestFilters: {
          ...search.activitiesRequestFilters,
          [search.tab]: payload
        }
      });

    case SEARCH_ACTIONS.SEARCH_MERGE_PROFILES_REQUEST_FILTERS: {
      return mergeSearchData({
        profilesRequestFilters: payload,
        profilesPage: 1
      });
    }

    case SEARCH_ACTIONS.SEARCH_MERGE_PROFILES_REQUEST_ORDER: {
      return mergeSearchData({
        profilesRequestOrder: payload,
        profilesPage: 1
      });
    }

    case SEARCH_ACTIONS.SEARCH_MERGE_ACTIVITIES_REQUEST_FILTERS:
      return mergeSearchData({
        activitiesRequestFilters: {
          ...search.activitiesRequestFilters,
          [search.tab]: payload
        },
        ...(search.activitiesPagination[search.tab] !== 1
          ? { activitiesPagination: { ...search.activitiesPagination, [search.tab]: 1 } }
          : {})
      });

    case SEARCH_ACTIONS.SEARCH_MERGE_ACTIVITIES_REQUEST_ORDER:
      return mergeSearchData({
        activitiesRequestOrder: {
          ...search.activitiesRequestOrder,
          [search.tab]: payload
        },
        activitiesPagination: { ...search.activitiesPagination, [search.tab]: 1 }
      });

    case SEARCH_ACTIONS.SEARCH_MERGE_MATCHES_REQUEST_FILTERS: {
      const changesCopy = { ...payload };

      const newChoosenKeywords = search.choosenKeywords.filter((keyword) => {
        const ids = changesCopy[keyword.type.name];
        return isMatch(keyword) || (ids && ids.indexOf(keyword.id) > -1);
      });

      return mergeSearchData({
        choosenKeywords: newChoosenKeywords,
        matchesRequestFilters: payload,
        matchesPage: 1
      });
    }

    case SEARCH_ACTIONS.SEARCH_MERGE_MATCHES_REQUEST_ORDER:
      return mergeSearchData({
        matchesRequestOrder: payload,
        matchesPage: 1
      });

    case SEARCH_ACTIONS.SEARCH_SET_TYPES: {
      const { currentProfile } = payload;
      const searchTemplate = currentProfile.templates.find(({ key }) => key === TemplateKey.Task);
      const visibleProperties = getVisibleProperties(searchTemplate);

      const matchTypes = [];
      const filterTypes = [];

      visibleProperties.forEach((property) => {
        if (property.type === "custom_field") {
          const customType = currentProfile.custom_types.find((type) => type.name === property.name);
          if (customType && property.match) {
            matchTypes.push(customType);
          } else if (customType && property.filter) {
            filterTypes.push(customType);
          }
        }
      });

      return mergeSearchData({ matchTypes, filterTypes, customTypes: currentProfile.custom_types });
    }

    case SEARCH_ACTIONS.SET_AVAILABLE_TEMPLATES: {
      return mergeSearchData({ availableTemplates: payload.availableTemplates });
    }

    case SEARCH_ACTIONS.SET_ACTIVITIES_PAGINATION: {
      const activitiesPagination = payload.availableTemplates.reduce(
        (acc, item) => ({ ...acc, [item.key]: 1 }),
        {}
      );
      return mergeSearchData({ activitiesPagination });
    }

    default:
      return {};
  }
};

export const SEARCH_ASYNC_ACTIONS = {
  SEARCH_TRIGGER_MATCHES_FETCH: "SEARCH_TRIGGER_MATCHES_FETCH",
  SEARCH_FETCH_PROFILES: "SEARCH_FETCH_PROFILES",
  SEARCH_FETCH_SUGGESTED_KEYWORDS: "SEARCH_FETCH_SUGGESTED_KEYWORDS",
  SEARCH_FETCH_ALL_ACTIVITIES: "SEARCH_FETCH_ALL_ACTIVITIES"
};

export const searchDispatch = (dispatch, { type, payload }) => {
  switch (type) {
    // this starts with fetching search task and then proceed (maybe) to fetching matches themselves
    case SEARCH_ASYNC_ACTIONS.SEARCH_TRIGGER_MATCHES_FETCH: {
      const [searchTask, needsASave] = getSearchTask(payload);

      const fetchMatchesCollection = prepareMatchesCollectionFetch({ dispatch, payload });
      if (!needsASave) {
        if (!payload.prevMatchesFetch) {
          fetchMatchesCollection(searchTask);
        }
        break;
      }

      // save triggers matches recalculation, so just fetch task if it was not fetched yet
      const searchTaskSyncPromise =
        payload.searchTaskFetchCounter === 0 && searchTask.id
          ? fetchActivity(searchTask.id)
          : searchTask.id
          ? updateActivity(searchTask.id, searchTask)
          : createActivity(searchTask);

      dispatch({
        type: SEARCH_ACTIONS.SEARCH_MERGE_STATE,
        payload: { searchTaskFetchCounter: payload.searchTaskFetchCounter + 1 }
      });

      searchTaskSyncPromise
        .then((resp) => {
          // TODO: [PROF-5746] Remove decamelizeKeys when app is camelized
          const decamelizedResponse = decamelizeKeys(resp);

          if (decamelizedResponse.template_id !== payload.taskTemplateId) {
            throw { status: 404 };
          }
          return decamelizedResponse;
        })
        .then((resp) => {
          dispatch({ type: SEARCH_ACTIONS.SEARCH_PUSH_UPDATE_FROM_TASK_RESP_TO_MATCHES, payload: resp });
          dispatch({ type: SEARCH_ACTIONS.SEARCH_TASK_SET, payload: resp });
          if (!payload.prevMatchesFetch) {
            fetchMatchesCollection(resp);
          }
        })
        .catch((error) => {
          if (error.status === 404) {
            // looks like the searchTaskId is wrong
            dispatch({ type: SEARCH_ACTIONS.SEARCH_MERGE_STATE, payload: { searchTaskId: null } });
          }
          dispatch({ type: SEARCH_ACTIONS.SEARCH_CLEAR_MATCHES });
        });
      break;
    } // </SEARCH_TRIGGER_MATCHES_FETCH>

    case SEARCH_ASYNC_ACTIONS.SEARCH_FETCH_PROFILES: {
      if (abortProfilesController) {
        abortProfilesController.fail();
      }
      if (payload.term.trim() === "") {
        return;
      }

      const order = payload.profilesRequestOrder;
      abortProfilesController = fetchProfiles({
        page: payload.profilesPage || 1,
        per_page: 10, // if you update this one take a look on MoreButton as well
        nested: true,
        filters: {
          ...(payload.profilesRequestFilters || {}),
          fields: {
            ...(payload.profilesRequestFilters?.fields || {}),
            ...(payload.term ? { term: payload.term } : {})
          }
        },
        ...(isEmpty(order) ? null : { order })
      }).then((resp) => {
        dispatch({ type: SEARCH_ACTIONS.SEARCH_PROFILES_DONE, payload: resp });
      });
      // TODO: [ENG-2261] more complex because we need to handle aborting: review!
      // .catch(() => dispatch({ type: SEARCH_ACTIONS.SEARCH_CLEAR_ACTIVITIES }));

      dispatch({
        type: SEARCH_ACTIONS.SEARCH_START_LOADING_PROFILES
      });
      break;
    }

    case SEARCH_ASYNC_ACTIONS.SEARCH_FETCH_SUGGESTED_KEYWORDS: {
      if (abortKeywordsController) {
        abortKeywordsController.abort();
      }
      if (payload.term.trim() === "") {
        return;
      }

      const merge = (payload) => dispatch({ type: SEARCH_ACTIONS.SEARCH_MERGE_STATE, payload });

      abortKeywordsController = new AbortController();
      const params = {
        text: payload.term,
        ...(payload.choosenKeywords.length > 0
          ? {
              selectedIds: payload.choosenKeywords.map(getId)
            }
          : {}),
        filterTypes: payload.filterTypes.map(({ name }) => name),
        matchTypes: payload.matchTypes.map(({ name }) => name)
      };

      merge({ pendingKeywordSearch: true });
      fetchKeywords(params, { signal: abortKeywordsController.signal })
        .then((resp) => {
          if (resp.entries) {
            dispatch({ type: SEARCH_ACTIONS.SEARCH_SUGGESTED_KEYWORDS_FROM_RESP, payload: resp.entries });
          } else {
            // TODO: [ENG-2261] api failed to prepare response - not sure what to do in such case - tbd
            merge({ suggestedKeywords: [], pendingKeywordSearch: false });
          }
        })
        .catch(() => {
          merge({ pendingKeywordSearch: false });
        });

      break;
    } // </SEARCH_FETCH_SUGGESTED_KEYWORDS>

    case SEARCH_ASYNC_ACTIONS.SEARCH_FETCH_ALL_ACTIVITIES: {
      if (`${payload.term}`.trim() === "") {
        break;
      }

      const { availableTemplates } = payload;

      Promise.all(
        availableTemplates.map(async (template) => {
          const order = payload.activitiesRequestOrder[template.key];
          const request = await fetchPaginatedCollection(ApiRoute(`/api/activities`), {
            page: payload.activitiesPage?.[template.key] || 1,
            perPage: 10,
            filters: {
              ...payload.activitiesRequestFilters[template.key],
              fields: {
                ...payload.activitiesRequestFilters[template.key]?.fields,
                term: payload.term,
                template_key: template.key
              }
            },
            ...(isEmpty(order) ? null : { order })
          });

          return { [template.key]: request };
        })
      ).then((resp) => {
        const formattedResponse = resp.reduce((acc, item) => ({ ...acc, ...item }), {});

        dispatch({ type: SEARCH_ACTIONS.SEARCH_ALL_ACTIVITIES_DONE, payload: formattedResponse });
      });

      break;
    }

    case SEARCH_ACTIONS.SEARCH_MORE_ACTIVITIES: {
      const { tab, allActivities, availableTemplates, term, activitiesPagination } = payload;
      const filters = payload.activitiesRequestFilters[tab];
      const order = payload.activitiesRequestOrder[tab];
      const templateKey = availableTemplates?.find((template) => template.key === tab)?.key;

      const page = activitiesPagination?.[tab] || 1;

      if (!allActivities && !allActivities?.[tab]) {
        break;
      }

      fetchPaginatedCollection(ApiRoute(`/api/activities`), {
        page,
        perPage: 10,
        filters: {
          ...filters,
          fields: {
            ...filters?.fields,
            template_key: templateKey,
            ...(term ? { term } : null)
          }
        },
        ...(isEmpty(order) ? null : { order })
      }).then((res) => {
        const formattedResponse = {
          ...allActivities,
          [tab]: { entries: [...allActivities[tab].entries, ...res.entries], meta: res.meta }
        };

        dispatch({ type: SEARCH_ACTIONS.SEARCH_ALL_ACTIVITIES_DONE, payload: formattedResponse });
      });
      break;
    }

    case SEARCH_ACTIONS.SEARCH_FILTER_ACTIVITIES: {
      const { tab, allActivities, availableTemplates, term, activitiesPagination } = payload;
      const filters = payload.activitiesRequestFilters[tab];
      const order = payload.activitiesRequestOrder[tab];
      const templateKey = availableTemplates?.find((template) => template.key === tab)?.key;

      const page = activitiesPagination?.[tab] || 1;

      if (!templateKey || (!allActivities && !allActivities?.[tab])) {
        break;
      }

      fetchPaginatedCollection(ApiRoute(`/api/activities`), {
        page,
        perPage: 10,
        filters: {
          ...filters,
          fields: {
            ...filters?.fields,
            template_key: templateKey,
            term
          }
        },
        ...(isEmpty(order) ? null : { order })
      }).then((res) => {
        const formattedResponse = {
          ...allActivities,
          [tab]: res
        };

        dispatch({ type: SEARCH_ACTIONS.SEARCH_ALL_ACTIVITIES_DONE, payload: formattedResponse });
      });
      break;
    }
  }
};
