import { Camelized } from "humps";
import compact from "lodash/compact";
import uniq from "lodash/uniq";
import { PER_PAGE_UNPAGINATED } from "PFApp/constants/unpaginated";
import { useProfiles } from "PFCore/hooks/queries/profiles/use_profiles";
import { ActivityHistoryProfile } from "PFCore/services/activity";
import { ActivityMemberEvent, Id, Profile } from "PFTypes";
import { useCallback, useMemo } from "react";

import { useActivityHistory } from "./use_activity_history";

export const useEventsProfiles = () => {
  const { data: history } = useActivityHistory();

  const profileIds = useMemo(
    () =>
      uniq(
        compact(
          ((history?.entries as ActivityMemberEvent[]) || []).flatMap(
            ({ operator, diff, metadata }: ActivityMemberEvent) => [
              operator?.id,
              ...(diff.profileId || []).flat(),
              ...(metadata.profileIds || []).flat()
            ]
          )
        )
      ),
    [history?.entries]
  );

  const { data: matches, isLoading } = useProfiles(
    {
      page: 1,
      perPage: PER_PAGE_UNPAGINATED,
      full: false,
      nested: false,
      filters: {
        fields: {
          id: profileIds
        }
      }
    },
    {
      enabled: profileIds.length > 0
    }
  );

  const profiles = useMemo(
    () =>
      compact(
        profileIds.map<Camelized<Profile> | ActivityHistoryProfile | undefined>((profileId) => {
          const profile = matches?.entries.find(({ id }) => profileId === id)?.profile;
          /* in case profile is not returned from profiles endpoint (for example is suspended)
           * we use limited profile data provided in history endpoint */
          return (profile as unknown as Camelized<Profile>) ?? history?.meta.profiles[profileId];
        })
      ),
    [matches, history?.meta, profileIds]
  );

  const findProfile = useCallback(
    (profileId?: Id) => profiles?.find(({ id }) => id === profileId),
    [profiles]
  );

  const findProfiles = useCallback(
    (profileIds: Id[]) => profiles?.filter(({ id }) => profileIds.includes(id)),
    [profiles]
  );

  return {
    profiles,
    findProfile,
    findProfiles,
    isLoading
  };
};
