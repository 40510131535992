import { NotificationSetting } from "PFTypes";

import { NotificationSettingsFormValues } from "./notifications_settings_form.types";

export const parseNotificationsSettingsToFormValues = (
  notificationsSettings: NotificationSetting[]
): NotificationSettingsFormValues =>
  notificationsSettings.reduce((acc, setting) => {
    acc[setting.id] = setting.frequency;
    return acc;
  }, {});
