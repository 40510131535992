import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { Id, ProfileCustomValue } from "PFTypes";

import { VerticalSkillsList } from "../../vertical_skills_list";
import { SkillCheckbox } from "./parts/skill_checkbox";

type AlphabeticalSkillsListProps = {
  skills: ProfileCustomValue[];
  profileId: Id;
  selectedSkillsIds: Id[];
  onSkillSelectionChange: (skillId: Id) => void;
};

export const AlphabeticalSkillsList = ({
  skills,
  profileId,
  selectedSkillsIds,
  onSkillSelectionChange
}: AlphabeticalSkillsListProps) => {
  const { data: currentProfile } = useCurrentProfile();

  const isMe = profileId === currentProfile.id;

  return (
    <VerticalSkillsList
      skills={skills.sort((skill1, skill2) => skill1.text.localeCompare(skill2.text))}
      profileId={profileId}
      renderCustomValueLeftContent={
        isMe
          ? (skillId) => (
              <SkillCheckbox
                skillId={skillId}
                selectedSkillsIds={selectedSkillsIds}
                onSkillSelectionChange={onSkillSelectionChange}
              />
            )
          : undefined
      }
    />
  );
};
