import has from "lodash/has";
import values from "lodash/values";
import {
  AvailabilityRule,
  DateWithRangesValue,
  DateWithSingleRangeValue,
  Filter,
  FilterType,
  IntegerValue,
  OptionsFilter,
  PeriodRange,
  Value
} from "PFTypes";

const isObject = (value: any): value is object => typeof value === "object";

export const isAvailabilityRule = (value: any): value is AvailabilityRule =>
  value && isObject(value) && "mode" in value && "ranges" in value && "availability_threshold" in value;

export const isDateWithRangesValue = (value: any): value is DateWithRangesValue =>
  value && isObject(value) && "mode" in value && "ranges" in value;

export const isDateWithSingleRangeValue = (value: any): value is DateWithSingleRangeValue | PeriodRange =>
  value && isObject(value) && "start" in value && "end" in value;

export const isFilterValueIntegerRange = (filterValue: Value): filterValue is IntegerValue =>
  has(filterValue, "lte") || has(filterValue, "gte");

export const isOptionsFilter = (filter: Filter): filter is OptionsFilter => has(filter, "options");

export const isFilter = (filter: any): filter is Filter =>
  has(filter, "type") && values(FilterType).includes(filter.type);
