import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { AppContextStore } from "PFApp/app_context";
import { getSegment } from "PFCore/helpers/get_segment";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { SegmentAction, SegmentActionType } from "PFReducers/segment_reducer";
import { FeatureFlag } from "PFTypes";
import { Dispatch, useEffect } from "react";

const SEGMENT_STORAGE_KEY = "user_segment";

export const useSegment = (dispatch: Dispatch<SegmentAction>, store: AppContextStore) => {
  const { data: currentAccount } = useCurrentAccount();
  const { data: currentProfile } = useCurrentProfile();

  const hasSegments = useIsFeatureEnabled()(FeatureFlag.Segments) && !!currentAccount.segment?.length;
  const storageSegment = window.storage.getItem(SEGMENT_STORAGE_KEY);

  useEffect(() => {
    // Apply Segment accordingly to Account/Profile settings
    // Prioritize locally stored Segment values

    if (hasSegments) {
      const segment = getSegment(currentAccount, currentProfile, storageSegment);

      if (isEmpty(segment) || isEqual(segment, store.segment)) {
        return;
      }

      dispatch({
        type: SegmentActionType.SegmentSet,
        payload: segment
      });
    }
  }, [currentAccount.segment, currentProfile, hasSegments]);

  useEffect(() => {
    // Update locally stored Segment on AppContext Segment change
    const profileDefaultSegment = getSegment(currentAccount, currentProfile);

    if (!hasSegments || isEmpty(store.segment)) {
      return;
    }

    if (isEqual(store.segment, profileDefaultSegment)) {
      window.storage.removeItem(SEGMENT_STORAGE_KEY);
    } else {
      window.storage.setItem(SEGMENT_STORAGE_KEY, store.segment);
    }
  }, [store.segment]);
};
