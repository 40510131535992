import classNames from "classnames";
import isString from "lodash/isString";
import { CSSProperties, PropsWithChildren } from "react";
import { Link } from "react-router-dom";

import { ButtonKind } from "./button";
import css from "./button.module.scss";

type ButtonLinkProps = PropsWithChildren & {
  kind?: ButtonKind;
  size?: string;
  small?: boolean;
  style?: CSSProperties;
  qaId?: string;
  href?: string;
  target?: string;
  rootClassName?: string;
  onClick?: (event: React.MouseEvent) => void;
  theme?: any;
  title?: string;
  state?: any;
};

const ButtonLink = ({
  children,
  kind = "primary",
  size,
  small,
  style,
  qaId = "Button",
  href = "",
  target,
  rootClassName,
  onClick,
  theme = {},
  title,
  state
}: ButtonLinkProps) => {
  const className = classNames(css.button, rootClassName, css[`button-${kind}`], theme.root, {
    [css[`button-${size}`]]: size,
    [css.buttonSmall]: small
  });
  return (
    <Link
      to={href}
      target={target}
      rel={target === "_blank" ? "noopener noreferrer" : undefined}
      className={className}
      style={style}
      data-qa-id={qaId}
      onClick={onClick}
      title={title || (isString(children) ? children : undefined)}
      state={state}
    >
      {children}
    </Link>
  );
};

export default ButtonLink;
