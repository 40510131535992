import cloneDeep from "lodash/cloneDeep";
import { isProfileActive } from "PFCore/helpers/profile";
import useBookingCategories from "PFCore/hooks/use_booking_categories";
import { Activity, ActivityCustomField, Assignee, Attachment, BookingCategory, Metadata } from "PFTypes";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useToggle } from "react-use";

import { useActivityEditPageContext, useActivityEditPageFlags } from "../../activity_edit_page_context";

export type UseActivityEditFormSimpleFieldsReturn = {
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  description: string;
  setDescription: Dispatch<SetStateAction<string>>;
  isDevelopmental: boolean;
  toggleIsDevelopmental: (toggle: unknown) => void;
  isPrivate: boolean;
  setIsPrivate: React.Dispatch<React.SetStateAction<boolean>>;
  assignee: Assignee["profile"] | null;
  setAssignee: React.Dispatch<React.SetStateAction<Assignee["profile"] | null>>;
  bookingCategory: BookingCategory | null;
  setBookingCategory: React.Dispatch<React.SetStateAction<BookingCategory | null>>;
  communicationType: Activity["communication_type"];
  setCommunicationType: React.Dispatch<React.SetStateAction<Activity["communication_type"]>>;
  selectedParentActivity: Activity | undefined;
  setSelectedParentActivity: React.Dispatch<React.SetStateAction<Activity | undefined>>;
  metadata: Metadata;
  setMetadata: React.Dispatch<React.SetStateAction<Metadata>>;
  customFields: ActivityCustomField[];
  setCustomFields: React.Dispatch<React.SetStateAction<ActivityCustomField[]>>;
  attachments: Attachment[];
  setAttachments: React.Dispatch<React.SetStateAction<Attachment[]>>;
  minRequiredCount: number;
  setMinRequiredCount: React.Dispatch<React.SetStateAction<number>>;
  audiences: string[][];
  setAudiences: React.Dispatch<React.SetStateAction<string[][]>>;
};

export const useActivityEditFormSimpleField = (): UseActivityEditFormSimpleFieldsReturn => {
  const {
    isClone,
    activity,
    parentActivity,
    attachments: activityAttachments
  } = useActivityEditPageContext();
  const { isDefaultPrivate, isEdit } = useActivityEditPageFlags();
  const { bookingCategories } = useBookingCategories();

  const { t } = useTranslation("activities");

  const [name, setName] = useState<string>(
    isClone ? t("edit.sections.clone", { activityName: activity.name }) : activity.name || ""
  );

  const [isDevelopmental, toggleIsDevelopmental] = useToggle(activity?.developmental || false);
  const [description, setDescription] = useState<string>(activity.description || "");

  const [isPrivate, setIsPrivate] = useState<boolean>(
    isClone || isEdit ? !!activity.private : isDefaultPrivate
  );
  const [assignee, setAssignee] = useState<Assignee["profile"] | null>(
    isProfileActive(activity.assignee?.profile) ? activity?.assignee?.profile || null : null
  );
  const [bookingCategory, setBookingCategory] = useState<BookingCategory | null>(
    bookingCategories.find(({ id }) => id === activity.booking_category_id) || null
  );
  const [communicationType, setCommunicationType] = useState<Activity["communication_type"]>(
    activity.communication_type || "direct"
  );

  const [selectedParentActivity, setSelectedParentActivity] = useState<Activity | undefined>(parentActivity);
  const [metadata, setMetadata] = useState<Metadata>(activity.metadata || {});
  const [customFields, setCustomFields] = useState<ActivityCustomField[]>(cloneDeep(activity.custom_fields));
  const [attachments, setAttachments] = useState<Attachment[]>(activityAttachments);
  const [minRequiredCount, setMinRequiredCount] = useState<number>(activity.min_required_count || 1);
  const [audiences, setAudiences] = useState<string[][]>(activity.audiences || []);

  return useMemo(
    () => ({
      name,
      setName,
      description,
      setDescription,
      isDevelopmental,
      toggleIsDevelopmental,
      isPrivate,
      setIsPrivate,
      assignee,
      setAssignee,
      bookingCategory,
      setBookingCategory,
      communicationType,
      setCommunicationType,
      selectedParentActivity,
      setSelectedParentActivity,
      metadata,
      setMetadata,
      customFields,
      setCustomFields,
      attachments,
      setAttachments,
      minRequiredCount,
      setMinRequiredCount,
      audiences,
      setAudiences
    }),
    [
      assignee,
      attachments,
      audiences,
      bookingCategory,
      communicationType,
      customFields,
      description,
      isDevelopmental,
      isPrivate,
      metadata,
      minRequiredCount,
      name,
      selectedParentActivity,
      toggleIsDevelopmental
    ]
  );
};
