import { decamelizeKeys } from "humps";
import { ReadableAvailabilityRules } from "PFApp/components/readable_availability_rules";
import Tooltip from "PFComponents/tooltip/tooltip";
import { Activity, AuditRole, AvailabilityRule } from "PFTypes";

import { useReadableAvailability } from "../../../helpers/use_readable_availability";
import { Inline } from "../../containers";
import { Icon } from "../../icon";

type RoleAvailabilityProps = {
  role: AuditRole | Activity;
};

export const RoleAvailability = ({ role }: RoleAvailabilityProps) => {
  // TODO: [SP-2590] It might be that role comes camelized. Remove decamelizeKeys when app is camelized
  const availability = decamelizeKeys(role.availability) as AvailabilityRule;

  const { readableAvailability } = useReadableAvailability({
    availability
  });

  return (
    <Inline gap="spacingMd" alignItems="center">
      {readableAvailability}
      {availability?.ranges && (
        <Tooltip
          content={<ReadableAvailabilityRules availability={availability} />}
          maxWidth={400}
          interactive
        >
          <Icon name="info" />
        </Tooltip>
      )}
    </Inline>
  );
};
