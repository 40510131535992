import { IconName } from "PFTheme/graphics/icons";
import colors from "PFTheme/tokens/colors";
import { SVGProps, useCallback, useState } from "react";
import { RequireExactlyOne } from "type-fest";

export type Color = keyof typeof colors;

export type IconSize = "xxs" | "xs" | "sm0" | "sm" | "md" | "lg" | "xl" | "xxl" | "xxxl";

export const IconSizeTable: Record<IconSize, number> = {
  xxs: 10,
  xs: 12,
  sm0: 14, // TODO: [SP-3853] The sizes names will be remapped in https://github.com/Profinda/premiumui/pull/10767
  sm: 16,
  md: 20,
  lg: 24,
  xl: 32,
  xxl: 64,
  xxxl: 128
};

export const IconDefaultSize: IconSize = "md";

export type IconProps = Omit<SVGProps<SVGElement>, "color" | "ref"> &
  Partial<
    RequireExactlyOne<{
      color?: Color;
      fill?: SVGProps<SVGElement>["fill"];
    }>
  > & {
    name: IconName;
    size?: IconSize;
  };

export const Icon = ({ name, color, fill, size = IconDefaultSize, ...svgProps }: IconProps) => {
  const [pathDefaultColor, setPathDefaultColor] = useState<string | null>(null);

  const ref = useCallback((node: HTMLDivElement | null) => {
    if (!node) {
      return;
    }

    setPathDefaultColor(node.getElementsByTagName("path")[0]?.getAttribute("fill"));
    node.getElementsByTagName("path")[0]?.removeAttribute("fill");
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-require-imports
  const IconSvg = require(`PFTheme/graphics/icons/${name}.svg`).default;

  const length = IconSizeTable[size];

  return (
    <IconSvg
      ref={ref}
      width={length}
      height={length}
      style={{ minWidth: length, minHeight: length, ...(svgProps.style || {}) }}
      {...svgProps}
      fill={fill || (color && colors[color]) || pathDefaultColor}
    />
  );
};
