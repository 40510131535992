import { keepPreviousData } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { fetchCurrentAccount } from "PFCore/services/accounts/account";
import { CurrentAccount } from "PFTypes";
import { QueryOptions } from "PFTypes/request_options";

import { accountKeys } from "./query_keys";

const initialData = {} as CurrentAccount;

export const useCurrentAccount = <TData = CurrentAccount>(
  options: QueryOptions<CurrentAccount, unknown, TData> = {}
) => {
  const currentAccountQuery = useQuery<CurrentAccount, unknown, TData>({
    queryKey: accountKeys.current(),
    queryFn: () => fetchCurrentAccount(),
    retry: false,
    placeholderData: keepPreviousData,
    initialData,
    ...options
  });

  return {
    ...currentAccountQuery,
    data: currentAccountQuery.data!
  };
};
