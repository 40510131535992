import { DEFAULT_SKILL_EXPERIENCE, Experience, Id, PureCustomValueData } from "PFTypes";

import { ComponentProps } from "../../activities/edit/sections/field_wrappers/component_prop_types";
import { CustomValuesEditField, CustomValuesEditFieldProps } from "../custom_values_edit_field";
import { SelectedSkills } from "./parts/selected_skills";

export type SkillsSelectProps = Omit<CustomValuesEditFieldProps, "values" | "errors" | "handleChange"> &
  Pick<ComponentProps, "label" | "qaId" | "required" | "error"> & {
    values: PureCustomValueData[];
    onUpdate: CustomValuesEditFieldProps["handleChange"];
    property?: ComponentProps["property"];
    withProficiency?: boolean;
  };

export const SkillsSelect = ({
  values,
  property,
  qaId,
  error,
  onUpdate,
  adminPage,
  withProficiency,
  ...props
}: SkillsSelectProps) => {
  const handleUpdate: CustomValuesEditFieldProps["handleChange"] = (updatedSkills) => {
    onUpdate(
      updatedSkills.map((skill) => {
        const existingSkill = values.find(({ id }) => id === skill.id);

        return {
          ...skill,
          required_experience: existingSkill?.required_experience || DEFAULT_SKILL_EXPERIENCE
        };
      })
    );
  };

  const handleExperienceChange = (id: Id, newExperience: Experience) => {
    onUpdate(
      values.map((skill) => ({
        ...skill,
        required_experience: skill.id === id ? newExperience : skill.required_experience
      }))
    );
  };

  const handleDelete = (id: Id) => {
    onUpdate(values.filter((skill) => skill.id !== id));
  };

  return (
    <CustomValuesEditField
      {...props}
      handleChange={handleUpdate}
      values={values}
      tip=""
      adminPage={adminPage || false}
      multi={!property || property.kind === "multiple"}
      qaIdPrefix={qaId}
      errors={error}
      hasValuesWithExpiryDate={false}
      displayValuesBelow={withProficiency}
      displayValues={
        withProficiency && values.length > 0 ? (
          <SelectedSkills
            values={values}
            onExperienceChange={handleExperienceChange}
            onDelete={handleDelete}
          />
        ) : undefined
      }
    />
  );
};
