import { useBookingFormContext } from "PFApp/booking/components/booking_form/booking_form_context_provider";
import { mapProfileToBasicProfile } from "PFCore/helpers/profile";
import { useIsCurrentUserPermittedTo } from "PFCore/helpers/use_is_permitted_to";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { PermissionRule } from "PFTypes";
import { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { BookingDetailSidePanelProfile } from "../../../parts/overview/detail_view/booking_detail/booking_detail_side_panel_context/booking_detail_side_panel_context_provider";
import { BookingFormValues } from "../booking_form_provider";

const WORKFORCE_MEMBER_LOADING = "workforce-member-loading";

type UseInitialWorkforceMember = {
  profileId?: number;
  suspendedProfileDetails?: BookingDetailSidePanelProfile;
};
type UseInitialWorkforceMembeReturn = { loading: boolean };

export const useInitialWorkforceMember = ({
  profileId,
  suspendedProfileDetails
}: UseInitialWorkforceMember): UseInitialWorkforceMembeReturn => {
  const { data: currentProfile } = useCurrentProfile();
  const { fetchProfile } = useBookingFormContext();
  const isBookingResourcer = useIsCurrentUserPermittedTo(PermissionRule.BookingResourcer);

  const { loadingState, setLoadingState } = useBookingFormContext();

  const { setValue } = useFormContext<BookingFormValues>();
  const workforceMember = useWatch<BookingFormValues>({ name: "workforceMember" });

  useEffect(() => {
    const fetchPreFilledProfile = async (profileId) => {
      setLoadingState((prev) => ({ ...prev, [WORKFORCE_MEMBER_LOADING]: true }));
      const profileResponse = await fetchProfile(profileId);
      setValue("workforceMember", mapProfileToBasicProfile(profileResponse));
      setLoadingState((prev) => ({ ...prev, [WORKFORCE_MEMBER_LOADING]: false }));
    };

    if (!isBookingResourcer && !workforceMember) {
      fetchPreFilledProfile(currentProfile.id);
      return;
    }

    if (!profileId || !!workforceMember) {
      return;
    }

    if (suspendedProfileDetails) {
      setValue("workforceMember", suspendedProfileDetails);
    } else {
      fetchPreFilledProfile(profileId);
    }
  }, [profileId, isBookingResourcer, suspendedProfileDetails]);

  return { loading: loadingState[WORKFORCE_MEMBER_LOADING] };
};
