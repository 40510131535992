import { fetchBookingHistory } from "PFApp/booking/services/api";
import { BookingHistoryItem } from "PFApp/booking/types";
import { getNextPageParam } from "PFCore/helpers/get_next_page_param";
import { InfiniteQueryOptions, PaginatedCollection } from "PFTypes";

import { useInfiniteQuery } from "../../use_infinite_query";
import { historyKeys } from "./query_keys";

export type BookingHistoryProfile = {
  id: number;
  first_name?: string;
  last_name?: string;
  status: "active" | "inactive";
  suspended_at?: string | null;
};
export type BookingHistoryResponse = PaginatedCollection<
  BookingHistoryItem[],
  { profiles: Record<number, BookingHistoryProfile> }
>;

export const useBookingHistoryInfinite = (
  bookingId: number,
  perPage?: number,
  options: InfiniteQueryOptions<BookingHistoryResponse> = {}
) =>
  useInfiniteQuery<BookingHistoryResponse>({
    queryKey: historyKeys.ofBooking(bookingId),
    queryFn: ({ pageParam }) => {
      const params = {
        bookingId,
        page: pageParam,
        perPage
      };
      return fetchBookingHistory(params) as unknown as Promise<BookingHistoryResponse>;
    },
    initialPageParam: 1,
    getNextPageParam,
    ...options
  });
