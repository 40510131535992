import { activityCustomTypesAccessLevels } from "PFCore/helpers/custom_types";
import { getVisibleProperties } from "PFCore/helpers/templates";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { AccessLevel, Activity, Template } from "PFTypes";
import { useMemo } from "react";

const PRIORITY = "priority";

export const usePriorityVisibility = (activity: Activity, template?: Template) => {
  const { data: currentProfile } = useCurrentProfile();
  const { customTypes } = useCustomTypes();

  return useMemo(() => {
    const subtemplate = activity.subtemplate_key
      ? template?.schema.subtemplates?.[activity.subtemplate_key]
      : null;

    const isPriorityInTemplate = !!getVisibleProperties(template).find(({ name }) => name === PRIORITY);
    const isPriorityInSubtemplate = !!subtemplate?.properties.priority;
    const priorityCustomType = customTypes.find((type) => type.name === PRIORITY);

    const accessLevel =
      !!priorityCustomType && activityCustomTypesAccessLevels(currentProfile)[priorityCustomType.id];

    const isPriorityAvailable =
      isPriorityInTemplate &&
      (!subtemplate || isPriorityInSubtemplate) &&
      !!priorityCustomType &&
      !!accessLevel;

    const isPriorityDisabled = accessLevel !== AccessLevel.ReadWrite;
    const isPriorityRequired = (subtemplate ?? template?.schema)?.required.includes(PRIORITY);

    return { isPriorityAvailable, isPriorityDisabled, isPriorityRequired };
  }, [template, activity.subtemplate_key, customTypes, currentProfile]);
};
