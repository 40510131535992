import { useGrowl } from "PFApp/use_growl";
import api from "PFCore/api";
import { usePusherEvent } from "PFCore/base/pusher";
import isIOS from "PFCore/helpers/ios";
import isSafari from "PFCore/helpers/is_safari";
import { useResponseErrors } from "PFCore/helpers/use_response_errors";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { ExportResponse } from "PFCore/services/common";
import { DocFormat } from "PFTypes/doc_format";
import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUnmount } from "react-use";

export type UseDownloadDocument = {
  generateDocument: (format: DocFormat) => Promise<ExportResponse>;
};

type UseDownloadDocumentReturn = {
  handleDownloadClick: (format: DocFormat) => void;
  fileUrl: string;
  isLoading: boolean;
};

export const useDownloadDocument = ({ generateDocument }: UseDownloadDocument): UseDownloadDocumentReturn => {
  const { t } = useTranslation("core");
  const { data: currentProfile } = useCurrentProfile();
  const growl = useGrowl();
  const { getFirstError } = useResponseErrors();

  const timeoutID = useRef<NodeJS.Timeout>(undefined);

  const [cachedUrl, setCachedUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const useAnchorTag = isIOS() || isSafari();

  useUnmount(() => {
    clearTimeout(timeoutID.current);
  });

  const handleDownloadClick = (format: DocFormat) => {
    setIsLoading(true);
    generateDocument(format).then(handleDocGenerated).catch(handleDocFail);
  };

  const handleDocGenerated = useCallback(
    ({ fileUrl }: ExportResponse) => {
      if (!fileUrl) {
        return;
      }

      api({
        url: fileUrl
      })
        .then(({ fileUrl }: ExportResponse) => {
          setCachedUrl(fileUrl ?? "");

          if (useAnchorTag) {
            timeoutID.current = setTimeout(() => {
              setCachedUrl("");
            }, 10000);
          }
        })
        .catch(() => {
          growl({ message: t("fileCannotBeOpen"), kind: "error" });
        })
        .finally(() => setIsLoading(false));
    },
    [t, growl, useAnchorTag]
  );

  const handleDocFail = ({ response }) => {
    const message = response?.data?.message;
    growl({
      kind: "error",
      message: message || getFirstError(response) || t("documentButtons.fail.default")
    });

    setIsLoading(false);
  };

  usePusherEvent(`private-profile-${currentProfile.id}`, "file_generated", ({ file_url }) =>
    handleDocGenerated({ fileUrl: file_url })
  );

  return { handleDownloadClick, fileUrl: cachedUrl, isLoading };
};
