import { useQuery } from "@tanstack/react-query";
import { fetchProficiencyLevels, ProficiencyLevel } from "PFCore/services/proficiency_levels";
import { QueryOptions } from "PFTypes";

import { proficiencyLevelsKeys } from "./query_keys";

export const useProficiencyLevels = <TData = ProficiencyLevel[]>(
  options: QueryOptions<ProficiencyLevel[], unknown, TData> = {}
) =>
  useQuery<ProficiencyLevel[], unknown, TData>({
    queryKey: proficiencyLevelsKeys.all(),
    queryFn: fetchProficiencyLevels,
    ...options
  });
