import { useQueryClient } from "@tanstack/react-query";
import { Id } from "PFTypes";

import { allocationWindowsKeys } from "./query_keys";

export const useTargetAllocationInvalidate = () => {
  const queryClient = useQueryClient();

  return {
    invalidateSingleAllocationWindow: (allocationWindowId: Id) =>
      queryClient.invalidateQueries({ queryKey: allocationWindowsKeys.single(allocationWindowId) }),
    invalidateAllAllocationWindows: () =>
      queryClient.invalidateQueries({ queryKey: allocationWindowsKeys.all() }),
    invalidateAllRoles: () => queryClient.invalidateQueries({ queryKey: allocationWindowsKeys.allRoles() })
  };
};
