import { useMutation } from "@tanstack/react-query";
import {
  updateProfileNotificationSettings,
  UpdateProfileNotificationSettingsPayload
} from "PFCore/services/notifications/update_profile_notifications_settings";
import { MutationOptions } from "PFTypes";

import { useNotificationsInvalidate } from "./use_notifications_invalidate";

export const useProfileNotificationsSettingsUpdate = ({
  onSuccess,
  ...options
}: MutationOptions<UpdateProfileNotificationSettingsPayload> = {}) => {
  const { invalidateProfileNotificationsSettings } = useNotificationsInvalidate();

  return useMutation({
    mutationFn: updateProfileNotificationSettings,
    onSuccess: (...params) => {
      invalidateProfileNotificationsSettings();
      onSuccess?.(...params);
    },
    ...options
  });
};
