import { AvailabilityRule } from "./booking";
import jqXHR = JQuery.jqXHR;
import { Id } from "./id";

export type FilterByValueType = "fields" | "numbers" | "strings" | "locations";
export type FilterAvailabilityType = "availability";
export type FilterFamily = FilterByValueType | FilterAvailabilityType;

export enum FilterType {
  Radio = "radio_buttons",
  Search = "search",
  SelectMany = "select_many",
  checkboxes = "checkboxes",
  SelectOne = "select_one",
  String = "string",
  Integer = "integer",
  Availability = "availability",
  AvailabilityRange = "availability_range",
  DateRange = "date_range",
  ReportingRange = "reporting_range",
  Date = "date",
  ToggleCheckbox = "toggle_checkbox",
  Number = "number",
  Hidden = "hidden"
}

export type FilterValue = {
  id: number;
  text: string;
};

export type IntegerValue = {
  gte: number;
  lte: number;
  sliderId?: string;
};

export type Range = {
  start: string;
  end: string;
};

export type PeriodRange = Partial<Range> & {
  period?: string;
};

export type DateWithRangesValue = {
  mode?: string;
  ranges: Range[];
};

export type DateWithSingleRangeValue = {
  mode?: string;
  start: string;
  end: string;
};

export type Value =
  | (FilterValue | string | number)[]
  | FilterValue
  | IntegerValue
  | DateWithRangesValue
  | DateWithSingleRangeValue
  | AvailabilityRule
  | PeriodRange
  | string
  | number
  | null
  | boolean;

export type StandardFilter = {
  type: FilterType;
  value: Value;
  title: string;
  name?: string;
  query?: (({ value, term }) => jqXHR<any> | Promise<any>) | null;
  text?: string;
  hidden?: boolean | null;
  operator?: string;
  ordinal?: number;
  facets?: boolean;
  important?: boolean;
  children?: boolean;
  disabled?: boolean;
  templateKey?: Id;
};

export type OptionsFilter = StandardFilter & {
  options: { id: string; text: string }[];
};

type NumberFilterStatistics = {
  statistics: { min: number; max: number };
};

export type NumberFilter = Omit<StandardFilter, "query"> &
  NumberFilterStatistics & {
    statistics: { min: number; max: number };
    query: ({ value, term }) => Promise<NumberFilterStatistics>;
  };

export type PeriodFilter = StandardFilter & {
  period?: string;
};

export type Filter = StandardFilter | OptionsFilter | PeriodFilter | NumberFilter;

export type FilterWithDefaults = Filter & {
  defaultValue: Value | null;
};

export type FilterOption = FilterValue & {
  type: string;
};

export type Filters<T extends Filter | Value = Filter> = Partial<
  Record<FilterByValueType, Record<string, T>> &
    Record<FilterAvailabilityType, T> &
    Partial<{ children: Filters<T> }>
>;
