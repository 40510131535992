import { useMatchProfileInfo } from "PFApp/hooks/use_match_profile_info";
import { Pill } from "PFComponents/pill";
import { SuspendedPill } from "PFComponents/suspended_pill";
import { Typography } from "PFComponents/typography";
import { getProfileName } from "PFCore/helpers/profile";
import { profileHash } from "PFCore/helpers/profile_hash";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useMatchCardContext } from "../../match_card_context";
import { MatchActivitySkills } from "../match_activity_skills";
import css from "./match_profile_info.module.scss";

export const MatchProfileInfo = () => {
  const { t } = useTranslation("activities");
  const { data: currentProfile } = useCurrentProfile();
  const { profile, isCompressed, creator, isBlindModeOn, shortlist } = useMatchCardContext();
  const { headerInfo: headerProfileInfo } = useMatchProfileInfo(profile);

  const formattedHeaderProfileInfo = headerProfileInfo.map(({ value }) => value).join(", ");

  return (
    <div className={css.matchProfileInfo}>
      {!isBlindModeOn ? (
        <span className={css.matchProfileHeader}>
          <Typography variant="h4">{getProfileName(profile)}</Typography>
          {shortlist?.named && <Pill text={t("parts.namedResource")} small />}
          {!!profile.suspended_at && <SuspendedPill suspendedAt={profile.suspended_at} />}
        </span>
      ) : (
        <Typography variant="h4">
          {t("parts.candidateHash", { profileHash: profileHash(profile.id) })}
        </Typography>
      )}

      {formattedHeaderProfileInfo && (
        <Typography variant="labelRegular" className={css.customValues}>
          {formattedHeaderProfileInfo}
        </Typography>
      )}
      {creator && (
        <span className={css.addedBy}>
          <Typography withMargin variant="labelRegular">
            {t("show.shortlist.addedBy")}
          </Typography>
          <Typography withMargin variant="labelBold">
            <Link
              to={`/profiles/${creator.id === currentProfile.id ? "me" : creator.id}`}
              onClick={(event) => event.stopPropagation()}
            >
              {getProfileName(creator)}
            </Link>
          </Typography>
        </span>
      )}
      {!isCompressed && <MatchActivitySkills className={css.skills} />}
    </div>
  );
};
