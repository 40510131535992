import { NotificationsSettingsForm } from "PFApp/notifications_settings_form/notifications_settings_form";
import ProfileEditPanel from "PFApp/profiles/edit/profile_edit_panel";
import { getProfileSectionText } from "PFApp/profiles/edit/profile_edit_sections";
import { useGrowl } from "PFApp/use_growl";
import { Inline } from "PFComponents/containers";
import { LoadingDots } from "PFComponents/loading_dots";
import { useProfileNotificationsSettings } from "PFCore/hooks/queries/notifications/use_profile_notifications_settings";
import { useProfileNotificationsSettingsUpdate } from "PFCore/hooks/queries/notifications/use_profile_notifications_settings_update";
import { NotificationSetting } from "PFTypes";
import { useTranslation } from "react-i18next";

export const NotificationsSection = () => {
  const { t } = useTranslation("profiles");
  const growl = useGrowl();
  const { data: notificationsSettings } = useProfileNotificationsSettings();
  const { mutateAsync: updateNotificationsSettings } = useProfileNotificationsSettingsUpdate();

  const handleSubmit = (formValues: Pick<NotificationSetting, "id" | "frequency">[]) => {
    updateNotificationsSettings({ notificationSettings: formValues }).then(() => {
      growl({ kind: "success", message: t("edit.sections.settingsUpdatedMessage") });
    });
  };

  return (
    <ProfileEditPanel title={getProfileSectionText("notifications")}>
      {notificationsSettings ? (
        <NotificationsSettingsForm notificationsSettings={notificationsSettings} onSubmit={handleSubmit} />
      ) : (
        <Inline center>
          <LoadingDots circlesEnabled circleSize="xs" />
        </Inline>
      )}
    </ProfileEditPanel>
  );
};
