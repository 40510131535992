import camelCase from "lodash/camelCase";
import { MultiToggle, Option } from "PFComponents/multi_toggle";
import { NotificationFrequency, NotificationSetting } from "PFTypes";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { humanize } from "underscore.string";

import css from "./notifications_settings_form.module.scss";

type NotificationSettingSelectProps = {
  notificationSetting: NotificationSetting;
};

export const NotificationSettingSelect = ({ notificationSetting }: NotificationSettingSelectProps) => {
  const { t } = useTranslation("notificationsSettings");
  const { control } = useFormContext();
  const options: Option<NotificationFrequency>[] = notificationSetting.frequencyOptions.map((frequency) => ({
    id: frequency,
    value: t(`frequency.${frequency}` as unknown as TemplateStringsArray)
  }));

  const camelCaseCategory = camelCase(notificationSetting.category);

  return (
    <Controller
      control={control}
      name={String(notificationSetting.id)}
      render={({ field: { onChange, value } }) => (
        <MultiToggle
          label={t(`category.${camelCaseCategory}` as unknown as TemplateStringsArray, {
            defaultValue: humanize(notificationSetting.category)
          })}
          tip={t(`hint.${camelCaseCategory}` as unknown as TemplateStringsArray, { defaultValue: "" })}
          controlledValue={value}
          options={options}
          onChange={onChange}
          classes={{ button: css.option }}
        />
      )}
    />
  );
};
