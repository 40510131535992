import { Typography } from "PFComponents/typography";
import React from "react";
import { useTranslation } from "react-i18next";

import { RoleWatcher } from "../../../../../../types/watchers";

export const Questions = ({ watcher }: { watcher: RoleWatcher }) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.interested.table" });

  return (
    <>
      {(watcher.activity?.questions || []).map(([question, answer], i) => (
        <React.Fragment key={i}>
          <div>{question}</div>
          <Typography variant="bodyBold">{t(`answers.${answer}`)}</Typography>
        </React.Fragment>
      ))}
    </>
  );
};
