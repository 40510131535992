import entries from "lodash/entries";
import isEmpty from "lodash/isEmpty";
import some from "lodash/some";
import { useAppContext } from "PFApp/app_context";
import { CalendarMode, CalendarModeData, CalendarModeInitialData, CalendarView } from "PFApp/booking/types";
import {
  checkFiltersAreClear,
  checkFiltersAreDefault,
  getAPIFilters,
  getValueFilters,
  handleRequestFiltersReplace
} from "PFApp/use_filtered_collection";
import { stringify } from "PFCore/helpers/use_deterministic_stringify";
import {
  ProjectsResponse,
  useProjectsEntries
} from "PFCore/hooks/queries/bookings/projects/use_projects_entries";
import { useProjectsInvalidate } from "PFCore/hooks/queries/bookings/projects/use_projects_invalidate";
import { MetaWithPagination } from "PFTypes";
import { useCallback, useEffect, useState } from "react";
import { useUnmount } from "react-use";

import { BookingCalendarData, ProjectsCalendarData } from "./context/booking_overview_context.types";
import { useCalendarMetric } from "./context/use_calendar_metric";
import { useCalendarView } from "./context/use_calendar_view";
import { useProjectsFiltersOrder } from "./use_projects_filters";

const DEFAULT_PAGE = 1;
const DEFAULT_PAGE_BY_VIEW = {
  [CalendarView.RolesAndAuditRoles]: DEFAULT_PAGE,
  [CalendarView.EngagementsAndAuditEngagements]: DEFAULT_PAGE
};
const DEFAULT_META = {} as unknown as MetaWithPagination;
const DEFAULT_PROJECTS_DATA = {
  data: { entries: [], meta: DEFAULT_META, loading: false },
  loaded: false,
  noFiltersSelected: true,
  noOrderSelected: true,
  updateFilter: () => {},
  setFilters: () => {},
  resetFilters: () => {},
  onOrderChange: () => {}
};

const PER_PAGE = 15;

interface UseProjectsData {
  enabled: boolean;
}

const useProjectsData = ({
  enabled
}: UseProjectsData): BookingCalendarData<ProjectsCalendarData, ProjectsResponse> => {
  const {
    store: { segment }
  } = useAppContext();
  const [metric, setMetric] = useCalendarMetric(CalendarMode.Projects);
  const [view, setCalendarView] = useCalendarView(CalendarMode.Projects);

  const [pageByView, setPageByView] = useState<Partial<Record<CalendarView, number>>>(DEFAULT_PAGE_BY_VIEW);
  const page = pageByView[view] || 1;
  const setPage = useCallback(
    (pageNumber: number) => setPageByView((prev) => ({ ...prev, [view]: pageNumber })),
    [view]
  );
  const resetPagination = useCallback(() => setPage(DEFAULT_PAGE), [setPage]);

  const [projects, setProjects] = useState<CalendarModeData<ProjectsResponse>>(DEFAULT_PROJECTS_DATA);
  const projectsFiltersOrder = useProjectsFiltersOrder({ postChangeAction: resetPagination });
  const {
    filters: { selectedFilters, setFilters, updateFilter, resetFilters, setFiltersRaw },
    order: { selectedOrder, setSelectedOrder, noOrderSelected }
  } =
    projectsFiltersOrder[
      view as CalendarView.RolesAndAuditRoles | CalendarView.EngagementsAndAuditEngagements
    ];

  const setView = useCallback(
    (view?: CalendarView, initialData?: Omit<CalendarModeInitialData, "view">) => {
      view && setCalendarView(view);
      if (!initialData) {
        return;
      }

      // in case "view" is not specified, apply filters to each view
      entries(projectsFiltersOrder)
        .filter(([viewKey]) => !view || viewKey === view)
        .forEach(([, viewData]) => {
          const {
            filters: { setFilters }
          } = viewData;
          const { filters } = initialData;
          setFilters(filters);
        });
    },
    [projectsFiltersOrder, setCalendarView]
  );

  const { data, isLoading, isFetching } = useProjectsEntries(
    pageByView[view],
    view,
    PER_PAGE,
    selectedOrder,
    getAPIFilters(selectedFilters, segment),
    {
      enabled: enabled
    }
  );

  const { removeCache: removeProjectsCache } = useProjectsInvalidate();
  useUnmount(removeProjectsCache);

  useEffect(() => {
    setProjects((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        loading: isLoading || isFetching
      }
    }));
  }, [isLoading, isFetching]);

  const stringifiedFilters = stringify(selectedFilters);

  useEffect(() => {
    const basicFiltersAndOrderState = {
      updateFilter,
      setFilters,
      resetFilters,
      onOrderChange: setSelectedOrder,
      noOrderSelected
    };
    if (!data) {
      setProjects((prev) => ({ ...prev, ...basicFiltersAndOrderState }));
      return;
    }
    const { entries = [], meta = DEFAULT_META } = data;

    // we need to update filters schema on meta change, but keep selected filters values
    // this way we avoid another api call when default filters are configured,
    // or children toogle was turned off
    const valueFilters = getValueFilters(meta.filters);
    const newSelectedFilters = handleRequestFiltersReplace(valueFilters, selectedFilters);
    setFiltersRaw(newSelectedFilters);

    setProjects((prev) => ({
      data: {
        ...prev.data,
        entries,
        meta,
        loading: false
      },
      loaded: true,
      noFiltersSelected:
        isEmpty(meta) ||
        checkFiltersAreClear(meta.filters, ["state"]) ||
        checkFiltersAreDefault(selectedFilters, {}),
      ...basicFiltersAndOrderState
    }));
  }, [data, stringifiedFilters]);

  const anyChildrenFilters = some(
    Object.values(getAPIFilters(selectedFilters)?.children || {}),
    (value) => !!value
  );

  return {
    ...projects,
    view,
    setView,
    metric,
    setMetric,
    page,
    setPage,
    isChildrenFiltersEnabledDefault: anyChildrenFilters
  };
};

export default useProjectsData;
