import { Accordion } from "PFComponents/accordion";
import { Stack } from "PFComponents/containers";
import { Divider } from "PFComponents/divider";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { Id, ProfileCustomValue } from "PFTypes";

import { VerticalSkillsList } from "../../../vertical_skills_list";
import { SkillCheckbox } from "./skill_checkbox";

type GroupedSkillsListProps<T = Id> = {
  groups: {
    id: T;
    title: string;
    skills: ProfileCustomValue[];
  }[];
  profileId: Id;
  selectedSkillsIds: Id[];
  onSkillSelectionChange: (skillId: Id) => void;
};

export const GroupedSkillsList = <T extends Id>({
  groups,
  profileId,
  selectedSkillsIds,
  onSkillSelectionChange
}: GroupedSkillsListProps<T>) => {
  const { data: currentProfile } = useCurrentProfile();

  const isMe = profileId === currentProfile.id;

  return (
    <Stack gap="spacingLg">
      {groups.map(({ id, title, skills }, index) => (
        <>
          <Accordion key={id} header={title} headerVariant="bodyBold" animated={false}>
            <VerticalSkillsList
              skills={skills}
              profileId={profileId}
              renderCustomValueLeftContent={
                isMe
                  ? (skillId) => (
                      <SkillCheckbox
                        skillId={skillId}
                        selectedSkillsIds={selectedSkillsIds}
                        onSkillSelectionChange={onSkillSelectionChange}
                      />
                    )
                  : undefined
              }
            />
          </Accordion>
          {index < groups.length - 1 && <Divider />}
        </>
      ))}
    </Stack>
  );
};
