import find from "lodash/find";
import includes from "lodash/includes";
import { OrderOption } from "PFTypes";
import { TableColumn } from "PFTypes";

const COLUMNS_SIZE = {
  1: 100,
  2: 180,
  3: 250
};

export const getColumnConfig = (column: TableColumn, orderOptions: OrderOption[]) => ({
  id: column.name,
  header: column.title,
  enableSorting:
    !!column.orderKey && !!find(orderOptions, (option) => includes(option.name, column.orderKey)),
  size: COLUMNS_SIZE[column.size || 2]
});
