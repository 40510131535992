import { CustomValuesEditFieldProps } from "PFApp/components/custom_values_edit_field";
import { useSkillsProficiencyOptions } from "PFApp/hooks/skills/use_skills_proficiency_options";
import { SelectPill } from "PFComponents/double_section_pill/select_pill";
import { Experience, Id } from "PFTypes";

import css from "../skills_select.module.scss";

type SelectedSkillsProps = Required<Pick<CustomValuesEditFieldProps, "values">> & {
  onExperienceChange: (id: Id, newExperience: Experience) => void;
  onDelete: (id: Id) => void;
};

export const SelectedSkills = ({ values, onExperienceChange, onDelete }: SelectedSkillsProps) => {
  const proficiencyOptions = useSkillsProficiencyOptions();

  return (
    <div className={css.selectedSkills}>
      {values.map((value) => (
        <SelectPill
          key={value.id}
          leftContent={String(value.text || value.value)}
          attribute={
            proficiencyOptions.find(({ item }) => item === (value.required_experience || Experience.Basic))
              ?.displayElement || ""
          }
          attributeOptions={proficiencyOptions}
          onAttributeChange={(newExperience: Experience) => {
            onExperienceChange(value.id, newExperience);
          }}
          onDelete={() => {
            onDelete(value.id);
          }}
        />
      ))}
    </div>
  );
};
