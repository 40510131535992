import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { useActivityParent } from "PFApp/activities/show/hooks/use_activity_parent";
import { Accordion } from "PFComponents/accordion";
import { Stack } from "PFComponents/containers";
import { Typography } from "PFComponents/typography";
import { useReadableAvailability } from "PFCore/helpers/use_readable_availability";
import { AvailabilityRule } from "PFTypes";
import { useTranslation } from "react-i18next";

import { BookingCategoryInformation } from "./parts/booking_category_information";
import { SegmentInformation } from "./parts/segment_information";

export const RoleInformation = () => {
  const { t } = useTranslation("activities", { keyPrefix: "roleInformation" });
  const { task: activity } = useActivityPageContext();

  const parentActivity = useActivityParent(activity);
  const { readableAvailability } = useReadableAvailability({
    availability: activity.metadata.availability as AvailabilityRule
  });

  const title = parentActivity ? `${parentActivity?.name} - ${activity.name}` : activity.name;

  return (
    <Accordion header={t("title")} defaultState="closed" headerVariant="bodyBold" iconSet="chevron">
      <Stack>
        <Typography variant="labelRegular">{title}</Typography>
        {readableAvailability && <Typography variant="labelRegular">{readableAvailability}</Typography>}
        <BookingCategoryInformation />
        <SegmentInformation />
      </Stack>
    </Accordion>
  );
};
