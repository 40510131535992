import without from "lodash/without";
import { TemplateKey } from "PFApp/constants/templates";
import { useGrowl } from "PFApp/use_growl";
import { ActionIcon } from "PFComponents/action_icon";
import { Flex } from "PFComponents/containers";
import { Activity, Id } from "PFTypes";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ActivityActions } from "../../activity_actions";
import css from "./rows_actions.module.scss";

type RowActionsCellProps = {
  item: Activity;
  pinnedIds?: number[];
  type: TemplateKey.Role | TemplateKey.Engagement;
  setPinnedIds?: React.Dispatch<React.SetStateAction<number[]>>;
  onMarkAsRead?: (activityId: Id) => Promise<void>;
  onShare?: (activityId: Id) => void;
  onRefetch?: VoidFunction;
};

export const RowActionsCell = ({
  item,
  type,
  pinnedIds,
  onRefetch,
  onShare,
  onMarkAsRead,
  setPinnedIds
}: RowActionsCellProps) => {
  const { t } = useTranslation("workflow");
  const growl = useGrowl();

  const isPinned = useMemo(() => item.id && (pinnedIds?.includes(item.id) ?? false), [item.id, pinnedIds]);

  const unpinActivity = useCallback(
    () => setPinnedIds?.(without(pinnedIds, Number(item.id))),
    [item.id, setPinnedIds, pinnedIds]
  );

  const handleMarkAsRead = () => {
    onMarkAsRead?.(item.id).then(() => {
      growl({
        message: t("parts.markedAsRead")
      });
    });
  };

  return (
    <Flex className={css.rowActions} justifyContent="end" alignItems="center" gap="spacingLg">
      {isPinned && <ActionIcon name="pin" onClick={unpinActivity} />}
      {onMarkAsRead && <ActionIcon name="unseen" onClick={handleMarkAsRead} />}
      <ActivityActions
        item={item}
        type={type}
        pinnedIds={pinnedIds}
        setPinnedIds={setPinnedIds}
        onRefetch={onRefetch}
        onShare={onShare}
      />
    </Flex>
  );
};
