import difference from "lodash/difference";
import differenceBy from "lodash/differenceBy";
import unionBy from "lodash/unionBy";
import i18n from "PFCore/i18n";

import { OptionOriginal, ResultOption } from "../select_v2.types";

export const SELECT_ALL_OPTION_ID = "select-all";

export const getSelectAllOption = <T extends OptionOriginal>({
  availableOptions,
  selectedOptions,
  selectedOptionIds,
  selectedOptionsLimit,
  onChange,
  multiple,
  isSearchable
}): ResultOption<T> | null => {
  if (!multiple || availableOptions.length === 0) {
    return null;
  }

  const visibleUnselectedOptionsNumber = difference(
    availableOptions.map(({ id }) => id),
    selectedOptionIds
  ).length;

  const mayExceedSelectedOptionsLimit =
    selectedOptionsLimit && selectedOptionIds.length + visibleUnselectedOptionsNumber > selectedOptionsLimit;

  if (mayExceedSelectedOptionsLimit) {
    return null;
  }

  const visibleSelectableOptions = availableOptions.filter(
    ({ disabled, id }) => !disabled || selectedOptionIds.includes(id)
  );

  const isSelected =
    difference(
      visibleSelectableOptions.map(({ id }) => id),
      selectedOptionIds
    ).length === 0;

  return {
    id: SELECT_ALL_OPTION_ID,
    value: i18n.t(`core:components.selectV2.${isSearchable ? "selectResults" : "selectAll"}`, {
      count: visibleSelectableOptions.length
    }),
    selected: isSelected,
    original: {} as T,
    onChange: () =>
      onChange(
        isSelected
          ? differenceBy(selectedOptions, visibleSelectableOptions, "id")
          : unionBy(selectedOptions, visibleSelectableOptions, "id")
      )
  };
};
