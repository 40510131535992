import classNames from "classnames";
import { ActivityState } from "PFTypes/activity";
import { useTranslation } from "react-i18next";
import { humanize, titleize } from "underscore.string";

import { Pill } from "../pill/pill";
import css from "./role_status_badge.module.scss";

export type RoleStatus = ActivityState | "expires" | "interested" | "shared";

type RoleStatusBadgeProps = {
  workflowState?: string;
  state?: RoleStatus;
  className?: string;
  children?: React.ReactNode;
};

export const RoleStatusBadge = ({ workflowState, state, className, children }: RoleStatusBadgeProps) => {
  const { t } = useTranslation("core");

  const translationKey = workflowState
    ? `roleStatus.workflowState.${workflowState}`
    : `roleStatus.state.${state}`;

  const text =
    children ||
    t(translationKey as unknown as TemplateStringsArray, titleize(humanize(workflowState || state || "")));

  if (!text) {
    return null;
  }

  return (
    <Pill className={classNames(className, css[`dataType-${workflowState || state}`])} small>
      {text}
    </Pill>
  );
};
