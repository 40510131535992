import { datadogRum } from "@datadog/browser-rum";
import SortBy from "PFApp/components/sort_by";
import { SubsidiaryModeAlert } from "PFApp/components/subsidiary_mode_alert";
import { Button } from "PFComponents/button";
import { Inline, Stack } from "PFComponents/containers";
import { Id, Profile, ProfileCustomValue } from "PFTypes";
import { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { SkillsBulkEditSidePanel } from "./skills_bulk_edit_side_panel";
import css from "./skills_list_view.module.scss";
import { AlphabeticalSkillsList } from "./views/alphabetical_skills_list";
import { CategoryGroupedSkillsList } from "./views/category_grouped_skills_list";
import { ProficiencyGroupedSkillsList } from "./views/proficiency_grouped_skills_list";

type SkillsListViewProps = {
  skills: ProfileCustomValue[];
  profile: Profile;
  searchTerm: string;
  clearSearch: VoidFunction;
};

export const SkillsListView = ({ skills, profile, searchTerm, clearSearch }: SkillsListViewProps) => {
  const { t } = useTranslation("profiles");

  useEffect(() => {
    if (!datadogRum) {
      return;
    }
    const unrankedSkills = skills.filter(({ experience }) => !experience);

    if (unrankedSkills.length > 0) {
      datadogRum.addError(new Error("Unranked skills were found"), { unrankedSkills });
    }
  }, []);

  const sortOptions = useMemo(
    () =>
      [
        { text: t("show.parts.proficiency"), value: "proficiency" },
        { text: t("show.parts.skillCategory"), value: "skill_category" },
        { text: t("show.parts.alphabetical"), value: "alphabetical" }
      ].map((item) => ({
        id: item.value,
        displayElement: item.text,
        item
      })),
    [t]
  );

  const [selectedSort, setSelectedSort] = useState(sortOptions[0].id);
  const [selectedSkillsIds, setSelectedSkillsIds] = useState<Id[]>([]);
  const [isBulkEditSidePanelOpen, setBulkEditSidePanelOpen] = useState(false);

  useEffect(() => {
    const nonExistingSelectedSkillsIds = selectedSkillsIds.filter(
      (id) => !(profile.skills || []).find(({ id: skillId }) => skillId === id)
    );
    if (nonExistingSelectedSkillsIds.length > 0) {
      setSelectedSkillsIds((selectedIds) =>
        selectedIds.filter((id) => !nonExistingSelectedSkillsIds.includes(id))
      );
    }
  }, [profile.skills]);

  useEffect(() => {
    if (selectedSkillsIds.length === 0) {
      setBulkEditSidePanelOpen(false);
    }
  }, [selectedSkillsIds]);

  const handleSortChange = (sort) => {
    setSelectedSort(sort.value);
    setSelectedSkillsIds([]);
  };

  const handleSkillSelectionChange = (skillId: Id) => {
    setSelectedSkillsIds((prev) => {
      if (prev.includes(skillId)) {
        return prev.filter((id) => id !== skillId);
      } else {
        return [...prev, skillId];
      }
    });
  };

  const selectedView = {
    proficiency: (
      <ProficiencyGroupedSkillsList
        skills={skills}
        profileId={profile.id}
        selectedSkillsIds={selectedSkillsIds}
        onSkillSelectionChange={handleSkillSelectionChange}
      />
    ),
    skill_category: (
      <CategoryGroupedSkillsList
        skills={skills}
        profile={profile}
        selectedSkillsIds={selectedSkillsIds}
        onSkillSelectionChange={handleSkillSelectionChange}
      />
    ),
    alphabetical: (
      <AlphabeticalSkillsList
        skills={skills}
        profileId={profile.id}
        selectedSkillsIds={selectedSkillsIds}
        onSkillSelectionChange={handleSkillSelectionChange}
      />
    )
  }[selectedSort];

  const hasSearchTerm = searchTerm && searchTerm.length > 0;

  return (
    <Stack gap="spacingLg" className={css.container}>
      <Inline justifyContent="end" alignItems="center" className={css.header}>
        {hasSearchTerm && (
          <Inline justifyContent="space-between" alignItems="center" className={css.headerSearchActions}>
            <span>
              <Trans
                i18nKey="show.parts.searchedTermFoundCountSkills"
                t={t}
                values={{ count: skills.length, searchTerm }}
                components={[<strong key="0" />]}
              />
            </span>
            <div>
              <Button icon="cross" text={t("show.parts.clearSearch")} kind="text" onClick={clearSearch} />
            </div>
          </Inline>
        )}
        <SortBy options={sortOptions} onSortChange={handleSortChange} sort={selectedSort} />
      </Inline>
      {selectedSkillsIds.length > 0 && (
        <div className={css.bulkActionsContainer}>
          <SubsidiaryModeAlert
            title={t("show.parts.nSkillsSelected", { count: selectedSkillsIds.length })}
            actions={[
              {
                icon: "edit",
                onClick: () => setBulkEditSidePanelOpen(true)
              }
            ]}
            onClose={() => setSelectedSkillsIds([])}
            className={css.alert}
          />
        </div>
      )}
      <div className={css.list}>{selectedView}</div>
      {isBulkEditSidePanelOpen && (
        <SkillsBulkEditSidePanel
          skills={(profile.skills || []).filter(({ id }) => selectedSkillsIds.includes(id))}
          onSuccess={() => setSelectedSkillsIds([])}
          onClose={() => setBulkEditSidePanelOpen(false)}
        />
      )}
    </Stack>
  );
};
