import { Typography } from "PFComponents/typography";

import { CustomValueOption, CustomValueSelectProps } from "./custom_value_select";
import { ValueBadge } from "./value_badge";
import css from "./values_list.module.scss";

type ValuesListProps = Pick<
  CustomValueSelectProps,
  "onChange" | "onDateChange" | "withExpiryDate" | "multiple" | "placeholder"
> & {
  selectedOptions: CustomValueOption[];
};

export const ValuesList = ({
  selectedOptions,
  onChange,
  onDateChange,
  withExpiryDate,
  multiple,
  placeholder
}: ValuesListProps) => {
  if (selectedOptions.length === 0) {
    return placeholder ? (
      <Typography className={css.placeholder} variant="bodyBold">
        {placeholder}
      </Typography>
    ) : null;
  }

  const singleTextValue = !multiple && !withExpiryDate && selectedOptions[0]?.value;

  if (singleTextValue) {
    return (
      <Typography variant="bodyBold" clipOverflow title={singleTextValue}>
        {selectedOptions[0]?.value}
      </Typography>
    );
  }

  return (
    <div className={css.valuesList}>
      {selectedOptions.map((value) => (
        <ValueBadge
          key={value.id}
          value={value}
          locked={value.disabled}
          onRemove={(selectedOption, event) => {
            event.stopPropagation();
            onChange(selectedOptions.filter(({ id }) => id !== selectedOption.id));
          }}
          onDateChange={onDateChange}
          withExpiryDate={withExpiryDate}
        />
      ))}
    </div>
  );
};
