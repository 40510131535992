import { ActionIcon } from "PFComponents/action_icon";
import SimpleBadge from "PFComponents/badges_list/simple_badge";
import { useTranslation } from "react-i18next";

import { RemoveHandler, ValueBadgeGenericContentProps } from "./value_badge";

type ValueBadgeGenericProps = ValueBadgeGenericContentProps & {
  onRemove: RemoveHandler;
};

export const ValueBadgeGeneric = ({ displayValue, value, onRemove, locked }: ValueBadgeGenericProps) => {
  const { t } = useTranslation("core", {
    keyPrefix: "components.selectV2"
  });

  return (
    <SimpleBadge
      small
      text={displayValue}
      kind={value.original?.kind}
      icon={
        !locked ? (
          <ActionIcon
            name="cross"
            size="xs"
            title={t("removeOption", {
              label: displayValue
            })}
            onClick={(e) => onRemove(value, e)}
            color="paletteBlue2"
          />
        ) : null
      }
    />
  );
};
