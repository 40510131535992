import { Button } from "PFComponents/button";
import { LoadingDots } from "PFComponents/loading_dots";
import { isProfileActive } from "PFCore/helpers/profile";
import useAddShortlist from "PFCore/hooks/use_add_shortlist";
import { Activity } from "PFTypes";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useActivityPageContext } from "../activity_page_context";

type Props = {
  profile: { id: number };
  task: Activity;
  onClick?: () => void;
};

const AddShortlistButton = ({ profile, task, onClick }: Props) => {
  const { shortlists } = useActivityPageContext();
  const { shortlistProfile, undoShortlist, loading, isShortlisted, isShortlistsLoaded } = useAddShortlist(
    profile,
    task,
    shortlists
  );
  const { t } = useTranslation("activities", { keyPrefix: "show.parts" });

  const getButtonContent = useMemo(() => {
    if (loading) {
      return <LoadingDots />;
    }
    return isShortlisted ? t("shortlisted") : t("shortlist");
  }, [loading, isShortlisted]);

  if (!isProfileActive(profile)) {
    return null;
  }

  if (!isShortlistsLoaded) {
    return <LoadingDots />;
  }

  const handleClick = () => {
    if (isShortlisted) {
      undoShortlist({ onSuccess: onClick });
    } else {
      shortlistProfile({ onSuccess: onClick });
    }
  };

  const buttonKind = isShortlisted ? "primary" : "secondary";

  return (
    <Button disabled={loading} kind={buttonKind} onClick={handleClick} style={{ minWidth: 100 }}>
      {getButtonContent}
    </Button>
  );
};

export default AddShortlistButton;
