import { horizontalListSortingStrategy, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import classNames from "classnames";

import { ActionIcon } from "../../action_icon";
import { Stack } from "../../containers";
import { Tile } from "../../tile";
import Toggle from "../../toggle/toggle";
import { Typography } from "../../typography";
import { SortableListProps } from "../sortable_list";
import css from "../sortable_list.module.scss";

export enum ListAction {
  Sort = "sort",
  Removal = "removal",
  Toggle = "toggle"
}

export type SortableListItem = {
  id: string;
  label: string | React.ReactNode;
  subtitle?: string;
  toggled?: boolean;
  actionDisabled?: boolean;
};

type SortableItemProps = SortableListItem & {
  disabled?: boolean;
  onRemove: (id: string) => void;
  onToggle?: (id: string, value: boolean) => void;
  strategy: typeof horizontalListSortingStrategy | typeof verticalListSortingStrategy;
  action?: Omit<ListAction, ListAction.Sort>;
  classes?: SortableListProps["classes"];
};

export const SortableItem = ({
  label,
  subtitle,
  id,
  onRemove,
  onToggle,
  strategy,
  toggled,
  action,
  disabled,
  actionDisabled,
  classes
}: SortableItemProps) => {
  const { attributes, listeners, setNodeRef, transform } = useSortable({
    id
  });

  const style = {
    transform: transform
      ? `translate3d(${strategy === horizontalListSortingStrategy ? transform.x : 0}px, ${
          strategy === verticalListSortingStrategy ? transform.y : 0
        }px, 0)`
      : undefined
  };

  return (
    <Tile
      ref={setNodeRef}
      className={classNames(css.cell, classes?.item)}
      style={style}
      paddingVariant="md"
      border
    >
      {!disabled && (
        <ActionIcon
          name={
            strategy === horizontalListSortingStrategy ? "arrow-2-directions" : "arrow-2-directions-vertical"
          }
          {...listeners}
          {...attributes}
          size="xs"
          cursor="pointer"
          classes={{ root: classes?.dragIcon, icon: css.dragHandle }}
        />
      )}
      <Stack className={classNames(css.label, { [css.offsetLeft]: disabled })} gap={null}>
        {typeof label === "string" && <Typography variant="bodyBold">{label}</Typography>}
        {typeof label !== "string" && label}
        {subtitle && <Typography variant="bodyRegular">{subtitle}</Typography>}
      </Stack>
      {action === ListAction.Removal && (
        <ActionIcon name="cross" size="xs" onClick={() => onRemove(id)} disabled={actionDisabled} />
      )}
      {action === ListAction.Toggle && (
        <Toggle
          onChange={(value) => onToggle?.(id, value)}
          checked={toggled}
          disabled={actionDisabled}
          compact
        />
      )}
    </Tile>
  );
};
