import classNames from "classnames";
import { useGetFormattedPostUntil } from "PFCore/helpers/use_get_formatted_post_until";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import HourglassIcon from "PFIcons/hourglass.svg";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import css from "./activity_details_common.scss";

const ActivityDetailsTimeAndDate = ({ activity, parentActivity, renderTopLine = true }) => {
  const { t } = useTranslation("activities");
  const { data: currentProfile } = useCurrentProfile();
  const location = useLocation();

  const { formatDate } = useDateFormatter();

  const createdAt = formatDate(activity.created_at);

  const parentActivityTemplate = parentActivity
    ? currentProfile.templates.find((template) => template.id === parentActivity.template_id)
    : null;
  const taskTemplate = currentProfile.templates.find((template) => template.id === activity.template_id);

  const { timeHeader, timeValue } = useGetFormattedPostUntil(activity.post_until);
  const parentActivityTemplateName = parentActivityTemplate
    ? t(`templates.${parentActivityTemplate.key}`)
    : null;

  return (
    <>
      {parentActivity && (
        <div className={css.infoItem}>
          <strong>{parentActivityTemplateName ?? t("show.details.parentActivity")}</strong>
          <Link
            to={{
              pathname: `/activities/${parentActivity.id}`,
              search: `origin_id=${activity.id}&origin_type=${taskTemplate.name}`
            }}
            state={{ utmSource: location?.state?.utmSource }}
            style={parentActivityTemplate?.key === "engagement" ? { fontSize: 25 } : null}
          >
            {parentActivity.name}
          </Link>
        </div>
      )}
      {renderTopLine && <div className={css.topLine} />}
      <div className={css.twoCols} data-qa-id="ActivityDetailsTimeAndDate">
        {timeHeader && (
          <div className={css.infoItem}>
            <strong>{timeHeader}</strong>
            <div className={classNames(css.inlineIcon, css.description)}>
              <HourglassIcon width={20} height={20} />
              {timeValue}
            </div>
          </div>
        )}
        <div className={css.infoItem}>
          <strong>{t("translation:createdAt")}</strong>
          <div className={classNames(css.inlineIcon, css.description)}>
            <span>{createdAt}</span>
          </div>
        </div>
      </div>
    </>
  );
};

ActivityDetailsTimeAndDate.propTypes = {
  activity: PropTypes.object,
  parentActivity: PropTypes.object,
  renderTopLine: PropTypes.bool
};

export default ActivityDetailsTimeAndDate;
