import { Option, SelectV2 } from "PFComponents/select_v2";
import { HistorySortBy } from "PFTypes";
import { useTranslation } from "react-i18next";

import { useActivityHistoryContext } from "../../activity_history_context";
import { useActivityHistory } from "../../hooks";
import css from "./history_sort.module.scss";

export const HistorySort = () => {
  const { t } = useTranslation("activities", { keyPrefix: "show.history.sort" });
  const { sortBy, setSortBy } = useActivityHistoryContext();
  const { data } = useActivityHistory();
  const historyEntries = data?.entries || [];

  if (historyEntries.length === 0) {
    return null;
  }

  const options = [
    {
      id: HistorySortBy.Latest,
      value: t("latest"),
      original: HistorySortBy.Latest
    },
    {
      id: HistorySortBy.Oldest,
      value: t("oldest"),
      original: HistorySortBy.Oldest
    }
  ];

  return (
    <div className={css.root}>
      <SelectV2<HistorySortBy>
        value={sortBy}
        options={options}
        onChange={(option: Option<HistorySortBy>) => setSortBy(option.original)}
      />
    </div>
  );
};
