import classNames from "classnames";
import { Typography } from "PFCore/components/typography";
import radii from "PFTheme/tokens/radii";

import css from "./drop_area_overlay.module.scss";

type DropAreaOverlayProps = {
  message?: string;
  zIndex?: number;
  borderRadius?: keyof typeof radii;
  solid?: boolean;
  className?: string;
};

export const DropAreaOverlay = ({
  message,
  zIndex,
  borderRadius,
  solid,
  className
}: DropAreaOverlayProps) => (
  <div
    className={classNames(css.overlay, { [css.solid]: solid }, className)}
    style={{ zIndex, borderRadius: borderRadius ? radii[borderRadius] : undefined }}
    aria-hidden
  >
    {message && <Typography variant="h4">{message}</Typography>}
  </div>
);
