import api from "PFCore/api";
import { ProfileNotificationSetting } from "PFTypes";

export type UpdateProfileNotificationSettingsPayload = {
  notificationSettings: Pick<ProfileNotificationSetting, "id" | "frequency">[];
};

export const updateProfileNotificationSettings = (data: UpdateProfileNotificationSettingsPayload) =>
  api<ProfileNotificationSetting[]>({
    url: "profile_notification_settings/bulk_update",
    method: "POST",
    data
  });
