import classNames from "classnames";
import { Typography } from "PFComponents/typography";

import { Pill, PillProps } from "../../pill/pill";
import css from "./double_section_pill.module.scss";

type PillKind = "primary" | "secondary" | "selected" | "faded";

export type DoubleSectionPillProps = {
  leftContent: React.ReactElement | string;
  rightContent: React.ReactElement | string;
  rightContentClassName?: string;
  kind?: PillKind;
} & Pick<PillProps, "onDelete" | "onDeleteKeyDown" | "small" | "deleteTitle">;

export const DoubleSectionPill = ({
  leftContent,
  rightContent,
  rightContentClassName,
  onDelete,
  onDeleteKeyDown,
  kind = "primary",
  small,
  deleteTitle
}: DoubleSectionPillProps) => (
  <span className={classNames(css.wrapper, css[kind])}>
    <Pill
      small={small}
      className={classNames(css.leftSection)}
      onDelete={onDelete}
      onDeleteKeyDown={onDeleteKeyDown}
      deleteTitle={deleteTitle}
      kind="general"
    >
      {leftContent}
    </Pill>
    <Pill small={small} className={classNames(rightContentClassName, css.rightSection)}>
      <Typography variant="bodyRegular" tag="span">
        {rightContent}
      </Typography>
    </Pill>
  </span>
);
