import includes from "lodash/includes";
import { marked, Renderer, Tokens } from "marked";
import { emojify } from "PFCore/helpers/emojify";
import { gidToPath } from "PFCore/helpers/gid";
import { startsWith } from "underscore.string";
import filterXSS from "xss";

const LINK_MENTION_RE =
  /^[!@]{0,1}?\[((?:\[[^\]]*\]|[^[\]]|\](?=[^[]*\]))*)\]\(\s*<?([\s\S]*?)>?(?:\s+['"]([\s\S]*?)['"])?\s*\)/;
const LINK_TEXT_RE = /^[\s\S]+?(?=[\\<![_*`~@$]|https?:\/\/| *\n|$)/;

marked.Lexer.rules.inline.breaks.link = LINK_MENTION_RE;
marked.Lexer.rules.inline.breaks.text = LINK_TEXT_RE;

// Mentions renderer gid profile links
// $[<text>](gid://profinda-api/Profile/<Id>)
//
const mentions = (renderer: Renderer, args: Tokens.Link) => {
  const data = args.href.replace(/^gid:\/\/profinda-api\//, "").split("/");
  const path = gidToPath(args.href as any);
  const tokens = [...args.tokens] as unknown as Tokens.Link;
  tokens[0].text = `@${args.text}`;
  const tag = marked.Renderer.prototype.link.call(renderer, {
    ...args,
    href: path,
    tokens
  });
  return tag.replace(
    /^<a/,
    `<a data-js-mention='true'
      data-js-mention-type='${data[0]}'
      data-js-mention-id='${data[1]}'`
  );
};

const renderer = {
  link(args: Tokens.Link) {
    if (args.href.match(/^gid:\/\/profinda-api\/Profile\/[0-9]+/)) {
      return mentions(this, args);
    }
    const link = marked.Renderer.prototype.link.call(this, args).replace(/href="([^"]+)"/, (match, url) => {
      // filterXXS will filter anything without http or https protocol so here we
      // this add it so it supports things like "www.example.com" and "example.com"
      if (
        !(
          startsWith(url, "http://") ||
          startsWith(url, "https://") ||
          startsWith(url, "/") ||
          startsWith(url, "mailto:")
        )
      ) {
        url = `http://${url}`;
      }

      // adds target=_blank to 3rd party links
      if (!startsWith(url, "#/")) {
        return `href='${url}' target='_blank'`;
      } else {
        return `href='${url}'`;
      }
    });
    return link;
  }
};

const defaultOptions = {
  renderer,
  gfm: true,
  tables: true,
  breaks: true,
  pedantic: false
};

marked.use(defaultOptions);

const allow = (tag, attr) => {
  const tagWhiteList = filterXSS.whiteList[tag];
  if (!includes(tagWhiteList, attr)) {
    tagWhiteList.push(attr);
  }
  return allow;
};

export const fullMarkdown = (text?: string, options = { emojify: true }) => {
  if (!text) {
    return "";
  }

  let html = options.emojify ? emojify(text, { careful: true }) : text;

  // escape ` for non code blocks for cases like "ProFinda`s team"
  html = html.replace(/([^ `\n])`([^ `\n])/g, "$1&#96;$2");
  html = marked.parse(html);
  options.emojify && (html = emojify(html));

  allow("img", "class");
  allow("span", "class");
  allow("code", "class");
  allow("a", "data-js-mention");
  allow("a", "data-js-mention-type");
  allow("a", "data-js-mention-id");

  return filterXSS(html).replace(/<a/g, " <a rel='noopener noreferrer'").replace(/&amp;/g, "&");
};
