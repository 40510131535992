import classNames from "classnames";
import { HTMLAttributes, PropsWithChildren, useEffect, useRef, useState } from "react";

import css from "./animate.module.scss";

type Asable = "article" | "div" | "footer" | "header" | "li" | "main" | "ol" | "section" | "span" | "ul";

type AnimateProps = {
  as?: Asable;
  trigger?: boolean;
} & HTMLAttributes<HTMLElement>;

export const Animate = ({
  as: Component = "div",
  children,
  trigger,
  className,
  ...divProps
}: PropsWithChildren<AnimateProps>) => {
  const [currentHeight, setCurrentHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      setCurrentHeight(ref?.current?.clientHeight || 0);
    });
    resizeObserver.observe(ref.current);

    return () => resizeObserver.disconnect();
  }, []);

  return (
    <Component
      style={{
        maxHeight: currentHeight,
        minHeight: currentHeight
      }}
      className={classNames(css.root, className)}
      {...divProps}
    >
      <div ref={ref}>{children}</div>
    </Component>
  );
};
