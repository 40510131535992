import { DragEndEvent, DragStartEvent } from "@dnd-kit/core";
import { useState } from "react";

type UseSkillsDND = {
  addToLog: (...changes: any[]) => void;
};

export const useSkillsDND = ({ addToLog }: UseSkillsDND) => {
  const [draggedSkill, setDraggedSkill] = useState(null);

  const handleDragStart = (event: DragStartEvent) => {
    setDraggedSkill(event.active.data.current?.skill ?? null);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    setDraggedSkill(null);

    if (!event.over) {
      return;
    }

    const { skill, parentSection } = event.active.data.current || {};
    const newExperience = event.over.data.current?.experience;
    const currentExperience = skill.experience;

    if (newExperience === currentExperience) {
      return;
    }

    const changeType = ["discovered", "suggested", "framework"].includes(parentSection)
      ? parentSection
      : "ranked";

    addToLog({ id: skill.id, data: { experience: newExperience }, type: changeType });
  };

  return {
    draggedSkill,
    handleDragStart,
    handleDragEnd
  };
};
