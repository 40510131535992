import { Inline, Stack } from "PFComponents/containers";
import { Icon } from "PFComponents/icon";
import { Tile } from "PFComponents/tile";
import TooltipIcon from "PFComponents/tooltip_icon/tooltip_icon";
import { Typography } from "PFComponents/typography";
import { PropsWithChildren, ReactNode } from "react";

import css from "./skills_tile.module.scss";

type SkillsTileProps = PropsWithChildren<{
  title: string;
  tooltipContent: string | ReactNode;
  customHeaderRightContent?: ReactNode;
}>;

export const SkillsTile = ({
  title,
  tooltipContent,
  customHeaderRightContent,
  children
}: SkillsTileProps) => (
  <Tile paddingVariant="lg" className={css.skillsTile} selected>
    <Stack>
      <Inline alignItems="center">
        <Typography variant="h5">{title}</Typography>
        <TooltipIcon
          tooltipProps={{
            theme: "pf-white"
          }}
          content={tooltipContent}
          IconComponent={() => <Icon name="info" size="sm" />}
        />
        {customHeaderRightContent}
      </Inline>
      <div className={css.content}>{children}</div>
    </Stack>
  </Tile>
);
