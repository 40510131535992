import { usePusherEvent } from "PFCore/base/pusher";
import { gidToId, gidToType } from "PFCore/helpers/gid";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { useNotificationMarkAsRead } from "PFCore/hooks/queries/notifications/use_notification_mark_as_read";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { ApiRoute, UiRoute } from "PFCore/utilities/routes";
import logoURL from "PFImages/native_notification_icon.png";
import { CHAT_ACTIONS } from "PFReducers/chat_reducer";
import { FeatureFlag } from "PFTypes";
import pluralize from "pluralize";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const useNativeNotifications = (dispatch) => {
  const navigate = useNavigate();
  const { data: currentProfile } = useCurrentProfile();
  const { mutate: markNotificationAsRead } = useNotificationMarkAsRead();
  const isFeatureEnabled = useIsFeatureEnabled();
  const isNativeNotificationsEnabled = isFeatureEnabled(FeatureFlag.NativeNotifications);

  const [notificationsAllowed, setNotificationsAllowed] = useState(false);

  const checkNotificationPromise = () => {
    try {
      Notification.requestPermission().then();
    } catch {
      return false;
    }
    return true;
  };

  const setupNotificationsInBrowser = () => {
    if (!("Notification" in window)) {
      setNotificationsAllowed(false);
    } else {
      if (checkNotificationPromise()) {
        Notification.requestPermission().then((permission) => {
          setNotificationsAllowed(permission === "granted");
        });
      } else {
        Notification.requestPermission((permission) => {
          setNotificationsAllowed(permission === "granted");
        });
      }
    }
  };

  useEffect(() => {
    if (isNativeNotificationsEnabled) {
      setupNotificationsInBrowser();
    } else {
      setNotificationsAllowed(false);
    }
  }, [isNativeNotificationsEnabled]);

  const handleCreateNotificationEvent = useCallback(
    (data) => {
      if (!notificationsAllowed) {
        return;
      }

      const type = pluralize(gidToType(data.resource_gid).toLowerCase());
      const id = gidToId(data.resource_gid);
      if (type === "notifications") {
        $.ajax(ApiRoute(`/api/notifications/${id}`), {
          api_version: 2
        }).then((data) => {
          const notification = new Notification(data.title, {
            body: data.body,
            icon: data.picture_url,
            tag: data.category_type,
            image: UiRoute(logoURL)
          });
          notification.onclick = () => {
            if (data.category_type === "message") {
              dispatch({ type: CHAT_ACTIONS.CHAT_RENDER, payload: { id: data.target_id } });
            } else {
              navigate(data.url_address);
            }
            window.focus();
            markNotificationAsRead({ id: data.id });
            notification.close();
          };
        });
      }
    },
    [dispatch, markNotificationAsRead, navigate, notificationsAllowed]
  );

  usePusherEvent(
    `private-profile-${currentProfile.id}`,
    "create",
    handleCreateNotificationEvent,
    !!currentProfile.id
  );
};
