import groupBy from "lodash/groupBy";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import values from "lodash/values";
import { PER_PAGE_UNPAGINATED } from "PFApp/constants/unpaginated";
import { Inline, Stack } from "PFComponents/containers";
import { Divider } from "PFComponents/divider";
import { LoadingDots } from "PFComponents/loading_dots";
import { Modal } from "PFComponents/modal";
import { Typography } from "PFComponents/typography";
import { useProfileSkillsFrameworks } from "PFCore/hooks/queries/skills_frameworks/use_profile_skills_frameworks";
import { useSkillsType } from "PFCore/hooks/use_skills_type";
import { CurrentProfile, CustomValue, Profile, SkillsFramework } from "PFTypes";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { EditableCustomValuePill } from "./editable_custom_value_pill";
import css from "./skills_frameworks_suggested_skills_modal.module.scss";

type SkillsFrameworksSuggestedSkillsModalProps = {
  profile: Profile | CurrentProfile;
  onSkillRanked?: (customValue: Partial<CustomValue>) => void;
  handleClose: VoidFunction;
};

export const SkillsFrameworksSuggestedSkillsModal = ({
  profile,
  onSkillRanked,
  handleClose
}: SkillsFrameworksSuggestedSkillsModalProps) => {
  const { t } = useTranslation("profiles", { keyPrefix: "show.parts.skillsModal.suggestions.framework" });
  const { t: tCore } = useTranslation("core");
  const [portalElement, setPortalElement] = useState<HTMLDivElement | null>(null);

  const skillsType = useSkillsType();

  const { data, isLoading } = useProfileSkillsFrameworks({
    profileId: profile.id,
    params: {
      page: 1,
      perPage: PER_PAGE_UNPAGINATED
    }
  });

  const getMatchedCustomFields = useCallback(
    (skillsFramework: SkillsFramework) => {
      const customFieldsGrouped = values(groupBy(skillsFramework.customFields, "customType.id")).map(
        (customFields) => ({
          customTypeName: customFields[0].customType.displayAs,
          customValues: map(customFields, "customValue")
        })
      );

      const customFieldsValues = customFieldsGrouped.map(({ customTypeName, customValues }) => {
        let formattedValues = map(customValues, "value").join(", ");
        if (customValues.length > 1) {
          formattedValues = `(${formattedValues})`;
        }

        return `${customTypeName} = ${formattedValues}`;
      });

      return customFieldsValues.join(` ${tCore("and")} `);
    },
    [tCore]
  );

  const getSuggestedSkills = useCallback(
    (skillsFramework: SkillsFramework) =>
      orderBy(
        skillsFramework.skills.map(({ customValue, requiredExperience }) => {
          const profileExperience = profile.skills?.find(({ id }) => id === customValue.id)?.experience ?? 0;

          return {
            ...customValue,
            experience: profileExperience,
            requiredExperience,
            state: "approved"
          };
        }),
        [({ experience }) => experience === 0, "value"]
      ),
    [profile.skills]
  );

  const sortedSkillsFrameworks = orderBy(data?.entries || [], "name");

  return (
    <Modal title={t("title")} onClose={handleClose} showOKButton={false} classes={{ modal: css.modal }}>
      <div
        ref={(node) => {
          setPortalElement(node);
        }}
      />
      <Stack gap="spacingLg" className={css.content}>
        {isLoading && <LoadingDots circlesEnabled circleSize="xs" centered />}
        {sortedSkillsFrameworks.map((skillsFramework, index) => (
          <>
            <Stack key={skillsFramework.id}>
              <Typography variant="h5" className={css.sectionTitle}>
                {t("skillsFrameworkName", { name: skillsFramework.name })}
              </Typography>
              <Typography variant="bodyRegular">
                {t("description", { matchedCustomFields: getMatchedCustomFields(skillsFramework) })}
              </Typography>
              <Inline wrap="wrap" alignItems="center">
                {getSuggestedSkills(skillsFramework).map((skill, index, array) => (
                  <Inline key={skill.id} alignItems="center">
                    <EditableCustomValuePill
                      key={skill.id}
                      id={skill.id}
                      name={String(skill.value)}
                      experience={skill.experience}
                      requiredExperience={skill.requiredExperience}
                      customTypeName={skillsType!.name}
                      customTypeAriaLabel={skillsType!.display_as}
                      portalElement={portalElement ?? undefined}
                      onChange={onSkillRanked}
                    />
                    {index < array.length - 1 && (
                      <Divider
                        type="vertical"
                        color="paletteNeutral0"
                        theme={{ divider: css.skillDivider }}
                      />
                    )}
                  </Inline>
                ))}
              </Inline>
            </Stack>
            {index < sortedSkillsFrameworks.length - 1 && <Divider />}
          </>
        ))}
      </Stack>
    </Modal>
  );
};
