import jqXHR = JQuery.jqXHR;
import { ApiRoute } from "PFCore/utilities/routes";
import { ProfileMinimized } from "PFTypes";

export type FetchChangeRequestsPayload = {
  conversationId: number;
  profileId: number;
  term?: string;
  page?: number;
  perPage?: number;
};

type FetchChangeRequestsResponse = {
  entries: ProfileMinimized[];
  meta: { total: number };
};

export const fetchMentionables = ({
  profileId,
  conversationId,
  term = "",
  page = 1,
  perPage = 50
}: FetchChangeRequestsPayload): jqXHR<FetchChangeRequestsResponse> => {
  const searchParams = new URLSearchParams({
    profile_id: profileId.toString(),
    conversation_id: conversationId.toString(),
    term,
    page: page.toString(),
    per_page: perPage.toString()
  });
  // @ts-ignore
  return $.ajax({
    url: ApiRoute(`/api/chat/mentionables?${searchParams.toString()}`),
    api_version: 2
  });
};
