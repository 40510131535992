import classNames from "classnames";
import isArray from "lodash/isArray";
import { Stack } from "PFComponents/containers";
import { SelectV2 } from "PFComponents/select_v2";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import useBookingCategories from "PFCore/hooks/use_booking_categories";
import { BookingCategory, FeatureFlag } from "PFTypes";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import CategoryOption from "../booking_form/booking_category_select/category_option/category_option";
import css from "./booking_category_select.modules.scss";

type BookingCategorySelectProps = {
  category: BookingCategory | null;
  className?: string;
  error?: string;
  tip?: string;
  onCategoryChange?: (value: BookingCategory | null) => void;
  required?: boolean;
  demand?: boolean;
  label?: string;
  letClear?: boolean;
  restrictedTextWidth?: boolean;
  disabled?: boolean;
  withoutLabel?: boolean;
  onRestore?: VoidFunction;
};

export const BookingCategorySelect = ({
  category,
  className,
  error,
  tip,
  onCategoryChange,
  required,
  demand,
  label,
  letClear,
  restrictedTextWidth,
  disabled,
  withoutLabel,
  onRestore
}: BookingCategorySelectProps) => {
  const { t } = useTranslation("bookingModule");
  const isBookingModuleEnabled = useIsFeatureEnabled()(FeatureFlag.BookingModule);
  const { bookingCategories } = useBookingCategories();

  const options = useMemo(() => {
    let options: BookingCategory[];
    if (!isBookingModuleEnabled) {
      options = bookingCategories.filter((category) => category.main);
    } else {
      options = bookingCategories.filter(
        (category) =>
          !category.archived &&
          !category.readonly &&
          (demand ? category.activity_assignable : !category.activity_assignable)
      );
    }
    return options.map((category) => ({
      id: category.id,
      value: category.display_as,
      displayElement: (
        <CategoryOption
          category={category}
          restrictedTextWidth={restrictedTextWidth}
          className={css.option}
        />
      ),
      original: category
    }));
  }, [bookingCategories, demand, restrictedTextWidth, isBookingModuleEnabled]);

  const mainCategory = bookingCategories.find(({ main }) => main);
  const value = isBookingModuleEnabled ? category?.id : mainCategory?.id;

  return (
    <Stack className={classNames(css.root, className)}>
      <SelectV2<BookingCategory>
        label={withoutLabel ? undefined : label || t("bookings.create.selectCategory")}
        disabled={!isBookingModuleEnabled || disabled}
        value={value}
        renderDisplayValue={(value) =>
          !!value?.id &&
          !!value.original && (
            <CategoryOption category={value.original} restrictedTextWidth={restrictedTextWidth} />
          )
        }
        onChange={(option) => {
          const categoryOption = isArray(option) ? option[0] : option;
          onCategoryChange?.(categoryOption?.original ?? null);
        }}
        options={options}
        required={required}
        onClear={letClear && !!category ? () => onCategoryChange?.(null) : undefined}
        onRestore={onRestore}
        error={error}
        tip={tip}
      />
    </Stack>
  );
};
