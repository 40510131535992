import { ElementType, forwardRef, Ref } from "react";

import { Flex, FlexProps } from "./flex";

export type StackProps<T extends ElementType = "div"> = FlexProps<T> & {
  direction?: never;
  reverse?: boolean;
  spread?: boolean;
};

export const Stack = forwardRef(
  <T extends ElementType = "div">({ reverse, spread, ...props }: StackProps<T>, ref: Ref<any>) => (
    <Flex
      ref={ref}
      direction={reverse ? "column-reverse" : "column"}
      justifyContent={spread ? "space-between" : undefined}
      {...props}
    />
  )
);

Stack.displayName = "Stack";
