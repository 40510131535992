import { ProfileCustomValuesList } from "PFApp/components/custom_values_list";
import { Id, ProfileCustomValue } from "PFTypes";
import { ReactNode } from "react";

type VerticalSkillsListProps = {
  skills: ProfileCustomValue[];
  profileId: Id;
  renderCustomValueLeftContent?: (skillId: Id) => ReactNode;
};

export const VerticalSkillsList = ({
  skills,
  profileId,
  renderCustomValueLeftContent
}: VerticalSkillsListProps) => (
  <ProfileCustomValuesList
    customFieldName="skills"
    profileId={profileId}
    customValues={skills}
    limit={Infinity}
    renderCustomValueLeftContent={renderCustomValueLeftContent}
    canEdit
    vertical
  />
);
