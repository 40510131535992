import { ColumnHelper } from "@tanstack/react-table";
import find from "lodash/find";
import includes from "lodash/includes";
import map from "lodash/map";
import { useTableSorting } from "PFApp/hooks/use_table_sorting";
import { Card } from "PFComponents/card";
import { Stack } from "PFComponents/containers";
import Table from "PFComponents/table";
import i18n from "PFCore/i18n";
import NoProfilesIcon from "PFIcons/no_profiles.svg";
import { TableColumn } from "PFTypes";
import { OrderOption } from "PFTypes";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { RoleWatcher } from "../../../../../types/watchers";
import { useCellFactory } from "./hooks/use_cell_factory";
import { useInterestedContext } from "./interested_context_provider";
import css from "./interested_table.module.scss";

const COLUMNS_SIZE = {
  1: 100,
  2: 180,
  3: 250
};

const getColumnConfig = (column: TableColumn, orderOptions: OrderOption[]) => ({
  id: column.name,
  header: i18n.t(column.title),
  enableSorting:
    !!column.orderKey && !!find(orderOptions, (option) => includes(option.name, column.orderKey)),
  size: COLUMNS_SIZE[column.size || 2]
});

const InterestedTable = () => {
  const { t } = useTranslation("activities", { keyPrefix: "show.interested" });

  const {
    selectedIds,
    setSelectedIds,
    filteredWatchers,
    watchersMetadata,
    watchersQuery: { isLoading },
    selectedColumns: columns,
    orderStorage: { setSelectedOrder }
  } = useInterestedContext();

  const renderCell = useCellFactory();

  const { sorting, handleSortingChange } = useTableSorting({
    metaOrder: watchersMetadata?.order,
    setParamsOrder: setSelectedOrder,
    parseTableSortingId: (id) => find(columns, ["name", id])?.orderKey || id,
    parseMetaOrderName: (name) => find(columns, ["orderKey", name])?.name || name
  });

  const generateColumns = useCallback(
    (columnHelper: ColumnHelper<RoleWatcher>) => [
      ...map(columns, (column) =>
        columnHelper.accessor((row) => row[column.name], {
          ...getColumnConfig(column, watchersMetadata?.order?.options || []),
          cell: renderCell(column)
        })
      )
    ],
    [columns]
  );

  if ((watchersMetadata?.total === 0 || !watchersMetadata) && !isLoading) {
    return (
      <div className={css.noItems}>
        <NoProfilesIcon />
        <div className={css.msg}>{t("noInterestedUsers")}</div>
      </div>
    );
  }

  return (
    <Card>
      <Stack>
        <Table
          tableData={filteredWatchers}
          isLoading={isLoading}
          generateColumns={generateColumns}
          onSortingChange={handleSortingChange}
          controlledSorting={sorting}
          enableSorting={!!watchersMetadata?.order}
          onRowSelectionChange={setSelectedIds}
          rowSelection={selectedIds}
          getRowId={({ id }) => String(id)}
          enableRowSelection={!!setSelectedIds}
          enableMultiRowSelection
        />
      </Stack>
    </Card>
  );
};

export default InterestedTable;
