import moment from "moment";
import { getSegment } from "PFCore/helpers/get_segment";
import { getProfileName } from "PFCore/helpers/profile";
import { getVisibleProperties } from "PFCore/helpers/templates";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import useIsPermittedTo from "PFCore/helpers/use_is_permitted_to";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import {
  Activity,
  ActivityCustomField,
  ActivityPureCustomValue,
  CustomValue,
  FeatureFlag,
  PermissionRule,
  Subtemplate,
  Template
} from "PFTypes";
import { useMemo } from "react";

import { shouldDisplayProperty } from "../../utils/should_display_property";
import { useIsAssigneeRequired } from "./use_is_assignee_required";

export type ActivityWithDefaultAttributes = Partial<Omit<Activity, "custom_fields">> & {
  custom_fields?: (Omit<ActivityCustomField, "values"> & {
    values: (ActivityPureCustomValue | CustomValue)[];
  })[];
};

type UseActivityEditPageDefaultAttributesProps = {
  isClone: boolean;
  isNew: boolean;
  template?: Template;
  parentActivity?: Activity;
  subtemplates?: Subtemplate[];
  attributes: ActivityWithDefaultAttributes;
};

export const useActivityEditPageDefaultAttributes = ({
  isClone,
  isNew,
  template,
  parentActivity,
  subtemplates,
  attributes
}: UseActivityEditPageDefaultAttributesProps): ActivityWithDefaultAttributes => {
  const { data: currentProfile } = useCurrentProfile();
  const { data: currentAccount } = useCurrentAccount();
  const { customTypes } = useCustomTypes();
  const isPermittedTo = useIsPermittedTo(currentProfile);
  const isResourcer = isPermittedTo(PermissionRule.Resourcer);
  const isSegmentEnabled = useIsFeatureEnabled()(FeatureFlag.Segments);

  const isAssigneeRequiredFnc = useIsAssigneeRequired();

  const defaultAttributes = useMemo<ActivityWithDefaultAttributes>(() => {
    if (!isNew || !template) {
      return {};
    }

    const result = {
      custom_fields: [],
      metadata: {},
      booking_category_id: parentActivity?.booking_category_id,
      ...attributes
    };

    if (!isClone && parentActivity?.assignee) {
      result.assignee = parentActivity.assignee;
    }

    if (!result.assignee && isAssigneeRequiredFnc(template) && isResourcer) {
      result.assignee = { profile: currentProfile, id: -1 };
    }

    result.post_until = moment().add(template.default_post_until_days, "days").toISOString();

    const getParentCustomFieldValues = () => {
      if (!parentActivity) {
        return {};
      }

      return parentActivity.custom_fields.reduce((obj, customField) => {
        if (template.schema.properties[customField.type.name]) {
          obj[customField.type.name] = customField.values;
        }
        return obj;
      }, {});
    };

    // custom defaults not from schema
    const customDefaultValues: Record<string, CustomValue[] | string> =
      {
        engagement: {
          requested_by: getProfileName(currentProfile),
          service_line: currentProfile.service_line
        },
        role: {
          requested_by: getProfileName(currentProfile),
          talent_pool: currentProfile.talent_pool
        }
      }[template.key] || {};

    const parentActivityValues: Record<string, ActivityPureCustomValue[]> =
      template.key === "role" ? getParentCustomFieldValues() : {};

    const profileSegment = isSegmentEnabled ? getSegment(currentAccount, currentProfile) : {};

    getVisibleProperties(template).forEach((property) => {
      const defaultValue =
        parentActivityValues[property.name] ||
        (property.segment && profileSegment[property.name]) ||
        property.default ||
        customDefaultValues[property.name];

      const isInAttributesSection =
        !property.match && !property.segment && shouldDisplayProperty(property, customTypes, currentProfile);
      const hasSubtemplates = Number(subtemplates?.length) > 0;
      const allowFillingDefault = !(hasSubtemplates && isInAttributesSection);

      if (!defaultValue || !allowFillingDefault) {
        return;
      }

      if (property.type !== "custom_field") {
        const valueBlank = result.metadata[property.name] === undefined;
        if (valueBlank) {
          result.metadata[property.name] = defaultValue;
        }

        return;
      }

      const customType = customTypes.filter((type) => type.name === property.name)[0];
      const valueBlank = !result.custom_fields.find((field) => field.type.id === customType.id);
      if (customType && defaultValue && valueBlank) {
        result.custom_fields = [
          ...result.custom_fields,
          {
            type: { id: customType.id as number, name: customType.name },
            values: defaultValue
          }
        ];
      }
    });
    return result;
  }, [currentProfile, customTypes, parentActivity, attributes, template, isClone, isNew, subtemplates]);

  return defaultAttributes;
};
