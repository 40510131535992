import kebabCase from "lodash/kebabCase";
import { useMemo } from "react";

import { useOptionsInvalidate } from "../use_options/use_options_invalidate";

export const useOptionCreate = ({
  searchTerm,
  onCreateOption,
  createOptionBlocklist,
  setIsOpen,
  dropdownId,
  isFetchingOptionsEnabled,
  resultOptions,
  selectedOptions,
  isFetching
}) => {
  const { invalidate } = useOptionsInvalidate();

  const allowCreate = useMemo(() => {
    const term = searchTerm.trim();

    return (
      !isFetching &&
      !!onCreateOption &&
      term.length > 0 &&
      !createOptionBlocklist?.includes(term) &&
      !selectedOptions.find(({ value }) => value.toLowerCase() === term.toLowerCase()) &&
      !resultOptions.find(({ value }) => value.toLowerCase() === term.toLowerCase())
    );
  }, [isFetching, onCreateOption, searchTerm, resultOptions, selectedOptions, createOptionBlocklist]);

  const handleCreateOption = allowCreate
    ? () => {
        const newOption = { id: kebabCase(searchTerm), value: searchTerm };
        if (isFetchingOptionsEnabled) {
          Promise.resolve(onCreateOption(newOption)).then(() => {
            invalidate(dropdownId);
          });
        } else {
          onCreateOption(newOption);
        }
        setIsOpen(false);
      }
    : undefined;

  return { handleCreateOption };
};
