import { Icon } from "PFComponents/icon";
import { LoadingDots } from "PFComponents/loading_dots";
import { Modal } from "PFComponents/modal";
import AutoSelect from "PFComponents/select/autoselect";
import { Typography } from "PFComponents/typography";
import { fetchProfiles } from "PFCore/services/profile";
import { IMatchScores, Profile } from "PFTypes";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ProfileItem } from "../../../components/profile_item";
import {
  ProfileFrom,
  useReassignActionApi,
  useReassignActionState
} from "../../parts/overview/booking_module_calendar/context/reassign_action_context_provider";
import { useReassignSubmit } from "../../parts/overview/booking_module_calendar/context/use_reassign_submit";
import { BookingsToReassignSelector, ReassignSelectOption } from "./bookings_to_reassign_selector";
import { MatchScoreSection } from "./match_score_section";
import { ProfileItemDisplay } from "./profile_item_display";
import css from "./reassign_booking_modal.module.scss";

type SelectedUserData = {
  profile: Profile | undefined;
  scores?: IMatchScores;
};

type ReassignBookingModalProps = {
  profileFrom?: ProfileFrom;
  profileTo?: SelectedUserData;
  readOnlyMode?: boolean;
  shouldDisplayReassignOptions: boolean;
  isLoading?: boolean;
};

export const ReassignBookingModal = ({
  profileFrom,
  profileTo,
  readOnlyMode,
  shouldDisplayReassignOptions,
  isLoading
}: ReassignBookingModalProps) => {
  const { t } = useTranslation("bookingModule");

  const { data, isModalOpened } = useReassignActionState();
  const { closeModal } = useReassignActionApi();
  const { handleSubmit } = useReassignSubmit({ data });

  const [reassignOption, setReassignOption] = useState(ReassignSelectOption.ThisBooking);
  const [selectedUser, setSelectedUser] = useState<SelectedUserData | null>(profileTo ?? null);

  useEffect(() => {
    setSelectedUser(profileTo ?? null);
  }, [profileTo]);

  // TODO: [PROF-3444] Display matching scores section when the API/HAL part is ready
  const displayMatchingScoreSection = false;

  if (!isModalOpened || !data) {
    return null;
  }
  const roleName = data.activityName ?? "";

  const handleClose = () => {
    data?.onClose && data.onClose();
    closeModal();
  };

  const handleOk = () => {
    handleSubmit(reassignOption);
    handleClose();
  };

  const thisBooking = {
    ...data.booking,
    startDate: data.booking.start_date,
    endDate: data.booking.end_date
  };

  return (
    <Modal
      title={t("bookings.reassign.title", { roleName })}
      labelOK={t("bookings.reassign.reassignAction")}
      onOK={handleOk}
      onClose={handleClose}
      classes={{ modal: css.modal }}
    >
      <div className={css.root}>
        <Typography withMargin variant="bodyRegular">
          {t("bookings.reassign.question")}
        </Typography>
        {shouldDisplayReassignOptions && !!profileFrom && (
          <BookingsToReassignSelector
            profileId={profileFrom.id}
            activityId={data.booking.activity_id ?? -1}
            currentBooking={{
              id: thisBooking.id ?? -1,
              startDate: thisBooking.startDate,
              endDate: thisBooking.endDate
            }}
            reassignOptionSelected={reassignOption}
            setReassignOptionSelected={setReassignOption}
          />
        )}
        {isLoading || !profileFrom || !selectedUser?.profile ? (
          <LoadingDots circlesEnabled circleSize="xs" />
        ) : (
          <div className={css.reassignContainer}>
            <ProfileItemDisplay profile={profileFrom} />
            <div>
              <Icon name="reassign" />
            </div>

            <div className={css.personToContainer}>
              {readOnlyMode ? (
                selectedUser && <ProfileItemDisplay profile={selectedUser.profile} />
              ) : (
                <div className={css.personTo}>
                  <AutoSelect
                    displayValues={
                      selectedUser ? <ProfileItemDisplay profile={selectedUser.profile} /> : null
                    }
                    rootClassName={css.selectInput}
                    multi={false}
                    disabled={readOnlyMode}
                    closeOnChange
                    values={selectedUser ? [selectedUser.profile] : null}
                    query={(term) => {
                      const filters = term ? { fields: { term: term } } : {};
                      return fetchProfiles({ filters, page: 1, per_page: 20, full: true });
                    }}
                    formatOption={({ scores, profile, profiles, displayElement, search_id, total }) => ({
                      id: profile?.id,
                      displayElement: <ProfileItem profile={profile} />,
                      item: profile ? { profile, scores } : { profiles, displayElement, search_id, total }
                    })}
                    handleChange={(user) => {
                      setSelectedUser(user[0]);
                    }}
                    parseResponse={(resp) => (Array.isArray(resp.entries) ? resp.entries : resp)}
                    required
                  />
                </div>
              )}

              {displayMatchingScoreSection && (
                <div className={css.matchScoresSection}>
                  {selectedUser && <MatchScoreSection scores={selectedUser.scores} />}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};
