import map from "lodash/map";
import reduce from "lodash/reduce";
import { useAppContext } from "PFApp/app_context";
import { Button } from "PFComponents/button";
import Tooltip from "PFComponents/tooltip/tooltip";
import { CustomValue } from "PFTypes";
import { useEffect, useState } from "react";

import { SegmentComposerSidePanel } from "./segment_composer_side_panel";
import { SegmentComposerTooltipContent } from "./segment_composer_tooltip_content";

type SegmentComposerProps = {
  className?: string;
  disabled?: boolean;
};

export const SegmentComposer = ({ className, disabled }: SegmentComposerProps) => {
  const {
    store: { segment }
  } = useAppContext();

  const [isSegmentSidePanelVisible, setSegmentSidePanelVisibility] = useState(false);

  useEffect(() => {
    if (disabled) {
      setSegmentSidePanelVisibility(false);
    }
  }, [disabled]);

  const segmentLabel = reduce(
    segment,
    (acc: string[], value: CustomValue[]) => [...acc, ...map(value, "text")],
    []
  ).join(" - ");

  return (
    <>
      <Tooltip content={<SegmentComposerTooltipContent segment={segment} />}>
        <Button
          icon="segment"
          kind="tertiary"
          title=""
          text={segmentLabel}
          onClick={() => setSegmentSidePanelVisibility(true)}
          className={className}
          disabled={disabled}
        />
      </Tooltip>
      <SegmentComposerSidePanel
        isVisible={isSegmentSidePanelVisible}
        onClose={() => setSegmentSidePanelVisibility(false)}
      />
    </>
  );
};
