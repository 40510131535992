import classNames from "classnames";
import { useAppContext } from "PFApp/app_context";
import { TemplateKey } from "PFApp/constants/templates";
import useDebounce from "PFCore/helpers/use_debounce";
import { useActivities } from "PFCore/hooks/queries/activities/use_activities";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { SEARCH_ACTIONS, SEARCH_ASYNC_ACTIONS } from "PFReducers/search_reducer/search_reducer";
import { useEffect, useState } from "react";

import { HeaderToolbox } from "../components/header_toolbox";
import RecentSearches from "./parts/recent_searches";
import SearchLikeAPro from "./parts/search_like_a_pro";
import ChoosenKeywords from "./search_box/choosen_keywords";
import SearchBox from "./search_box/search_box";
import css from "./search_page.less";
import SmartSuggestions from "./smart_suggestions/smart_suggestions";

const RecentSearchesPage = () => {
  const {
    dispatch,
    store: {
      search: { choosenKeywords, term, matchTypes, filterTypes }
    }
  } = useAppContext();
  const { data: currentProfile } = useCurrentProfile();

  // setup
  useEffect(() => {
    dispatch({ type: SEARCH_ACTIONS.SEARCH_RESET_STATE });
  }, []);

  useEffect(() => {
    dispatch({ type: SEARCH_ACTIONS.SEARCH_SET_TYPES, payload: { currentProfile } });
  }, [currentProfile]);

  // keywords
  const trimmedTerm = term.trim(); // avoid triggering effects on no-changes (use in the deps)
  useEffect(
    useDebounce(() => {
      const type = SEARCH_ASYNC_ACTIONS.SEARCH_FETCH_SUGGESTED_KEYWORDS;
      dispatch({
        type,
        payload: { choosenKeywords, term, matchTypes, filterTypes }
      });
    }, 500),
    [
      trimmedTerm,
      choosenKeywords.map(({ id, global_id, globalId }) => `${global_id || globalId},${id}`).join(":")
    ]
  );

  // searchbox
  const [searchBoxKey, setSearchBoxKey] = useState(0);
  const handleResetStateClick = () => {
    dispatch({ type: SEARCH_ACTIONS.SEARCH_RESET_STATE });
    setSearchBoxKey(searchBoxKey + 1);
  };
  const encodedTerm = window.encodeURI(term).replace(/\//g, "%2f");

  // searches
  const { data, isFetching } = useActivities(
    {
      filters: { fields: { owner_id: currentProfile.id, template_key: TemplateKey.Task, state: "search" } },
      per_page: 5
    },
    {
      staleTime: 0
    }
  );

  const searches = data?.entries || null;

  return (
    <div className={css.root}>
      <HeaderToolbox className={css.headerToolbox} showSegment />
      <SmartSuggestions />
      <div className={classNames(css.wrap)}>
        <div className={css.content}>
          <SearchBox key={searchBoxKey} buttonHref={`/search/new/all/${encodedTerm}`} />
          <ChoosenKeywords handleResetStateClick={handleResetStateClick} />
          <div className={css.flexRow}>
            <RecentSearches searches={searches} isLoading={isFetching} />
            <SearchLikeAPro />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecentSearchesPage;
