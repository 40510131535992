import filter from "lodash/filter";
import { useStorageTableColumns } from "PFApp/hooks/use_storage_table_columns";
import { TableColumn } from "PFTypes";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { MatchesShortlistColumnId } from "../../types/matches_shortlist_column_id";
import { useMatchSidebarCustomTypes } from "./use_match_sidebar_info_custom_types";

export const useMatchesShortlistTablesColumns = () => {
  const { t } = useTranslation("activities", { keyPrefix: "show.matches.table" });
  const { matchSidebarInfoCustomTypes } = useMatchSidebarCustomTypes();

  const staticColumns = useMemo<TableColumn[]>(
    () => [
      {
        name: MatchesShortlistColumnId.Match,
        title: t("match"),
        isDefault: true
      },
      {
        name: MatchesShortlistColumnId.Availability,
        title: t("availability"),
        isDefault: true
      },
      {
        name: MatchesShortlistColumnId.WorkforceMember,
        title: t("workforceMember"),
        isDefault: true,
        locked: true
      },
      {
        name: MatchesShortlistColumnId.SupportingSkills,
        title: t("supportingSkills")
      },
      {
        name: MatchesShortlistColumnId.EssentialSkills,
        title: t("essentialSkills"),
        isDefault: true
      },
      {
        name: MatchesShortlistColumnId.RequiredSkills,
        title: t("requiredSkills")
      },
      {
        name: MatchesShortlistColumnId.RelatedSkills,
        title: t("relatedSkills")
      },
      {
        name: MatchesShortlistColumnId.Actions,
        title: t("actions"),
        isDefault: true
      }
    ],
    [t]
  );

  const availableMatchesShortlistColumns = useMemo(
    () => [
      ...staticColumns,
      ...matchSidebarInfoCustomTypes.map(({ name, display_as }) => ({ name, title: display_as ?? name }))
    ],
    [staticColumns, matchSidebarInfoCustomTypes]
  );

  const { selectedColumns, setSelectedColumns } = useStorageTableColumns(
    "matches_shortlist_tables_columns",
    availableMatchesShortlistColumns,
    filter(availableMatchesShortlistColumns, "isDefault")
  );

  return {
    availableMatchesShortlistColumns,
    selectedMatchesShortlistColumns: selectedColumns,
    setSelectedMatchesShortlistColumns: setSelectedColumns
  };
};
