import { Pill } from "PFComponents/pill";
import Tooltip from "PFComponents/tooltip/tooltip";
import { PureCustomValue } from "PFTypes";
import { useTranslation } from "react-i18next";

import { Description } from "../description";
import css from "./skill_option_display_element.module.scss";

type SkillOptionDisplayElementProps = {
  option: PureCustomValue;
  isDisabled: boolean;
  isAlreadyAdded: boolean;
  portalRef?: React.RefObject<HTMLElement | null>;
};

export const SkillOptionDisplayElement = ({
  option,
  isDisabled,
  isAlreadyAdded,
  portalRef
}: SkillOptionDisplayElementProps) => {
  const { t } = useTranslation("profiles", { keyPrefix: "show.parts.skillsModal" });

  return (
    <Tooltip
      content={option.description && <Description text={option.description} />}
      placement="right"
      interactive
      className={css.descTooltip}
      appendTo={portalRef?.current || undefined}
    >
      <span className={css.selectValueWrapper}>
        {option.text || option.value}
        {isDisabled && <Pill text={t(isAlreadyAdded ? "added" : (option.state as any))} small />}
      </span>
    </Tooltip>
  );
};
