export const APP_LAYOUT_ACTIONS = {
  APP_LAYOUT_SET_MAIN_DIMENSIONS: "APP_LAYOUT_SET_MAIN_DIMENSIONS",
  APP_LAYOUT_TOGGLE_NAV: "APP_LAYOUT_TOGGLE_NAV",
  APP_LAYOUT_HIDE_NAV: "APP_LAYOUT_HIDE_NAV",
  APP_LAYOUT_SET_LEFT_ACTIONS_PANEL: "APP_LAYOUT_SET_LEFT_ACTIONS_PANEL",
  APP_LAYOUT_SET_EDGE_TO_EDGE: "APP_LAYOUT_SET_EDGE_TO_EDGE",

  APP_LAYOUT_SHOW_BACKDROP: "APP_LAYOUT_SHOW_BACKDROP",
  APP_LAYOUT_HIDE_BACKDROP: "APP_LAYOUT_HIDE_BACKDROP",
  APP_LAYOUT_SET_LAYOUT: "APP_LAYOUT_SET_LAYOUT"
};

export const appLayoutReducer = (state, { type, payload }) => {
  const changeAppLayout = (changes) => ({ ...state, appLayout: { ...state.appLayout, ...changes } });

  switch (type) {
    case APP_LAYOUT_ACTIONS.APP_LAYOUT_SET_MAIN_DIMENSIONS:
      return changeAppLayout({
        mainLeft: payload.mainLeft || 0,
        mainRight: payload.mainRight || 0,
        mainWidth: payload.mainWidth || 0,
        windowWidth: payload.windowWidth || 320,
        windowHeight: payload.windowHeight || 320
      });

    case APP_LAYOUT_ACTIONS.APP_LAYOUT_TOGGLE_NAV:
      return changeAppLayout({ navBarRevealed: !state.appLayout.navBarRevealed });

    case APP_LAYOUT_ACTIONS.APP_LAYOUT_HIDE_NAV:
      return changeAppLayout({ navBarRevealed: false });

    case APP_LAYOUT_ACTIONS.APP_LAYOUT_SET_EDGE_TO_EDGE:
      return changeAppLayout({ edgeToEdge: payload });

    case APP_LAYOUT_ACTIONS.APP_LAYOUT_SET_LEFT_ACTIONS_PANEL:
      return changeAppLayout({
        leftActionPanel: {
          id: payload.id,
          title: payload.title,
          actions: payload.actions || false,
          content: payload.content,
          collapsed: payload.collapsed // be explict!
        }
      });

    case APP_LAYOUT_ACTIONS.APP_LAYOUT_SHOW_BACKDROP: {
      return changeAppLayout({
        backdropOnClicks: [...state.appLayout.backdropOnClicks, payload.onClick]
      });
    }

    case APP_LAYOUT_ACTIONS.APP_LAYOUT_HIDE_BACKDROP: {
      while (state.appLayout.backdropOnClicks.length > 0) {
        state.appLayout.backdropOnClicks.pop()();
      }
      return changeAppLayout({ backdropOnClicks: [] });
    }

    case APP_LAYOUT_ACTIONS.APP_LAYOUT_SET_LAYOUT: {
      return changeAppLayout({ layout: payload.layout });
    }
  }
};
