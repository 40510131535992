import { Divider } from "PFComponents/divider";
import { Tile } from "PFComponents/tile";

import { MatchAttributes } from "../../../components/match_attributes";
import { RoleInformation } from "../../../components/role_information";
import { MatchesChart } from "../../components/matches_chart";
import css from "./matches_information.module.scss";

export const MatchesInformation = () => (
  <Tile className={css.root} paddingVariant="lg" selected>
    <RoleInformation />
    <Divider />
    <MatchesChart />
    <Divider />
    <MatchAttributes />
  </Tile>
);
