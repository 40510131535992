import classNames from "classnames";
import { Ref } from "react";

import css from "./tile.module.scss";

type TileProps = {
  ref?: Ref<HTMLDivElement>;
  className?: string;
  light?: boolean;
  selected?: boolean;
  border?: boolean;
  paddingVariant?: "none" | "sm" | "md" | "lg" | "xl";
  children?: React.ReactNode;
  style?: React.CSSProperties;
};

export const Tile = ({
  ref,
  className,
  children,
  light,
  selected,
  border,
  paddingVariant = "sm",
  style,
  ...props
}: TileProps) => (
  <div
    ref={ref}
    className={classNames(css.tile, className, {
      [css.light]: light,
      [css.selected]: selected,
      [css.border]: border,
      [css.noPadding]: paddingVariant === "none",
      [css.smallPadding]: paddingVariant === "sm",
      [css.mediumPadding]: paddingVariant === "md",
      [css.largePadding]: paddingVariant === "lg",
      [css.xlargePadding]: paddingVariant === "xl"
    })}
    style={style}
    {...props}
  >
    {children}
  </div>
);
