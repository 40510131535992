import { useMutation } from "@tanstack/react-query";
import { createDictionaryConnection, CreateDictionaryConnectionParams } from "PFCore/services/custom_values";
import { MutationOptions } from "PFTypes/request_options";

import { ActivitiesCollection } from "../activities/query_keys";
import { useActivitiesInvalidate } from "../activities/use_activities_invalidate";
import { useCustomValuesInvalidate } from "../custom_fields/admin";
import { useCustomValueInvalidate } from "../custom_fields/use_custom_value_invalidate";
import { useCurrentProfile } from "../profile";
import { useProfileInvalidate } from "../profile/use_profile_invalidate";
import { useSkillsFrameworksInvalidate } from "../skills_frameworks/use_skills_frameworks_invalidate";

export const useDictionaryConnectionCreate = ({
  onSuccess,
  shouldInvalidateCurrentProfile = true,
  ...options
}: MutationOptions<CreateDictionaryConnectionParams> & {
  shouldInvalidateCurrentProfile?: boolean;
} = {}) => {
  const { data: currentProfile } = useCurrentProfile();
  const { invalidateCurrentProfile } = useProfileInvalidate();
  const { invalidateByCollection } = useActivitiesInvalidate();
  const { invalidate: invalidateCustomValues } = useCustomValuesInvalidate();
  const { invalidateCustomValueProfileScope } = useCustomValueInvalidate();
  const { invalidateProfileSkillsFrameworks } = useSkillsFrameworksInvalidate();

  return useMutation({
    mutationFn: (payload: CreateDictionaryConnectionParams) => createDictionaryConnection(payload),
    onSuccess: (...params) => {
      const { customValueId } = params[1];
      shouldInvalidateCurrentProfile && invalidateCurrentProfile();
      invalidateByCollection(ActivitiesCollection.MatchingActivityStatus);
      invalidateCustomValues();
      invalidateCustomValueProfileScope(customValueId, currentProfile.id);
      invalidateProfileSkillsFrameworks(currentProfile.id);
      onSuccess?.(...params);
    },
    ...options
  });
};
