import identity from "lodash/identity";
import { ActionIcon, ActionIconProps } from "PFComponents/action_icon";

import css from "./input_field_icons.module.scss";

export type InputFieldIconsList = (ActionIconProps | undefined | null)[];

type InputFieldProps = {
  icons: InputFieldIconsList;
  disabled?: boolean;
};

export const InputFieldIcons = ({ icons, disabled }: InputFieldProps) => (
  <div className={css.icons}>
    {(icons.filter(identity) as ActionIconProps[]).map(({ disabled: iconDisabled, ...iconProps }) => (
      <ActionIcon
        tabIndex={0}
        key={`input-icon-${iconProps.name}`}
        size="sm"
        {...iconProps}
        disabled={disabled || iconDisabled}
      />
    ))}
  </div>
);
