import {
  CollisionDetection,
  DndContext,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";

import { SortableItem, SortableListItem } from "./parts/sortable_list_item";

export enum ListAction {
  Sort = "sort",
  Removal = "removal",
  Toggle = "toggle"
}

export type SortableListProps = {
  onChange: (sortedList: SortableListItem[], itemId: string, listAction: ListAction) => void;
  onToggle?: (itemId: string, value: boolean) => void;
  onDragStart?: (event: DragStartEvent) => void;
  strategy: typeof horizontalListSortingStrategy | typeof verticalListSortingStrategy;
  items: SortableListItem[];
  collisionDetection?: CollisionDetection;
  action?: Omit<ListAction, ListAction.Sort>;
  disabled?: boolean;
  classes?: Partial<{
    item: string;
    dragIcon: string;
  }>;
};

export const SortableList = ({
  onChange,
  onToggle,
  onDragStart,
  strategy,
  items,
  collisionDetection,
  action,
  disabled,
  classes
}: SortableListProps) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active && over && active.id !== over.id) {
      const oldIndex = items.findIndex((item) => item.id === active.id);
      const newIndex = items.findIndex((item) => item.id === over.id);

      const newOrder = arrayMove(items, oldIndex, newIndex);
      onChange(newOrder, active.id, ListAction.Sort);
      return newOrder;
    }
  };

  const handleRemove = (itemId: string) => {
    const itemIndex = items.findIndex((item) => item.id === itemId);
    const listCopy = [...items];
    listCopy.splice(itemIndex, 1);
    onChange(listCopy, itemId, ListAction.Removal);
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetection}
      onDragStart={onDragStart}
      onDragEnd={handleDragEnd}
    >
      <SortableContext strategy={strategy} items={items}>
        {items.map((item) => (
          <SortableItem
            key={item.id}
            {...item}
            onRemove={handleRemove}
            onToggle={onToggle}
            strategy={strategy}
            action={action}
            disabled={disabled}
            actionDisabled={item.actionDisabled}
            classes={classes}
          />
        ))}
      </SortableContext>
    </DndContext>
  );
};
