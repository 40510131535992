import classNames from "classnames";
import { BookingPill } from "PFApp/components/pills/booking_pill";
import { Typography } from "PFComponents/typography";
import { BookingCategory } from "PFTypes";

import css from "./category_option.module.scss";

interface CategoryOptionProps {
  category: BookingCategory;
  restrictedTextWidth?: boolean;
  className?: string;
}

const CategoryOption = ({ category, restrictedTextWidth, className }: CategoryOptionProps) => {
  const text = category.display_as;
  return (
    <div className={classNames(css.option, className)} title={text}>
      <div className={classNames(css.text, { [css.restrictedTextWidth]: restrictedTextWidth })}>
        <Typography withMargin variant="bodyRegular" tag="span">
          {text}
        </Typography>
      </div>
      <BookingPill
        category={category}
        className={css.pill}
        center
        style={{
          borderWidth: 1
        }}
      />
    </div>
  );
};

export default CategoryOption;
