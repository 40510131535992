import { ExperienceMultiToggle } from "PFApp/components/experience_multi_toggle";
import { useGrowl } from "PFApp/use_growl";
import { Button } from "PFComponents/button";
import { Inline, Stack } from "PFComponents/containers";
import { LoadingDots } from "PFComponents/loading_dots";
import SidePanel from "PFComponents/side_panel/side_panel";
import { Typography } from "PFComponents/typography";
import { useDictionaryConnectionCreate } from "PFCore/hooks/queries/custom_values/use_dictionary_connection_create";
import { Experience, ProfileCustomValue } from "PFTypes";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import css from "./skills_list_view.module.scss";

type SkillsBulkEditSidePanelProps = {
  skills: ProfileCustomValue[];
  onSuccess: VoidFunction;
  onClose: VoidFunction;
};

export const SkillsBulkEditSidePanel = ({ skills, onSuccess, onClose }: SkillsBulkEditSidePanelProps) => {
  const { t: tTranslation } = useTranslation();
  const { t } = useTranslation("profiles");
  const growl = useGrowl();
  const { mutateAsync: createDictionaryConnection, isPending } = useDictionaryConnectionCreate({
    shouldInvalidateCurrentProfile: false
  });

  const [experience, setExperience] = useState<Experience | null>(null);

  const handleSave = (onSidePanelClose: VoidFunction) => {
    Promise.all(
      skills.map(({ id, text }) =>
        createDictionaryConnection({
          customValueId: id,
          experience: experience!
        }).catch(() => {
          growl({ message: tTranslation("customValueSidepanel.saveError", { value: text }), kind: "error" });
        })
      )
    ).then(() => {
      growl({ message: t("show.parts.bulkSaveSuccess") });
      onSidePanelClose();
      onSuccess();
    });
  };

  const handleRemove = (onSidePanelClose: VoidFunction) => {
    Promise.all(
      skills.map(({ id, text }) =>
        createDictionaryConnection({ customValueId: id, experience: Experience.None }).catch(() => {
          growl({
            message: tTranslation("customValueSidepanel.removeError", { value: text }),
            kind: "error"
          });
        })
      )
    ).then(() => {
      growl({ message: t("show.parts.bulkRemoveSuccess") });
      onSidePanelClose();
      onSuccess();
    });
  };

  const renderFooter = ({ onSidePanelClose }: { onSidePanelClose: VoidFunction }) => (
    <Inline gap="spacingLg" justifyContent="space-between">
      <Inline gap="spacingLg">
        <Button kind="secondary" text={tTranslation("cancel")} onClick={onClose} />
        <Button kind="danger" text={tTranslation("remove")} onClick={() => handleRemove(onSidePanelClose)} />
      </Inline>
      <Button
        kind="primary"
        disabled={!experience || isPending}
        text={tTranslation("save")}
        onClick={() => handleSave(onSidePanelClose)}
      />
    </Inline>
  );

  return (
    <SidePanel
      show
      fullHeight
      title={t("show.parts.editSkills")}
      footerRenderer={renderFooter}
      onClose={onClose}
    >
      {!!isPending && (
        <div className={css.savingOverlay}>
          <LoadingDots circlesEnabled circleSize="xl" />
        </div>
      )}
      <Stack gap="spacingLg">
        <Typography variant="bodyRegular">{skills.map(({ text }) => text).join(", ")}</Typography>
        <Typography variant="bodyRegular">{t("show.parts.chooseProficiencyForAllSkills")}</Typography>
        <ExperienceMultiToggle experience={experience} onChange={setExperience} />
      </Stack>
    </SidePanel>
  );
};
