import { DndContext, DragOverlay } from "@dnd-kit/core";
import filter from "lodash/filter";
import { Divider } from "PFComponents/divider";
import { Modal } from "PFComponents/modal";
import { useModalContext } from "PFComponents/modal/modal_context";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { Profile, ProfileCustomValue } from "PFTypes";
import { Id } from "PFTypes/id";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Skill } from "../common/skill";
import { useSkillsDND } from "./hooks/use_skills_dnd";
import { useSkillsModal } from "./hooks/use_skills_modal";
import { AddNewSkillSection } from "./parts/add_new_skill_section";
import SkillsGrid from "./parts/skills_grid";
import { SkillsInsightsSection } from "./parts/skills_insights_section";
import { SkillsSuggestions } from "./parts/skills_suggestions";
import css from "./skills_modal.module.scss";
import { customCollisionDetectionAlgorithm } from "./skills_modal.utils";

export type ChangesLogItem = {
  id: Id;
  type: "new" | "suggested" | "discovered" | "delete" | "framework" | "ranked";
  data: ProfileCustomValue;
  oldData?: ProfileCustomValue;
  developmental?: boolean;
  top?: boolean;
};

type SkillsModalProps = {
  profile: Profile;
  handleProfileUpdate: () => Profile;
  onClose: VoidFunction;
};

export const SkillsModal = ({ profile, onClose, handleProfileUpdate }: SkillsModalProps) => {
  const { t } = useTranslation("profiles", { keyPrefix: "show.parts.skillsModal" });
  const { data: currentAccount } = useCurrentAccount();
  const [portalElement, setPortalElement] = useState<HTMLDivElement | null>(null);

  const {
    addNewSkill,
    addToLog,
    changesLog,
    discoveredSkills,
    discoveredSkillsLoading,
    suggestedSkills,
    suggestedFrameworksSkills,
    isLoadingSuggestedFrameworksSkills,
    skills,
    skillsByExperience,
    skillsValues,
    newSkills,
    saveChanges,
    newSkillRating,
    setNewSkillRating,
    addedValues,
    deletedValues,
    coreSkillsCount,
    developmentalSkillsCount,
    skillsSortingSelected,
    setSkillsSortingSelected,
    setSkills,
    removeFromLog,
    handleSkillChange,
    blockedSkills
  } = useSkillsModal(profile, handleProfileUpdate);
  const { draggedSkill, handleDragStart, handleDragEnd } = useSkillsDND({
    addToLog
  });

  const [isConfirmModalDisplayed, setIsConfirmModalDisplayed] = useState(false);

  const tabs = useMemo(
    () =>
      [
        {
          id: "suggested",
          title: t("suggestedSkills"),
          infoText: t("suggestions.suggested.info"),
          skills: suggestedSkills as unknown as ProfileCustomValue[]
        },
        {
          id: "framework",
          title: t("suggestions.framework.title"),
          infoText: t("suggestions.framework.info", { name: currentAccount.name }),
          skills: suggestedFrameworksSkills,
          disabled: isLoadingSuggestedFrameworksSkills
        },
        {
          id: "discovered",
          title: t("discoveredSkills"),
          infoText: t("suggestions.discovered.title"),
          skills: discoveredSkills as unknown as ProfileCustomValue[],
          disabled: discoveredSkillsLoading
        }
      ].filter(({ skills }) => skills.length),
    [
      currentAccount.name,
      discoveredSkills,
      discoveredSkillsLoading,
      suggestedSkills,
      suggestedFrameworksSkills,
      isLoadingSuggestedFrameworksSkills,
      t
    ]
  );

  return (
    <Modal
      title={t("mySkills")}
      onOK={saveChanges}
      labelOK={t("save")}
      disableOKButton={changesLog.length === 0}
      onBeforeClose={() => {
        if (changesLog.length > 0) {
          setIsConfirmModalDisplayed(true);
          return false;
        }

        return true;
      }}
      onClose={onClose}
      classes={{ modal: css.modal }}
    >
      <div
        className={css.outerGrid}
        ref={(node) => {
          setPortalElement(node);
        }}
      >
        <div className={css.sidebar}>
          <AddNewSkillSection
            addNewSkill={addNewSkill}
            skillsValues={skillsValues}
            newSkills={newSkills}
            newSkillRating={newSkillRating}
            setNewSkillRating={setNewSkillRating}
            addedValues={addedValues}
            deletedValues={deletedValues}
            handleSkillChange={handleSkillChange}
            blockedSkills={blockedSkills}
          />
          <Divider type="horizontal" className={css.divider} />
          <SkillsInsightsSection profile={profile} skills={skills} />
        </div>
        <DndContext
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          collisionDetection={customCollisionDetectionAlgorithm}
        >
          <div className={css.content} tabIndex={-1}>
            <SkillsGrid
              skillsByExperience={skillsByExperience}
              skillsSortingSelected={skillsSortingSelected}
              setSkillsSortingSelected={setSkillsSortingSelected}
              addToLog={addToLog}
              coreSkillsCount={coreSkillsCount}
              developmentalSkillsCount={developmentalSkillsCount}
              portalElement={portalElement ?? undefined}
            />
            <div>
              {tabs.length > 0 && (
                <SkillsSuggestions
                  addToLog={addToLog}
                  tabs={tabs}
                  profile={profile}
                  onSkillRanked={(customValue) => {
                    setSkills((prevSkills) => [
                      customValue,
                      ...filter(prevSkills, ({ id }) => id !== customValue.id)
                    ]);

                    // remove the log describing suggestion change
                    // after its added to profile via SkillsFrameworksSuggestedSkillsModal
                    removeFromLog(({ id }) => customValue.id === id);
                  }}
                />
              )}
            </div>
          </div>
          <DragOverlay dropAnimation={null}>
            {draggedSkill && <Skill skill={draggedSkill} isDragging />}
          </DragOverlay>
        </DndContext>
      </div>
      {isConfirmModalDisplayed && (
        <ConfirmCloseModal onConfirmClose={() => setIsConfirmModalDisplayed(false)} />
      )}
    </Modal>
  );
};

type ConfirmCloseModalProps = {
  onConfirmClose: VoidFunction;
};

const ConfirmCloseModal = ({ onConfirmClose }: ConfirmCloseModalProps) => {
  const { t: defaultT } = useTranslation("translation");
  const { t } = useTranslation("profiles", { keyPrefix: "show.parts.skillsModal" });

  const { closeWithAnimation } = useModalContext();

  return (
    <Modal
      title={t("closeSkillsModal")}
      onOK={() => {
        // Close skills modal
        closeWithAnimation(false);
      }}
      onClose={() => {
        onConfirmClose();
      }}
      labelOK={defaultT("ok")}
    >
      {t("leavingPageWillDiscardChanges")}
    </Modal>
  );
};
