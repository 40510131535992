import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import isNil from "lodash/isNil";
import useStorage from "PFCore/helpers/use_storage";
import { OrderOption } from "PFTypes";
import { useCallback, useEffect } from "react";

const STORAGE_OPTIONS = { expiresIn: 0 };

// TODO: [SP-2332] Fix storage order type
export type OrderStorage = {
  selectedOrder: OrderOption | {}; // eslint-disable-line @typescript-eslint/no-empty-object-type
  setSelectedOrder: (order: OrderOption) => void; // eslint-disable-line @typescript-eslint/no-empty-object-type
  noOrderSelected: boolean;
};

const useStorageOrder = ({
  storagePrefix,
  defaultValue,
  options
}: {
  storagePrefix: string;
  defaultValue: OrderOption | {}; // eslint-disable-line @typescript-eslint/no-empty-object-type
  options?: OrderOption[];
}): OrderStorage => {
  const [selectedOrder, setSelectedOrder] = useStorage<OrderOption | { name?: string }>(
    `${storagePrefix}_selectedOrder`,
    defaultValue,
    STORAGE_OPTIONS
  );

  const noOrderSelected = isEqual(selectedOrder, {}) || isNil(selectedOrder);

  // TODO: [ENG-2596] Double check with API if defaulting order is not needed before making API call
  useEffect(() => {
    if (isEmpty(options)) {
      return;
    }
    const isSelectedOptionAvailable = options!.some(({ name }) =>
      isEqual(name, (selectedOrder?.name as string[]) || [])
    );
    if (!isSelectedOptionAvailable) {
      setSelectedOrder(defaultValue);
    }
  }, [options]);

  const handleOrderChange = useCallback(
    (order: OrderOption) => {
      setSelectedOrder(order || "");
    },
    [setSelectedOrder]
  );

  return {
    selectedOrder,
    setSelectedOrder: handleOrderChange,
    noOrderSelected
  };
};

export default useStorageOrder;
