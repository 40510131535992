import { RoleAvailability } from "PFComponents/availability";
import { RoleStatusBadge } from "PFComponents/role/role_status_badge";
import { RoleVacanciesBadge } from "PFComponents/role/role_vacancies_badge";
import { daysFromNowFormat } from "PFCore/helpers/date";

import CategoryBadge from "../../../booking/components/category_badge";

export const getRolesCellRenderers = () => ({
  engagement: ({ getValue }) => getValue()?.name,
  availability: ({ row }) => <RoleAvailability role={row.original} />,
  vacancies: ({ row }) => <RoleVacanciesBadge role={row.original} />,
  workflow_state: ({ getValue }) => <RoleStatusBadge workflowState={getValue()} />,
  due: ({ row }) => {
    const item = row.original;
    return item.state === "complete" ? "-" : daysFromNowFormat(item.post_until);
  },
  booking_category_id: ({ getValue }) => {
    const id = getValue();
    return id && <CategoryBadge categoryId={id} />;
  }
});
