import { Flex, Stack } from "PFComponents/containers";
import { ProfileAvatar } from "PFComponents/profile_avatar/profile_avatar";
import { SuspendedPill } from "PFComponents/suspended_pill";
import { getProfileName } from "PFCore/helpers/profile";
import { Profile } from "PFTypes";
import { Link } from "react-router-dom";

import css from "./profile_cell.module.scss";

type IProps = {
  qaId?: string;
  profile: Profile;
  onClick?: (IProfile) => void;
};

const ProfileCell = ({ profile, qaId, onClick }: IProps) => {
  const content = (
    <Stack gap="spacingSm">
      <Flex alignItems="center">
        <ProfileAvatar profile={profile} size={20} />
        <span className={css.username}>{getProfileName(profile)}</span>
      </Flex>
      {profile?.suspended_at && <SuspendedPill suspendedAt={profile.suspended_at} />}
    </Stack>
  );

  if (profile?.suspended_at) {
    return content;
  }

  if (onClick) {
    return (
      <button className={css.button} onClick={() => onClick(profile)}>
        {content}
      </button>
    );
  }

  return (
    <Link to={`/profiles/${profile.id}`} data-qa-id={qaId}>
      {content}
    </Link>
  );
};

export default ProfileCell;
