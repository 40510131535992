import activities from "../../../../config/i18n/frCA/activities.json";
import admin from "../../../../config/i18n/frCA/admin.json";
import auditPlanner from "../../../../config/i18n/frCA/auditPlanner.json";
import availability from "../../../../config/i18n/frCA/availability.json";
import bookingModule from "../../../../config/i18n/frCA/bookingModule.json";
import branding from "../../../../config/i18n/frCA/branding.json";
import changeRequests from "../../../../config/i18n/frCA/changeRequests.json";
import collaboratorsConfigs from "../../../../config/i18n/frCA/collaboratorsConfigs.json";
import core from "../../../../config/i18n/frCA/core.json";
import customValuesManagement from "../../../../config/i18n/frCA/customValuesManagement.json";
import feed from "../../../../config/i18n/frCA/feed.json";
import insights from "../../../../config/i18n/frCA/insights.json";
import interestQuestions from "../../../../config/i18n/frCA/interestQuestions.json";
import locales from "../../../../config/i18n/frCA/locales.json";
import marketplace from "../../../../config/i18n/frCA/marketplace.json";
import msTeams from "../../../../config/i18n/frCA/msTeams.json";
import notificationsSettings from "../../../../config/i18n/frCA/notificationsSettings.json";
import onboarding from "../../../../config/i18n/frCA/onboarding.json";
import profiles from "../../../../config/i18n/frCA/profiles.json";
import saasAdmin from "../../../../config/i18n/frCA/saasAdmin.json";
import samlConfigs from "../../../../config/i18n/frCA/samlConfigs.json";
import search from "../../../../config/i18n/frCA/search.json";
import skillsFrameworks from "../../../../config/i18n/frCA/skillsFrameworks.json";
import translation from "../../../../config/i18n/frCA/translation.json";
import workflow from "../../../../config/i18n/frCA/workflow.json";

export const frCA = {
  activities,
  admin,
  auditPlanner,
  availability,
  bookingModule,
  branding,
  changeRequests,
  collaboratorsConfigs,
  core,
  feed,
  insights,
  interestQuestions,
  locales,
  marketplace,
  msTeams,
  notificationsSettings,
  onboarding,
  profiles,
  saasAdmin,
  samlConfigs,
  search,
  skillsFrameworks,
  customValuesManagement,
  translation,
  workflow
};
