import { CustomValueOption, CustomValuesEditField } from "PFApp/components/custom_values_edit_field";
import { allowsNewValues } from "PFCore/helpers/custom_type";
import { CreateCustomValueData, CreateCustomValueResponse } from "PFCore/services/custom_values";
import { CustomType } from "PFTypes";

import { useCustomValuePreviewContext } from "../context/custom_value_preview_context";

type CustomValueSelectProps = {
  customType: CustomType;
  createCustomValue: (data: CreateCustomValueData) => Promise<CreateCustomValueResponse>;
};

export const CustomValueSelect = ({ customType, createCustomValue }: CustomValueSelectProps) => {
  const { customValuesIdsBlacklist, setSelectedCustomValueId } = useCustomValuePreviewContext();

  const handleChange = async (values) => {
    const selectedCustomValue = values[0];

    if (!selectedCustomValue) {
      return;
    }

    let selectedCustomValueId = selectedCustomValue.id;

    if (selectedCustomValue.created) {
      const { id } = await createCustomValue({
        payload: {
          customTypeId: customType.id,
          value: selectedCustomValue.id
        }
      });

      selectedCustomValueId = id;
    }

    setSelectedCustomValueId(selectedCustomValueId);
  };

  return (
    <CustomValuesEditField
      label={customType.display_as}
      letCreate={allowsNewValues(customType)}
      multi={false}
      hasValuesWithExpiryDate={false}
      forceUnlocked
      customType={customType}
      parseOptions={(options: CustomValueOption[]) =>
        options.filter(({ original: { id } }) => !(customValuesIdsBlacklist || []).includes(id))
      }
      handleChange={handleChange}
    />
  );
};
