import { Modal } from "PFComponents/modal";
import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useBlocker } from "react-router-dom";

import { Typography } from "../typography";

type RouterPromptProps = {
  when: boolean;
  onOK?: () => Promise<boolean> | boolean;
  onCancel?: () => Promise<boolean> | boolean;
  title?: string;
  labelOk?: string;
  labelCancel?: string;
  message?: string;
};

export const RouterPrompt = ({
  when,
  onOK = () => true,
  onCancel = () => false,
  title,
  labelOk,
  labelCancel,
  message
}: RouterPromptProps) => {
  const { t } = useTranslation(["translation", "core"]);

  const isMountedRef = useRef<boolean>(false);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const shouldBlock = useCallback(
    ({ currentLocation, nextLocation }) =>
      isMountedRef.current && when && currentLocation.pathname !== nextLocation.pathname,
    [when]
  );

  const blocker = useBlocker(shouldBlock);

  const handleOK = useCallback(async () => {
    const shouldNavigate = await onOK();
    if (shouldNavigate) {
      blocker.proceed?.();
    } else {
      blocker.reset?.();
    }
  }, [onOK, blocker]);

  const handleCancel = useCallback(async () => {
    const shouldNavigate = await onCancel();
    if (shouldNavigate) {
      blocker.proceed?.();
    } else {
      blocker.reset?.();
    }
  }, [onCancel, blocker]);

  return blocker.state === "blocked" ? (
    <Modal
      title={title ?? t("core:routerPrompt.title")}
      onOK={handleOK}
      onCancel={handleCancel}
      onClose={() => blocker.reset?.()}
      labelOK={labelOk ?? t("translation:confirm")}
      labelCancel={labelCancel}
    >
      <Typography withMargin variant="bodyRegular" tag="span">
        {message ?? t("core:routerPrompt.message")}
      </Typography>
    </Modal>
  ) : null;
};
