import { propertyToTitle } from "PFCore/helpers/templates";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { ParsedSchemaProperty, Subtemplate } from "PFTypes";
import { useMemo } from "react";

import { useActivityEditFormContext } from "../activity_edit_form_context";
import { useActivityEditPageContext } from "../activity_edit_page_context";
import { useChangeActivityField } from "../hooks/use_change_activity_field";
import AutoSelectWrapper from "./field_wrappers/auto_select_wrapper";
import { CheckboxesWrapper } from "./field_wrappers/checkboxes_wrapper";
import CustomValuesEditFieldWrapper from "./field_wrappers/custom_values_edit_field_wrapper";
import InlineCalendarWrapper from "./field_wrappers/inline_calendar_wrapper";
import InlineRangeCalendarWrapper from "./field_wrappers/inline_range_calendar_wrapper";
import InputFieldSetWrapper from "./field_wrappers/input_field_set_wrapper";
import { IntegerWrapper } from "./field_wrappers/integer_wrapper";
import ToggleWrapper from "./field_wrappers/toggle_wrapper";
import { useConnectedFields } from "./hooks/use_connected_fields";
import { useFieldError } from "./hooks/use_field_error";

const propertyTypeToComponent = {
  "string": InputFieldSetWrapper,
  "text": InputFieldSetWrapper,
  "url": InputFieldSetWrapper,
  "link": InputFieldSetWrapper,
  "date": InlineCalendarWrapper,
  "date-time": InlineCalendarWrapper,
  "date-range": InlineRangeCalendarWrapper,
  "select": AutoSelectWrapper,
  "integer": IntegerWrapper,
  "toggle": ToggleWrapper,
  "checkboxes": CheckboxesWrapper,
  "custom_field": CustomValuesEditFieldWrapper
};

type FieldFactoryProps = {
  property: ParsedSchemaProperty;
  subtemplate?: Subtemplate;
  qaIdPrefix: string;
  usedOn?: string;
};

export const FieldFactory = ({ subtemplate, property, qaIdPrefix, usedOn }: FieldFactoryProps) => {
  const Component = propertyTypeToComponent[property.type] || false;
  const isText = property.type === "text";
  const isCustomField = property.type === "custom_field";
  const handleChange = useChangeActivityField(property);
  const { customTypes } = useCustomTypes();
  const { data: currentProfile } = useCurrentProfile();
  const { template } = useActivityEditPageContext();
  const { name, description, metadata, customFields } = useActivityEditFormContext();
  const connectedFields = useConnectedFields(subtemplate, property);

  const additionalProps = useMemo(() => {
    if (!isCustomField) {
      return isText ? { inputType: "textarea", minHeight: 40, controlledValue: true } : undefined;
    }

    const customType = customTypes.find((type) => type.name === property.name);

    return customType ? { currentProfile, customType, qaIdPrefix, name, description, usedOn } : {};
  }, [
    isCustomField,
    customTypes,
    currentProfile,
    qaIdPrefix,
    name,
    description,
    usedOn,
    isText,
    property.name
  ]);

  const style = useMemo(() => ({ width: property?.width || "100%", display: "block" }), [property]);

  const value = useMemo(() => {
    if (isCustomField) {
      const customField = customFields.find((customField) => customField.type.name === property.name);
      return customField ? customField.values : [];
    }

    return metadata[property.name];
  }, [customFields, isCustomField, metadata, property.name]);

  const error = useFieldError(property);

  const label = propertyToTitle(template, property.name, customTypes) || "";

  const requiredInTemplate = template.schema.required.includes(property.name);
  const requiredInSubtemplate = (subtemplate?.schema.required || []).includes(property.name);
  const required = subtemplate ? requiredInSubtemplate : requiredInTemplate;

  const isDescriptiveLabel = label.length > 60;

  return (
    Component && (
      <div style={style}>
        {isDescriptiveLabel && <span>{label}</span>}
        <Component
          property={property}
          connectedFields={connectedFields}
          onChange={handleChange}
          maxLength={property.max_length}
          error={error}
          label={isDescriptiveLabel ? "" : label}
          value={value}
          qaId={`${qaIdPrefix}-${property.name}`}
          required={required}
          {...additionalProps}
        />
      </div>
    )
  );
};
