import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import { Icon } from "PFComponents/icon";
import { LockedIcon } from "PFComponents/text/input_field_set/components/locked_icon";
import {
  FocusEvent,
  forwardRef,
  KeyboardEvent,
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import { ActionIconData, ActionIcons } from "../action_icons";
import { Option, OptionOriginal, SelectV2Props } from "../select_v2.types";
import css from "./value_box.module.scss";
import { ValueContainer } from "./value_container";

const DEFAULT_DISPLAY_VALUES_HEIGHT = 22;
const DISPLAY_VALUES_PADDING_OFFSET = 8;
const DISPLAY_VALUES_BORDER_OFFSET = 2;

type ValueBoxProps<T> = Pick<
  SelectV2Props<T>,
  | "label"
  | "multiple"
  | "locked"
  | "disabled"
  | "renderDisplayValue"
  | "onChange"
  | "displayValueBelow"
  | "required"
  | "lockedTip"
> & {
  isOpen: boolean;
  onKeyDown: (event: KeyboardEvent<HTMLDivElement>) => void;
  onClick: VoidFunction;
  onBlur: (event: FocusEvent<HTMLDivElement>) => void;
  actionIcons?: ActionIconData[];
  dropdownId: string;
  highlightedId?: string;
  selectedOptions: Option<T>[];
  isFetching: boolean;
  isError?: boolean;
};

const ValueBoxComponent = <T extends OptionOriginal = OptionOriginal>(
  {
    selectedOptions,
    label,
    multiple,
    renderDisplayValue,
    isOpen,
    onKeyDown,
    onClick,
    onChange,
    onBlur,
    actionIcons,
    dropdownId,
    highlightedId,
    locked,
    lockedTip,
    disabled,
    displayValueBelow,
    isFetching,
    isError,
    required
  }: ValueBoxProps<T>,
  ref: MutableRefObject<HTMLDivElement | null>
) => {
  const [style, setStyle] = useState<React.CSSProperties>({});
  const displayValueRef = useRef<HTMLDivElement | null>(null);

  const selectedIdsString = selectedOptions.map(({ id }) => id).join(",");

  useEffect(() => {
    if (!displayValueRef.current) {
      setStyle({});
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      const customHeight = displayValueRef.current?.getBoundingClientRect().height || 0;

      setStyle({
        height: Math.max(
          customHeight + DISPLAY_VALUES_PADDING_OFFSET + DISPLAY_VALUES_BORDER_OFFSET,
          DEFAULT_DISPLAY_VALUES_HEIGHT + DISPLAY_VALUES_PADDING_OFFSET + DISPLAY_VALUES_BORDER_OFFSET
        )
      });
    });
    resizeObserver.observe(displayValueRef.current);

    return () => resizeObserver.disconnect();
  }, []);

  const ariaLabel = useMemo(() => {
    if (selectedOptions.length > 0) {
      return `${label}, ${
        multiple ? selectedOptions.map(({ value }) => value).join(", ") : selectedOptions[0].value
      }`;
    } else {
      return label;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiple, label, selectedIdsString]);

  return (
    <div
      ref={ref}
      role="combobox"
      aria-controls={dropdownId}
      aria-haspopup="listbox"
      aria-expanded={isOpen}
      aria-label={ariaLabel}
      aria-required={required}
      aria-activedescendant={highlightedId}
      tabIndex={0}
      className={classNames(css.root, { [css.disabled]: locked || disabled, [css.error]: isError })}
      onClick={onClick}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      style={style}
    >
      {!displayValueBelow ? (
        <ValueContainer
          isFetching={isFetching}
          onChange={onChange}
          renderDisplayValue={renderDisplayValue}
          multiple={multiple}
          displayValueRef={displayValueRef}
          selectedOptions={selectedOptions}
          className={css.valueContainer}
          locked={locked}
          disabled={disabled}
        />
      ) : (
        <div />
      )}
      {locked && <LockedIcon className={css.lockedIcon} lockedTip={lockedTip} />}
      {!isEmpty(actionIcons) && <ActionIcons actionIcons={actionIcons!} />}
      {!locked && <Icon name="chevron-down" size="sm" className={css.chevronIcon} />}
    </div>
  );
};

export const ValueBox = forwardRef(ValueBoxComponent) as <T>(
  props: ValueBoxProps<T> & { ref?: MutableRefObject<HTMLDivElement | null> }
) => ReturnType<typeof ValueBoxComponent>;
