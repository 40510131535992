/*
 * We need to create an structure that mirrors the MetaFilters but contains just values
 *
 * metaFilters: MetaFilters TO valueFilters: ValueFilters
 *
 * This structure needs to preserve all keys even if the values are empty
 * Later when a filter changes value or gets a new one, we can use this structure to inject it
 *
 * returns ValueFilters
 * */
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import set from "lodash/set";
import { Filter, FilterByValueType, FilterFamily, Filters, Value } from "PFTypes";

type FilterCondition = (key: string, filter: Filter | undefined) => boolean | any;

export const getValueFilters = (
  filters: Filters | undefined,
  condition: FilterCondition = () => true
): Filters<Value> => {
  if (isEmpty(filters)) {
    return filters || {};
  }

  const filterValues = {} as Filters<Value>;

  Object.keys(filters).forEach((key: FilterFamily | "children") => {
    if (key === "availability") {
      if (condition(key, filters[key])) {
        filterValues[key] = cloneDeep(filters[key]?.value) || null;
      }
      return;
    }

    if (key === "children") {
      filterValues.children = getValueFilters(filters.children);
      return;
    }

    const filtersGroup = filters[key as FilterByValueType] || {};
    const filtersGroupAcceptedKeys = Object.keys(filtersGroup).filter((filterName) =>
      condition(filterName, filtersGroup[filterName])
    );

    filterValues[key] = {};

    if (filtersGroupAcceptedKeys.length === 0) {
      return;
    }

    filtersGroupAcceptedKeys.forEach((filterName) => {
      filterValues[key]![filterName] = cloneDeep(filtersGroup[filterName]?.value) || null;
    });
  });

  return filterValues;
};

export const getFilterValuesSubset = (
  filters: Filters | undefined,
  includeList: string[],
  additionalCondition?: FilterCondition
) => {
  const isIncluded = (filterKey: keyof Filters | string) => !includeList || includeList.includes(filterKey);
  return getValueFilters(
    filters,
    (key, filter) => isIncluded(key) && (!additionalCondition || additionalCondition(key, filter))
  );
};

type FilterName = string | "availability";

const BASE_FILTER_FAMILIES = ["fields", "numbers", "strings", "locations"];

export const filterWithValueExists = (filters: Filters<Value>, filterName: FilterName) => {
  if (filterName === "availability") {
    return !!filters.availability;
  }
  return Object.entries(filters)
    .map(([filterFamily, value]) => {
      if (BASE_FILTER_FAMILIES.includes(filterFamily)) {
        if (value && value[filterName]) {
          return true;
        }
        return false;
      }

      if (filterFamily === "children") {
        return Object.entries(filters).map(([key, value]) => {
          if (BASE_FILTER_FAMILIES.includes(key)) {
            if (value && value[filterName]) {
              return true;
            }
            return false;
          }
        });
      }
      return false;
    })
    .reduce((acc, curr) => acc || curr, false);
};

const getFilterWithMergedValue = (filter: Filter | undefined, value: Value | undefined) =>
  filter !== undefined
    ? ({
        ...filter,
        value: value !== undefined ? value : filter.value
      } as Filter)
    : undefined;

export const mergeFiltersWithValues = (
  filters: Filters,
  values: Filters<Value>,
  omitWithoutValues = false
) => {
  const filtersWithValues = {} as Filters;

  Object.keys(filters).forEach((filtersFamilyKey) => {
    if (filtersFamilyKey === "availability") {
      if (omitWithoutValues && !values[filtersFamilyKey]) {
        return;
      }

      set(
        filtersWithValues,
        [filtersFamilyKey],
        getFilterWithMergedValue(filters[filtersFamilyKey], values[filtersFamilyKey])
      );
      return;
    }

    if (filtersFamilyKey === "children" && filters[filtersFamilyKey]) {
      if (omitWithoutValues && !values[filtersFamilyKey]) {
        return;
      }

      set(
        filtersWithValues,
        [filtersFamilyKey],
        mergeFiltersWithValues(filters[filtersFamilyKey], values[filtersFamilyKey] || {})
      );
      return;
    }

    const filtersFamily = filters[filtersFamilyKey];
    Object.keys(filtersFamily).forEach((filterKey) => {
      if (omitWithoutValues && !values[filtersFamilyKey]?.[filterKey]) {
        return;
      }

      set(
        filtersWithValues,
        [filtersFamilyKey, filterKey],
        getFilterWithMergedValue(filters[filtersFamilyKey][filterKey], values[filtersFamilyKey]?.[filterKey])
      );
    });
  });

  return filtersWithValues;
};
