import api from "PFCore/api";
import { ActivityEvent, Id, PaginatedCollection, QueryParams } from "PFTypes";

export type FetchActivityHistoryParams = QueryParams;

export type ActivityHistoryProfile = {
  id: number;
  firstName?: string;
  lastName?: string;
  status: "active" | "inactive";
  suspendedAt?: string | null;
};
export type FetchActivityHistoryResponse = PaginatedCollection<
  ActivityEvent[],
  { profiles: Record<number, ActivityHistoryProfile> }
>;

export const fetchActivityHistory = (activityId: Id, params: FetchActivityHistoryParams) =>
  api<FetchActivityHistoryResponse>({
    url: `activities/${activityId}/history`,
    params
  });
