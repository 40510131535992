import initalSearch from "./search";

const initialState = () => ({
  profilePreview: { show: false, isClosing: false, ready: false },
  activityPreview: {},
  customValuePreview: { show: false },
  appLayout: {
    navBarRevealed: false, // only affects mobile!
    leftActionPanel: { content: null, title: "...", collapsed: true },
    rightActionPanel: { content: null, title: "...", collapsed: true },
    mainRight: 0,
    mainLeft: 0,
    mainWidth: 320,
    backdropOnClicks: []
  },
  growls: [],
  chats: {
    ids: [],
    minimizedIds: ["list"]
  },
  pages: {},
  modalSearch: null,
  search: initalSearch({}),
  themeVars: {}
});

export default initialState;
