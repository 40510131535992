import { Segment } from "PFTypes";

export enum SegmentActionType {
  SegmentSet = "SEGMENT_SET"
}

export interface SegmentAction {
  type: SegmentActionType;
  payload: Segment;
}

export const segmentReducer = (state: any, { type, payload }: SegmentAction) => {
  switch (type) {
    case SegmentActionType.SegmentSet:
      return {
        ...state,
        segment: payload
      };
  }
};
