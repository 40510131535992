import { Stack } from "PFComponents/containers";
import { Icon } from "PFComponents/icon";
import { Typography } from "PFComponents/typography";
import { useTranslation } from "react-i18next";

export const NoItemsToShow = () => {
  const { t } = useTranslation("profiles", { keyPrefix: "show.parts" });

  return (
    <Stack gap="spacingSm" alignItems="center">
      <Icon name="missing" size="md" />
      <Typography withMargin variant="labelRegular">
        {t("noItemsToShow")}
      </Typography>
    </Stack>
  );
};
