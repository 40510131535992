import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { BookingPill } from "PFApp/components/pills/booking_pill";
import { Inline } from "PFComponents/containers";
import { Typography } from "PFComponents/typography";
import useBookingCategories from "PFCore/hooks/use_booking_categories";

import css from "./booking_category_information.module.scss";

export const BookingCategoryInformation = () => {
  const { task: activity } = useActivityPageContext();
  const { getBookingCategory } = useBookingCategories();

  if (!activity.booking_category_id) {
    return null;
  }

  const category = getBookingCategory(activity.booking_category_id);

  return (
    <Inline alignItems="center" justifyContent="space-between" gap="spacingMd">
      <Typography variant="labelRegular">{category?.display_as}</Typography>
      <BookingPill className={css.pill} category={category} iconSize="xs" center />
    </Inline>
  );
};
