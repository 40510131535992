import { useCategorizedSkills } from "PFCore/helpers/use_categorized_skills";
import { Id, Profile, ProfileCustomValue } from "PFTypes";
import { useTranslation } from "react-i18next";

import { GroupedSkillsList } from "./parts/grouped_skills_list";

type ProficiencyGroupedSkillsListProps = {
  skills: ProfileCustomValue[];
  profile: Profile;
  selectedSkillsIds: Id[];
  onSkillSelectionChange: (skillId: Id) => void;
};

export const CategoryGroupedSkillsList = ({
  skills,
  profile,
  selectedSkillsIds,
  onSkillSelectionChange
}: ProficiencyGroupedSkillsListProps) => {
  const { t } = useTranslation("profiles");
  const [skillCategories, otherSkills] = useCategorizedSkills(skills, profile.skill_categories);

  const skillCategoryNames = Object.keys(skillCategories);

  const groups = [
    ...skillCategoryNames.map((categoryName) => {
      const categorySkills = skillCategories[categoryName] || [];
      return {
        id: categoryName,
        title: `${categoryName} (${categorySkills.length})`,
        skills: categorySkills
      };
    }),
    {
      id: "Other",
      title: t("show.parts.other"),
      skills: otherSkills || []
    }
  ];

  return (
    <GroupedSkillsList
      groups={groups}
      profileId={profile.id}
      selectedSkillsIds={selectedSkillsIds}
      onSkillSelectionChange={onSkillSelectionChange}
    />
  );
};
