import pick from "lodash/pick";
import { useBookingFormContext } from "PFApp/booking/components/booking_form";
import { StoredDetailsPanelData } from "PFApp/booking/components/details_panel";
import { useDeterministicStringify } from "PFCore/helpers/use_deterministic_stringify";
import { useBooking } from "PFCore/hooks/queries";
import { useBookingTemplate } from "PFCore/hooks/queries/bookings/booking_templates/use_booking_template";
import { useProfile } from "PFCore/hooks/queries/profile/use_profile";
import { Booking, BookingTemplate, Profile } from "PFTypes";
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useMemo
} from "react";

import { useBookingSiblings } from "../booking_detail_content/booking_siblings/use_booking_siblings";

export type BookingDetailSidePanelProfile = Pick<Profile, "id" | "status"> & {
  firstName?: string;
  lastName?: string;
  suspendedAt?: string | null;
};
type BookingDetailSidePanelContextType = {
  initialBooking: Booking | undefined;
  profile: BookingDetailSidePanelProfile | undefined;
  siblingBookings: Booking[] | undefined;
  bookingTemplate: BookingTemplate | undefined;
  isInitialBookingLoading: boolean;
  isSiblingBookingsFetching: boolean;
  isBookingTemplateFetching: boolean;
  currentSiblingBookingIndex: number | undefined;
  setCurrentSiblingBookingIndex: Dispatch<SetStateAction<number>>;
  invalidateBookingSiblings: VoidFunction;
};

const BookingDetailSidePanelContext = createContext({} as BookingDetailSidePanelContextType);

type BookingDetailSidePanelContextProviderProps = {
  data: StoredDetailsPanelData;
  enabled: boolean;
};

export const BookingDetailSidePanelContextProvider = ({
  children,
  data,
  enabled
}: PropsWithChildren<BookingDetailSidePanelContextProviderProps>) => {
  const stringify = useDeterministicStringify();
  const { activeBookingIndex, setActiveBookingsIndex } = useBookingFormContext();

  const { id: bookingId, suspendedProfileDetails } = data;
  const { data: initialBooking, isLoading: isInitialBookingLoading } = useBooking(bookingId, {
    enabled: enabled && !!bookingId
  });

  const { data: profileData } = useProfile(initialBooking?.profile_id, {
    enabled: enabled && !!initialBooking?.profile_id && !suspendedProfileDetails
  });
  const profile: BookingDetailSidePanelProfile | undefined = profileData
    ? {
        ...pick(profileData, ["id", "status"]),
        firstName: profileData.first_name,
        lastName: profileData.last_name ?? undefined,
        suspendedAt: profileData.suspended_at
      }
    : suspendedProfileDetails;

  const isRepeatedBooking = !!initialBooking?.booking_template_id;
  const {
    data: siblingBookings,
    isFetching: isSiblingBookingsFetching,
    invalidate: invalidateBookingSiblings
  } = useBookingSiblings({
    bookingId: initialBooking?.id,
    profileId: profile?.id,
    activityId: initialBooking?.activity?.id,
    bookingTemplateId: initialBooking?.booking_template_id,
    enabled: !!initialBooking && !!profile
  });
  const { data: bookingTemplate, isFetching: isBookingTemplateFetching } = useBookingTemplate(
    initialBooking?.booking_template_id,
    {
      enabled: isRepeatedBooking
    }
  );

  const siblingBookingsIds = siblingBookings?.map(({ id }) => id) ?? [];

  useEffect(() => {
    const index = (siblingBookings ?? []).findIndex(({ id }) => id === bookingId);
    setActiveBookingsIndex(index < 0 ? undefined : index);
  }, [bookingId, stringify(siblingBookingsIds)]);

  const value = useMemo(
    () => ({
      initialBooking,
      profile,
      siblingBookings,
      bookingTemplate,
      isInitialBookingLoading,
      isSiblingBookingsFetching,
      isBookingTemplateFetching,
      currentSiblingBookingIndex: activeBookingIndex,
      setCurrentSiblingBookingIndex: setActiveBookingsIndex,
      invalidateBookingSiblings
    }),
    [
      initialBooking,
      profile,
      siblingBookings,
      bookingTemplate,
      isInitialBookingLoading,
      isSiblingBookingsFetching,
      isBookingTemplateFetching,
      activeBookingIndex,
      invalidateBookingSiblings
    ]
  );

  return (
    <BookingDetailSidePanelContext.Provider value={value}>{children}</BookingDetailSidePanelContext.Provider>
  );
};

export const useBookingDetailSidePanelContext = () => {
  const context = useContext(BookingDetailSidePanelContext);
  if (!context) {
    throw new Error(
      "useBookingDetailSidePanelContext must be used within a BookingDetailSidePanelContextProvider"
    );
  }
  return context;
};
