import activities from "../../../../config/i18n/enGB/activities.json";
import admin from "../../../../config/i18n/enGB/admin.json";
import auditPlanner from "../../../../config/i18n/enGB/auditPlanner.json";
import availability from "../../../../config/i18n/enGB/availability.json";
import bookingModule from "../../../../config/i18n/enGB/bookingModule.json";
import branding from "../../../../config/i18n/enGB/branding.json";
import changeRequests from "../../../../config/i18n/enGB/changeRequests.json";
import collaboratorsConfigs from "../../../../config/i18n/enGB/collaboratorsConfigs.json";
import core from "../../../../config/i18n/enGB/core.json";
import customValuesManagement from "../../../../config/i18n/enGB/customValuesManagement.json";
import feed from "../../../../config/i18n/enGB/feed.json";
import insights from "../../../../config/i18n/enGB/insights.json";
import interestQuestions from "../../../../config/i18n/enGB/interestQuestions.json";
import locales from "../../../../config/i18n/enGB/locales.json";
import marketplace from "../../../../config/i18n/enGB/marketplace.json";
import msTeams from "../../../../config/i18n/enGB/msTeams.json";
import notificationsSettings from "../../../../config/i18n/enGB/notificationsSettings.json";
import onboarding from "../../../../config/i18n/enGB/onboarding.json";
import profiles from "../../../../config/i18n/enGB/profiles.json";
import saasAdmin from "../../../../config/i18n/enGB/saasAdmin.json";
import samlConfigs from "../../../../config/i18n/enGB/samlConfigs.json";
import search from "../../../../config/i18n/enGB/search.json";
import skillsFrameworks from "../../../../config/i18n/enGB/skillsFrameworks.json";
import translation from "../../../../config/i18n/enGB/translation.json";
import workflow from "../../../../config/i18n/enGB/workflow.json";

export const enGB = {
  activities,
  admin,
  auditPlanner,
  availability,
  bookingModule,
  branding,
  changeRequests,
  collaboratorsConfigs,
  core,
  feed,
  insights,
  interestQuestions,
  locales,
  marketplace,
  msTeams,
  notificationsSettings,
  onboarding,
  profiles,
  saasAdmin,
  samlConfigs,
  search,
  skillsFrameworks,
  customValuesManagement,
  translation,
  workflow
};
