import { ReassignBookingModal } from "PFApp/booking/components/reassign_booking_modal/reassign_booking_modal";
import { ObjectWithProfileName } from "PFCore/helpers/profile";
import { useProfile } from "PFCore/hooks/queries/profile/use_profile";
import { Activity, Availability, Booking } from "PFTypes";
import { createContext, PropsWithChildren, useContext, useMemo, useReducer } from "react";

import { BookingDetailSidePanelProfile } from "../../detail_view/booking_detail/booking_detail_side_panel_context/booking_detail_side_panel_context_provider";

type ReassignActionState = {
  isModalOpened: boolean;
  data: ReassignActionData | null;
};

const INITIAL_STATE: ReassignActionState = {
  isModalOpened: false,
  data: null
};

export type ProfileFrom = { id: number } & Partial<ObjectWithProfileName> & {
    email?: string;
    suspendedAt?: string | null;
  };

export type ReassignActionData = {
  activityName: string | undefined;
  activity?: Activity;
  sourceProfileId: number;
  targetProfileId?: number;
  booking: Booking;
  isEngagement?: boolean;
  availability?: Availability;
  suspendedProfileDetails?: BookingDetailSidePanelProfile;
  onClose?: () => void;
  onSubmit?: () => void;
  onError?: () => void;
};

type ReassignActionApi = {
  openModal: (data: ReassignActionData) => void;
  closeModal: () => void;
};

const INITIAL_API: ReassignActionApi = {
  openModal: () => {},
  closeModal: () => {}
};

const ReassignActionApiContext = createContext<ReassignActionApi>(INITIAL_API);
const ReassignActionStateContext = createContext<ReassignActionState>(INITIAL_STATE);

type Action = { type: "openReassignModal"; data: ReassignActionData } | { type: "closeReassignModal" };

const reassignActionReducer = (state: ReassignActionState, action: Action): ReassignActionState => {
  switch (action.type) {
    case "openReassignModal":
      return { ...state, isModalOpened: true, data: action.data };
    case "closeReassignModal":
      return { ...state, isModalOpened: false, data: null };
    default:
      return state;
  }
};

export const ReassignActionContextProvider = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer(reassignActionReducer, INITIAL_STATE);

  const { data: targetProfile, isLoading: targetProfileIsLoading } = useProfile(state.data?.targetProfileId, {
    enabled: !!state.data?.targetProfileId
  });

  const suspendedProfileDetails = state.data?.suspendedProfileDetails;
  const { data: sourceProfileData, isLoading: sourceProfileIsLoading } = useProfile(
    state.data?.sourceProfileId,
    {
      enabled: !!state.data?.sourceProfileId && !suspendedProfileDetails
    }
  );
  const sourceProfile = sourceProfileData
    ? {
        id: sourceProfileData.id,
        firstName: sourceProfileData.first_name,
        lastName: sourceProfileData.last_name,
        email: sourceProfileData.email,
        suspendedAt: sourceProfileData.suspended_at
      }
    : suspendedProfileDetails;

  const apiValue = useMemo<ReassignActionApi>(() => {
    const openModal = (data: ReassignActionData) => {
      dispatch({ type: "openReassignModal", data });
    };

    const closeModal = () => {
      dispatch({ type: "closeReassignModal" });
    };

    return { openModal, closeModal };
  }, []);

  const stateValue = useMemo(() => state, [state]);

  return (
    <ReassignActionApiContext.Provider value={apiValue}>
      <ReassignActionStateContext.Provider value={stateValue}>
        {children}
        <ReassignBookingModal
          profileFrom={sourceProfile}
          profileTo={{
            profile: targetProfile
          }}
          isLoading={targetProfileIsLoading || sourceProfileIsLoading}
          readOnlyMode={true}
          shouldDisplayReassignOptions
        />
      </ReassignActionStateContext.Provider>
    </ReassignActionApiContext.Provider>
  );
};

export const useReassignActionApi = () => useContext(ReassignActionApiContext);
export const useReassignActionState = () => useContext(ReassignActionStateContext);
