import { Accordion } from "PFComponents/accordion/accordion";
import { Typography } from "PFComponents/typography";
import { BookingHistoryProfile } from "PFCore/hooks/queries/bookings/history/use_booking_history";

import { Attribute, AttributeData } from "./attribute_items/attribute";
import css from "./booking_history_item.module.scss";
import { useBookingHistory } from "./use_booking_history";

type AttributesSectionProps = {
  attributes: AttributeData[];
  metaProfiles?: Record<number, BookingHistoryProfile>;
};

export const AttributesSection = ({ attributes, metaProfiles }: AttributesSectionProps) => {
  const { getUserFriendlyAttributeName } = useBookingHistory();
  const hasManyAttributes = attributes.length > 1;
  return (
    <div className={css.attributesContainer}>
      {hasManyAttributes ? (
        <Accordion
          header={
            <Typography withMargin variant="bodyBold" tag="span">
              {`${attributes.length} fields`}
            </Typography>
          }
        >
          <div className={css.attributes}>
            {attributes.map((attribute) => (
              <Attribute
                key={attribute.attributeId}
                title={getUserFriendlyAttributeName(attribute.attributeId)}
                attribute={attribute}
                metaProfiles={metaProfiles}
              />
            ))}
          </div>
        </Accordion>
      ) : (
        <Attribute attribute={attributes[0]} metaProfiles={metaProfiles} />
      )}
    </div>
  );
};
