import classNames from "classnames";
import head from "lodash/head";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import last from "lodash/last";
import { Overbooking } from "PFApp/booking/types";
import colors from "PFTheme/tokens/colors";
import { CalendarRange, Id } from "PFTypes";
import { useTranslation } from "react-i18next";

import { ProfileMisalignments } from "../../parts/hooks/use_get_misalignment_bars/get_misalignment_items_by_row";
import {
  OverbookingPotentialBooking,
  OverbookingsFilterConfig,
  useDetailsPanelApiContext
} from "../details_panel";
import { sortOverbookings } from "../details_panel/detail_view/overbookings_detail_side_panel/use_overbooking_ranges";
import { useOverbookingColor } from "./use_overbooking_color";
import css from "./warning_icons.module.scss";
import WarningTooltipIcon from "./warning_tooltip_icon";

const getCalendarRange = (overbookings?: Overbooking[]): CalendarRange | undefined =>
  !isEmpty(overbookings) ? { min: head(overbookings)!.date, max: last(overbookings)!.date } : undefined;

interface WarningIconsProps {
  overbookings?: Overbooking[];
  overbookingsFilterConfig?: OverbookingsFilterConfig;
  misalignments?: ProfileMisalignments[];
  roleName?: string;
  roleId?: Id;
  profileId: Id | undefined;
  classes?: { icon?: string };
  shouldCloseOtherSidePanelsOnClick?: boolean;
  enableMisalignmentsGoToDate?: boolean;
  potentialBookings?: OverbookingPotentialBooking[];
}

const WarningIcons = ({
  overbookings,
  overbookingsFilterConfig,
  misalignments = [],
  roleName,
  roleId,
  profileId,
  shouldCloseOtherSidePanelsOnClick,
  enableMisalignmentsGoToDate,
  potentialBookings
}: WarningIconsProps) => {
  const { t } = useTranslation("bookingModule");

  const currentOverbookings =
    !overbookingsFilterConfig || overbookingsFilterConfig?.showAllOverbookingsEnabled
      ? overbookings
      : overbookingsFilterConfig?.filteredOverbookings;

  const overbookedCounter = (currentOverbookings ?? []).length;

  const flattenMisalignments = misalignments.flatMap(({ misalignments }) => misalignments);
  const misalignmentsCounter = profileId
    ? misalignments.find((misalignment) => isEqual(String(misalignment.profileId), String(profileId)))
        ?.misalignments.length ?? 0
    : flattenMisalignments.length;

  const { overbookingColor } = useOverbookingColor({ overbookings: currentOverbookings || [] });
  const { openDetailsPanel, closeAllDetailsPanels } = useDetailsPanelApiContext();
  const sortedOverbookings = sortOverbookings(overbookings);
  const overbookingsCalendarRange = getCalendarRange(sortedOverbookings);

  return (
    <div className={css.iconsWrapper}>
      {!!overbookedCounter && (
        <WarningTooltipIcon
          title={t("rowsSidePanel.warningIcons.seeOverbookings")}
          icon="warning"
          label={t("workforce.overbookedWarning")}
          iconColor={overbookingColor?.hex()}
          counterColor={overbookingColor?.hex()}
          counter={overbookedCounter}
          classes={{
            counter: classNames(css.overbookedCounter, {
              [css.lightText]: !!overbookingColor?.isDark()
            })
          }}
          onClick={
            profileId
              ? () => {
                  shouldCloseOtherSidePanelsOnClick && closeAllDetailsPanels();
                  openDetailsPanel(
                    {
                      type: "overbookings",
                      id: Number(profileId),
                      overbookingsDetails: {
                        overbookings,
                        filterConfig: overbookingsFilterConfig,
                        calendarRange: overbookingsCalendarRange!,
                        potentialBookings
                      }
                    },
                    true
                  );
                }
              : undefined
          }
        />
      )}
      {!!misalignmentsCounter && (
        <WarningTooltipIcon
          title={t("rowsSidePanel.warningIcons.seeMisalignments")}
          icon="calendar-delete"
          label={t("projects.misalignment")}
          counter={misalignmentsCounter}
          iconColor={colors.paletteOrange0}
          classes={{
            counter: css.misalignmentCounter
          }}
          onClick={() => {
            shouldCloseOtherSidePanelsOnClick && closeAllDetailsPanels();
            openDetailsPanel(
              {
                type: "misalignments",
                id: Number(roleId),
                misalignmentDetails: {
                  profileId,
                  misalignments,
                  roleName,
                  enableGoToDate: !!enableMisalignmentsGoToDate
                }
              },
              true
            );
          }}
        />
      )}
    </div>
  );
};

export default WarningIcons;
