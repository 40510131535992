import uniqBy from "lodash/uniqBy";
import { fetchPaginatedCollection } from "PFCore/helpers/collection";
import { useDeterministicStringify } from "PFCore/helpers/use_deterministic_stringify";
import { ApiRoute } from "PFCore/utilities/routes";
import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import { checkFiltersAreClear } from "./format_filters";
import { getAPIFilters, getValueFilters } from "./helpers";
import useStorageFilters from "./use_storage_filters";
import useStorageOrder from "./use_storage_order";

const defaultOptions = {
  paramsUrl: "",
  requestParams: {},
  method: "GET",
  allowFetch: () => true
};

/**
 * Hook that helps to manage filtered collection of given api
 * @param {string} key - unique key for storage
 * @param {string} url - API URL for the collection
 * @param {Object} options - Additional options,
 *                           ex:{ paramsUrl: "", requestParams: {}, segment: {}, method: "GET", version: 2 }
 * @returns {UseFilteredCollectionReturn} - data, metadata and functions to manage collection
 */
export const useFilteredCollection = (key, url, options = {}) => {
  const {
    paramsUrl,
    requestParams,
    method,
    allowFetch,
    segment,
    stringify: shouldStrinfigy,
    contentType,
    excludeList
  } = {
    ...defaultOptions,
    ...options
  };
  const filters = requestParams.filters || {};
  const order = requestParams.order || {};
  const version = options.version || 1;
  const storagePrefix = `${key}-v${version}`;
  const stringify = useDeterministicStringify();
  const location = useLocation();

  const filtersFromParams = useMemo(() => {
    if (!paramsUrl || !location.pathname.includes(paramsUrl)) {
      return {};
    }
    const toReturn = {};
    const searchParams = new URLSearchParams(location.search);
    [...searchParams.entries()].forEach((entry) => (toReturn[`_${entry[0]}`] = entry[1]));

    return toReturn;
  }, [location.search]);

  const initData = {
    data: {
      entries: [],
      meta: { total: 0, filters: {}, counters: {}, page: 1 },
      loading: false
    },
    loaded: false
  };

  // for infinite scrolling we need a synchronous loading variable not to load same page multiple times
  const loadingRef = useRef(false);

  const [collection, setCollection] = useState(initData);

  const { selectedFilters, setFilters, updateFilter, resetFilters, clearFilters } = useStorageFilters({
    storagePrefix,
    defaultValue: { ...filtersFromParams, ...filters }
  });
  const { selectedOrder, setSelectedOrder, noOrderSelected } = useStorageOrder({
    storagePrefix,
    defaultValue: order
  });

  const [noFiltersSelected, setNoFilersSelected] = useState(true);

  const disabled = !allowFetch(selectedFilters, selectedOrder);

  const mergeCollection = (data) => setCollection((prev) => ({ ...prev, ...data }));

  const loadMore = (params = {}) => {
    const { page, perPage, total } = collection.data.meta;

    if (page * perPage < total && !disabled) {
      return fetchData({ ...params, page: collection.data.meta.page + 1, append: true });
    }
  };

  const fetch = (params = {}) => {
    if (loadingRef.current) {
      return;
    } else {
      loadingRef.current = true;
    }

    const { append } = params;
    delete params.append;
    const omittedParams = {
      ...requestParams,
      ...params,
      filters: params.filters
    };
    if (collection.data) {
      mergeCollection({ data: { ...collection.data, loading: true } });
    }

    return fetchPaginatedCollection(ApiRoute(url), omittedParams, null, {
      method,
      contentType,
      stringify: shouldStrinfigy
    }).then(
      (resp) => {
        const { data } = collection;
        if (append) {
          // in case of database changes we can fetch the same item
          data.entries = uniqBy(data.entries.concat(resp.entries), "id");
          data.meta = resp.meta;
          mergeCollection({ data: { ...data, loading: false } });
        } else {
          mergeCollection({ data: { ...data, ...resp, loading: false } });
        }
        // Save valueFilters
        setFilters(getValueFilters(resp.meta.filters));
        setNoFilersSelected(checkFiltersAreClear(resp.meta.filters, excludeList));
        loadingRef.current = false;
      },
      () => {
        mergeCollection({ errors: [`Could not load data`], data: { ...collection.data, loading: true } });
        loadingRef.current = false;
      }
    );
  };

  const fetchData = (params = {}) =>
    fetch({
      ...params,
      filters: getAPIFilters({ ...params.filters, ...selectedFilters }, segment),
      order: { ...params.order, ...selectedOrder }
    })?.then(() => mergeCollection({ loaded: true }));

  useEffect(() => {
    if (!disabled) {
      fetchData({ page: 1 });
    }
  }, [stringify(getAPIFilters(selectedFilters)), selectedOrder, disabled, segment]);

  return {
    ...collection,
    fetchData,
    onOrderChange: setSelectedOrder,
    selectedFilters,
    updateFilter,
    setFilters,
    resetFilters,
    clearFilters,
    noFiltersSelected,
    noOrderSelected,
    loadMore
  };
};
