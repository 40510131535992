import classNames from "classnames";
import capitalize from "lodash/capitalize";
import { useTemplate } from "PFApp/hooks";
import ButtonLink from "PFComponents/button/button_link";
import { Icon } from "PFComponents/icon";
import { Activity } from "PFTypes";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import css from "./actions.module.scss";
import { AssignmentActions } from "./assignment_actions";

export const Actions = ({ task, className }: { task: Activity; className?: string }) => {
  const { t } = useTranslation("activities");
  const location = useLocation();

  const originData = window.location.href.match(/origin_id=([0-9]+)&origin_type=(\w+)/);
  const template = useTemplate(task.template_id);

  return (
    <div className={classNames(css.actionsContainer, className)}>
      {originData && !task.coowned && (
        <ButtonLink
          kind="secondary"
          href={`/activities/${originData[1]}`}
          rootClassName={css.goBackButton}
          state={{ utmSource: location?.state?.utmSource }}
        >
          <>
            <Icon name="caret-left" size="sm" />
            {t("show.parts.backTo", { location: capitalize(originData[2]) })}
          </>
        </ButtonLink>
      )}
      {task.shortlist_assignment && template?.shortlist_invites && (
        <AssignmentActions shortlistItem={task.shortlist_assignment} template={template} activity={task} />
      )}
    </div>
  );
};
