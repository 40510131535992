import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { Activity } from "PFTypes";

type CurrentUserRoleInActivity = {
  isResourcer: boolean;
  isReviewer: boolean;
  isReviewerUnbiased: boolean;
  isOwner: boolean;
  isAssignee: boolean;
};

type CurrentUserRoleInActivityChecker = {
  checkCurrentUserRoleInActivity: (activity?: Activity) => CurrentUserRoleInActivity;
};

type UseActivityCurrentUserRoleReturn = CurrentUserRoleInActivity & CurrentUserRoleInActivityChecker;

export const useActivityCurrentUserRole = (activityToCheck?: Activity): UseActivityCurrentUserRoleReturn => {
  const { data: currentProfile } = useCurrentProfile();

  const checkCurrentUserRoleInActivity = (activity?: Activity) => {
    if (!activity) {
      return {
        isResourcer: false,
        isReviewer: false,
        isReviewerUnbiased: false,
        isOwner: false,
        isAssignee: false
      };
    }

    const ownGroup = currentProfile.permissions_group;

    const isResourcer = !!ownGroup?.permissions.find(
      ({ name, permitted }) => name === "resourcer" && permitted
    );

    const userAsActivityReviewer = activity.reviewers?.find(
      ({ profile }) => profile.id === currentProfile.id
    );

    const isReviewer = !!activity.reviewed_by_requester || !!userAsActivityReviewer;

    const isReviewerUnbiased = !!userAsActivityReviewer?.unbiased;

    const isOwner = !!activity.owners?.find((owner) => owner.profile?.id === currentProfile.id);

    const isAssignee = activity.assignee?.profile.id === currentProfile.id;

    return { isResourcer, isReviewer, isReviewerUnbiased, isOwner, isAssignee };
  };

  return { ...checkCurrentUserRoleInActivity(activityToCheck), checkCurrentUserRoleInActivity };
};
