import SimpleBadge from "PFCore/components/badges_list/simple_badge";
import { Engagement } from "PFTypes";

import css from "./engagement_roles_badge.module.scss";

export const EngagementRolesBadge = ({ engagement }: { engagement: Engagement }) => {
  const { roles_workflow_state_tally: { filled, exception } = {}, roles } = engagement;
  const filledCount = (filled || 0) + (exception || 0);
  const rolesCountText = `${filledCount || 0} / ${roles.length}`;
  return <SimpleBadge small classes={{ root: css.badge }} text={rolesCountText} />;
};
