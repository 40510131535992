import { useTranslation } from "react-i18next";

type NoItemsProps = {
  itemType: string;
};

export const NoItems = ({ itemType }: NoItemsProps) => {
  const { t } = useTranslation("workflow");

  return (
    <div style={{ padding: 30, textAlign: "center" }}>
      {itemType === "role"
        ? t("parts.noItems.roles")
        : itemType === "engagement"
        ? t("parts.noItems.engagements")
        : t("parts.noItems.other")}
    </div>
  );
};
