import uniqBy from "lodash/uniqBy";
import { useExperienceLabelMap } from "PFApp/hooks/use_experience_label_map";
import { Alert } from "PFComponents/alert";
import SimpleBadge from "PFComponents/badges_list/simple_badge";
import CustomValuesList from "PFComponents/custom_values_list/custom_values_list";
import { DoubleSectionPill } from "PFComponents/double_section_pill/double_section_pill";
import { Icon } from "PFComponents/icon";
import { Modal } from "PFComponents/modal";
import AutoSelect from "PFComponents/select/autoselect";
import { Typography } from "PFComponents/typography";
import { fetchSkillsFrameworksOptions } from "PFCore/services/skills_frameworks/fetch_skills_frameworks_options";
import { SkillsFramework, SkillsFrameworkRank } from "PFTypes";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { ConflictRadios } from "./components/conflict_radios";
import css from "./skills_framework_select_modal.module.scss";
import { SkillsFrameworkCustomField, SkillsFrameworkDecorated } from "./skills_framework_select_modal.types";
import { useSkillsFrameworkModal } from "./use_skills_framework_select_modal";

type SkillsFrameworksSelectModalProps = {
  skillsFrameworks: SkillsFramework[];
  onSubmit: (
    items: SkillsFramework[],
    skills: SkillsFramework["skills"],
    customFields: SkillsFrameworkCustomField[]
  ) => void;
  onClose: () => void;
};

type ItemProps = {
  label?: string;
  children: ReactNode;
  isConflict?: boolean;
};

const Section = ({ children, label, isConflict }: ItemProps) => {
  const { t } = useTranslation(["skillsFrameworks", "core"]);
  return (
    <div className={css.item}>
      <div className={css.header}>
        {label && (
          <Typography withMargin variant="labelBold">
            {label}
          </Typography>
        )}
        {isConflict && (
          <>
            <Icon name="warning" size="sm" color="paletteOrange0" />
            <Typography withMargin variant="labelRegular">
              {t("skillsFrameworks:selectModal.conflict")}
            </Typography>
          </>
        )}
      </div>
      <div className={css.skills}>{children}</div>
    </div>
  );
};

export const SkillsFrameworksSelectModal = ({
  skillsFrameworks,
  onSubmit,
  onClose
}: SkillsFrameworksSelectModalProps) => {
  const { t } = useTranslation(["skillsFrameworks", "core"]);
  const experienceLabelMap = useExperienceLabelMap();

  const {
    essentialSkills,
    supportingSkills,
    customFields,
    primaryFramework,
    setPrimaryFramework,
    secondaryFrameworks,
    setSecondaryFrameworks,
    handleSubmit,
    handleCustomFieldConflictResolve
  } = useSkillsFrameworkModal(skillsFrameworks, onSubmit);

  const uniqSkills = (skills: SkillsFrameworkDecorated["skills"]) =>
    uniqBy(skills, ({ customValue: { id }, requiredExperience }) => `${id}-${requiredExperience}`);

  const conflictsCount = customFields.filter(({ conflict }) => conflict).length;

  return (
    <Modal
      title={t("skillsFrameworks:selectModal.skillsFramework")}
      onOK={handleSubmit}
      onClose={onClose}
      labelOK={t("skillsFrameworks:selectModal.apply")}
      classes={{
        modal: css.modal
      }}
    >
      <div className={css.root}>
        <AutoSelect
          values={primaryFramework ? [primaryFramework] : []}
          label={t("skillsFrameworks:selectModal.primarySkillsFramework")}
          query={(term) =>
            fetchSkillsFrameworksOptions({ rank: SkillsFrameworkRank.Primary, ...(term ? { term } : {}) })
          }
          formatOption={(item) => ({
            id: item.id,
            displayElement: item.name,
            item: {
              ...item,
              text: item.name
            }
          })}
          handleChange={(value) => setPrimaryFramework(value[0])}
          rootClassName={css.select}
          closeOnChange
          letClear
          displayValues={
            primaryFramework ? (
              <SimpleBadge classes={{ root: css.primaryFramework }} text={primaryFramework.name} />
            ) : undefined
          }
        />
        <AutoSelect
          values={secondaryFrameworks}
          label={t("skillsFrameworks:selectModal.secondarySkillsFramework")}
          query={(term) =>
            fetchSkillsFrameworksOptions({ rank: SkillsFrameworkRank.Secondary, ...(term ? { term } : {}) })
          }
          formatOption={(item) => ({
            id: item.id,
            displayElement: item.name,
            item: {
              ...item,
              text: item.name
            }
          })}
          handleChange={setSecondaryFrameworks}
          rootClassName={css.select}
          maxLength={3}
          closeOnChange
          letClear
          multi
        />
        {conflictsCount > 0 && (
          <Alert
            hideIcon
            small
            title={t(
              `skillsFrameworks:selectModal.${
                primaryFramework ? "conflictsAlertWithPrimary" : "conflictsAlert"
              }`,
              { count: conflictsCount }
            )}
          />
        )}
        {(essentialSkills.length > 0 || supportingSkills.length > 0) && (
          <div className={css.section}>
            {essentialSkills.length > 0 && (
              <Section label={t("skillsFrameworks:selectModal.essentialSkills")}>
                {uniqSkills(essentialSkills).map(({ id, requiredExperience, customValue }) => (
                  <DoubleSectionPill
                    key={id}
                    leftContent={String(customValue.value)}
                    rightContent={experienceLabelMap[requiredExperience]}
                    kind={customValue.kind}
                  />
                ))}
              </Section>
            )}
            {supportingSkills.length > 0 && (
              <Section label={t("skillsFrameworks:selectModal.supportingSkills")}>
                {uniqSkills(supportingSkills).map(({ id, requiredExperience, customValue }) => (
                  <DoubleSectionPill
                    key={id}
                    leftContent={String(customValue.value)}
                    rightContent={experienceLabelMap[requiredExperience]}
                    kind={customValue.kind}
                  />
                ))}
              </Section>
            )}
          </div>
        )}
        {customFields.length > 0 && (
          <div className={css.section}>
            {customFields.map(({ type, values, conflict }) => (
              <Section key={type.id} label={type.displayAs} isConflict={conflict}>
                {conflict ? (
                  <ConflictRadios
                    id={type.id}
                    value={values.find(({ selected }) => selected)?.id}
                    values={values}
                    onChange={(value) => handleCustomFieldConflictResolve(value, type)}
                  />
                ) : (
                  <CustomValuesList
                    type={type}
                    customValues={uniqBy(values, "id").map((value) => ({
                      ...value,
                      classes: { root: value.kind }
                    }))}
                    sort
                  />
                )}
              </Section>
            ))}
          </div>
        )}
      </div>
    </Modal>
  );
};
