import { Divider } from "PFComponents/divider";
import { Tile } from "PFComponents/tile";

import { MatchAttributes } from "../../../components/match_attributes";
import { RoleInformation } from "../../../components/role_information";
import css from "./shortlist_information.module.scss";

export const ShortlistInformation = () => (
  <Tile className={css.root} paddingVariant="lg" selected>
    <RoleInformation />
    <Divider />
    <MatchAttributes />
  </Tile>
);
