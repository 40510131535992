import { Camelized, decamelizeKeys } from "humps";
import { ProfileItem } from "PFApp/components/profile_item";
import { useProfilePreview } from "PFApp/use_profile_preview";
import { Stack } from "PFComponents/containers";
import { Typography } from "PFComponents/typography";
import { ActivityHistoryProfile } from "PFCore/services/activity";
import { BasicProfile, Profile } from "PFTypes";

type HistoryEntryProfileProps = {
  profile: Camelized<Profile> | ActivityHistoryProfile;
  subtitle?: string;
};

export const HistoryEntryProfile = ({ profile, subtitle }: HistoryEntryProfileProps) => {
  const { showPreview } = useProfilePreview();

  const isSuspended = !!profile.suspendedAt;
  const handleProfileClick = () => {
    const decamelizedProfile = decamelizeKeys(profile) as Profile;
    showPreview({ id: profile.id, profile: decamelizedProfile });
  };

  return (
    <Stack>
      <ProfileItem
        profile={profile as BasicProfile}
        displayJobTitle={false}
        displaySuspendedDate={true}
        displaySuspendedDateBelow={false}
        rootFlexProps={{ gap: null }}
        onClick={!isSuspended ? handleProfileClick : undefined}
      />
      {!!subtitle && (
        <Typography variant="bodyRegular" clipOverflow>
          {subtitle}
        </Typography>
      )}
    </Stack>
  );
};
