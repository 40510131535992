import { RowSelectionState } from "@tanstack/react-table";
import SidePanel from "PFComponents/side_panel/side_panel";
import { useSidePanelClose } from "PFComponents/side_panel/use_side_panel_close";
import { getProfileName } from "PFCore/helpers/profile";
import { useIsCurrentUserPermittedTo } from "PFCore/helpers/use_is_permitted_to";
import { useProfile } from "PFCore/hooks/queries/profile/use_profile";
import { PermissionRule } from "PFTypes";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useReassignSubmit } from "../../../../parts/overview/booking_module_calendar/context/use_reassign_submit";
import { ReassignSelectOption } from "../../../reassign_booking_modal/bookings_to_reassign_selector";
import { DetailsPanelComponentProps, DetailsSidePanelProps } from "../../details_panel";
import { DetailsHeader } from "../details_header";
import { ReassignContent } from "./components/reassign_content";
import { ReassignSidePanelFooter } from "./components/reassign_side_panel_footer";

type ReassignSidePanelProps = DetailsSidePanelProps & Pick<DetailsPanelComponentProps, "data">;

export const ReassignSidePanel = React.memo(
  ({ isOnTop, onClose, show, zIndex, data }: ReassignSidePanelProps) => {
    const { t } = useTranslation("bookingModule");

    const reassignData = data.reassignDetails;

    const { data: sourceProfileData } = useProfile(reassignData?.sourceProfileId, {
      enabled: !!reassignData?.sourceProfileId && !data.suspendedProfileDetails
    });
    const sourceProfile = sourceProfileData ?? data.suspendedProfileDetails;

    const { handleSubmit: handleReassignSubmit } = useReassignSubmit({ data: reassignData ?? null });
    const { isClosing, onSidePanelClose } = useSidePanelClose({
      onClose: () => {
        onClose();
        setReassignSelectOption(ReassignSelectOption.ThisBooking);
      }
    });

    const [rowSelectionState, setRowSelectionState] = useState<RowSelectionState>({});
    const [reassignSelectOption, setReassignSelectOption] = useState<ReassignSelectOption>(
      ReassignSelectOption.ThisBooking
    );

    const canReassignBookingsNamedResources = useIsCurrentUserPermittedTo(
      PermissionRule.ReassignBookingsNamedResource
    );
    const canReassignBookingsMatches = useIsCurrentUserPermittedTo(PermissionRule.ReassignBookingsMatches);

    if (!reassignData || (!canReassignBookingsNamedResources && !canReassignBookingsMatches)) {
      return null;
    }

    const selectedRowIds = Object.keys(rowSelectionState);
    const handleSubmit = () => {
      if (selectedRowIds.length === 1) {
        handleReassignSubmit(reassignSelectOption, parseInt(selectedRowIds[0]));
      }
      onClose();
      setReassignSelectOption(ReassignSelectOption.ThisBooking);
    };

    const handleClose = () => {
      onClose();
      setReassignSelectOption(ReassignSelectOption.ThisBooking);
    };

    return (
      <SidePanel
        size="medium"
        show={show}
        isSidePanelClosing={isClosing}
        onClose={handleClose}
        title={
          <DetailsHeader
            title={reassignData.booking.title}
            subtitle={t("bookings.reassign.reassignAction")}
          />
        }
        zIndex={zIndex}
        isOnTop={isOnTop}
        footerRenderer={() => (
          <ReassignSidePanelFooter
            submitDisabled={selectedRowIds.length !== 1}
            onClose={onSidePanelClose}
            onSubmit={handleSubmit}
          />
        )}
      >
        <ReassignContent
          booking={reassignData.booking}
          activity={reassignData.activity}
          isEngagement={reassignData.isEngagement}
          profileFullName={getProfileName(sourceProfile)}
          onTargetProfileChange={setRowSelectionState}
          rowSelectionState={rowSelectionState}
          reassignOption={reassignSelectOption}
          setReassignOption={setReassignSelectOption}
        />
      </SidePanel>
    );
  }
);

ReassignSidePanel.displayName = "ReassignSidePanel";
