import { useExperienceConfig } from "PFApp/hooks/use_experience_config";
import { useExperienceLabelMap } from "PFApp/hooks/use_experience_label_map";
import { Experience, Id, ProfileCustomValue } from "PFTypes";
import { useMemo } from "react";

import { groupSkillsByExperience } from "../../charts/helpers";
import { GroupedSkillsList } from "./parts/grouped_skills_list";

type ProficiencyGroupedSkillsListProps = {
  skills: ProfileCustomValue[];
  profileId: Id;
  selectedSkillsIds: Id[];
  onSkillSelectionChange: (skillId: Id) => void;
};

export const ProficiencyGroupedSkillsList = ({
  skills,
  profileId,
  selectedSkillsIds,
  onSkillSelectionChange
}: ProficiencyGroupedSkillsListProps) => {
  const { experienceLevels } = useExperienceConfig();
  const experienceLabelMap = useExperienceLabelMap();

  const sortedExperienceLevels = [...experienceLevels].reverse();

  const skillsByExperience = useMemo(() => groupSkillsByExperience(skills), [skills]);

  return (
    <GroupedSkillsList<Experience>
      groups={sortedExperienceLevels.map((experience) => {
        const experienceSkills = skillsByExperience[experience] || [];
        return {
          id: experience,
          title: `${experienceLabelMap[experience]} (${experienceSkills.length})`,
          skills: experienceSkills
        };
      })}
      profileId={profileId}
      selectedSkillsIds={selectedSkillsIds}
      onSkillSelectionChange={onSkillSelectionChange}
    />
  );
};
