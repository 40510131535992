import { AttributeKey, BookingHistoryItem as BookingHistoryItemType } from "PFApp/booking/types";
import { ProfileItem } from "PFApp/components/profile_item";
import { Typography } from "PFComponents/typography";
import { camelCase } from "PFCore/helpers/camel_case";
import { BookingHistoryProfile } from "PFCore/hooks/queries/bookings/history/use_booking_history";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { BasicProfile } from "PFTypes";
import { forwardRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { AttributeData } from "./attribute_items/attribute";
import { AttributesSection } from "./attributes_section";
import css from "./booking_history_item.module.scss";
import { useBookingHistory } from "./use_booking_history";

type BookingHistoryItemProps = {
  item: BookingHistoryItemType;
  metaProfiles?: Record<number, BookingHistoryProfile>;
};

const BookingHistoryItem = forwardRef<HTMLDivElement, BookingHistoryItemProps>(
  (
    {
      item: { requester_name: requesterName, requester_id: requesterId, diff, created_at: createdAt, action },
      metaProfiles
    },
    ref
  ) => {
    const { t } = useTranslation(["bookingModule", "translation"]);
    const { formatDateTime, utc } = useDateFormatter();
    const { getUserFriendlyAttributeName } = useBookingHistory();
    const navigate = useNavigate();

    const propertyNames = diff
      ? Object.keys(diff)
          .map((attribute) => getUserFriendlyAttributeName(attribute))
          .join(", ")
      : t("translation:property");

    const attributes: AttributeData[] = useMemo(
      () =>
        diff
          ? Object.entries(diff).map(([key, value]) => ({
              attributeId: key as AttributeKey,
              valueFrom: value[0],
              valueTo: value[1]
            }))
          : [],
      [diff]
    );

    const hasManyAttributes = attributes.length > 1;
    const shouldDisplayAttributes = attributes.length > 0 && action === "booking_updated";
    const requesterProfile = (metaProfiles?.[requesterId] ?? {
      id: requesterId,
      first_name: requesterName
    }) as BasicProfile;
    const isRequesterSuspended = requesterProfile.suspended_at;

    const goToRequesterProfile = () => navigate(`/profiles/${requesterId}`);

    return (
      <div ref={ref} className={css.root}>
        <ProfileItem
          profile={requesterProfile}
          displayJobTitle={false}
          displayEmail={false}
          displayAvatar={false}
          displaySuspendedDate={true}
          rootFlexProps={{ gap: null }}
          onClick={!isRequesterSuspended ? goToRequesterProfile : undefined}
        />
        <Typography withMargin variant="bodyRegular" tag="span">
          {t(`bookingModule:details.history.${camelCase(action)}`, {
            properties: hasManyAttributes ? t("bookingModule:details.history.someFields") : propertyNames,
            date: formatDateTime(utc(createdAt))
          })}
        </Typography>
        <div>
          {shouldDisplayAttributes && (
            <AttributesSection attributes={attributes} metaProfiles={metaProfiles} />
          )}
        </div>
      </div>
    );
  }
);

BookingHistoryItem.displayName = "BookingHistoryItem";

export default BookingHistoryItem;
