import map from "lodash/map";
import { Stack } from "PFComponents/containers";
import { Typography } from "PFComponents/typography";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { Segment } from "PFTypes";

type SegmentComposerTooltipContentProps = {
  segment: Segment;
};

export const SegmentComposerTooltipContent = ({ segment }: SegmentComposerTooltipContentProps) => {
  const { getCustomTypeByName } = useCustomTypes();

  return (
    <Stack gap="spacingLg">
      {map(segment, (values, key) => (
        <Stack key={key} gap="spacingSm">
          <Typography variant="labelRegular">{getCustomTypeByName(key)?.display_as || key}</Typography>
          <Typography variant="bodyBold">{map(values, "text").join(", ")}</Typography>
        </Stack>
      ))}
    </Stack>
  );
};
