import classNames from "classnames";
import { useSynchronizedState } from "PFCore/hooks/use_synchronized_state";
import { useRef } from "react";

import { useSliderContext } from "../context/slider_context";
import { useAriaLabels } from "../hooks/use_aria_labels";
import { useInputWidth } from "../hooks/use_input_width";
import { SideTools } from "../parts/side_tools";
import { Steps } from "../parts/steps";
import { getLeftOffset } from "../sliders.common";
import slidersCss from "../sliders.module.scss";
import css from "./multirange_slider.module.scss";

export type MultiRangeValue = {
  min: number;
  max: number;
  sliderId?: string;
};

export type MultiRangeSliderProps = {
  onChange: ({ min, max, sliderId }: MultiRangeValue) => void;
  value: MultiRangeValue;
};

export const MultiRangeSlider = () => {
  const { min, max, value, step, showSteps, disabled, instantOnChange, sliderId, small, onChange } =
    useSliderContext<MultiRangeSliderProps>();
  const inputRef = useRef<HTMLInputElement>(null);
  const inputWidth = useInputWidth(inputRef);
  const { minAriaLabel, maxAriaLabel } = useAriaLabels();
  const [localSliderValue, setLocalSliderValue] = useSynchronizedState<MultiRangeValue>(
    value,
    !instantOnChange
  );

  const sliderValue = instantOnChange ? value : localSliderValue;

  const minPercent = getLeftOffset({
    value: sliderValue.min,
    min,
    max,
    inputWidth,
    small
  });
  const maxPercent = getLeftOffset({
    value: sliderValue.max,
    min,
    max,
    inputWidth,
    small
  });

  const rangeLeft = `${minPercent}px`;
  const rangeWidth = `${maxPercent - minPercent}px`;

  const handleChange = (newValue: MultiRangeValue) => {
    if (instantOnChange) {
      onChange(newValue);
    } else {
      setLocalSliderValue(newValue);
    }
  };

  return (
    <SideTools<MultiRangeValue> localSliderValue={sliderValue} onChange={onChange}>
      <div className={slidersCss.sliderContainer}>
        <input
          id={sliderId}
          ref={inputRef}
          type="range"
          min={min}
          max={max}
          step={step}
          value={sliderValue.min}
          disabled={disabled}
          onChange={(e) => {
            const newMinValue = Math.min(e.target.valueAsNumber, sliderValue.max - 1);
            const newValue = { min: newMinValue, max: sliderValue.max, sliderId };

            handleChange(newValue);
          }}
          onMouseUp={instantOnChange ? undefined : () => onChange(sliderValue)}
          onKeyUp={instantOnChange ? undefined : () => onChange(sliderValue)}
          aria-label={minAriaLabel}
          className={classNames(slidersCss.sliderInput, css.multiRangeSliderInput, {
            [slidersCss.small]: small
          })}
        />
        <input
          type="range"
          min={min}
          max={max}
          step={step}
          value={sliderValue.max}
          disabled={disabled}
          onChange={(e) => {
            const newMaxValue = Math.max(e.target.valueAsNumber, sliderValue.min + 1);
            const newValue = { min: sliderValue.min, max: newMaxValue, sliderId };

            handleChange(newValue);
          }}
          onMouseUp={instantOnChange ? undefined : () => onChange(sliderValue)}
          onKeyUp={instantOnChange ? undefined : () => onChange(sliderValue)}
          aria-label={maxAriaLabel}
          className={classNames(slidersCss.sliderInput, css.multiRangeSliderInput, {
            [slidersCss.small]: small
          })}
        />

        <div className={slidersCss.slider}>
          <div className={slidersCss.sliderTrack}>
            {showSteps && <Steps value={sliderValue} inputWidth={inputWidth} />}
          </div>
          <div className={slidersCss.sliderRange} style={{ left: rangeLeft, width: rangeWidth }} />
        </div>
      </div>
    </SideTools>
  );
};
