import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import some from "lodash/some";
import { useAppContext } from "PFApp/app_context";
import { Alert } from "PFComponents/alert";
import { Button } from "PFComponents/button";
import { Inline, Stack } from "PFComponents/containers";
import { Icon } from "PFComponents/icon";
import SidePanel from "PFComponents/side_panel/side_panel";
import { Typography } from "PFComponents/typography";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { SegmentActionType } from "PFReducers/segment_reducer";
import { PureCustomValueData, Segment } from "PFTypes";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { CustomValuesEditField } from "../custom_values_edit_field";

type SegmentComposerSidePanelProps = {
  isVisible: boolean;
  onClose?: () => void;
};

export const SegmentComposerSidePanel = ({ isVisible, onClose }: SegmentComposerSidePanelProps) => {
  const {
    store: { segment },
    dispatch
  } = useAppContext();
  const { getCustomTypeByName } = useCustomTypes();
  const { t } = useTranslation("translation");

  const [localSegment, setLocalSegment] = useState<Segment>(segment);

  const handleSegmentValueChange = useCallback(
    (key: string, values: PureCustomValueData[]) =>
      setLocalSegment({
        ...localSegment,
        [key]: values
      } as Segment),
    [localSegment]
  );

  const handleClose = useCallback(() => {
    setLocalSegment(segment);
    onClose?.();
  }, [onClose, segment]);

  const handleApply = useCallback(() => {
    dispatch({
      type: SegmentActionType.SegmentSet,
      payload: localSegment
    });

    onClose?.();
  }, [dispatch, onClose, localSegment]);

  const renderTitle = useCallback(
    () => (
      <Inline alignItems="center">
        <Icon name="segment" />
        <Typography variant="h4">{t("segment")}</Typography>
      </Inline>
    ),
    [t]
  );

  const renderFooter = useCallback(
    () => (
      <Inline spread alignItems="center">
        <Button text={t("close")} kind="secondary" onClick={handleClose} />
        <Button text={t("filters.apply")} onClick={handleApply} disabled={some(localSegment, isEmpty)} />
      </Inline>
    ),
    [handleApply, handleClose, t, localSegment]
  );

  return (
    <SidePanel title={renderTitle()} show={isVisible} onClose={handleClose} footerRenderer={renderFooter}>
      <Stack gap="spacingMd">
        <Alert kind="warning" title={t("segmentWarning")} small />
        {map(localSegment, (values, key) => {
          const customType = getCustomTypeByName(key);
          return (
            customType && (
              <CustomValuesEditField
                required
                handleChange={(values) => handleSegmentValueChange(key, values)}
                customType={customType}
                values={values}
                errors={!values.length ? t("segmentRequired") : undefined}
                multi
                forceUnlocked
              />
            )
          );
        })}
      </Stack>
    </SidePanel>
  );
};
