import { Booking } from "PFTypes";
import React, { useCallback } from "react";

import { BookingDetailSidePanel } from "../../parts/overview/detail_view/booking_detail/booking_detail_side_panel";
import { EngagementSidePanelWrapper } from "./detail_view/engagement_detail_side_panel";
import { MisalignmentsDetailSidePanel } from "./detail_view/misalignments_detail_side_panel/misalignments_detail_side_panel";
import { OverbookingsDetailSidePanel } from "./detail_view/overbookings_detail_side_panel/overbookings_detail_side_panel";
import { ProfileDetailsSidePanel } from "./detail_view/profile_detail/profile_detail_side_panel";
import { ReassignSidePanel } from "./detail_view/reassign_side_panel/reassign_side_panel";
import { RoleDetailSidePanelWrapper } from "./detail_view/role_detail_side_panel";
import {
  DetailsPanelType,
  StoredDetailsPanelData,
  useDetailsPanelApiContext,
  useDetailsPanelOrderContext,
  useDetailsPanelStateContext
} from "./details_panel_context/details_panel_context";
import { usePrerequisiteChecker } from "./use_prerequisite_checker";

type SidePanelComponent =
  | typeof BookingDetailSidePanel
  | typeof EngagementSidePanelWrapper
  | typeof ProfileDetailsSidePanel
  | typeof RoleDetailSidePanelWrapper
  | typeof OverbookingsDetailSidePanel
  | typeof MisalignmentsDetailSidePanel
  | typeof ReassignSidePanel;

const SIDE_PANEL: Record<DetailsPanelType, SidePanelComponent> = {
  booking: BookingDetailSidePanel,
  engagement: EngagementSidePanelWrapper,
  profile: ProfileDetailsSidePanel,
  role: RoleDetailSidePanelWrapper,
  overbookings: OverbookingsDetailSidePanel,
  misalignments: MisalignmentsDetailSidePanel,
  reassign: ReassignSidePanel
};

export type DetailsSidePanelProps = {
  show: boolean;
  isOnTop: boolean;
  onClose: () => void;
  zIndex?: number;
};

export interface DetailsPanelComponentProps {
  data: StoredDetailsPanelData;
  onDeleted?: (booking: Booking) => Promise<void>;
  onEdited?: () => void;
  onClose?: () => void;
  goToDate?: (date: Date) => void;
  classes?: {
    show?: string;
  };
  checkCanGoToDate?: (date: string) => boolean;
}

export const DetailsPanel = ({
  onDeleted,
  onEdited,
  onClose,
  goToDate,
  classes,
  checkCanGoToDate
}: Omit<DetailsPanelComponentProps, "data">): React.ReactElement | null => {
  const { closeDetailsPanel } = useDetailsPanelApiContext();
  const { detailsData, detailsDataStack } = useDetailsPanelStateContext();
  const { checkTopValue, findIndexOfElement } = useDetailsPanelOrderContext();

  const checkIfSidePanelCanBeDisplayed = usePrerequisiteChecker({ data: detailsData });
  const canDisplaySidePanel = checkIfSidePanelCanBeDisplayed({ type: detailsData?.type });
  const show = !!detailsData && canDisplaySidePanel;

  const containsManyData = (detailsDataStack ?? []).length > 0;

  const handleDelete = useCallback(
    async (entity) => {
      closeDetailsPanel();

      if ((!!detailsData && detailsData.type !== "booking") || !entity) {
        return;
      }
      onDeleted?.(entity);
    },
    [closeDetailsPanel, detailsData, onDeleted]
  );

  // Side panel is memoized - useCallback() to prevent unnecessary re-renders due to changed props
  const handleClose = useCallback(() => {
    closeDetailsPanel();
    onClose?.();
  }, [closeDetailsPanel, onClose]);

  // Side panel is memoized - useCallback() to prevent unnecessary re-renders due to changed props
  const handleCheckCanGoToDate = useCallback(
    (date: string) => checkCanGoToDate?.(date) ?? false,
    [checkCanGoToDate]
  );

  if (!detailsData) {
    return null;
  }

  if (containsManyData) {
    const isSinglePanel = detailsDataStack.length === 1;

    return (
      <>
        {detailsDataStack.map((detailsData, index) => {
          const sidePanelType = detailsData.type;
          const Panel = SIDE_PANEL[sidePanelType];
          const isLastSidePanel = index === detailsDataStack.length - 1;
          const zIndex = findIndexOfElement(detailsData.uniqueId);

          const defaultKey = `side-panel-${sidePanelType}-${detailsData.id}-${detailsData.uniqueId}`;
          const misalignmentKey = `side-panel-${sidePanelType}-${detailsData.id}`;

          return (
            <Panel
              zIndex={zIndex + 1}
              isOnTop={isSinglePanel || (isLastSidePanel && !checkTopValue("booking_form"))}
              key={sidePanelType === "misalignments" ? misalignmentKey : defaultKey}
              show
              data={detailsData}
              onDeleted={handleDelete}
              onEdited={onEdited}
              onClose={handleClose}
              goToDate={goToDate}
              classes={{ show: classes?.show }}
              checkCanGoToDate={handleCheckCanGoToDate}
            />
          );
        })}
      </>
    );
  }
  const isOnTop = !checkTopValue("booking_form");
  const Panel = SIDE_PANEL[detailsData?.type];

  if (!show || !Panel) {
    return null;
  }

  return (
    <Panel
      isOnTop={isOnTop}
      show={show}
      data={detailsData}
      onDeleted={onDeleted}
      onEdited={onEdited}
      onClose={handleClose}
      goToDate={goToDate}
      checkCanGoToDate={handleCheckCanGoToDate}
    />
  );
};
