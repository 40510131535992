import flatten from "lodash/flatten";
import uniqBy from "lodash/uniqBy";
import { PER_PAGE_UNPAGINATED } from "PFApp/constants/unpaginated";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { useProfileSkillsFrameworks } from "PFCore/hooks/queries/skills_frameworks/use_profile_skills_frameworks";
import { FeatureFlag, Id } from "PFTypes";

type UseProfileSuggestedSkills = {
  profileId: Id;
  enabled?: boolean;
};

export const useProfileSuggestedSkills = ({ profileId, enabled = true }: UseProfileSuggestedSkills) => {
  const isEnabled = useIsFeatureEnabled();
  const isSkillsFrameworksEnabled = isEnabled(FeatureFlag.SkillsFramework);

  const { data, isFetching } = useProfileSkillsFrameworks({
    profileId,
    params: { page: 1, perPage: PER_PAGE_UNPAGINATED },
    options: {
      enabled: isSkillsFrameworksEnabled && enabled,
      select: (data) =>
        uniqBy(
          flatten(
            (data.entries || []).map((skillsFramework) =>
              skillsFramework.skills
                .filter(({ proposed }) => proposed)
                .map(({ customValue, requiredExperience }) => ({
                  text: String(customValue.value),
                  requiredExperience,
                  ...customValue
                }))
            )
          ),
          "id"
        )
    }
  });

  return {
    suggestedFrameworksSkills: data || [],
    isLoading: isFetching
  };
};
