import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import isNil from "lodash/isNil";
import { useErrorsGrowl } from "PFCore/hooks/use_errors_growl";
import { Id } from "PFTypes";
import { useEffect } from "react";

import { FetchOptionsResponse, SelectOptions, SelectV2Props } from "../select_v2.types";
import { optionsKeys } from "./query_keys";
import { filterOptions } from "./use_options.utils";

export type UseOptions<T> = {
  dropdownId: string;
  ids?: Id[];
  searchTerm?: string;
  enabled?: boolean;
  queryOptions?: Omit<UseQueryOptions<FetchOptionsResponse<T>>, "queryKey" | "queryFn">;
} & Pick<SelectV2Props<T>, "options" | "fetchOptions" | "parseOptions">;

type UseOptionsReturn<T> = { data: SelectOptions<T>; total: number; isFetching: boolean; limit?: number };

export const useOptions = <T>({
  dropdownId,
  ids,
  searchTerm,
  options,
  fetchOptions,
  parseOptions,
  queryOptions = {}
}: UseOptions<T>): UseOptionsReturn<T> => {
  const isFetchingOptionsEnabled = !!fetchOptions;
  const growlErrors = useErrorsGrowl();
  const enabled = isFetchingOptionsEnabled && (!!queryOptions.enabled || isNil(queryOptions.enabled));
  const {
    data: fetchedOptions,
    isFetching,
    error
  } = useQuery({
    queryKey: optionsKeys.dropdownWithParams(dropdownId, ids, searchTerm),
    queryFn: () => (fetchOptions ? fetchOptions({ ids, searchTerm }) : undefined),
    ...queryOptions,
    enabled
  });

  useEffect(() => {
    if (error) {
      growlErrors(error);
    }
  }, [error, growlErrors]);

  if (isFetchingOptionsEnabled) {
    const data = fetchedOptions?.entries ?? [];
    return {
      data: parseOptions ? parseOptions(data) : data,
      total: fetchedOptions?.meta?.total || 0,
      limit: fetchedOptions?.meta?.selected_options_limit || fetchedOptions?.meta?.selectedOptionsLimit,
      isFetching
    };
  }

  const resultOptions = searchTerm ? filterOptions(options ?? [], searchTerm) : options ?? [];
  return {
    data: parseOptions ? parseOptions(resultOptions) : resultOptions,
    total: resultOptions.length,
    isFetching: false
  };
};
