import i18n from "PFCore/i18n";
import { IconName } from "PFTheme/graphics/icons";

import { SelectV2Props } from "../select_v2.types";

type UseActionIcons = Pick<
  SelectV2Props,
  "onClear" | "onRestore" | "label" | "restoreLabel" | "locked" | "disabled"
>;

export type ActionIconData = {
  icon: IconName;
  onClick: VoidFunction;
  title: string;
  disabled: boolean;
};

export const useActionIcons = ({
  onRestore,
  onClear,
  label,
  restoreLabel,
  disabled,
  locked
}: UseActionIcons): ActionIconData[] => {
  const icons = [
    {
      icon: "history" as IconName,
      onClick: onRestore,
      disabled,
      title:
        restoreLabel ||
        (label
          ? i18n.t("core:components.selectV2.restoreValue", { label })
          : i18n.t("core:components.selectV2.restore"))
    },
    {
      icon: "filter-clean" as IconName,
      onClick: onClear,
      disabled,
      title: label
        ? i18n.t("core:components.selectV2.clearValue", { label })
        : i18n.t("core:components.selectV2.clear")
    }
  ];

  return icons.filter(({ onClick }) => !locked && !!onClick) as ActionIconData[];
};
