import { keepPreviousData } from "@tanstack/react-query";
import concat from "lodash/concat";
import map from "lodash/map";
import { useAppContext } from "PFApp/app_context";
import { getAPIFilters } from "PFApp/use_filtered_collection";
import { useMetaFilters } from "PFApp/use_filtered_collection/use_meta_filters";
import useStorage from "PFCore/helpers/use_storage";
import { useProfilesInfinite } from "PFCore/hooks/queries/profiles/use_profiles";
import { FetchProfilesResponse } from "PFCore/services/profile";
import { Filter, Filters, OrderOption, Value } from "PFTypes";
import { createContext, Dispatch, SetStateAction, useContext, useMemo } from "react";

type ProfilesPageContextProvider = {
  profiles: FetchProfilesResponse["entries"];
  meta: FetchProfilesResponse["meta"];
  isFetching: boolean;
  error: any;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
  filters: Filters<Value>;
  updateFilter: (filter: Filter, value: Value) => void;
  resetFilters: (params?: unknown) => void;
  setFilters: Dispatch<SetStateAction<Record<string, Value>>>;
  areFiltersClear: boolean;
  order: OrderOption | null;
  setOrder: (newOrder: OrderOption) => void;
  fetchNextPage: VoidFunction;
};

export const ProfilesPageContext = createContext({} as ProfilesPageContextProvider);

const LS_EXPIRY_TIME_SECONDS = 60 * 60;

export const ProfilesPageContextProvider = ({ children }) => {
  const {
    store: { segment }
  } = useAppContext();
  const [filters, setFilters] = useStorage<Filters<Value>>(
    "profilesPageFilters",
    {},
    {
      expiresIn: LS_EXPIRY_TIME_SECONDS
    }
  );
  const [order, setOrder] = useStorage<OrderOption | null>("profilesPageOrder", null, {
    expiresIn: LS_EXPIRY_TIME_SECONDS
  });

  const { data, isFetching, error, hasNextPage, isFetchingNextPage, fetchNextPage } = useProfilesInfinite(
    {
      filters: getAPIFilters(filters, segment),
      order: order ?? undefined,
      perPage: 20,
      nested: true
    },
    { placeholderData: keepPreviousData }
  );

  const profiles = concat(...(map(data?.pages, "entries") || [])) ?? [];
  const meta = useMemo(
    () => data?.pages[0].meta ?? { total: 0, page: 0, perPage: 0, totalPages: 0 },
    [data?.pages, filters, order]
  );

  const { updateFilter, resetFilters, areFiltersClear } = useMetaFilters(meta, setFilters);

  return (
    <ProfilesPageContext.Provider
      value={{
        profiles,
        meta,
        isFetching,
        error,
        hasNextPage: hasNextPage ?? false,
        isFetchingNextPage,
        filters,
        updateFilter,
        resetFilters,
        setFilters,
        areFiltersClear,
        order,
        setOrder,
        fetchNextPage
      }}
    >
      {children}
    </ProfilesPageContext.Provider>
  );
};

export const useProfilesPageContext = () => {
  const context = useContext(ProfilesPageContext);

  if (!context) {
    throw new Error("useProfilesPageContext must be used within a ProfilesPageContextProvider");
  }

  return context;
};
