import { UseMutateAsyncFunction } from "@tanstack/react-query";
import { useGrowl } from "PFApp/use_growl";
import { useCustomValueInvalidate } from "PFCore/hooks/queries/custom_fields/use_custom_value_invalidate";
import { CreateDictionaryConnectionParams } from "PFCore/services/custom_values";
import { Experience, PureCustomValueCamelCased } from "PFTypes";
import { useTranslation } from "react-i18next";

import { useCustomValuePreviewContext } from "../../context/custom_value_preview_context";
import { Footer, FooterProps } from "./footer";

type ProfileCustomValueFooterProps = {
  customValue: PureCustomValueCamelCased | undefined;
  isCore: boolean;
  isDevelopmental: boolean;
  experience: Experience | null;
  createDictionaryConnection: UseMutateAsyncFunction<unknown, unknown, CreateDictionaryConnectionParams>;
  closeWithAnimation: VoidFunction;
} & Omit<FooterProps, "saveNewLabel" | "onRemove" | "onSave">;

export const ProfileCustomValueFooter = ({
  customValue,
  experience,
  isCore,
  isDevelopmental,
  createDictionaryConnection,
  closeWithAnimation,
  ...props
}: ProfileCustomValueFooterProps) => {
  const { t } = useTranslation("translation", { keyPrefix: "customValueSidepanel" });
  const growl = useGrowl();
  const { removeCustomValueProfileScope, invalidateCustomValue, invalidateCustomValueInsights } =
    useCustomValueInvalidate();
  const { customValueId, profileId } = useCustomValuePreviewContext();

  const invalidate = (removeFromCache: boolean = false) => {
    invalidateCustomValue(customValueId!);
    invalidateCustomValueInsights(customValueId!);
    if (removeFromCache) {
      removeCustomValueProfileScope(customValueId!, profileId!);
    }
  };

  const handleRemove = () => {
    createDictionaryConnection({ customValueId: customValueId!, experience: Experience.None })
      .then(() => {
        growl({ message: t("removeSuccess", { value: customValue?.text }) });
        closeWithAnimation();
        setTimeout(() => {
          // Invalidate after isClosing is true, to avoid incorrect query call
          invalidate(true);
        }, 0);
      })
      .catch(() => {
        growl({ message: t("removeError", { value: customValue?.text }), kind: "error" });
      });
  };

  const handleSave = () => {
    createDictionaryConnection({
      customValueId: customValueId!,
      experience: experience || Experience.Intermediate,
      top: isCore,
      developmental: isDevelopmental
    })
      .then(() => {
        growl({ message: t("saveSuccess", { value: customValue?.text }) });
        closeWithAnimation();
        setTimeout(() => {
          // Invalidate after isClosing is true, to avoid incorrect query call
          invalidate();
        }, 0);
      })
      .catch(() => {
        growl({ message: t("saveError", { value: customValue?.text }), kind: "error" });
      });
  };

  return <Footer {...props} saveNewLabel={t("addToMyProfile")} onRemove={handleRemove} onSave={handleSave} />;
};
