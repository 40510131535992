import { useQuery } from "@tanstack/react-query";
import { fetchProfileNotificationSettings } from "PFCore/services/notifications/fetch_profile_notifications_settings";

import { notificationsKeys } from "./query_keys";

export const useProfileNotificationsSettings = () =>
  useQuery({
    queryKey: notificationsKeys.settings(),
    queryFn: fetchProfileNotificationSettings
  });
