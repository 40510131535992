import { Divider } from "PFComponents/divider";
import { Typography } from "PFComponents/typography";
import { useTranslation } from "react-i18next";

import { FieldFactory } from "../field_factory";
import { useFilteredMetadataProperties } from "../hooks/use_filtered_metadata_properties";
import css from "./segment_section.module.scss";

type SegmentSectionProps = {
  qaIdPrefix: string;
};

export const SegmentSection = ({ qaIdPrefix }: SegmentSectionProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "edit.sections.segment" });

  const segmentProperties = useFilteredMetadataProperties(undefined, "segment");

  return (
    <>
      <Divider className={css.divider} />
      <Typography variant="bodyBold">{t("title")}</Typography>
      <Typography variant="labelRegular">{t("description")}</Typography>
      {segmentProperties.map((property) => (
        <FieldFactory key={property.name} property={property} qaIdPrefix={qaIdPrefix} />
      ))}
    </>
  );
};
