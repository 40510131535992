import orderBy from "lodash/orderBy";
import partition from "lodash/partition";
import take from "lodash/take";
import { EXPIRABLE_CUSTOM_TYPES } from "PFApp/constants/expirable_custom_types";
import { useCustomValuePreviewActions } from "PFApp/hooks/use_custom_value_preview_actions";
import { useExperienceConfig } from "PFApp/hooks/use_experience_config";
import { CustomValuePill } from "PFComponents/custom_value_pill";
import { isRankable } from "PFCore/helpers/custom_type";
import { requiresApproval } from "PFCore/helpers/custom_value";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { ActivityPureCustomValue, CustomValueScope, Id, MatchCustomValue, PureCustomValue } from "PFTypes";
import { ReactNode } from "react";

import { CustomValuesListCommonProps, DEFAULT_LIMIT } from "../custom_values_list.consts";
import css from "../custom_values_list.module.scss";
import { useCustomValuesVisibility } from "../hooks/use_custom_values_visibility";
import { CustomValuesDivider } from "../parts/custom_values_divider";
import { CustomValuesWrapper } from "../parts/custom_values_wrapper";
import { useTextWithExpiryDateFormatter } from "./profile_custom_values_list.utils";

export type ProfileCustomValuesListProps = CustomValuesListCommonProps & {
  customValues: (MatchCustomValue | ActivityPureCustomValue | PureCustomValue)[];
  profileId: Id;
  experienceTooltipDisabled?: boolean;
  showLeftIcon?: boolean;
  allowExpiryDate?: boolean;
  renderCustomValueLeftContent?: (customValueId: Id) => ReactNode;
  renderCustomValueRightContent?: (customValueId: Id) => ReactNode;
  onMoreLessButtonClick?: VoidFunction;
  onMissingClick?: (id: Id) => void;
  isBlindModeOn?: boolean;
};

export const ProfileCustomValuesList = ({
  customFieldName,
  customValues,
  profileId,
  maxNameLength,
  limit = DEFAULT_LIMIT,
  dark = false,
  experienceTooltipDisabled = false,
  canEdit = false,
  vertical = false,
  showLeftIcon = true,
  allowExpiryDate = true,
  renderCustomValueLeftContent,
  renderCustomValueRightContent,
  onMissingClick,
  onMoreLessButtonClick,
  isBlindModeOn = false
}: ProfileCustomValuesListProps) => {
  const { data: currentAccount } = useCurrentAccount();
  const { customTypes } = useCustomTypes();
  const { showPreview } = useCustomValuePreviewActions();
  const { formatTextWithExpiryDate } = useTextWithExpiryDateFormatter();
  const { experienceLevels } = useExperienceConfig();

  const [availableCustomValues, missingCustomValues] = partition(
    customValues,
    ({ experience }: MatchCustomValue) => experience !== undefined
  ) as [MatchCustomValue[], (ActivityPureCustomValue | PureCustomValue)[]];

  const sortedAvailableCustomValues = orderBy(
    availableCustomValues,
    [({ top }) => Boolean(top), "value"],
    ["desc", "asc"]
  );

  const sortedMissingCustomValues = orderBy(missingCustomValues, "value");

  const {
    visibleAvailableCustomValuesCount,
    visibleMissingCustomValuesCount,
    showAll,
    displayShowMoreButton,
    toggleShowAll
  } = useCustomValuesVisibility({
    availableCustomValuesCount: sortedAvailableCustomValues.length,
    missingCustomValuesCount: sortedMissingCustomValues.length,
    limit
  });

  const handleMoreLessButtonClick = () => {
    toggleShowAll();
    onMoreLessButtonClick?.();
  };

  const customType = customTypes.find(({ name }) => name === customFieldName);
  const isCustomTypeRankable = Boolean(customType && isRankable(customType));
  const isValidatedIconVisible = currentAccount.profile_view_customization.validated_skill_text;
  const isCustomTypeWithExpiryDate = EXPIRABLE_CUSTOM_TYPES.includes(customType?.name || "");

  return (
    <CustomValuesWrapper
      displayShowMoreButton={displayShowMoreButton}
      showAll={showAll}
      dark={dark}
      limit={limit}
      vertical={vertical}
      onMoreLessButtonClick={handleMoreLessButtonClick}
    >
      {take(sortedAvailableCustomValues, visibleAvailableCustomValuesCount).map((customValue, index) => {
        const isLastCustomValue = index === visibleAvailableCustomValuesCount - 1;
        const shouldSeparateMissingCustomValuesList =
          isLastCustomValue && visibleMissingCustomValuesCount > 0;
        const isPendingApproval = requiresApproval(customType!, customValue);
        const text = String(customValue.value ?? customValue.text);
        const name =
          isCustomTypeWithExpiryDate && allowExpiryDate && customValue.expiry_date
            ? formatTextWithExpiryDate(text, customValue.expiry_date)
            : text;

        return (
          <span className={css.customValueWithDivider} key={customValue.id}>
            <CustomValuePill
              experience={showLeftIcon && isCustomTypeRankable ? customValue.experience : undefined}
              requiredExperience={
                showLeftIcon && isCustomTypeRankable ? customValue.required_experience : undefined
              }
              name={name}
              experienceLevels={experienceLevels}
              customTypeAriaLabel={customType?.display_as}
              isCore={customValue.top}
              isDevelopmental={customValue.developmental}
              isVerified={isValidatedIconVisible && customValue.assessed}
              isVerifiedCredly={isValidatedIconVisible && customValue.assessment_source === "credly"}
              isVerifiedOthers={isValidatedIconVisible && customValue.em_verified}
              isPendingApproval={isPendingApproval}
              maxNameLength={maxNameLength}
              theme={dark ? "dark" : "light"}
              showTooltip={isCustomTypeRankable && !experienceTooltipDisabled}
              customLeftIconName={!isCustomTypeRankable && showLeftIcon ? "check" : undefined}
              customLeftContent={renderCustomValueLeftContent?.(customValue.id)}
              customRightContent={renderCustomValueRightContent?.(customValue.id)}
              onClick={() => {
                showPreview({
                  scope: CustomValueScope.Profile,
                  customTypeId: customType!.id,
                  customValueId: customValue.id,
                  profileId,
                  canEdit,
                  isBlindModeOn
                });
              }}
            />
            {(!isLastCustomValue || shouldSeparateMissingCustomValuesList) && !vertical && (
              <CustomValuesDivider />
            )}
          </span>
        );
      })}
      {take(sortedMissingCustomValues, visibleMissingCustomValuesCount).map((customValue, index) => {
        const isLastCustomValue = index === visibleMissingCustomValuesCount - 1;
        const isPendingApproval = requiresApproval(customType!, customValue);

        return (
          <span className={css.customValueWithDivider} key={customValue.id}>
            <CustomValuePill
              requiredExperience={
                showLeftIcon && isCustomTypeRankable
                  ? (customValue as ActivityPureCustomValue).required_experience
                  : undefined
              }
              name={String(customValue.text)}
              experienceLevels={experienceLevels}
              customTypeAriaLabel={customType?.display_as}
              isPendingApproval={isPendingApproval}
              maxNameLength={maxNameLength}
              theme={dark ? "dark" : "light"}
              showTooltip={isCustomTypeRankable && !experienceTooltipDisabled}
              customLeftIconName={!isCustomTypeRankable && showLeftIcon ? "cross" : undefined}
              customLeftContent={renderCustomValueLeftContent?.(customValue.id)}
              customRightContent={renderCustomValueRightContent?.(customValue.id)}
              onClick={onMissingClick ? () => onMissingClick?.(customValue.id) : undefined}
            />
            {!isLastCustomValue && !vertical && <CustomValuesDivider />}
          </span>
        );
      })}
    </CustomValuesWrapper>
  );
};
