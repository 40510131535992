import isNil from "lodash/isNil";
import Panel from "PFComponents/panel/panel";
import useBookingCategories from "PFCore/hooks/use_booking_categories";
import { BookingCategory, Template } from "PFTypes";
import { useTranslation } from "react-i18next";

import { BookingCategorySelect } from "../../../booking/components/booking_category_select";
import { useActivityEditFormContext } from "../activity_edit_form_context";
import { Note } from "../components/note";
import { useEngagementSchema } from "../hooks/use_engagement_schema";
import css from "./sections.module.scss";

type BookingCategorySectionProps = {
  qaIdPrefix: string;
  template: Template;
  setBookingCategory: (category: BookingCategory) => void;
  bookingCategory?: BookingCategory | null;
  errors: Record<string, string>;
};

export const BookingCategorySection = ({
  template,
  bookingCategory,
  setBookingCategory,
  qaIdPrefix,
  errors
}: BookingCategorySectionProps) => {
  const { t } = useTranslation(["activities", "translation"]);
  const { title } = template.schema.properties.booking_category_id;
  const { selectedParentActivity } = useActivityEditFormContext();
  const { getBookingCategory } = useBookingCategories();

  const engagementSchema = useEngagementSchema();
  const isPropertyLinked = !!engagementSchema?.properties.booking_category_id?.linked_from_engagement;
  const isEqualToParentValue =
    selectedParentActivity && bookingCategory?.id === selectedParentActivity.booking_category_id;
  const isLinked = isPropertyLinked && isEqualToParentValue;
  const canLink = isPropertyLinked && !isNil(selectedParentActivity?.booking_category_id);
  const handleRestore = () => {
    const engagementCategory = getBookingCategory(selectedParentActivity?.booking_category_id);
    engagementCategory && setBookingCategory(engagementCategory);
  };
  const required = template.schema.required?.includes("booking_category_id");
  const headerTitle = `${title}${required ? " *" : ""}`;

  return (
    <Panel className={css.wrapper} qaId={`${qaIdPrefix}-bookingCategory`}>
      <div className={css.header}>{headerTitle}</div>
      <BookingCategorySelect
        onCategoryChange={setBookingCategory}
        category={bookingCategory ?? null}
        label={t("translation:selectEllipsis")}
        error={errors["booking_category_id"]}
        tip={isLinked ? t("activities:edit.engagementLink.valueLinked") : undefined}
        demand
        letClear
        restrictedTextWidth
        onRestore={!isLinked && canLink ? handleRestore : undefined}
        required={required}
      />
      <Note>{t("activities:edit.sections.addBookingCategoryDescription")}</Note>
    </Panel>
  );
};
