import classNames from "classnames";
import { getFilterQuery } from "PFApp/use_filtered_collection/format_filters";
import { Filter, Id, Meta, Value } from "PFTypes";
import { PageTarget } from "PFTypes/saved_filters";
import { RefObject } from "react";

import AnyFilter from "../any_filter";
import { useSavedViewsContext } from "../saved_views_context/saved_views_context";
import css from "./filters_panel.module.scss";

export type ExternalFiltersData = {
  onFocus: (filter: object) => void;
  onBlur: () => void;
  lastFocusedFilterNameRef: RefObject<string | null>;
};

type ExternalFilter = {
  element: React.ReactNode;
  id: Id;
};

export type ExternalFiltersProps = {
  renderExternalFilters?: (data: ExternalFiltersData) => ExternalFilter[];
};

type FiltersElementProps = {
  filters: Filter[];
  onFocus: (filter: object) => void;
  onBlur: () => void;
  style?: React.CSSProperties;
  lastFocusedFilterNameRef: RefObject<string | null>;
  viewsKey: PageTarget | null;
  disabled?: boolean;
  classes?: {
    list?: string;
  };
  portalRef?: React.RefObject<HTMLDivElement | null>;
  shouldUseChildrenFilters: boolean;
} & FiltersBaseProps &
  ExternalFiltersProps;

export type FiltersBaseProps = {
  clearRefValue?: number | string;
  fetchOptions?: (filter: any, props?: Record<string, any>) => void;
  meta?: Partial<Meta>;
  narrow?: boolean;
  onFilterChange: (filter: Filter, valueId: Value, filterValue: any) => void;
};

export const FiltersElement = ({
  filters,
  renderExternalFilters,
  clearRefValue,
  style,
  fetchOptions,
  lastFocusedFilterNameRef,
  meta,
  narrow,
  onFilterChange,
  onFocus,
  onBlur,
  viewsKey,
  portalRef,
  disabled,
  classes = {},
  shouldUseChildrenFilters
}: FiltersElementProps) => {
  const { setSelectedViewId } = useSavedViewsContext(viewsKey);
  const scope = meta?.counters?.filtered_profiles_count;

  return (
    <ul className={classNames(css.list, classes.list)} key={clearRefValue} style={style}>
      {filters.map((filter, index) => {
        const fetchOptionsWrapper = fetchOptions
          ? (props?: Record<string, any>) => fetchOptions(filter, props)
          : null;
        const query =
          fetchOptionsWrapper || filter.query || getFilterQuery(filter, meta, shouldUseChildrenFilters);

        return (
          <li
            className={classNames(css.item, filter.name && css[filter.name], { [css.widerItem]: narrow })}
            key={`${index}:${filter.name}`}
            data-qa-id={`filters--${filter.name}`}
          >
            <AnyFilter
              /* @ts-ignore */
              scope={scope}
              /* @ts-ignore */
              filter={{ ...filter, query }}
              handleChange={(value, valueObject) => {
                setSelectedViewId(null);
                onFilterChange(filter, value, valueObject);
              }}
              autofocus={lastFocusedFilterNameRef.current === filter.name}
              searchId={meta?.searchId || meta?.search_id}
              handleFocus={() => onFocus(filter)}
              handleBlur={onBlur}
              portalRef={portalRef}
              disabled={disabled}
            />
          </li>
        );
      })}
      {renderExternalFilters &&
        renderExternalFilters({ lastFocusedFilterNameRef, onBlur, onFocus })?.map(({ element, id }) => (
          <li className={classNames(css.item, { [css.widerItem]: narrow })} key={`${id}:external-filter`}>
            {element}
          </li>
        ))}
    </ul>
  );
};
