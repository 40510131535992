import { useDraggable } from "@dnd-kit/core";

import { Skill, SkillProps } from "../../common/skill";
import css from "../skills_modal.module.scss";

type DraggableSkillProps = {
  index: number;
  parentSection?: string;
  portalElement?: HTMLDivElement;
} & SkillProps;

export const DraggableSkill = (props: DraggableSkillProps) => {
  const { skill, parentSection, index, ...rest } = props;

  const { attributes, listeners, isDragging, setNodeRef } = useDraggable({
    id: `draggable-${skill.id}`,
    data: {
      skill,
      parentSection
    }
  });

  return (
    <div ref={setNodeRef} className={css.draggableSkill} style={{ opacity: isDragging ? 0 : 1 }}>
      <Skill skill={skill} dragHandleProps={{ ...listeners, ...attributes }} {...rest} />
    </div>
  );
};
